import React, { useEffect } from "react"

import { MadeWithZelf } from "./madeWithZelf"
import { Footer } from "./footer"

const OnboardingCreatorContainer = ({ className = "", cardClassName = "", children }) => {
  return (
    <>
      <div
        className={`onboarding-page-creator-specific-container mobile-nc00-bg  full-min-height-without-header-and-footer-onboarding ${className}`}
      >
        <div
          className={`onboarding-page-creator-specific-card border-1 line-shadow nc10-border padding-3x margin-top-11x nc00-bg flex column a-center  ${cardClassName}`}
        >
          {children}
        </div>
        <MadeWithZelf className={"margin-top-12x  margin-top-12x hide-on-mobile"} />
      </div>
      {/* <Footer/> */}
    </>
  )
}

const useOnboardingUrlQueryParamHelper = () => {}

export { OnboardingCreatorContainer }
