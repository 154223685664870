import React from "react"
import { IfCreator } from "../functional/auth_components"
import { useNavigate } from "react-router-dom"

const BackgroundGiftIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="62" height="64" fill="none" viewBox="0 0 62 64">
    <g clipPath="url(#a)" opacity=".3">
      <path
        fill="#fff"
        d="M25.832 12.002c.487-3.032 2.494-5.573 5.267-6.675l.19-.076c4.225-1.679 8.967.497 10.592 4.861s-.481 9.262-4.706 10.941l-6.258 2.487 2.676 7.183-5.563 2.21-2.675-7.182-6.258 2.487c-4.224 1.68-8.966-.497-10.592-4.86-1.625-4.364.482-9.263 4.706-10.942l.191-.076c2.773-1.102 5.911-.606 8.24 1.307l3.466 2.85.724-4.515Zm-6.974 5.28a3.828 3.828 0 0 0-3.85-.612l-.192.076c-1.92.763-2.877 2.99-2.139 4.973.74 1.984 2.894 2.973 4.815 2.21l6.37-2.532-5.004-4.115Zm10.341 1.994 6.371-2.533c1.921-.763 2.878-2.989 2.14-4.973-.74-1.983-2.894-2.973-4.815-2.21l-.191.077c-1.295.514-2.23 1.701-2.46 3.12l-1.045 6.519Zm7.392 36.643-7.49-20.112 5.562-2.21 7.49 20.11-5.562 2.212Z"
      />
      <path
        fill="#fff"
        d="m19.097 28.238 6.258-2.487 2.675 7.183-16.688 6.633c-1.536.61-3.26-.179-3.851-1.768l-2.14-5.746c-.593-1.59.175-3.368 1.71-3.979l3.616-1.437c2.219 2.056 5.447 2.783 8.42 1.601ZM50.28 24.09l-16.688 6.633-2.676-7.183 6.258-2.487c2.973-1.181 4.894-3.955 5.21-7.018L46 12.598c1.538-.612 3.26.178 3.852 1.768l2.14 5.746c.592 1.589-.173 3.367-1.711 3.978ZM12.412 42.44 29.1 35.807l7.491 20.112-12.516 4.974c-2.304.916-4.89-.272-5.777-2.651L12.412 42.44Zm29.742 11.268-7.491-20.112 16.688-6.632 5.885 15.802c.887 2.379-.263 5.052-2.566 5.967l-12.516 4.975Z"
        opacity=".4"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h47.887v49.055H0z" transform="matrix(.9293 -.36935 .34904 .9371 0 17.687)" />
      </clipPath>
    </defs>
  </svg>
)

const DollarSign = (
  <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="circle-dollar-duotone 1" opacity="0.3" clipPath="url(#clip0_406_9990)">
      <path
        id="Vector"
        d="M41.418 19.4554C42.7218 19.9772 43.3509 21.446 42.829 22.7498L42.2091 24.2986C43.4109 24.9616 44.5186 25.8599 45.6164 26.6974C46.7339 27.5427 46.9512 29.131 46.0961 30.2445C45.2507 31.362 43.6625 31.5793 42.5489 30.7242C41.7196 30.0965 40.8982 29.4493 40.0219 28.8825C38.7005 28.0351 36.983 27.4956 35.4045 27.5462C33.9908 27.5832 32.4985 28.4417 33.5119 29.8595C34.4724 31.2107 35.9663 32.1953 37.2484 33.1975C38.7619 34.3606 40.6812 35.8453 41.8651 37.6044C43.4019 39.9027 43.4503 42.6517 41.9675 44.9926C40.5494 47.257 38.2002 48.1819 35.8316 48.1893C34.8512 48.195 33.8689 47.9497 32.8199 47.7573L32.1568 49.414C31.635 50.7178 30.1662 51.3469 28.8624 50.825C27.5586 50.3032 26.9295 48.8345 27.4514 47.5307L28.1204 45.6034C26.7716 44.6541 25.4267 43.5244 24.0976 42.4123C23.0272 41.5062 22.8942 39.9062 23.7866 38.8417C24.6927 37.7713 26.2044 37.603 27.3572 38.5307C28.6609 39.6212 29.9547 40.8214 31.4094 41.6766C33.1523 42.6927 34.6993 43.1185 35.8267 43.1148C37.3385 43.1171 38.576 41.8154 37.6547 40.4231C36.6982 39.0052 35.1533 37.9774 33.8222 36.9556C32.3636 35.8259 30.5463 34.4275 29.3937 32.8174C27.8118 30.6034 27.6437 27.8975 29.105 25.5821C30.4898 23.3726 32.9742 22.5359 35.2625 22.4737C35.9625 22.4581 36.6919 22.5112 37.333 22.5859L38.0354 20.8311C38.5572 19.5273 40.1142 18.9335 41.3297 19.4201L41.418 19.4554Z"
        fill="white"
      />
      <path
        id="Vector_2"
        opacity="0.4"
        d="M10.0444 25.0957C15.5924 11.2343 31.3231 4.49646 45.1846 10.0444C59.0461 15.5924 65.7839 31.3232 60.2359 45.1846C54.6879 59.0461 38.9572 65.7839 25.0957 60.2359C11.2342 54.6879 4.49644 38.9572 10.0444 25.0957ZM42.829 22.7497C43.3508 21.4459 42.7217 19.9772 41.3297 19.42C40.1141 18.9335 38.5572 19.5273 38.0353 20.8311L37.333 22.5858C36.6918 22.5112 35.9624 22.4581 35.2624 22.4736C32.9742 22.5359 30.4897 23.3726 29.105 25.5821C27.6437 27.8975 27.8118 30.6034 29.3936 32.8174C30.5462 34.4275 32.3636 35.8259 33.8221 36.9556C34.0299 37.1184 34.2613 37.2792 34.4789 37.4459C35.6316 38.3167 36.8805 39.1464 37.6547 40.423C38.576 41.8154 37.3385 43.1171 35.8267 43.1148C34.6993 43.1185 33.1523 42.6926 31.4094 41.6766C30.2233 40.9744 29.1372 40.0506 28.0707 39.1347C27.8316 38.9367 27.5963 38.7288 27.3571 38.5307C26.2044 37.603 24.6926 37.7713 23.7865 38.8417C22.8941 39.9062 23.0271 41.5062 24.0975 42.4123C25.4267 43.5243 26.7715 44.654 28.2086 45.6386L27.4513 47.5306C26.9295 48.8344 27.5586 50.3032 28.8624 50.825C30.1662 51.3469 31.6349 50.7178 32.1568 49.414L32.8199 47.7572C33.8688 47.9496 34.8511 48.1949 35.8315 48.1893C38.2001 48.1819 40.5493 47.2569 41.9674 44.9925C43.4503 42.6517 43.4018 39.9027 41.8651 37.6043C40.6811 35.8453 38.7619 34.3606 37.2484 33.1975C37.0347 33.021 36.8073 32.8504 36.4917 32.6444C35.4683 31.8481 34.3156 30.9773 33.5119 29.8595C32.4985 28.4416 33.9908 27.5831 35.4045 27.5462C36.9829 27.4955 38.7005 28.0351 40.0218 28.8824C40.7472 29.3434 41.4255 29.8651 42.0941 30.3829C42.2509 30.5025 42.3979 30.6182 42.5489 30.7241C43.6624 31.5793 45.2507 31.362 46.096 30.2445C46.9512 29.131 46.7338 27.5427 45.6164 26.6974C45.4341 26.5561 45.2615 26.4189 45.0792 26.2776C44.1578 25.5676 43.2168 24.8498 42.2091 24.2986L42.829 22.7497Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_406_9990">
        <rect width="54.0624" height="54.0624" fill="white" transform="translate(20.0889) rotate(21.8135)" />
      </clipPath>
    </defs>
  </svg>
)

const giftIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="currentColor" viewBox="0 0 24 25">
    <path d="m12 6.947.797-1.361c.598-.93 1.46-1.417 2.59-1.461h.066c.82.022 1.505.31 2.059.863.553.554.84 1.24.863 2.059 0 .465-.11.907-.332 1.328h.863c.443.022.82.177 1.13.465.287.31.442.686.464 1.129v2.125c-.044.73-.398 1.228-1.063 1.494V19c-.022.598-.232 1.096-.63 1.494-.399.399-.897.609-1.494.631H6.688c-.598-.022-1.096-.232-1.495-.63-.398-.4-.608-.897-.63-1.495v-5.412c-.665-.266-1.019-.764-1.063-1.494V9.969c.022-.443.177-.82.465-1.13.31-.287.686-.442 1.129-.464h.863a2.813 2.813 0 0 1-.332-1.328c.022-.82.31-1.505.863-2.059.554-.553 1.24-.84 2.059-.863h.066c1.13.044 1.992.531 2.59 1.46L12 6.948Zm1.063 3.022v2.125h5.843V9.969h-5.843Zm-2.126 0H5.095v2.125h5.843V9.969Zm0 3.719h-4.78V19c.021.332.198.51.53.531h4.25v-5.843Zm2.126 5.843h4.25c.332-.022.509-.199.53-.531v-5.313h-4.78v5.844Zm2.39-11.156c.376 0 .686-.133.93-.398.265-.244.398-.554.398-.93s-.133-.686-.398-.93a1.206 1.206 0 0 0-.93-.398h-.066c-.532.022-.93.254-1.196.697L13.03 8.375h2.424Zm-4.482 0L9.84 6.416a1.46 1.46 0 0 0-1.228-.697h-.066a1.21 1.21 0 0 0-.93.398 1.206 1.206 0 0 0-.398.93c0 .376.133.686.398.93.244.265.554.398.93.398h2.424Z" />
  </svg>
)

export const ReferralBanner = ({ className }) => {
  const navigate = useNavigate()

  const inviteNowButtonHandler = () => {
    navigate("/create-your-referral")
  }

  return (
    <IfCreator>
      <div className={`${className} cursor-pointer`} onClick={inviteNowButtonHandler}>
        <div className="referral-banner-wrapper banner-serpated-mobile-desktop">
          <div className="referral-banner-background-gift-icon">{BackgroundGiftIcon}</div>
          <div className="referral-banner-background-dollar-icon">{DollarSign}</div>
          <div>
            <div className="flex j-space-between a-center">
              <div className="flex a-center">
                <div>{giftIcon}</div>
                <span className="txt-center button-large-font margin-left-2x hide-on-desktop">
                  Earn 10% of what your
                  <br />
                  friends earn, extra!
                </span>
                <span className="txt-center button-large-font margin-left hide-on-mobile">
                  Invite your friends, earn
                  <br />
                  10% of what they earn
                </span>
              </div>

              <button className="referral-banner-button" onClick={inviteNowButtonHandler}>
                Invite Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </IfCreator>
  )
}
