const ProgressBar = ({
  progress = 50,
  height = 5,
  showPercent = false,
  percentPosition = "left",
  percentClassName = "",
  className = "",
  barClassName = "",
  fillClassName = "",
}) => {
  return (
    <div className={`flex vcenter gap-3 ${className}`}>
      {showPercent && percentPosition === "left" && <span className={`${percentClassName}`}>{progress}%</span>}
      <div className={`full border-radius-capsule rb20-bg ${barClassName}`} style={{ height: `${height}px` }}>
        <div
          className={`border-radius-capsule rb100-bg ${fillClassName}`}
          style={{ width: `${progress > 100 ? 100 : progress}%`, height: `${height}px` }}
        ></div>
      </div>
      {showPercent && percentPosition === "right" && <span className={`${percentClassName}`}>{progress}%</span>}
    </div>
  )
}

export { ProgressBar }
