import React, { useState, useContext, useEffect } from "react"
import { Radio } from "./../radio"
import { Spacer } from "./../spacer"
import { Button } from "./../button"
import OnboardingImage from "../../assets/onboarding-start.jpg"
import { apiContext } from "../../contexts/apicontext"
import { _lsw } from "../../helpers/utils"

const details = {
  brand: `You must use a brand email address. Personal emails are not allowed.`,
  agency: `You must use an official email address. Personal emails are not allowed.`,
}
export const OnboardingStartPage = (props) => {
  const [selected, setSelected] = useState("creator")
  const api = useContext(apiContext)

  useEffect(() => {
    _lsw("cs_last_set_mode", selected)
    api.analytics.action("user", `onboarding_set_role`, selected)
  }, [selected])

  useEffect(() => {
    api.analytics.page(`/`, "Login/Signup page")
  }, [])

  return (
    <div className="onboarding-page-container full-min-height-without-header-and-footer-onboarding">
      <div className="onboarding-mobile-background hide-on-desktop bg-purple"></div>

      <div className="onboarding-card">
        <div className="onboarding-start-left-side">
          <div className="onboarding-img-container">
            <img className="onboarding-img" alt="onboarding" src={OnboardingImage} loading="lazy" />
          </div>
        </div>
        <div className="onboarding-start-right-side flex column a-center">
          <Spacer height={64} />
          <div className="flex column a-center">
            <h3 className="onboading-start-header headline-xlarge txt-center nc100-fg">Register Your Account</h3>
          </div>
          <Spacer height={48} />
          <div className="onboarding-group">
            <Radio
              label="I am a Creator/ Influencer"
              value="creator"
              selected={selected === "creator"}
              justify="center"
              onClick={() => setSelected("creator")}
            />
            <Spacer height={8} />
            <Radio
              label="I Represent a Brand"
              value="brand"
              selected={selected === "brand"}
              justify="center"
              onClick={() => setSelected("brand")}
            />
            <Spacer height={8} />
            <Radio
              label="I Represent a Brand Agency"
              value="agency"
              selected={selected === "agency"}
              justify="center"
              onClick={() => setSelected("agency")}
            />

            <Spacer className="hide-on-mobile" height={24} />
            <Spacer className="hide-on-desktop" height={32} />
            {details[selected] ? (
              <p className="body-regular-font nc50-fg txt-center margin-bottom-2x">{details[selected]}</p>
            ) : null}
            <Button type="primary" fluid size="large" onClick={() => api.login(true)}>
              Continue
            </Button>
            <Spacer className="hide-on-mobile" height={72} />
            <Spacer className="hide-on-desktop" height={24} />

            <p className="nc50-fg body-regular-font txt-center">
              Already a member?{" "}
              <span
                className="primary-fg  body-highlight-font cursor-pointer"
                onClick={() => api.login(false)}
                role="button"
              >
                Sign In
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

OnboardingStartPage.propTypes = {}
