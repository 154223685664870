import { Tabs } from "../tabs"
import { Tab } from "../tab"
import { useContext, useEffect } from "react"
import { dataContext } from "../../contexts/datacontext"
import React from "react"
import { num2Hip, suggestSort } from "../../helpers/utils"
import { PlayLargeOutlinedMono, SearchMediumFilledMono, SqaureLargeFilledMono, VerticalLargeFilledMono } from "../Icons"
import { platforms, media_types } from "../../consts"
import { PlatformIcon } from "../dumbs"
import { Input } from "../input"
import { apiContext } from "../../contexts/apicontext"

const tab_icons = {
  FEED: SqaureLargeFilledMono,
  REEL: PlayLargeOutlinedMono,
  VIDEO: PlayLargeOutlinedMono,
  SHORT: VerticalLargeFilledMono,
}

function CreatorTabBar() {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  useEffect(() => {
    const platform_contents = media_types[data.creator_current_platform]
    if (platform_contents?.length) {
      dispatch({ creator_platform_content: platform_contents[Object.keys(platform_contents)[0]].slug })
    } else dispatch({ creator_platform_content: "all" })
  }, [data.creator_current_platform])

  useEffect(() => {
    if (data?.creator_social_list?.length) dispatch({ creator_current_platform: data.creator_social_list[0] })
  }, [data.creator_social_list])

  const changeCreatorTab = (tab) => {
    api.analytics.button("clicks_platform_portfolio", tab)
    dispatch({ creator_current_platform: tab })
  }
  const changeCreatorPlatformContent = (content) => {
    dispatch({ creator_platform_content: content })
  }
  const changeCreatorSearchText = (e) => dispatch({ creator_content_search_raw: e.target.value })
  const doSearch = (e, direct = false) => {
    if (e?.key === "Enter" || direct) {
      dispatch({ creator_search_filter: data.creator_content_search_raw })
    }
  }
  const _media = media_types[data.creator_current_platform]
  if (!data?.creator_social_list?.length) return null
  return (
    <>
      <div className="creator-social-tabs cs-homepage-shrinked-top-padding flex">
        <Tabs>
          {data?.creator_social_list?.map((item) => (
            <React.Fragment key={item}>
              <Tab
                className="social-tab"
                type="capsules"
                icon={PlatformIcon({ platform: item, color: data.creator_current_platform === item })}
                title={platforms[item].title}
                selected={data.creator_current_platform === item}
                onChange={() => changeCreatorTab(item)}
              />
            </React.Fragment>
          ))}
        </Tabs>
        <div className="creator-search-box-homepage margin-left-auto hide-on-mobile">
          {/* <Input type="text"
                    className="creator-search-box full" 
                    value={data.creator_content_search_raw}
                    onKeyUp={doSearch}
                    onChange={changeCreatorSearchText}
                    onSecondIconClick={e=>doSearch(e, true)}
                    placeholder={`Search photo, videos from ${data?.creator?.name || data?.creator?.username}`} 
                    secondIconClasses="cursor-pointer"
                    secondIconElement={SearchMediumFilledMono}/> */}
        </div>
      </div>
      {media_types?.[data.creator_current_platform]?.length ? (
        <div className="creator-social-content-tab flex hcenter margin-top-6x">
          <Tabs>
            {Object.keys(media_types?.[data.creator_current_platform]).map((item, idx) => (
              <React.Fragment key={item}>
                <Tab
                  type="capsules"
                  className="social-tab"
                  title={_media[item].title}
                  icon={tab_icons[_media[item].slug]}
                  selected={data.creator_platform_content === _media[item].slug}
                  onChange={() => changeCreatorPlatformContent(_media[item].slug)}
                />
              </React.Fragment>
            ))}
          </Tabs>
        </div>
      ) : null}
    </>
  )
}

export { CreatorTabBar }
