import { _nopropagate } from "../../../helpers/utils"
import { useBDPostData } from "../../../hooks/useBoundData"
import { useToast } from "../../../hooks/useToast"
import { ArchiveLargeMonoOutline, BanSolid, RestoreIcon } from "../../Icons"
import { Card, ContentCloud, ProfilePictureCloud, StatBlock } from "../../dumbs"
import { Loader } from "../../loaders"

function CampaignSummary({ campaign, className = "", onClick, onArchiveClick, showArchiveIcon }) {
  const profilePictures = campaign?.creators?.map((x) => x?.profile_picture_url)
  const thumbnails = (campaign?.thumbnails ?? []).filter((x) => Boolean(x))
  const { executeCall: updateCampaign, loader: update_campaign_loader } = useBDPostData({
    section: "update_single_campaign",
    uniqueSectionPostfix: campaign?.id,
  })
  const toast = useToast()
  const onArchiveOrRestore = async (e) => {
    _nopropagate(e)
    const res = await updateCampaign({
      is_archived: !campaign?.is_archived,
    })
    if (!res) {
      toast("Some went wrong while updating campaign", BanSolid)
      return
    }
    onArchiveClick(campaign)
  }

  return (
    <Card onClick={onClick} className={`padding-5x cursor-pointer ${className}`}>
      <div className="campaign-summary-top-section flex j-space-between">
        <div className="headline-medium-highlight">{campaign.name}</div>
        <div className="flex a-center ">
          <ProfilePictureCloud total={campaign?.creator_count} images={profilePictures} />
          {update_campaign_loader ? (
            <Loader className="margin-left-5x" />
          ) : (
            <div
              role="button"
              onClick={onArchiveOrRestore}
              className="margin-left-5x max-width-40 border-1 min-width-40 max-height-40 min-height-40 flex a-center j-center nc00-bg border-radius-round nc10-border nc50-fg cursor-pointer"
            >
              {showArchiveIcon ? ArchiveLargeMonoOutline : RestoreIcon}
            </div>
          )}
        </div>
      </div>
      <div className="campaign-summary-content-stats-section margin-top-6x flex j-space-between">
        <ContentCloud total={campaign.total_posts} cutoff={5} images={campaign?.thumbnails ?? []} />
        <div className="stat-section-campaign-summary flex vcenter ">
          <StatBlock title="Creators total" data={campaign?.creator_count} />
          <StatBlock title="Posts total" data={campaign?.content_count} />
          {/* <StatBlock title="Ads total" data={campaign?.total_ads}/> */}
          {/* <StatBlock title="Total views" data={86666}/> */}
          <StatBlock separator={true} title="Total Engagement" data={campaign?.total_engagement} />
          <StatBlock
            className="padding-x-4x"
            separator={true}
            hip={false}
            title={
              <>
                Cost per
                <br /> engagement
              </>
            }
            data={campaign?.cost_per_engagement}
            isCent={true}
          ></StatBlock>
          <StatBlock
            className="padding-x-4x"
            separator={false}
            hip={false}
            title={<>CPM</>}
            data={campaign?.cost_per_view ? campaign?.cost_per_view * 1000 : null}
            isCent={true}
          ></StatBlock>
        </div>
      </div>
    </Card>
  )
}

export { CampaignSummary }
