import React, { useContext, useEffect, useState } from "react"
import { apiContext } from "../../../contexts/apicontext"
import { GET_CALLS } from "../../../consts"
import { dataContext } from "../../../contexts/datacontext"
import { capitalizeFirstLetter, convertToPercentage, formatDate, getSubtractedDate, moveIdsToFront, num2Hip } from "../../../helpers/utils"
import { useDebounce } from "../../../hooks/helperHooks"
import { useAuthHelper } from "../../../hooks/useAuthHelper"
import { useBDBoundData, useBDPostData, useLocalBoundData } from "../../../hooks/useBoundData"
import { SearchMediumFilledMono } from "../../Icons"
import { Button } from "../../button"
import { Card, Collapsible, ExpandButtonInline, WrappedInLoader } from "../../dumbs"
import { ScrollSpy } from "../../scrollspy"
import { Toggle } from "../../toggle"
import { DiscoveryPlatformFilter, SubBrandFilterTabs } from "../contentAndCreatorFilter"
import {
  BECreatorList,
  BELocalContentList,
  ContentOverviewSection,
  PublicVSOwnedStats,
} from "./beSections"
import { KVMDataParcer } from "../../pages/brandStats"
import { BarChart } from "../../ui/graphs"
import { POLITICAL_CANDIDATE_NAME_SERIAL } from "../../../consts"
import { DateRangePicker } from "../../input"
import { FullLoader } from "../../loaders"

function CreatorSection() {
  const [data, dispatch] = useContext(dataContext)

  return (
    <>
      <SubBrandFilterTabs className={"margin-top-7x"}></SubBrandFilterTabs>
      {!data?.be_section_view_all ? <DiscoveryPlatformFilter className="margin-top-6x margin-bottom-4x" /> : null}

      <BECreatorList
        guestBlurAfter={8}
        useTimeframeStats
        cutoffAt={4}
        mini={true}
        section="top_creators_by_brand"
        className="margin-top-6x"
        title={`Top Creators`}
        showStats={true}
        showFilter
        forceViewAllButton
      ></BECreatorList>
      <BECreatorList
        guestBlurAfter={8}
        useTimeframeStats
        cutoffAt={4}
        mini={true}
        section="new_creators_by_brand"
        className="margin-top-6x"
        title={`Newly discovered creators`}
        showStats={true}
        showFilter
        forceViewAllButton
      ></BECreatorList>
      <BECreatorList
        guestBlurAfter={8}
        useTimeframeStats
        cutoffAt={4}
        mini={true}
        section="influencers_by_brand"
        className="margin-top-6x"
        title={`Influencers`}
        showStats={true}
        showFilter
        forceViewAllButton
      ></BECreatorList>
      <BECreatorList
        guestBlurAfter={8}
        useTimeframeStats
        cutoffAt={4}
        mini={true}
        section="all_creators_by_brand"
        className="margin-top-6x"
        title={`All creators`}
        showStats={true}
        showFilter
        forceViewAllButton
      ></BECreatorList>
    </>
  )
}

const getContentDropDownSectionName = (viewAllSectionName = "", currentSubsection) => {
  if (!viewAllSectionName || viewAllSectionName == "all_posts") {
    return `discovery_analytics_${currentSubsection}`
  }
  console.log(`${viewAllSectionName}_${currentSubsection}`)
  return `${viewAllSectionName}_${currentSubsection}`
}

function ContentSection() {
  const [fromDate, setFromDate] = useState(getSubtractedDate(new Date(), 29, 'YYYY-MM-DD'))
  const [toDate, setToDate] = useState(formatDate(new Date(), 'YYYY-MM-DD'))

  const [data, dispatch] = useContext(dataContext)
  const [detailOpen, sDO] = useState(false)
  const [subSection, setSubSection] = useState("owned")
  const [selectedSubBrandType, setSelectedSubBrandType] = useState(null)
  const analytics_section = `discovery_analytics_${data?.be_section_view_all || "all_posts"}`
  const { loader: all_stats_loader, payload: all_stats_payload } = useLocalBoundData({
    ...GET_CALLS["discovery_analytics_all_posts"],
    filter: {
      ...GET_CALLS["discovery_analytics_all_posts"].filter,
      post_date__gte: fromDate,
      post_date__lte: toDate,
    },
    platform: data?.discovery_section_platform,
    sub_brand_id: data?.sub_brand_id,
  })

  const titles = {
    discovery_analytics_all_posts: "",
    discovery_analytics_trending_posts: "(Trending Posts)",
    discovery_analytics_new_posts: "(New posts)",
  }
  // get query params
  const queryParams = new URLSearchParams(window.location.search)

  const competitors = data?.me?.brand?.sub_brands?.filter((x) => x?.sub_brand_type === 1) ?? []

  const isViewAll = !!data?.be_section_view_all || data?.be_section_view_all == "all_posts"

  useEffect(() => {
    if (data?.sub_brand_id) {
      const brand = data?.me?.brand?.sub_brands?.find((x) => x.id == data?.sub_brand_id)
      setSelectedSubBrandType(brand?.sub_brand_type ?? null)
    }
  }, [data?.sub_brand_id])

  const itemFilterFunction = (contentPayload) => {
    const removeList = data?.deleted_from_discovery ?? []
    return !removeList.some((removed_content_id) => removed_content_id == contentPayload.content.id)
  }

  const competitorMode = queryParams.get("competitorMode")
  const isTrending = queryParams.get("trending")
  const trendingCategoryId = queryParams.get("trending_category")
  const trendingSubCategoryId = queryParams.get("trending_sub_category")

  const trendingCategory = data?.me?.god_view_brands?.find((x) => x.category_id == trendingCategoryId)
  const trendingSubCategory = trendingCategory?.sub_categories?.find((x) => x.sub_category_id == trendingSubCategoryId)

  const _filters = {
    owned: {
      is_owned_by_brand: true

    },
    influencers: {
      is_influencer_content: true
    },
    public: {
      is_from_organic_search: true
    }
  }

  return (
    <>
      <SubBrandFilterTabs showAllCompetitors showTrending className={"margin-top-7x"} />
      {!data?.be_section_view_all && (
        <div className="flex j-space-between margin-top-6x margin-bottom-4x">
          {/* <h1 className="headline-large">Discover Trending Content {trendingSubCategory || trendingSubCategory ? 'About' : ''} { trendingSubCategory?.name ?? trendingCategory?.name ?? "Across Zelf" }</h1> */}
          <DateRangePicker fromDate={fromDate} toDate={toDate} onChange={(from, to) => { setFromDate(from); setToDate(to) }} />
          
          <DiscoveryPlatformFilter skipYoutube={data?.me?.brand?.settings?.is_political_dashboard_enabled} />
        </div>
      )}
      {(isViewAll || competitorMode || isTrending) ? null : (
        <Card className={"circle-stat-block margin-y-3x"}>
          <PublicVSOwnedStats
            skipCreatorPublic
            payload={all_stats_payload}
            loader={all_stats_loader}
            ownedPostsSection={"discovery_analytics_all_posts_owned"}
            influencersPostSection={"discovery_analytics_all_posts_influencers"}
            skipOwnedSummary={isViewAll || selectedSubBrandType == 2}
            skipInfluencerSummery={selectedSubBrandType == 2}
            timeFrameString={titles[analytics_section]}
            isYoutube={data?.discovery_section_platform === "youtube"}
            fromDate={fromDate}
            toDate={toDate}
          />
          <hr className="hr" />
          <div
            className={`${detailOpen ? "nc10-border border-top-1 border-bottom-1" : ""} padding-y-4x padding-x-6x flex vcenter nc75-fg button-medium-font`}
          >
            {detailOpen ? (
              <>
                {!data?.be_section_view_all ? (
                  <>
                    <div
                      className={`cursor-pointer  ${subSection === "owned" ? "" : "nc30-fg"}`}
                      onClick={() => {
                        setSubSection("owned")
                      }}
                    >
                      Owned posts
                    </div>
                    <div className="nc30-fg margin-x-2x button-regular-font">/</div>
                  </>
                ) : null}
                <div
                  className={`cursor-pointer ${subSection === "influencers" ? "" : "nc30-fg"}`}
                  onClick={() => {
                    setSubSection("influencers")
                  }}
                >
                  Influencer posts
                </div>
                <div className="nc30-fg margin-x-2x body-regular-font">/</div>
                <div
                  className={`cursor-pointer ${subSection === "public" ? "" : "nc30-fg"}`}
                  onClick={() => {
                    setSubSection("public")
                  }}
                >
                  Earned posts
                </div>
              </>
            ) : null}
            <div className="full"></div>
            {!all_stats_loader || !all_stats_payload ? (
              <ExpandButtonInline trigger={detailOpen} onClick={() => sDO(!detailOpen)} />
            ) : null}
          </div>
          <Collapsible expanded={detailOpen}>
            <ContentOverviewSection
              guestBlurAfter={10}
              section={"general_contents"}
              fromDate={fromDate}
              toDate={toDate}
              customFilter={{ ..._filters[subSection], mention_type__not: 2 }}
            />
          </Collapsible>
        </Card>
      )}

      {competitorMode ? (
        <CompetitorModeContents
          isViewAll={isViewAll}
          competitors={competitors}
          competitorMode={competitorMode}
          isPolitical={data?.me?.brand?.settings?.is_political_dashboard_enabled}
          itemFilterFunction={itemFilterFunction}
          mainBrandId={data?.me?.brand?.id}
          fromDate={fromDate}
          toDate={toDate}
          platform={data?.discovery_section_platform}
        />
      ) : isTrending ? (
        <TrendingContents
          category_id={trendingCategoryId}
          sub_category_id={trendingSubCategoryId}
          fromDate={fromDate}
          toDate={toDate}
        />
      ) : (
        <>
          <BELocalContentList
            itemFilterFunction={itemFilterFunction}
            showContentCountInTitle
            onlyWhenData
            forceViewAllButton
            cutoffAt={5}
            guestBlurAfter={20}
            section="trending_posts"
            className="margin-top-6x"
            title="Trending posts"
            showFilter
            showStats
            fromDate={fromDate}
            toDate={toDate}
          />
          <BELocalContentList
            itemFilterFunction={itemFilterFunction}
            showContentCountInTitle
            onlyWhenData
            forceViewAllButton
            cutoffAt={5}
            guestBlurAfter={20}
            section="negative_nps_posts"
            className="margin-top-6x"
            title="Negative posts"
            showFilter
            showStats
            fromDate={fromDate}
            toDate={toDate}
          />
          <BELocalContentList
            itemFilterFunction={itemFilterFunction}
            showContentCountInTitle
            onlyWhenData
            forceViewAllButton
            cutoffAt={5}
            guestBlurAfter={20}
            section="owned_posts"
            className="margin-top-6x"
            title="Owned posts"
            showFilter
            showStats
            fromDate={fromDate}
            toDate={toDate}
          />
          <BELocalContentList
            itemFilterFunction={itemFilterFunction}
            showContentCountInTitle
            onlyWhenData
            forceViewAllButton
            cutoffAt={5}
            guestBlurAfter={20}
            section="influencer_posts"
            className="margin-top-6x"
            title="Influencer posts"
            showFilter
            showStats
            fromDate={fromDate}
            toDate={toDate}
          />
          <BELocalContentList
            itemFilterFunction={itemFilterFunction}
            showContentCountInTitle
            onlyWhenData
            forceViewAllButton
            cutoffAt={5}
            guestBlurAfter={20}
            section="social_priority_posts"
            className="margin-top-6x"
            title={`Earned posts - ${capitalizeFirstLetter(data?.discovery_section_platform)} prioritized`}
            showFilter
            showStats
            fromDate={fromDate}
            toDate={toDate}
          />
          <BELocalContentList
            itemFilterFunction={itemFilterFunction}
            showContentCountInTitle
            onlyWhenData
            forceViewAllButton
            cutoffAt={5}
            guestBlurAfter={20}
            section="community_posts"
            className="margin-top-6x"
            title="Earned posts - Community content"
            showFilter
            showStats
            fromDate={fromDate}
            toDate={toDate}
          />
          <BELocalContentList
            itemFilterFunction={itemFilterFunction}
            showContentCountInTitle
            onlyWhenData
            forceViewAllButton
            cutoffAt={5}
            guestBlurAfter={20}
            section="community_posts_comment_only"
            className="margin-top-6x"
            title="Earned posts - Mention in comment only"
            showFilter
            showStats
            fromDate={fromDate}
            toDate={toDate}
          />
          <BELocalContentList
            itemFilterFunction={itemFilterFunction}
            showContentCountInTitle
            onlyWhenData
            forceViewAllButton
            cutoffAt={5}
            guestBlurAfter={20}
            section="all_posts"
            className="margin-top-6x"
            title="Archive"
            showFilter
            showStats
            fromDate={"1970-01-01"} // all time
            toDate={formatDate(new Date(), 'YYYY-MM-DD')}
          />
        </>
      )}
    </>
  )
}

const CompetitorModeContents = ({
  fromDate,
  toDate,
  isViewAll,
  competitors,
  competitorMode,
  itemFilterFunction,
  isPolitical,
  mainBrandId,
  platform,
}) => {
  const [data, dispatch] = useContext(dataContext)
  const _filter = {
    "all-owned": {
      is_owned_by_brand: true
    },
    "best-five": {
      is_owned_by_brand: true,
      limit: 5,
    },
    "worst-five": {
      is_owned_by_brand: true,
      limit: 5,
    },
    "all-influencer": {
      is_influencer_content: true
    },
    "all-organic": {
      is_from_organic_search: true
    }
  }
  const _all_ids = competitors.map((x) => x.id)

  const all_brand_ids = mainBrandId && !isPolitical ? [mainBrandId, ..._all_ids] : _all_ids

  let apiURL = {}
  switch (competitorMode) {
    case "best-five":
      apiURL = GET_CALLS.best_performing_owned_posts
      break
    case "worst-five":
      apiURL = GET_CALLS.worst_performing_owned_posts
    break
    default:
      apiURL = GET_CALLS.key_performance_metrics
  }

  const { payload: kpm_payload, loader: kpm_loader } = useLocalBoundData({
    ...apiURL,
    filter: {
      ..._filter[competitorMode],
      post_date__gte: fromDate,
      post_date__lte: toDate,
      brand_id_list: all_brand_ids?.join(",")
    }
  })

  const all_ids = _all_ids.join(",")

  const sectionName =
    (competitorMode == "all-owned" || competitorMode == "best-five" || competitorMode == "worst-five")
      ? `competitor_owned_posts`
      : competitorMode == "all-influencer"
        ? `competitor_influencer_posts`
        : `competitor_organic_posts`


  const additionalFilter = _filter[competitorMode]

  const processPayload = (field) => {

    if (isPolitical && kpm_payload) {
      return moveIdsToFront(KVMDataParcer(kpm_payload, field), POLITICAL_CANDIDATE_NAME_SERIAL, x => x.x)
    }
    const _all_data = kpm_payload ? KVMDataParcer(kpm_payload, field) : []

    if (!_all_data?.length) {
      return []
    }
    const all_data = []

    const com = [{ ...data?.me?.brand }, ...competitors]
    com?.map(comp => {
      // console.log(comp)
      const x = comp?.title ?? comp?.name
      // console.log(x)
      const find = _all_data?.find(d => d.x === x)
      if (find) {
        all_data?.push(find)
      } else {
        all_data?.push({ x: x, y: 0 })
      }
    })
    return all_data

  }

  const is_best = competitorMode === "best-five" || competitorMode === "worst-five"

  return (
    <>
      {platform === "youtube" ? (
        <Card className={"circle-stat-block margin-y-3x"}>
          <div className="padding-x-8x txt-center padding-y-12x body-regular-font nc30-fg">
            In compliance with Youtube's Terms of Service, we cannot show you aggregate data, but you can still find the
            best performing content below
          </div>
        </Card>
      ) : (
        <>
          {(!isViewAll) && (
            <WrappedInLoader data={kpm_payload} loader={kpm_loader}>
              <div className={`grid grid-cols-${is_best ? 4 : 5} margin-bottom-4x`}>
                {!is_best && (
                  <BarChart
                    title="Total posts"
                    className={`margin-right-2x padding-x-2x full`}
                    data={processPayload("total_posts")}
                    options={{
                      dataLabels: {
                        offsetY: -20,
                        style: {
                          fontSize: "10px",
                          colors: ["#767B8A"],
                        },
                        formatter: function (val, opts) {
                          return num2Hip(val, 0)
                        },
                      },
                    }}
                  />
                )}
                <BarChart
                  title="Total views"
                  className={`margin-right-2x padding-x-2x full`}
                  data={processPayload("total_views")}
                  options={{
                    dataLabels: {
                      offsetY: -20,
                      style: {
                        fontSize: "10px",
                        colors: ["#767B8A"],
                      },
                      formatter: function (val, opts) {
                        return num2Hip(val, 0)
                      },
                    },
                  }}
                />
                <BarChart
                  title="Total comments"
                  className={`margin-right-2x padding-x-2x full`}
                  data={processPayload("total_comments")}
                  options={{
                    dataLabels: {
                      offsetY: -20,
                      style: {
                        fontSize: "10px",
                        colors: ["#767B8A"],
                      },
                      formatter: function (val, opts) {
                        return num2Hip(val, 0)
                      },
                    },
                  }}
                />
                <BarChart
                  title="Total engagement"
                  className={`margin-right-2x padding-x-2x full`}
                  data={processPayload("total_brand_engagement")}
                  options={{
                    dataLabels: {
                      offsetY: -20,
                      style: {
                        fontSize: "10px",
                        colors: ["#767B8A"],
                      },
                      formatter: function (val, opts) {
                        return num2Hip(val, 0)
                      },
                    },
                  }}
                />
                <BarChart
                  title="Engagement Rate"
                  className={` padding-x-2x full`}
                  data={processPayload("engagement_rate")}
                  options={{
                    dataLabels: {
                      offsetY: -20,
                      style: {
                        fontSize: "10px",
                        colors: ["#767B8A"],
                      },
                      formatter: function (val, opts) {
                        // return parseInt(num2Hip(val) * 100) + '%'
                        // add 1 decimal point
                        return parseFloat(val * 100).toFixed(1) + "%"
                      },
                    },
                  }}
                />
              </div>
            </WrappedInLoader>
          )}
        </>
      )}
      {is_best ? <>

        <BELocalContentList
          key={competitorMode + "indivisiual" + "main_brand" + competitorMode}
          itemFilterFunction={itemFilterFunction}
          showContentCountInTitle
          viewAllCustomSectionName={sectionName + "main_brand" + competitorMode}
          // customViewAllAction = {()=>{navigate(`/discover-content?sub_brand_id=${competitor?.id}`)}}
          additionalFilters={{ limit: 5, sort_by: competitorMode === "best-five" ? "impact" : "-impact" }}
          forceViewAllButton
          cutoffAt={5}
          guestBlurAfter={20}
          section={sectionName}
          className="margin-top-6x"
          title={`${competitorMode === "best-five" ? "Best five " : "Worst five"} ${data?.me?.brand?.title} posts`}
          showFilter
          showStats
          waitTillMainFilterSet
          fromDate={fromDate}
          toDate={toDate}
        />

        {competitors.map((competitor, index) => (
          <BELocalContentList
            key={competitorMode + "indivisiual" + index}
            itemFilterFunction={itemFilterFunction}
            showContentCountInTitle
            viewAllCustomSectionName={sectionName + competitorMode + competitor?.id}
            // customViewAllAction = {()=>{navigate(`/discover-content?sub_brand_id=${competitor?.id}`)}}
            additionalFilters={{ limit: 5, sort_by: competitorMode === "best-five" ? "impact" : "-impact" }}
            forceViewAllButton
            cutoffAt={5}
            guestBlurAfter={20}
            section={sectionName}
            className="margin-top-6x"
            title={`${competitorMode === "best-five" ? "Best five " : "Worst five"} ${competitor?.title} posts`}
            showFilter
            showStats
            brandId={competitor?.id}
            waitTillMainFilterSet
            fromDate={fromDate}
            toDate={toDate}
          />
        ))}

      </> : (<>
        <BELocalContentList
          key={competitorMode + "trending"}
          additionalFilters={{ ...additionalFilter, brand_id_list: all_ids }}
          itemFilterFunction={itemFilterFunction}
          showContentCountInTitle
          skipSubBrand
          forceViewAllButton
          cutoffAt={5}
          guestBlurAfter={20}
          section="trending_posts"
          className="margin-top-6x"
          title="Trending posts"
          showFilter
          showStats
          waitTillMainFilterSet
          fromDate={fromDate}
          toDate={toDate}
        />
        <BELocalContentList
          key={competitorMode + "all_posts_30_default"}
          additionalFilters={{ ...additionalFilter, brand_id_list: all_ids }}
          itemFilterFunction={itemFilterFunction}
          showContentCountInTitle
          skipSubBrand
          forceViewAllButton
          cutoffAt={5}
          guestBlurAfter={20}
          section="all_posts_30_default"
          className="margin-top-6x"
          title="All Posts"
          showFilter
          showStats
          waitTillMainFilterSet
          fromDate={fromDate}
          toDate={toDate}
        />
        {competitors.map((competitor, index) => (
          <BELocalContentList
            key={competitorMode + "indivisiual" + index}
            itemFilterFunction={itemFilterFunction}
            showContentCountInTitle
            viewAllCustomSectionName={sectionName + competitor?.id}
            // customViewAllAction = {()=>{navigate(`/discover-content?sub_brand_id=${competitor?.id}`)}}
            forceViewAllButton
            cutoffAt={5}
            guestBlurAfter={20}
            section={sectionName}
            className="margin-top-6x"
            title={competitor?.title}
            showFilter
            showStats
            brandId={competitor?.id}
            waitTillMainFilterSet
            fromDate={fromDate}
            toDate={toDate}
          />
        ))}
      </>)}
    </>
  )
}

const TrendingContents = ({
  fromDate,
  toDate,
  category_id,
  sub_category_id,
}) => {
  const [data, dispatch] = useContext(dataContext)

  if(!data?.trending_category && !data?.trending_sub_category){
    return <FullLoader />
  }

  let commonTrendingFilter = {}
  if (sub_category_id) {
    commonTrendingFilter.sub_category_id = sub_category_id
  }else if (category_id) {
    commonTrendingFilter.category_id = category_id
  }
  

  return (
    <>
      <BELocalContentList
        key={(sub_category_id ?? category_id) + "trending_organic"}
        itemFilterFunction={() => true}
        showContentCountInTitle
        forceViewAllButton
        cutoffAt={5}
        guestBlurAfter={20}
        section="god_view_trending_organic_posts"
        className="margin-top-6x"
        title="Trending organic posts"
        showFilter
        showStats
        waitTillMainFilterSet
        additionalFilters={{...commonTrendingFilter}}
        fromDate={fromDate}
        toDate={toDate}
      />
      <BELocalContentList
        key={(sub_category_id ?? category_id) + "trending_influencer"}
        itemFilterFunction={() => true}
        showContentCountInTitle
        forceViewAllButton
        cutoffAt={5}
        guestBlurAfter={20}
        section="god_view_trending_influencer_posts"
        className="margin-top-6x"
        title="Trending influencer posts"
        showFilter
        showStats
        waitTillMainFilterSet
        additionalFilters={{...commonTrendingFilter, is_influencer_content: true}}
        fromDate={fromDate}
        toDate={toDate}
      />
      <BELocalContentList
        key={(sub_category_id ?? category_id) + "trending_brand"}
        itemFilterFunction={() => true}
        showContentCountInTitle
        forceViewAllButton
        cutoffAt={5}
        guestBlurAfter={20}
        section="god_view_trending_owned_posts"
        className="margin-top-6x"
        title="Trending brand owned posts"
        showFilter
        showStats
        waitTillMainFilterSet
        additionalFilters={{...commonTrendingFilter, is_owned_by_brand: true}}
        fromDate={fromDate}
        toDate={toDate}
      />
    </>
  )
}


const defaultInclutionExclutionState = {
  include_add: [],
  include_remove: [],
  exclude_add: [],
  exclude_remove: [],
}
function HashtagsStatsTable({ section, onInclude, onExclude, title, className = "", showSearch = false }) {
  const [search, setSearch] = useState("")
  const debouncedSearchTerm = useDebounce(search, 500)
  const filter = debouncedSearchTerm ? new URLSearchParams({ q: debouncedSearchTerm }).toString() : null
  const { payload, loader, pagination } = useBDBoundData({
    section,
    queryFilter: filter,
    skipBrandRefresh: true,
    skipDiscoveryPlatformRefresh: true,
  })
  const api = useContext(apiContext)
  const [include_exclude_state, set_include_exclude_state] = useState(defaultInclutionExclutionState)
  const { loader: p_loader, executeCall } = useBDPostData({ section: "update_brand_inclution_exluclution" })
  const { isGuest } = useAuthHelper()
  const [data] = useContext(dataContext)

  const removeFromSetNames = (value, setNames) => {
    const a = {}
    for (let s of setNames) {
      const hashtagSet = new Set([...(include_exclude_state?.[s] ?? [])])
      hashtagSet.delete(value)
      a[s] = [...hashtagSet]
    }
    return a
  }
  const addInSetName = (value, s) => {
    const hashtagSet = new Set([...(include_exclude_state?.[s] ?? [])])
    hashtagSet.add(value)
    return [...hashtagSet]
  }

  const addOrRemove = (hashtag, type = "include", add = false) => {
    if (isGuest) {
      api?.alert?.unlockFullExperience()
      return
    }
    const opposite_type = { include: "exclude", exclude: "include" }[type]
    const adding_name = `${type}_${add ? "add" : "remove"}`

    let main_inclution_exlution_changes = {
      [adding_name]: addInSetName(hashtag.hashtag, adding_name),
    }
    let additional_changes = {}
    if (add) {
      console.log([type + "_remove", opposite_type + "_add"])
      additional_changes = removeFromSetNames(hashtag.hashtag, [type + "_remove", opposite_type + "_add"])
      additional_changes = {
        ...additional_changes,
        [opposite_type + "_remove"]: addInSetName(hashtag.hashtag, [opposite_type + "_remove"]),
      }
    } else {
      console.log([type + "_add", opposite_type + "_remove", opposite_type + "_add"])
      additional_changes = removeFromSetNames(hashtag.hashtag, [
        type + "_add",
        opposite_type + "_remove",
        opposite_type + "_add",
      ])
    }
    main_inclution_exlution_changes = { ...main_inclution_exlution_changes, ...additional_changes }
    set_include_exclude_state({
      ...include_exclude_state,
      ...main_inclution_exlution_changes,
    })
  }

  const onApply = async () => {
    // const _section ="update_brand_inclution_exluclution"
    // await api.common.postRequestHelper({
    //     endpoint:`/BrandTags/${data?.sub_brand_id ? data?.sub_brand_id : data?.me?.brand?.id}/inclusion_exclusion/`,
    //     section:_section,
    //     payload: include_exclude_state,
    //     dataContextPrefix:"bd_sec_",
    //     loaders:["bd-section-default",`bd-section-${_section}`]
    // })
    await executeCall(include_exclude_state)
    await api.brand.hardRefreshDashboardSections({ sectionsArray: [section] })
    set_include_exclude_state(defaultInclutionExclutionState)
  }

  const checkHashtag = (hashtag, type = "include") => {
    const _v_name = type == "include" ? "is_included" : "is_excluded"
    if (include_exclude_state?.[type + "_remove"]?.includes(hashtag.hashtag)) return false
    if (include_exclude_state?.[type + "_add"]?.includes(hashtag.hashtag)) return true
    return hashtag?.[_v_name]
  }

  const _payload = payload?.filter((x) => x.hashtag?.includes(search))

  useEffect(() => {
    return () => setSearch("")
  }, [])

  useEffect(() => {
    api.brand.resetSection(section)
  }, [debouncedSearchTerm])

  return (
    <div className={className}>
      {title ? <h3 className="headline-medium-highlight margin-bottom-3x">{title}</h3> : null}
      <Card>
        <div
          className={`hashtags-analysis-table-grid max-height-248 overflow-auto ${onInclude && onExclude ? "has-include-and-exclude" : ""} padding-y body-highlight-font row-stick-top `}
        >
          <div className="nc50-fg padding-y flex a-center padding-left-4x row-stick-top nc00-bg ">
            {showSearch ? (
              <div className="padding-x-3x padding-right-2x padding-y-2x nc02-bg nc10-border flex border-radius-4 nc10-border border-1">
                <input
                  placeholder="hashtags"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="bear-input nc02-bg full"
                />
                {SearchMediumFilledMono}
              </div>
            ) : (
              "hashtags"
            )}
          </div>
          <div className="nc50-fg padding-y flex a-center j-center row-stick-top nc00-bg txt-center">
            total posts <br /> on Zelf
          </div>
          <div className="nc50-fg padding-y flex a-center j-center row-stick-top nc00-bg txt-center">
            total views <br /> on Tiktok
          </div>
          <div className="nc50-fg padding-y flex a-center j-center row-stick-top nc00-bg txt-center">
            % of post <br /> on Zelf{" "}
          </div>
          {onInclude ? <div className=""></div> : null}
          {onInclude ? (
            <div className="nc50-fg padding-y flex a-center j-center row-stick-top nc00-bg">include</div>
          ) : null}
          {onExclude ? (
            <div className="nc50-fg padding-y flex a-center j-center row-stick-top nc00-bg">exclude</div>
          ) : null}

          <WrappedInLoader data={payload} loader={loader || p_loader} silent={data[`bd_sec_${section}_page`] > 1}>
            {_payload?.map((x, i) => (
              <React.Fragment key={i}>
                <hr className="hr full-span-grid-column " />
                <div className="padding-y-2x flex a-center padding-left-4x">#{x.hashtag}</div>
                <div className="padding-y-2x flex a-center j-center">{num2Hip(x.total_items)}</div>
                <div className="padding-y-2x flex a-center j-center">{num2Hip(x.total_hashtag_views)}</div>
                <div className="padding-y-2x flex a-center j-center">{convertToPercentage(x.percentage, 1)}</div>
                {onInclude ? <div className=""></div> : null}
                {onInclude ? (
                  <div className="nc50-fg padding-y flex a-center j-center">
                    <Toggle
                      value={checkHashtag(x, "include")}
                      onChange={() => addOrRemove(x, "include", !checkHashtag(x, "include"))}
                    />
                  </div>
                ) : null}
                {onExclude ? (
                  <div className="nc50-fg padding-y flex a-center j-center">
                    <Toggle
                      value={checkHashtag(x, "exclude")}
                      onChange={() => addOrRemove(x, "exclude", !checkHashtag(x, "exclude"))}
                    />
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          </WrappedInLoader>

          <ScrollSpy
            className="full-span-grid-column"
            rebindTrigger={data[`bd_sec_${section}_raw`]?.pagination?.next}
            loader={loader}
            onEngage={pagination.loadNext}
          />
        </div>
        {onInclude ? (
          <>
            <hr className="hr full-span-grid-column " />
            <div className="flex position-relative full-span-grid-column j-end a-center padding-x-4x nc00-bg-override z-index-100 padding-y">
              <Button type="ghost" onClick={onApply}>
                Apply changes
              </Button>
            </div>
          </>
        ) : null}
      </Card>
    </div>
  )
}

function HashtagsSection({ className = "margin-y-6x" }) {
  const [data] = useContext(dataContext)

  return (
    <div className={className}>
      <HashtagsStatsTable section="hashtag_analytics" title="Your Hashtags" />

      <HashtagsStatsTable
        showSearch
        section="related_hashtag_analytics"
        className="margin-y-4x"
        title="related Hashtags"
        onExclude={data?.me?.user_group != null || data?.me?.brand?.settings?.is_term_edit_enabled}
        onInclude={data?.me?.user_group != null || data?.me?.brand?.settings?.is_term_edit_enabled}
      />
    </div>
  )
}

export { ContentSection, CreatorSection }
