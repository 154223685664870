import { useContext } from "react"
import { apiContext } from "../contexts/apicontext"
import { dataContext } from "../contexts/datacontext"
import { useLoading } from "../contexts/loaderContext"
import { formatDate, notMissing, num2Hip, relTime, truncateString } from "../helpers/utils"
import {
  AngryFaceFilledMono,
  CommentLargeFilledMono,
  HeartLargeFilledMono,
  MagicWand,
  PlaneLargeFilledMono,
  PokerFaceFilledMono,
  RestoreIcon,
  SmallInfluencerMono,
  SmileyFaceFilledMono,
  TrashLargeFilledMono,
  Visibility,
} from "./Icons"
import { DotSeparator, PlatformIcon } from "./dumbs"
import { Loader } from "./loaders"
import { Media } from "./media"

function Overlay({ content, platform }) {
  return (
    <div
      className={`content-thumb-overlay-${platform} border-radius-dynamic content-thumb-overlay flex vertical hide-on-mobile`}
    >
      <div className={`content-stats-${platform} nc00-fg flex button-medium-font padding-3x`}>
        <div className={`${platform}-logo-at-thumb margin-right-auto`}>
          <PlatformIcon platform={platform} color={false} />
        </div>
        <div className={`${platform}-stats-at-thumb flex vcenter`}>
          {notMissing(content?.likes) ? (
            <div className={`${platform}-thumb-stat-item ${platform}-thumb-stat-likes flex vcenter margin-right-2x`}>
              {HeartLargeFilledMono} <p>{num2Hip(content.likes)}</p>
            </div>
          ) : null}
          {notMissing(content?.comments) ? (
            <div className={`${platform}-thumb-stat-item ${platform}-thumb-stat-comments flex vcenter margin-right-2x`}>
              {CommentLargeFilledMono} <p>{num2Hip(content.comments)}</p>
            </div>
          ) : null}
          {notMissing(content?.shares) ? (
            <div className={`${platform}-thumb-stat-item ${platform}-thumb-stat-shares flex vcenter margin-right-2x`}>
              {PlaneLargeFilledMono} <p>{num2Hip(content.comments)}</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

function UnlistOverlay({ platform, content }) {
  const api = useContext(apiContext)
  const { loaders } = useLoading()
  const [data] = useContext(dataContext)

  const unlist = async (e) => {
    e.stopPropagation()
    const changed = await api.creator.changeContentAvailability(content, false)
    if (changed) api.analytics.action("creator:content", `delete_content_${content?.content_platform}`, content?.uuid)
  }
  const relist = async (e) => {
    e.stopPropagation()
    const changed = await api.creator.changeContentAvailability(content, true)
    if (changed) api.analytics.action("creator:content", `restore_content_${content?.content_platform}`, content?.uuid)
  }
  const highlight = async () => {
    await api.creator.setTopContent(data.creator_current_platform, content.uuid)
  }
  const unHighlight = async () => {
    await api.creator.RemoveTopContent(data.creator_current_platform, content.uuid)
  }
  const showUnlist =
    (content?.is_active_on_zelf && data.deleted_temp.indexOf(content.uuid) === -1) ||
    (!content.is_active_on_zelf && data.restored_temp.indexOf(content.uuid) !== -1)
  return (
    <div
      className={`content-thumb-overlay-${platform} border-radius-dynamic content-thumb-overlay flex hcenter vcenter`}
    >
      {loaders[`availability_${content?.uuid}`] ? (
        <Loader />
      ) : (
        <>
          {showUnlist ? (
            <>
              <div onClick={unlist} className="unlist-button flex hcenter vcenter primary-fg pop-on-hover">
                {TrashLargeFilledMono}
              </div>
              <div
                onClick={highlight}
                className="highlight-unhighlight-button button-highlight nc00-bg flex vcenter padding-x-3x primary-fg button-small-font txt-bold margin-left pop-on-hover"
              >
                {loaders[`highlightcontent_${content?.uuid}`] ? (
                  <Loader />
                ) : (
                  <>
                    {MagicWand}
                    <div className="margin-left">highlight</div>
                  </>
                )}
              </div>
              <div
                onClick={unHighlight}
                className="highlight-unhighlight-button button-unhighlight nc00-bg flex vcenter padding-x-3x negative-fg button-small-font txt-bold margin-left pop-on-hover"
              >
                {loaders[
                  `unhighlightcontent_${content.proposition_content_uuid ? content.proposition_content_uuid : content.uuid}`
                ] ? (
                  <Loader />
                ) : (
                  <>
                    {TrashLargeFilledMono}
                    <div className="margin-left">Remove</div>
                  </>
                )}
              </div>
            </>
          ) : (
            <div onClick={relist} className="content-relist-button nc75-fg padding-y-2x padding-x-3x pop-on-hover">
              {RestoreIcon} Restore
            </div>
          )}
        </>
      )}
    </div>
  )
}

function ThumbWOverlay({ content, onClick, platform }) {
  const [data] = useContext(dataContext)
  const isListed =
    (content?.is_active_on_zelf && data.deleted_temp.indexOf(content.uuid) === -1) ||
    (!content.is_active_on_zelf && data.restored_temp.indexOf(content.uuid) !== -1)
  return (
    <div className={`content-thumb-${platform} content-thumb ${isListed ? "" : "media-unlisted"}`} onClick={onClick}>
      <Media className="border-radius-dynamic" platform={platform} content={content} />
      {content?.content_form === "VIDEO" || platform === "tiktok" ? (
        <div className="playable-content-icon"></div>
      ) : null}
      {data?.is_me_creator && data?.on_creator_dashboard ? (
        <UnlistOverlay platform={platform} content={content} />
      ) : (
        <Overlay platform={platform} content={content} />
      )}
    </div>
  )
}

function ContentThumb({
  content,
  onClick,
  platform,
  creator,
  hideCreatorInfo,
  hidePlatformInfo,
  hideExtraInfo,
  hideNPS = false,
}) {
  const [data] = useContext(dataContext)
  const _nps_score = data?.seen_content_from_modal?.[content?.id]?.nps_score ?? content?.nps_score
  return (
    <div className={`content-thumb-${platform} content-thumb content-ecosystem-thumb`} onClick={onClick}>
      {hideExtraInfo ? null : (
        <>
          {content?.is_new && data?.seen_content_from_modal?.[content?.id]?.is_new !== false ? (
            <div
              style={{ backgroundColor: "#14BD58" }}
              className="content-platform-new-indicator flex hcenter vcenter nc00-fg border-radius-4 border-1 nc00-border button-tiny-font"
            >
              New
            </div>
          ) : null}
          {!hideNPS && (
            <>
              {_nps_score > 0 ? (
                <div
                  style={{ height: 24, width: 24 }}
                  className="content-platform-nps-indicator flex hcenter positive-fg vcenter nc00-bg border-radius-round"
                >
                  {SmileyFaceFilledMono}
                </div>
              ) : null}
              {_nps_score < 0 ? (
                <div
                  style={{ height: 24, width: 24 }}
                  className="content-platform-nps-indicator flex hcenter negative-fg vcenter nc00-bg border-radius-round"
                >
                  {AngryFaceFilledMono}
                </div>
              ) : null}
              {_nps_score === 0 ? (
                <div
                  style={{ height: 24, width: 24, color: "#ECB651" }}
                  className="content-platform-nps-indicator flex hcenter attention-fg vcenter nc00-bg border-radius-round"
                >
                  {PokerFaceFilledMono}
                </div>
              ) : null}
            </>
          )}
        </>
      )}

      <Media className="border-radius-dynamic" platform={platform} content={content} />
      {hideCreatorInfo ? null : (
        <div className="content-thumb-user-avatar-holder flex vcenter">
          <img className="content-thumb-user-avatar" src={creator?.profile_picture_url} alt={creator?.username} />
          <div className="creator-name-on-thumb button-medium-font margin-left nc00-fg full">
            {creator?.name || creator?.username}
          </div>
        </div>
      )}
      {hidePlatformInfo ? null : (
        <div className="content-platform-icon flex hcenter vcenter nc00-bg">
          <PlatformIcon platform={platform} color={true} />
        </div>
      )}
    </div>
  )
}

function YTThumb({ content, onClick }) {
  const data = useContext(dataContext)
  return (
    <div className="content-thumb-youtube content-thumb" onClick={onClick}>
      <div className={`media-holder-youtube position-relative ${content?.is_active_on_zelf ? "" : "media-unlisted"}`}>
        <Media className="border-radius-dynamic" platform="youtube" content={content} />
        <div className="playable-content-icon"></div>
        {data?.is_me_creator && data?.on_creator_dashboard ? (
          <UnlistOverlay platform="youtube" content={content} />
        ) : null}
      </div>
      <div className="content-detail-youtube padding-2x">
        <div className="youtube-thumb-title body-highlight-font">{content.title}</div>
        <div className="youtube-content-thumb-stats caption-regular nc50-fg flex vcenter margin-top">
          <div className="youtube-thumbstat-views">{num2Hip(content.views)} Views</div>
          <div className="littledot nc50-bg margin-x-2x"></div>
          <div className="youtube-thumbstat-uploadedat">{relTime(content.timestamp)}</div>
        </div>
      </div>
    </div>
  )
}

function Thumbnail({ platform, content, onClick }) {
  switch (platform) {
    case "youtube":
      return <YTThumb content={content} onClick={onClick} />
    default:
      return <ThumbWOverlay platform={platform} content={content} onClick={onClick} />
  }
}

function SearchThumbnail({ platform, content, onClick, creator }) {
  return (
    <div className="search-thumbnail thumb-with-details-search">
      <Thumbnail platform={platform} content={content} onClick={onClick} />
      {platform !== "youtube" ? (
        <div className="creator-attrs-search padding-x padding-y-2x flex vcenter">
          <img
            className="search-result-avatar margin-right"
            alt={creator?.username}
            src={creator?.profile_picture_url}
          />
          <a href={`/${creator?.username}`} className="heading-small-highlight creator-attr-username-on-search">
            {creator?.username}
          </a>
          <div className="content-attrs headline-small-highlight nc50-fg flex vcenter margin-left-auto">
            {notMissing(content?.likes) ? (
              <div className="flex a-center margin-right">
                {HeartLargeFilledMono}
                <span className="margin-left">{num2Hip(content.likes)}</span>
              </div>
            ) : null}
            {notMissing(content?.comments) ? (
              <div className="flex a-center hide-on-mobile margin-right">
                {CommentLargeFilledMono}
                <span className="margin-left">{num2Hip(content.comments)}</span>
              </div>
            ) : null}
            {notMissing(content?.shares) ? (
              <div className="flex a-center margin-right hide-on-mobile">
                {PlaneLargeFilledMono}
                <span className="margin-left">{num2Hip(content.shares)}</span>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  )
}

function EcoSystemThumbnail({ payload, onClick, hideThumbNPSIcon = false }) {
  // const creator  = payload.creator;
  const content = payload.content
  const platform = content.content_platform
  const creator = payload.creator
  return (
    <div className="search-thumbnail thumb-with-details-search">
      <ContentThumb
        hideNPS={hideThumbNPSIcon}
        platform={platform}
        creator={creator}
        content={content}
        onClick={onClick}
      />
      <div className="creator-attrs-search  padding-y-2x flex vcenter">
        {/* <img className="search-result-avatar margin-right" alt={creator?.username} src={creator?.profile_picture_url}/> */}
        {/* <a href={`/${creator?.username}`} className="heading-small-highlight creator-attr-username-on-search">{creator?.username}</a> */}
        <div className="content-attrs button-small-font nc50-fg flex vcenter">
          <div className="flex a-center margin-right-2x">
            <span className="button-small-font">
              {notMissing(content?.views) ? (
                <>
                  <span className="txt-uppercase">{num2Hip(content.views)}</span> views
                </>
              ) : (
                "n/a"
              )}
            </span>
          </div>
          <div className="flex a-center margin-right-2x">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.9333 13.9993H4.53335V5.46602L9.16668 0.666016L9.81668 1.18268C9.88335 1.23824 9.93335 1.31602 9.96668 1.41602C10 1.51602 10.0167 1.63824 10.0167 1.78268V1.94935L9.26668 5.46602H14.25C14.5167 5.46602 14.75 5.56602 14.95 5.76602C15.15 5.96602 15.25 6.19935 15.25 6.46602V7.83C15.25 7.90956 15.2583 7.99101 15.275 8.07435C15.2917 8.15768 15.2833 8.23824 15.25 8.31602L13.15 13.1493C13.0514 13.3855 12.887 13.5862 12.6568 13.7514C12.4266 13.9167 12.1854 13.9993 11.9333 13.9993ZM5.53335 12.9993H12.15L14.25 8.01602V6.46602H8.03335L8.91668 2.31602L5.53335 5.88268V12.9993ZM4.53335 5.46602V6.46602H2.31668V12.9993H4.53335V13.9993H1.31668V5.46602H4.53335Z"
                fill="#767B8A"
              />
            </svg>

            <span className="margin-left txt-uppercase">
              {notMissing(content?.likes) ? num2Hip(content.likes) : "n/a"}
            </span>
          </div>
          <div className="flex a-center hide-on-mobile ">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.00001 11.3507H11.2167V10.3507H6.00001V11.3507ZM6.00001 9.18398H14V8.18398H6.00001V9.18398ZM6.00001 7.01732H14V6.01732H6.00001V7.01732ZM3.33334 16.6673V4.33398C3.33334 4.06732 3.43334 3.83398 3.63334 3.63398C3.83334 3.43398 4.06668 3.33398 4.33334 3.33398H15.6667C15.9333 3.33398 16.1667 3.43398 16.3667 3.63398C16.5667 3.83398 16.6667 4.06732 16.6667 4.33398V13.0007C16.6667 13.2673 16.5667 13.5007 16.3667 13.7007C16.1667 13.9007 15.9333 14.0007 15.6667 14.0007H6.00001L3.33334 16.6673ZM5.56668 13.0007H15.6667V4.33398H4.33334V14.334L5.56668 13.0007Z"
                fill="#767B8A"
              />
            </svg>

            <span className="margin-left txt-uppercase">
              {notMissing(content?.comments) ? num2Hip(content.comments, 1) : "n/a"}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const SimpleCreatorInfo = ({
  creator,
  content,
  className = "",
  creatorNameCutoff = 14,
  hideFollower = false,
  creatorNameClass = "headline-small-regular nc100-fg",
  followersClass = "button-medium-font nc50-fg",
}) => {
  return (
    <div className={`flex vcenter ${className}`}>
      <img className="content-thumb-user-avatar" src={creator?.profile_picture_url} alt={creator?.username} />
      <div className={`${creatorNameClass} margin-left hide-extra-text`}>
        {truncateString(creator?.username ?? "", creatorNameCutoff) ?? ""}
      </div>
      {creator?.followers && !hideFollower && (
        <>
          <DotSeparator className="margin-x" />
          <span className={followersClass}>{num2Hip(creator?.followers)}</span>
        </>
      )}
      {(creator?.is_influencer ?? content?.is_influencer_content) && (
        <>
          <DotSeparator className="margin-x" />
          <span className={`flex cursor-pointer primary-fg`}>{SmallInfluencerMono}</span>
        </>
      )}
    </div>
  )
}

function TinyEcoSystemThumbnail({
  payload,
  onClick,
  hideCreatorInfo = false,
  hidePostedAt = true,
  hideStats = false,
  width = "90",
  postImageElement = null,
}) {
  const content = payload.content
  const platform = content.content_platform
  const creator = payload.creator
  return (
    <div className="" onClick={onClick}>
      {!hideCreatorInfo && (
        <SimpleCreatorInfo
          content={content}
          creator={creator}
          className="margin-bottom-2x"
          creatorNameClass="headline-small-regular nc100-fg margin-left-2x"
          followersClass="button-tiny-font nc50-fg"
        />
      )}
      <div className={`flex border-1 nc10-border border-radius-8 overflow-hidden`}>
        <div className="flex-shrink-0" style={{ maxWidth: width + "px", width: width + "px" }}>
          <ContentThumb
            platform={platform}
            hideCreatorInfo
            hidePlatformInfo
            hideExtraInfo
            creator={creator}
            content={content}
          />
        </div>
        <div className="creator-attrs-search flex full column">
          {/* <img className="search-result-avatar margin-right" alt={creator?.username} src={creator?.profile_picture_url}/> */}
          {/* <a href={`/${creator?.username}`} className="heading-small-highlight creator-attr-username-on-search">{creator?.username}</a> */}
          {!hideStats && (
            <div className="button-small-font nc50-fg flex full column vcenter">
              <div className="border-bottom-1 flex full full-width j-space-between nc10-border padding-x-4x vcenter nc02-bg">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.5 9.75C6.5 7.83594 8.05859 6.25 10 6.25C11.9141 6.25 13.5 7.83594 13.5 9.75C13.5 11.6914 11.9141 13.25 10 13.25C8.05859 13.25 6.5 11.6914 6.5 9.75ZM10 11.9375C11.2031 11.9375 12.1875 10.9805 12.1875 9.75C12.1875 8.54688 11.2031 7.5625 10 7.5625C9.97266 7.5625 9.94531 7.5625 9.91797 7.5625C9.97266 7.72656 10 7.86328 10 8C10 8.98438 9.20703 9.75 8.25 9.75C8.08594 9.75 7.94922 9.75 7.8125 9.69531C7.8125 9.72266 7.8125 9.75 7.8125 9.75C7.8125 10.9805 8.76953 11.9375 10 11.9375ZM4.72266 5.83984C6.00781 4.63672 7.78516 3.625 10 3.625C12.1875 3.625 13.9648 4.63672 15.25 5.83984C16.5352 7.01562 17.3828 8.4375 17.793 9.42188C17.875 9.64062 17.875 9.88672 17.793 10.1055C17.3828 11.0625 16.5352 12.4844 15.25 13.6875C13.9648 14.8906 12.1875 15.875 10 15.875C7.78516 15.875 6.00781 14.8906 4.72266 13.6875C3.4375 12.4844 2.58984 11.0625 2.17969 10.1055C2.09766 9.88672 2.09766 9.64062 2.17969 9.42188C2.58984 8.4375 3.4375 7.01562 4.72266 5.83984ZM10 4.9375C8.19531 4.9375 6.74609 5.75781 5.625 6.79688C4.55859 7.78125 3.84766 8.92969 3.46484 9.75C3.84766 10.5703 4.55859 11.7461 5.625 12.7305C6.74609 13.7695 8.19531 14.5625 10 14.5625C11.7773 14.5625 13.2266 13.7695 14.3477 12.7305C15.4141 11.7461 16.125 10.5703 16.5078 9.75C16.125 8.92969 15.4141 7.78125 14.3477 6.79688C13.2266 5.75781 11.7773 4.9375 10 4.9375Z"
                    fill="#767B8A"
                  />
                </svg>
                <span className="txt-uppercase margin-left">
                  {notMissing(content?.views) ? num2Hip(content.views) : "n/a"}
                </span>
              </div>
              <div className="border-bottom-1 flex full full-width j-space-between nc10-border padding-x-4x vcenter nc02-bg">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.67188 5.04688L9.97266 5.375L10.3008 5.07422C11.2031 4.17188 12.4883 3.76172 13.7188 3.98047C15.6055 4.28125 17 5.92188 17 7.83594V7.97266C17 9.12109 16.5078 10.2148 15.6875 10.9805L10.7383 15.6016C10.5469 15.793 10.2734 15.875 10 15.875C9.69922 15.875 9.42578 15.793 9.23438 15.6016L4.28516 10.9805C3.46484 10.2148 3 9.12109 3 7.97266V7.83594C3 5.92188 4.36719 4.28125 6.25391 3.98047C7.48438 3.76172 8.76953 4.17188 9.67188 5.04688C9.67188 5.07422 9.64453 5.04688 9.67188 5.04688ZM9.97266 7.23438L8.74219 5.97656C8.14062 5.40234 7.29297 5.12891 6.47266 5.26562C5.21484 5.48438 4.3125 6.55078 4.3125 7.83594V7.97266C4.3125 8.76562 4.61328 9.50391 5.1875 10.0234L10 14.5078L14.7852 10.0234C15.3594 9.50391 15.6875 8.76562 15.6875 7.97266V7.83594C15.6875 6.55078 14.7578 5.48438 13.5 5.26562C12.6797 5.12891 11.832 5.40234 11.2305 5.97656L9.97266 7.23438Z"
                    fill="#767B8A"
                  />
                </svg>
                <span className="margin-left txt-uppercase">
                  {notMissing(content?.likes) ? num2Hip(content.likes) : "n/a"}
                </span>
              </div>
              <div className="flex full full-width j-space-between nc10-border padding-x-4x vcenter nc02-bg hide-on-mobile">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 3.625C13.8555 3.625 16.9727 6.19531 16.9727 9.3125C16.9727 12.457 13.8555 15 10 15C9.09766 15 8.22266 14.8633 7.45703 14.6172C6.63672 15.1914 5.32422 15.875 3.65625 15.875C3.38281 15.875 3.13672 15.7383 3.02734 15.4922C2.94531 15.2461 2.97266 14.9727 3.16406 14.7812C3.19141 14.7812 4.03906 13.8516 4.42188 12.7852C3.51953 11.8281 3 10.625 3 9.3125C3 6.19531 6.11719 3.625 10 3.625ZM10 13.6875C13.1172 13.6875 15.6875 11.7461 15.6875 9.3125C15.6875 6.90625 13.1172 4.9375 10 4.9375C6.85547 4.9375 4.3125 6.90625 4.3125 9.3125C4.3125 10.4883 4.88672 11.3633 5.37891 11.8828L5.95312 12.4844L5.65234 13.25C5.51562 13.6328 5.32422 14.0156 5.10547 14.3438C5.76172 14.125 6.30859 13.8242 6.69141 13.5508L7.21094 13.168L7.83984 13.3594C8.52344 13.5781 9.26172 13.6875 10 13.6875Z"
                    fill="#767B8A"
                  />
                </svg>
                <span className="margin-left txt-uppercase">
                  {notMissing(content?.comments) ? num2Hip(content.comments, 0) : "n/a"}
                </span>
              </div>
            </div>
          )}
          {postImageElement}
        </div>
      </div>
      {!hidePostedAt && (
        <div className="caption-regular nc50-fg margin-top-2x">Posted on {formatDate(content.timestamp)}</div>
      )}
    </div>
  )
}

export { EcoSystemThumbnail, SearchThumbnail, SimpleCreatorInfo, Thumbnail, TinyEcoSystemThumbnail }
