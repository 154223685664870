import { useAuth0 } from "@auth0/auth0-react"
import { useContext, useEffect } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { getQueryParam } from "../../helpers/utils"
import { Loader } from "../loaders"
import { apiContext } from "../../contexts/apicontext"
import { _lsr } from "../../helpers/utils"
import { dataContext } from "../../contexts/datacontext"
import { useAuthHelper } from "../../hooks/useAuthHelper"
import { useToast } from "../../hooks/useToast"

const qParamName = {
  tiktok: "code",
  pinterest: "code",
  youtube: "code",
  paypal: "code",
  instagram: "code",
  ig_graph: "access_token",
}

function OauthCallBack({ platform }) {
  const { isLoading, isAuthenticated } = useAuthHelper()
  const api = useContext(apiContext)
  const navigate = useNavigate()
  const toast = useToast()
  const [data, dispatch] = useContext(dataContext)
  const destination = _lsr("cs_last_login_step") || "/"
  const processCode = async (code) => {
    let connected = null
    switch (platform) {
      case "youtube":
        console.log("youtube oauthcallback")
        connected = await api.creator.creatorConnectSocialMedia({ platform: platform, conn_data: window.location.href })
        break
      case "tiktok":
        connected = await api.creator.creatorConnectSocialMedia({ platform: platform, conn_data: code })
        break
      case "paypal":
        connected = await api.creator.connectPayout(platform, { conn_data: code })
        if (connected) {
          toast("paypal connected")
        }
        break
      case "instagram":
        connected = await api.creator.creatorConnectSocialMedia({ platform: platform, conn_data: code })
        break
      case "ig_graph":
        connected = await api.creator.creatorConnectSocialMedia({ platform: platform, conn_data: window.location.href })
        if (connected) {
          dispatch({
            me: {
              ...data?.me,
              is_ig_graph_connected: true,
            },
          })
        }
        break
      default:
        console.error("Unsupported platform")
    }
    if (!connected) api?.alert?.failedConnect(platform)
    api.analytics.action("creator:profile", "connect", platform)
    navigate(destination)
  }
  useEffect(() => {
    const oAuthcode = getQueryParam(window.location.href, qParamName[platform])
    console.log(`oAuth Code for ${platform}: ${oAuthcode}`) //toDo: comment this
    if (oAuthcode) processCode(oAuthcode)
    else {
      api?.alert?.failedConnect(platform)
      navigate(destination)
    }
  }, [])

  if (!isLoading && !isAuthenticated) return <Navigate to="/" />
  return (
    <div className="callback-page flex hcenter margin-10x">
      <Loader className="margin-right-2x" />
      <div className="wait-text txt-center">Please wait...</div>
    </div>
  )
}

export { OauthCallBack }
