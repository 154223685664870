import React from "react"
import zlogo from "../assets/zelflogo.png"
const MadeWithZelf = ({ className = "" }) => (
  <a href="https://hellozelf.com" target="_blank" className={`flex j-center margin-top margin-bottom-7x  ${className}`}>
    <div className="flex a-center line-shadow border-1 nc10-border padding-x-5x padding-y-3x nc50-fg made-with-zelf nc00-bg">
      <span className="body-regular-font">Made with </span>
      <img className="margin-left zelf-logo-img" src={zlogo} alt="zelf" />
    </div>
  </a>
)

export { MadeWithZelf }
