import { useState } from "react"

function VideoMedia({ url, className = "", square = false, controls, poster }) {
  const videoAddition = {} // doing this way because if poster is missing, use browser default generated thumbnail
  if (poster) videoAddition.poster = poster
  return (
    <video
      src={url}
      className={`cs-media-video ${square ? "square-aspect-ratio" : ""} ${className}`}
      controls={controls}
      {...videoAddition}
    ></video>
  )
}

function ImageMedia({ url, className = "", square = false, backup = "", stretch = false }) {
  const [loadFail, setLoadFail] = useState(false)
  return (
    <>
      {Boolean(loadFail) ? (
        <img
          className={`cs-media-image ${stretch ? "image-media-stretched" : ""} media-backup ${className} ${square ? "square-aspect-ratio" : ""} has-broken-fallback`}
          src={backup}
          alt={backup}
        />
      ) : (
        <img
          onError={() => setLoadFail(true)}
          className={`cs-media-image media-primary ${stretch ? "image-media-stretched" : ""}  ${className} ${square ? "square-aspect-ratio" : ""} has-broken-fallback`}
          src={url}
          alt={url}
        />
      )}
    </>
  )
}
function IGEmbed({ content }) {
  return (
    <iframe
      title={content?.title ?? "Instagram Post"}
      src={`https://www.instagram.com/p/${content?.external_id}/embed`}
      width="400"
      height="520"
      frameborder="0"
      scrolling="no"
      allowtransparency="true"
    ></iframe>
  )
}
function IGMedia({ content, className = "", videoControls = false, showVideo = false }) {
  // if(!content.content_form) return null;
  let url = ""
  let backup = ""
  let form = content.content_form
  if (!form) {
    if (content?.thumbnail_url || content?.thumbnail_url_backup) form = "VIDEO"
    else form = "IMAGE"
  }
  switch (form) {
    case "IMAGE":
      url = content?.media_urls?.[0] ?? content?.thumbnail_url
      backup = content?.media_urls_backup?.[0] ?? content?.thumbnail_url_backup
      return <ImageMedia square={true} className={className} url={url} backup={backup} />
    case "VIDEO":
      url = content?.thumbnail_url
      backup = content?.thumbnail_url_backup
      return showVideo ? (
        <IGEmbed content={content} />
      ) : (
        <ImageMedia square={true} className={className} url={url} backup={backup} />
      )
    default:
      return null
  }
}

function UGCMedia({ content, className = "", videoControls = false, showVideo = false }) {
  if (!content.content_form) return null
  let url = ""
  let backup = ""
  switch (content.content_form) {
    case "IMAGE":
      url = content?.media_urls?.[0]
      backup = content?.media_urls_backup?.[0]
      return <ImageMedia square={false} className={className} url={url} stretch={true} backup={backup} />
    case "VIDEO":
      url = content?.thumbnail_url
      const videoUrl = content?.media_urls?.[0]
      backup = content?.thumbnail_url_backup
      return showVideo ? (
        <VideoMedia
          square={false}
          controls={videoControls}
          className={className}
          url={videoUrl}
          poster={url ?? backup}
        />
      ) : (
        <ImageMedia stretch={true} square={false} className={className} url={url} backup={backup} />
      )
    default:
      return null
  }
}

function TTMedia({ content, className = "" }) {
  const url = content?.thumbnail_url
  const backup = content?.thumbnail_url_backup
  if (!url) return null
  return <ImageMedia className={className} url={url} backup={backup} />
}
function YTMedia({ content, className = "" }) {
  const url = content?.thumbnail_url
  const backup = content?.thumbnail_url_backup
  if (!url && !backup) return null
  return <ImageMedia className={className} url={url} backup={backup} />
}

function Media({ platform, content, className = "", showVideo = false }) {
  switch (platform) {
    case "instagram":
      return <IGMedia className={className} content={content} showVideo={showVideo} />
    case "ugc":
      return <UGCMedia className={className} content={content} showVideo={showVideo} />
    case "youtube":
      return <YTMedia className={className} content={content} />
    case "tiktok":
      return <TTMedia className={className} content={content} />
    default:
      return null
  }
}

function EmbedMedia({ content, platform }) {
  switch (platform) {
    case "tiktok":
      return (
        <iframe
          className="embed-tiktok"
          src={`https://www.tiktok.com/player/v1/${content?.external_id}?autoplay=1`}
          title="Tiktok video"
          frameBorder="0"
        ></iframe>
      )
    case "youtube":
      return (
        <iframe
          className="embed-youtube"
          src={`https://www.youtube.com/embed/${content?.external_id}`}
          title="Youtube Video"
          frameBorder="0"
        ></iframe>
      )
    default:
      return null
  }
}

function MediaSelect(props) {
  switch (props.platform) {
    case "youtube":
      return <EmbedMedia {...props} />
    case "tiktok":
      return <EmbedMedia {...props} />
    case "instagram":
      return <IGMedia videoControls={true} {...props} />
    case "ugc":
      return <UGCMedia videoControls={true} {...props} />
    default:
      return null
  }
}
export { Media, IGMedia, TTMedia, YTMedia, EmbedMedia, MediaSelect }
