import React, { useState, useEffect, useContext } from "react"
import { OnboardingCreatorContainer } from "../onboadingCommon"
import { apiContext } from "../../contexts/apicontext"
import { dataContext } from "../../contexts/datacontext"
import { useLoading } from "../../contexts/loaderContext"
import { _lsd, _lsr } from "../../helpers/utils"
import { useNavigate } from "react-router-dom"
import { FullLoader } from "../loaders"
import { NewLineParser } from "../dumbs"

const DescriptionPage = ({ descriptionText }) => {
  const [data] = useContext(dataContext)
  const brandName = data?.brand_ecosystem_brand?.name

  return (
    <>
      <div className="max-width-526 nc75-fg">
        <div className="flex a-center j-center margin-bottom-4x">
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="57" fill="none" viewBox="0 0 56 57">
            <path
              fill="#1D65E9"
              d="M37.427 18.66 23.615 33.58l-5.042-5.504c-.262-.227-.682-.227-.893 0l-1.523 1.646c-.21.227-.21.68 0 .964l7.038 7.546c.262.284.63.284.892 0l15.756-17.02c.21-.228.21-.682 0-.965l-1.524-1.589c-.21-.284-.63-.284-.892 0Z"
            />
            <rect width="53" height="53" x="1.5" y="1.946" stroke="#1D65E9" strokeWidth="3" rx="26.5" />
          </svg>
        </div>

        <h2 className="headline-large margin-bottom-5x txt-center">All set! Thank you for joining!</h2>

        <p className="  body-regular-font nc50-fg txt-center margin-bottom-12x">
          {descriptionText ? (
            <NewLineParser value={descriptionText} />
          ) : (
            <>
              You have successfully set up your profile for {brandName}. Your {brandName} rep will reach out to you for
              follow-up steps!
            </>
          )}
        </p>
      </div>

      {/* <Button onClick={onContinueClick} className="max-width-400 margin-bottom-10 margin-x-auto" type='secondary' fluid size="large">My profile</Button> */}
    </>
  )
}

const OnboardingThankYou = (props) => {
  const [des, setDes] = useState(null)
  const api = useContext(apiContext)
  const [data] = useContext(dataContext)
  const [pageIndex, setPageIndex] = useState(0)
  const navigate = useNavigate()
  const { loaders } = useLoading()

  const onContinueClick = async () => {
    _lsd("brand-ecosystem-invite-refcode")
    navigate("/")
  }

  useEffect(() => {
    _lsd("brand-ecosystem-invite-refcode")
    let invite_config = _lsr("invite_config")
    if (invite_config?.thank_you_text) {
      setDes(invite_config?.thank_you_text)
    }
    _lsd("invite_config")
  }, [])

  useEffect(() => {
    if (!data?.brand_ecosystem_brand?.name) {
      const last_ecosystem_brand_id =
        data?.me?.creator_ecosystem?.length >= 1
          ? data?.me?.creator_ecosystem[data?.me?.creator_ecosystem?.length - 1]?.brand
          : null
      console.log(last_ecosystem_brand_id)
      if (last_ecosystem_brand_id) {
        api.creator.getBrandInfoFromInviteIDOrBrandID(undefined, last_ecosystem_brand_id)
      }
    }
  }, [data?.brand_ecosystem_brand?.name, data?.me?.creator_ecosystem])

  if (loaders["get_brand_invite_info"] || loaders?.me) {
    return <FullLoader />
  }

  return (
    <>
      <OnboardingCreatorContainer cardClassName="padding-y-11x">
        <DescriptionPage descriptionText={des} onContinueClick={onContinueClick} />
      </OnboardingCreatorContainer>
    </>
  )
}

export { OnboardingThankYou }
