import React from "react"
import PropTypes from "prop-types"

function retr(str) {
  var num = str.replace(/[^0-9]/g, "")
  return parseInt(num, 10)
}

export const Spacer = ({ width = 0, height = 0, className }) => {
  const gridSize = 4
  let style = {
    width: width + "px",
    height: height + "px",
    minWidth: width + "px",
    minHeight: height + "px",
  }
  if (typeof height === "string") {
    let number = height.match(/\d/g)
    // console.log(number)
  }

  return <div style={style} className={className}></div>
}

Spacer.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
}
