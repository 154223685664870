import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { DataContextProvider } from "./contexts/datacontext"
import { APIContextProvider } from "./contexts/apicontext"
import { ToastProvider } from "./hooks/useToast"
import { Auth0Provider } from "@auth0/auth0-react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { GlobalLoadingProvider } from "./contexts/loaderContext"
import { AUTH0_AUDIENCE, AUTH0_DOMAIN, AUTH0_KEY, AUTH0_REDIRECT_TO, STRIPE_KEY, GA_ID, DEBUG } from "./consts"
import ReactGA from "react-ga4"
// css
import "swiper/css"
import "./styles/index.scss"
import { ErrorBoundary } from "./components/errorBoundary"
import { Provider as JotaiProvider } from "jotai"
import { AtomLogger } from "./store/store"

//Initializations
const stripePromise = loadStripe(STRIPE_KEY)
ReactGA.initialize(GA_ID, { debug: DEBUG })

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <JotaiProvider>
        <AtomLogger />
        <Auth0Provider
          cacheLocation="localstorage"
          skipRedirectCallback={window.location.href.match(
            /oauth-callback-(tiktok|pinterest|youtube|paypal|instagram|facebook)/gi,
          )}
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_KEY}
          audience={AUTH0_AUDIENCE}
          redirectUri={AUTH0_REDIRECT_TO}
        >
          <DataContextProvider>
            <BrowserRouter>
              <Elements stripe={stripePromise}>
                <ToastProvider>
                  <GlobalLoadingProvider>
                    <APIContextProvider>
                      <App />
                    </APIContextProvider>
                  </GlobalLoadingProvider>
                </ToastProvider>
              </Elements>
            </BrowserRouter>
          </DataContextProvider>
        </Auth0Provider>
      </JotaiProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root"),
)
