import { num2Hip } from "../../helpers/utils"
import { Card } from "../dumbs"
import Chart from "react-apexcharts"

let barChartConfig = {
  series: [
    {
      data: [23, 32, 23, 21],
    },
  ],
  options: {
    chart: {
      type: "bar",
      // sparkline: {
      //     enabled: true  // this will remove gridlines, yaxis, xaxis labels, etc.
      // },

      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          // Customize the displayed value here.
          // This is just a simple example.
          return num2Hip(val)
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        distributed: true,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      // enabled: false  // This will hide the data labels
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#767B8A"],
      },
      formatter: function (val, opts) {
        return num2Hip(val)
      },
    },
    colors: [
      "#663FF1",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
      "#ACAFB9",
    ],
    xaxis: {
      snow: false,
      // categories: ["NC","DC","MR","SN"],
      labels: {
        rotate: -90, // Rotate the labels to make them vertical
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      paddding: {
        left: 0,
      },
    },
    grid: {
      show: false, // This will hide the grid
      padding: {
        left: 0,
      },
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      show: false, // This hides the entire legend
    },
  },
}

const donutChartConfig = {
  labels: ["Positive", "Negative", "Neutral"],
  fill: {
    colors: ["#663FF1", "#DD5C5C", "#FF9D00", "#ACAFB9", "#ACAFB9"],
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        // Customize the displayed value here.
        // This is just a simple example.
        return val.toFixed(2) + "%"
      },
    },
  },
  title: {
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 100,
    floating: true,
    style: {
      fontSize: "22px",
      fontFamily: "Poppins, Arial, sans-serif",
      fontWeight: 600,
      color: "#373d3f",
    },
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
}

const NotEnoughData = ({ text = "Not enough data", className = "" }) => {
  return (
    <div className={`min-height-248 flex a-center j-center ${className}`}>
      <h4 className="headline-small-highlight nc30-fg">{text}</h4>
    </div>
  )
}

const BarChart = ({
  title = "",
  data = [
    { x: "LD", y: 21 },
    { x: "OL", y: 10 },
    { x: "RB", y: 15 },
    { x: "MN", y: 25 },
  ],
  className = "",
  subtitle = "",
  colors,
  options,
}) => {
  return (
    <Card className={`padding-top-5x full ${className}`}>
      {title && <h3 className="padding-x-5x margin-bottom headline-small-highlight flex">{title}</h3>}
      {subtitle && <span className="caption-regular-font nc50-fg min-height-32 padding-x-5x flex">{subtitle}</span>}
      {!data || !data?.length ? (
        <NotEnoughData />
      ) : (
        <Chart
          series={[{ name: title, data: data }]}
          options={{
            ...barChartConfig.options,
            colors: colors || barChartConfig.options.colors,
            ...(options || {}),
          }}
          type="bar"
          height={250}
        />
      )}
    </Card>
  )
}

const DonutChart = ({
  title = "",
  data,
  labels,
  className = "",
  colors = [],
  height = 250,
  centerLabel,
  centerLabelOffsetY = 100,
  onDonutClick,
}) => {
  const handleClick = (event, chartContext, config) => {
    if (config.dataPointIndex < 0) return
    onDonutClick?.(config.dataPointIndex)
  }

  return (
    <Card className={`padding-y-5x full ${className}`}>
      {title && <h3 className="padding-x-5x margin-bottom-4x headline-small-highlight">{title}</h3>}
      {!data || !data?.length ? (
        <NotEnoughData />
      ) : (
        <Chart
          options={{
            ...donutChartConfig,
            chart: {
              events: {
                // click: handleClick,
                dataPointSelection: handleClick,
              },
            },
            title: {
              ...donutChartConfig.title,
              text: centerLabel || `${(data[0] - data[1]).toFixed(2)}%`,
              offsetY: centerLabelOffsetY,
            },
            fill: {
              colors: colors.length ? colors : donutChartConfig.fill.colors,
            },
            labels: labels || donutChartConfig.labels,
          }}
          series={data}
          type="donut"
          height={height}
        />
      )}
    </Card>
  )
}

const SmallBarNegativeChart = ({
  title = "",
  data = null,
  categories = [],
  className = "",
  subtitle = "",
  onBarClick,
  height = 200,
  enableCenterZero = false,
}) => {
  const handleClick = (event, chartContext, config) => {
    onBarClick?.({
      category: categories[config.dataPointIndex],
      type: config.seriesIndex === 0 ? "positive" : "negative",
    })
  }

  const maxNum = Math.max(...data?.positive, ...data?.negative.map((n) => -n))

  return (
    <Card className={`padding-top-5x full ${className}`}>
      {(title || subtitle) && (
        <div className="padding-x-3x gap-1 vcenter margin-bottom min-height-40">
          {title && <h3 className="headline-small-highlight">{title}</h3>}{" "}
          {subtitle && <span className="margin-left-1x caption-regular-font nc50-fg inline flex">{subtitle}</span>}
        </div>
      )}
      {!data ? (
        <NotEnoughData />
      ) : (
        <Chart
          series={[
            {
              name: "Positive",
              data: data?.positive || [],
            },
            {
              name: "Negative",
              data: data?.negative || [],
            },
          ]}
          options={{
            chart: {
              type: "bar",
              stacked: true,
              toolbar: {
                show: false,
              },
              events: {
                click: handleClick,
              },
            },
            colors: ["#32EAC9", "#F67979"],
            plotOptions: {
              bar: {
                borderRadius: 0,
                borderRadiusApplication: "end", // 'around', 'end'
                borderRadiusWhenStacked: "all", // 'all', 'last'
                horizontal: false,
                barHeight: "60%",
                dataLabels: {
                  position: "top", // top, center, bottom
                  total: {
                    offsetX: 0,
                    offsetY: 10,
                  },
                  hideOverflowingLabels: false,
                },
              },
            },
            dataLabels: {
              enabled: true,
              offsetY: -20,
              style: {
                fontSize: "12px",
                colors: ["#767B8A"],
                fontWeight: 400,
              },
              formatter: function (val, opts) {
                return typeof val == "number" ? num2Hip(val > 0 ? val : -val) : ""
                // return val < 0 ? num2Hip(val * -1) : num2Hip(val);
              },
            },
            stroke: {
              width: 1,
              colors: ["#fff"],
            },
            legend: {
              show: false,
            },
            grid: {
              show: false,
            },
            yaxis: {
              stepSize: 1,
              show: false,
              // increase the max value 20% to make the chart look better
              min: enableCenterZero ? maxNum * -1.2 : undefined,
              max: enableCenterZero ? maxNum * 1.2 : undefined,
            },
            xaxis: {
              categories: categories, // ['Biden', 'Trump', 'Kennedy'],
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
            },
            tooltip: {
              shared: false,
              x: {
                formatter: function (val) {
                  return val
                },
              },
              y: {
                formatter: function (val) {
                  return val < 0 ? num2Hip(val * -1) : num2Hip(val)
                },
              },
            },
          }}
          type="bar"
          height={height}
        />
      )}
    </Card>
  )
}

export { NotEnoughData, BarChart, DonutChart, SmallBarNegativeChart }
