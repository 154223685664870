import { useEffect, useRef, useState } from "react"
import { hotTextbox } from "../helpers/utils"
import { RoundButton } from "./dumbs"

function EditableTextfield({
  value,
  onChange,
  className = "",
  editModeTailComponent = null,
  defaultEditMode = false,
  readOnly = false,
  ...rest
}) {
  const [editMode, setEditMode] = useState(false)
  const inp = useRef()
  useEffect(() => {
    if (defaultEditMode) return
    if (editMode && inp.current) hotTextbox(inp.current)
  }, [editMode])

  useEffect(() => {
    setEditMode(defaultEditMode)
  }, [])

  return (
    <div
      className={`creator-right-settings-editable within-focus-parent-border border-2 border-radius-dynamic nc05-border ${className}`}
    >
      <div className={`rs-common-section  flex padding-y-2x padding-x-3x`}>
        <div className="rs-rightsection flex a-center full">
          <div className="rs-dollaramount button-medium-font nc50-fg flex full a-center">
            {editMode ? (
              <input
                value={value}
                ref={inp}
                onChange={onChange}
                {...rest}
                readOnly={readOnly}
                className="bare-input full min-height-32  button-medium-font margin-right"
                type="text"
              />
            ) : (
              <span className="full txt-left">{value}</span>
            )}
          </div>
          {!readOnly && (
            <>
              {editMode ? (
                editModeTailComponent
              ) : (
                <RoundButton onClick={() => setEditMode(true)} className="margin-left-2x" />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export { EditableTextfield }
