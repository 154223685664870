import { useContext, useEffect } from "react"
import { dataContext } from "../../contexts/datacontext"
import { useLoading } from "../../contexts/loaderContext"
import { HourglassLoader } from "../loaders"
import { ScrollSpy } from "../scrollspy"
import { num2Hip } from "../../helpers/utils"
import { PlatformIcon } from "../dumbs"
import { apiContext } from "../../contexts/apicontext"
import { useNavigate } from "react-router-dom"
import { platform_list_real } from "../../consts"

function CreatorRow({ data }) {
  const api = useContext(apiContext)
  const [ctxData] = useContext(dataContext)
  const gotoCreator = () => {
    api.analytics.link(`creator_search_clicked_creator_profile`, data.username)
  }
  return (
    <>
      <a
        href={`/${data.username}?filter=${ctxData.search_query}&mode=license`}
        onClick={gotoCreator}
        className="flex vcenter hasborder startborder padding-y-4x padding-x-3x cursor-pointer"
      >
        <img
          className="creator-search-avatar margin-right-2x"
          alt={data.username}
          src={data?.profile_picture_url}
        ></img>
        <span className="headline-medium-highlight nc100-fg search-creatorname">{data.username} </span>
      </a>
      <div className="platform-list-section hasborder flex vcenter padding-y-4x padding-x-2x">
        {platform_list_real.map((_p) => (
          <div key={_p} className="platform-data">
            {data?.connected_accounts[_p] ? (
              <div className="flex inline nc30-fg vcenter">
                <a
                  target="_blank"
                  onClick={api.analytics.link("clicks_profile_link_external", data?.username)}
                  rel="noreferrer"
                  href={data?.connected_accounts[_p]?.external_url}
                >
                  <PlatformIcon platform={_p} color={true} />
                </a>
                <span className="margin-left margin-right-3x nc75-fg">
                  {num2Hip(data.connected_accounts[_p].followers)}
                </span>
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <div className="nc02-bg hasborder flex vcenter hcenter nc75-fg padding-y-4x">
        {data.views_per_post ? num2Hip(data.views_per_post) : "N/A"}
      </div>
      <div className="nc02-bg hasborder flex vcenter hcenter nc75-fg padding-y-4x">
        {data.engagement_per_post ? `${data.engagement_per_post}%` : "N/A"}
      </div>
      <div className="nc02-bg hasborder flex vcenter hcenter nc75-fg padding-y-4x">
        {data.posts_about ? num2Hip(data.posts_about) : "N/A"}
      </div>
      <div className="nc02-bg hasborder endborder flex vcenter hcenter nc75-fg padding-y-4x">
        {data.engagement_per_post_about ? `${data.engagement_per_post_about}%` : "N/A"}
      </div>
    </>
  )
}

function SearchCreators({ contents = null }) {
  const [data, dispatch] = useContext(dataContext)
  const { loaders } = useLoading()
  const api = useContext(apiContext)
  // release content memory on exit
  const searchNextPage = (platform, next) => {
    if (!platform || !next) return false
    api.common.creatorSearch(data.search_query, platform, next)
  }
  useEffect(() => {
    ;(async () => {
      await api.common.searchCreators(data.search_query, data.search_platform)
    })()
  }, [data.search_query, data.search_platform])
  useEffect(() => {
    api.analytics.action("search", `visit_creator_search_page`, data.search_query)
    return () => dispatch({ creator_search_result: null })
  }, [])
  return (
    <>
      <div className={`margin-top-6x cs-homepage-shrinked-top-padding margin-bottom-11x`}>
        {data?.creator_search_result?.length && !loaders[`creator_search`] ? (
          <div className="creator-search-table margin-x-2x">
            <div className="padding-y-2x padding-x-3x nc50-fg">Creator</div>
            <div className="padding-y-2x padding-x-3x nc50-fg follower-count-head-search">Followers</div>
            <div className="padding-y-2x padding-x-2x nc50-fg txt-center">Avg. views</div>
            <div className="padding-y-2x padding-x-2x nc50-fg txt-center">Engagement</div>
            <div className="padding-y-2x padding-x-2x nc50-fg txt-center">
              '{data?.search_query}'<br />
              count
            </div>
            <div className="padding-y-2x padding-x-2x nc50-fg txt-center">
              '{data?.search_query}'<br />
              engagement
            </div>
            {/* <div className="padding-y-2x padding-x-2x nc50-fg txt-right">Services</div> */}
            {/* <div className="padding-y-2x padding-x-2x nc50-fg txt-center">Starting<br/>prices</div> */}
            {data?.creator_search_result?.map?.((_cr) => (
              <CreatorRow key={_cr.username} data={_cr} />
            ))}
          </div>
        ) : null}
        {!data?.creator_search_result?.length && !loaders[`creator_search`] ? (
          <div className="margin-y-10x margin-x-2x txt-center nc75-fg caption-regular-font">No results found</div>
        ) : null}
      </div>
      {loaders[`creator_search`] || loaders[`creator_search_next_page`] ? (
        <div className="flex hcenter vcenter margin-y-11x nc50-fg full-grid-width">
          <HourglassLoader className="margin-right-2x" /> Loading
        </div>
      ) : null}
      {data?.creator_search_result_raw?.pagination?.next ? (
        <ScrollSpy
          rebindTrigger={data.creator_search_result_raw.pagination.next}
          onEngage={() => searchNextPage(data.search_platform, data.creator_search_result_raw.pagination.next)}
        />
      ) : null}
    </>
  )
}

export { SearchCreators }
