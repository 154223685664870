import propTypes from "prop-types"
import { Hourglass } from "./Icons"
import InfinityLoaderLottie from "./../assets/animation/inifinityLoading.json"
import Lottie from "react-lottie"

function Loader({
  loading = true,
  bodyColor = "nc00",
  trackColor = "nc30",
  animationDuration = "0.5",
  className = "",
}) {
  if (!loading) return null
  return (
    <div
      style={{ animationDuration: `${animationDuration}s` }}
      className={`cs-loader cs-loader-circle ${bodyColor}-body ${trackColor}-track ${className}`}
    ></div>
  )
}

function HourglassLoader({ animationDuration = "1", color = "nc50", className = "" }) {
  return (
    <div
      style={{ animationDuration: `${animationDuration}s` }}
      className={`cs-loader cs-loader-hourglass ${color}-hourglass ${className}`}
    >
      {Hourglass}
    </div>
  )
}

function FullLoader(props) {
  return (
    <div className="fat-loader flex hcenter vcener margin-y-5x">
      <Loader {...props} />
    </div>
  )
}

function InfinityLoader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: InfinityLoaderLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      clearCanvas: true,
    },
  }

  return <Lottie options={defaultOptions} />
}

const ShimmerLoader = ({ className = "", titleLineCount = 1, contentLineCount = 3 }) => {
  return (
    <div className={`shimmer-card ${className}`}>
      <div className="margin-bottom-2x">
        <div className="shimmer-title-line  shimmerBG"></div>
      </div>

      <div>
        <div className="shimmer-content-line  shimmerBG" />
        <div className="shimmer-content-line  shimmerBG" />
        <div className="shimmer-content-line  shimmerBG" />
      </div>
    </div>
  )
}

// this could be used in the future for other components
export const ColorArrray = [
  "nc00",
  "nc02",
  "nc05",
  "nc10",
  "nc30",
  "nc50",
  "nc75",
  "nc100",
  "primary",
  "ultralight",
  "medium",
  "dark",
  "moredark",
  "positive",
  "negative",
]

Loader.propTypes = {
  loading: propTypes.bool,
  animationDuration: propTypes.number,
  bodyColor: propTypes.oneOf(ColorArrray),
  trackColor: propTypes.oneOf(ColorArrray),
}

export { Loader, FullLoader, HourglassLoader, InfinityLoader, ShimmerLoader }
