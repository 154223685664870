import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import OnboardingImage from "../../assets/onboarding-social.png"
import { Button } from "./../button"
import { Spacer } from "./../spacer"
import {
  YoutubeLargeFilledColored,
  InstagramLargeFilledColored,
  TiktokLargeFilledColored,
  CheckLargeFilledMono,
} from "../Icons"
import { apiContext } from "../../contexts/apicontext"
import { _empty, _lsd, _lsw, _lsr } from "../../helpers/utils"
import { dataContext } from "../../contexts/datacontext"
import { useSocial } from "../../hooks/useSocial"
import { useLoading } from "../../contexts/loaderContext"
import { Loader } from "../loaders"
import { OnboardingCreatorContainer } from "../onboadingCommon"
import { FORCE_ACCOUNT_CONNECT_ONBOARDING } from "../../consts"

export const ButtonAfterElement = ({ me_social_list, platform }) => {
  const { loaders } = useLoading()
  if (loaders?.creator_me) return <Loader trackColor="primary" />
  if (me_social_list?.length && me_social_list?.indexOf(platform) !== -1)
    return <div className="primary-fg">{CheckLargeFilledMono}</div>
  return null
}

export const OnBoardingConnectSocialPage = () => {
  const navigate = useNavigate()
  const api = useContext(apiContext)
  const [data] = useContext(dataContext)
  const { connect } = useSocial()
  const { loaders } = useLoading()

  useEffect(() => {
    _lsd("cs_last_login_step")
    api.analytics.page("/start-connect-socials", "Creator Onboarding: Connect Socials")
  }, [])

  const onContinueClick = async () => {
    navigate("/start-basic-info")
  }
  const invite_config = _lsr("invite_config")

  let skip_platform = [] // only youtube & instagram is supported

  if (invite_config?.skip_platform && Array.isArray(invite_config?.skip_platform)) {
    skip_platform = invite_config.skip_platform
  }

  const skip_youtube = skip_platform?.includes("youtube")
  const skip_instagram = skip_platform?.includes("instagram")

  const onSocialClick = (platform) => {
    _lsw("cs_last_login_step", "/start-connect-socials")
    connect(platform)
  }

  return (
    <OnboardingCreatorContainer>
      <div className="">
        <div className="">
          <div className="margin-top-12x margin-bottom-10x max-width-495">
            <div className="margin-bottom-5x margin-x-auto flex j-center ">
              <div className="onboarding-steps-number  ">1</div>
            </div>
            <h3 className="headline-large txt-center nc100-fg margin-bottom-5x ">
              Let’s connect Your Social Media Accounts
            </h3>

            <p className="body-regular-font txt-center nc50-fg  ">
              Connect your accounts to make your content discoverable, so{" "}
              {data?.brand_ecosystem_brand?.name ?? "brands"} can consider you for content creation projects.
            </p>
            {/* <p className="only-creator-bussiness-text caption txt-center">Only creator and business accounts</p> */}
          </div>

          <div className="max-width-400 margin-x-auto">
            <Button
              className="after-element-at-the-end margin-bottom-2x"
              fluid
              size="large"
              type="tertiary"
              beforeElement={TiktokLargeFilledColored}
              afterElement={<ButtonAfterElement me_social_list={data?.me_social_list} platform="tiktok" />}
              onClick={() => onSocialClick("tiktok")}
            >
              {data?.me_social_list?.length && data?.me_social_list?.indexOf("tiktok") !== -1
                ? "Tiktok Connected"
                : "Connect your Tiktok Account"}
            </Button>
            {!skip_instagram ? (
              <Button
                className="after-element-at-the-end margin-bottom-2x"
                fluid
                size="large"
                type="tertiary"
                beforeElement={InstagramLargeFilledColored}
                afterElement={<ButtonAfterElement me_social_list={data?.me_social_list} platform="instagram" />}
                onClick={() => onSocialClick("instagram")}
              >
                {data?.me_social_list?.length && data?.me_social_list?.indexOf("instagram") !== -1
                  ? "Instagram Connected"
                  : "Connect your Instagram Account"}
              </Button>
            ) : null}
            {!skip_youtube ? (
              <Button
                className="after-element-at-the-end margin-bottom-2x"
                fluid
                size="large"
                type="tertiary"
                beforeElement={YoutubeLargeFilledColored}
                afterElement={<ButtonAfterElement me_social_list={data?.me_social_list} platform="youtube" />}
                onClick={() => onSocialClick("youtube")}
              >
                {data?.me_social_list?.length && data?.me_social_list?.indexOf("youtube") !== -1
                  ? "YouTube Connected"
                  : "Connect your YouTube Account"}
              </Button>
            ) : null}

            <Button
              className="margin-top-10x margin-bottom-2x"
              disabled={
                loaders?.creator_claimname ||
                !(FORCE_ACCOUNT_CONNECT_ONBOARDING === "false" ? true : data?.me_social_list?.length)
              }
              fluid
              size="large"
              type="primary"
              onClick={onContinueClick}
            >
              {loaders?.creator_claimname ? <Loader /> : "Continue"}
            </Button>

            <p className="social-media-connection-txt nc30-fg button-small-font txt-center margin-top-6x margin-bottom-6x">
              Please connect all accounts for which you would like to be considered for content creation projects.
            </p>
          </div>
        </div>
      </div>
    </OnboardingCreatorContainer>
  )
}

OnBoardingConnectSocialPage.propTypes = {}
