import React, { useState, useContext, useEffect } from "react"

import { Button } from "../button"
import { OnboardingCreatorContainer } from "../onboadingCommon"
import { apiContext } from "../../contexts/apicontext"
import { dataContext } from "../../contexts/datacontext"
import { useNavigate } from "react-router-dom"

const OnBoardingCreatorConnectGraphApi = (props) => {
  const navigate = useNavigate()

  const [data, dispatch] = useContext(dataContext)

  const onContinueClick = () => {
    dispatch({ graph_api_minitour_pre_dialog_show: true })
  }
  useEffect(() => {
    if (data?.me?.connected_accounts?.instagram?.facebook_connected_instagram_page) {
      navigate("/start-thank-you")
    }
  }, [data?.me?.connected_accounts?.instagram?.facebook_connected_instagram_page])

  return (
    <OnboardingCreatorContainer cardClassName="padding-y-11x">
      <>
        <div className="max-width-526 nc75-fg">
          <h2 className="headline-large max-width-328 txt-center  nc100-fg margin-x-auto margin-bottom-5x">
            Finally, connect IG graph API to add post statistics
          </h2>
          <p className="body-regular-font margin-bottom-2x max-width-495 nc50-fg txt-center">
            To consider you for content creation, {data?.brand_ecosystem_brand?.name ?? "Brand"} needs to see your post
            and profile statistics. For this Instagram requires to connect with their “graph API”
            <br></br>
            <br></br>
            <span className="body-highlight-font nc50-fg">
              We promise this is the final step to complete your profile.
            </span>
          </p>
        </div>
        <Button onClick={onContinueClick} className="max-width-495 margin-top-15x margin-bottom-3x" fluid size="large">
          Connect now
        </Button>
        <Button
          onClick={() => navigate("/start-thank-you")}
          className="max-width-495 margin-bottom-10x negative-fg-override"
          type="ghost"
          fluid
          size="large"
        >
          I don't want to be compensated for my posts
        </Button>
      </>
    </OnboardingCreatorContainer>
  )
}

export { OnBoardingCreatorConnectGraphApi }
