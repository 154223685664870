const {
  // VITE_API_BASE : API_BASE = "https://api-dev.hellozelf.com/api/v1/",
  VITE_API_BASE: API_BASE = "https://api-dev.hellozelf.com/api/v1/",
  // VITE_API_BASE : API_BASE = "https://api-devusa.hellozelf.com/api/v1/",
  // VITE_API_BASE : API_BASE = "http://localhost:8000/api/v1/",
  MODE: ENV = "development",
  VITE_TIKTOK_CLIENT_KEY: TT_CLIENT_KEY = "awera4m7h1y3nb7g",
  VITE_PINTEREST_CLIENT_ID: PIN_CLIENT_ID = "1476465",
  VITE_PAYPAL_ID: PP_CLIENT_ID = "AdCSm2ytbrIRGVPjsWX3OQ3qCYufRVUF7McL19JYc5pxmZUXX4dQOgK_a1bsJg8uoYVZ7ZfeGGAPOYM5",
  VITE_YT_CLIENT_ID: YT_CLIENT_ID = "613449662589-e57504q6mcs4l89q9941te5h43kbd37m.apps.googleusercontent.com",
  VITE_IG_CLIENT_ID: IG_CLIENT_ID = "571694674191137",
  VITE_IG_GRAPH_APP_ID: FB_GRAPH_APP_ID = "540305763974959",
  VITE_AUTH0_DOMAIN: AUTH0_DOMAIN = "login.hellozelf.com",
  VITE_AUTH0_CLIENT_ID: AUTH0_KEY = "dHpQm3jDbnDW968rhYNfHI9PJ9kz02as",
  VITE_AUTH0_AUDIENCE: AUTH0_AUDIENCE = "https://plato.hellozelf.com",
  VITE_AUTH0_REDIRECT_URI: AUTH0_REDIRECT_TO = window.location.origin,
  VITE_STRIPE_PUBLISHABLE_KEY:
    STRIPE_KEY = "pk_test_51JeByuHLsco1QmINSoBlzabwSh1wdV9LiYqHivKsgiHjKK7Kl22EuATc7jsl0wRz5QgwsULk89lB0Cwg0MzRBzHo00HnDMxI7p",
  VITE_PUBLIC_URL: PUBLIC_URL = "hellozelf.com",
  VITE_REFERRAL_BASE_URL: REF_URL = "hellozelf.com/invite-dev",
  VITE_GA_MEASUREMENT_ID: GA_ID = "G-DK2CZV6B4K",
  VITE_DEFAULT_CREATOR_FILTER_TIMEFRAME: DEFAULT_CREATOR_FILTER_TIMEFRAME = "60",
  VITE_DEFAULT_CONTENT_FILTER_TIMEFRAME: DEFAULT_CONTENT_FILTER_TIMEFRAME = "30",
  VITE_NEW_CREATOR_TIMEFRAME: NEW_CREATOR_TIMEFRAME = "60",
  VITE_PERMITTED_UPLOAD_FORMATS: UPLOAD_FORMATS = ".pdf,.doc,.docx,.xlsx,.ppt,.key",
  VITE_TIKAPI_OAUTH_ID: TIKAPI_OAUTH_ID = "c_V86F6YB7BG",
  VITE_FORCE_ACCOUNT_CONNECT_ONBOARDING: FORCE_ACCOUNT_CONNECT_ONBOARDING = "false",
} = import.meta.env

const platforms = {
  ugc: {
    title: "UGC",
    slug: "ugc",
    content_path: "UgcPortfolio/__uuid__/contents/",
    sample_content_path: "UgcPortfolio/__uuid__/top_contents/",
    connect_path: "/UgcPortfolio/",
    detail_path: "/UgcPortfolioContent/__uuid__/",
    top_content_path: "/UgcPortfolioContent/__uuid__/set_top_content/",
    key: "uuid",
  },
  tiktok: {
    title: "TikTok",
    slug: "tiktok",
    content_path: "/Tiktok/__uuid__/contents/",
    sample_content_path: "/Tiktok/__uuid__/top_contents/",
    connect_path: "/Tiktok/",
    detail_path: "/TiktokContent/__uuid__/",
    top_content_path: "/TiktokContent/__uuid__/set_top_content/",
    key: "uuid",
  },
  instagram: {
    title: "Instagram",
    slug: "instagram",
    content_path: "Instagram/__uuid__/contents/",
    sample_content_path: "Instagram/__uuid__/top_contents/",
    connect_path: "/Instagram/",
    detail_path: "/InstagramContent/__uuid__/",
    top_content_path: "/InstagramContent/__uuid__/set_top_content/",
    key: "uuid",
  },
  youtube: {
    title: "YouTube",
    slug: "youtube",
    content_path: "/Youtube/__uuid__/contents/",
    sample_content_path: "/Youtube/__uuid__/top_contents/",
    connect_path: "/Youtube/",
    detail_path: "/YoutubeContent/__uuid__/",
    top_content_path: "/YoutubeContent/__uuid__/set_top_content/",
    key: "uuid",
  },
}

const payouts = {
  paypal: {
    title: "Paypal",
    slug: "paypal",
    connect_path: "/Payment/Paypal/",
  },
}
const licenses = {
  SOCIAL_MEDIA: {
    title: "Repost on social media (Non-advertisement; worldwide)",
    details:
      "Standard reposting of a creators’ unedited original content on the brand’s own social media profiles, without applying any funds for enhanced distribution.",
  },
  SOCIAL_MEDIA_TAGGED: {
    title: "Repost on social media (Non-advertisement; worldwide)",
    details:
      "Standard reposting of a creators’ unedited original content on the brand’s own social media profiles, without applying any funds for enhanced distribution.",
  },
  INTEGRATION: {
    title: "Integration in website (Non-advertisement; worldwide)",
    details: "Embedding the original content as UGC on the licensee’s website, e-commerce store, or other web-presence",
  },
  AD_CAMPAIGN: {
    title: "Integration in Ad Campaign",
    details:
      "A license to edit and use the content in a digital campaign with the intention of running the content as advertisement",
  },
  WHITELISTING: {
    title: "Whitelisting on Creator Profile",
    details:
      "A license to edit and use the content in a digital campaign with the intention of running the content as advertisement",
  },
}
const right_durations = {
  1: {
    title: "Per Day",
    alias: "1",
    key: "daily",
  },
  7: {
    title: "Per Week",
    alias: "7",
    key: "weekly",
  },
  30: {
    title: "Per Month",
    alias: "30",
    key: "monthly",
  },
  90: {
    title: "Per Quarter",
    alias: "90",
    key: "quarterly",
  },
  365: {
    title: "Per Year",
    alias: "365",
    key: "yearly",
  },
  DAILY: {
    title: "Per Day",
    alias: "1",
    key: "daily",
  },
  WEEKLY: {
    title: "Per Week",
    alias: "7",
    key: "weekly",
  },
  MONTHLY: {
    title: "Per Month",
    alias: "30",
    key: "monthly",
  },
  QUARTERLY: {
    title: "Per Quarter",
    alias: "90",
    key: "quarterly",
  },
  YEARLY: {
    title: "Per Year",
    alias: "365",
    key: "yearly",
  },
  SINGLE_USE: {
    title: "Single Use",
  },
}

const media_types = {
  instagram: [],
  tiktok: [],
  pinterest: [],
  youtube: [
    // {
    //     title: "Videos",
    //     slug: "VIDEO"
    // },
    // {
    //     title: "Shorts",
    //     slug: "SHORT"
    // },
  ],
}

const ROLES = ["creator", "brand"]
const platform_list = Object.keys(platforms)
const platform_list_real = platform_list.filter((p) => p !== "ugc")
const platform_list_search = ["tiktok", "instagram", "youtube"]
const ANIMATION_DURATION = 200 // Toast and dialog. change in css as well
const API_TIMEOUT = 500000
const DEFAULT_CACHE_VALIDITY = 300 // seconds
const AUTH0_TOKEN_CONF = {}
const LOCALSTORAGE_PREFIX = "cs__"
const CACHE_PREFIX = "cache__"
const CURRENCY = "USD"
const DEFAULT_ITEMS_PER_PAGE = 24
const LOG_PROMISE_RESOLVES = Boolean(ENV === "development")
const DEBUG = Boolean(ENV === "development")
const POLL_INTERVAL_MS = 2500
const POLL_MAX_COUNT = 19
// Links

const LINK_TO_TERMS = "https://hellozelf.com/wp-content/uploads/2022/03/Zelf.-Terms-of-Service.pdf"
const LINK_TO_REF_TERMS =
  "https://hellozelf.nyc3.cdn.digitaloceanspaces.com/files/Referral%20program%20Terms%20&%20Conditions.pdf"

// goal is to have all the api calls linked from here
// mutation calls
const GET_CALLS = {
  // discover section posts
  general_contents: {
    endpoint: "/Content/",
    section: "general_contents",
  },
  trending_posts: {
    endpoint: "/Content/",
    section: "trending_posts",
  },
  owned_posts: {
    endpoint: "/Content/",
    section: "owned_posts",
  },
  influencer_posts: {
    endpoint: "/Content/",
    section: "influencer_posts",
  },
  competitor_owned_posts: {
    endpoint: "/Content/",
    section: "competitor_owned_posts",
  },
  competitor_influencer_posts: {
    endpoint: "/Content/",
    section: "competitor_influencer_posts",
  },
  competitor_organic_posts: {
    endpoint: "/Content/",
    section: "competitor_organic_posts",
  },
  social_priority_posts: {
    endpoint: "/Content/",
    section: "social_priority_posts",
  },
  creator_public_not_zelf_profile_content: {
    endpoint: "/Content/",
    section: "creator_public_not_zelf_profile_content",
  },
  campaign_contents: {
    _endpoint_example: "/Campaign/${data?.selected_campaign_for_viewing?.id}/contents/",
    section: "campaign_contents",
  },
  community_posts: {
    endpoint: "/Content/",
    section: "community_posts",
  },
  community_posts_comment_only: {
    endpoint: "/Content/",
    section: "community_posts_comment_only",
  },
  negative_nps_posts: {
    endpoint: "/Content/",
    section: "negative_nps_posts",
  },
  all_posts: {
    endpoint: "/Content/",
    section: "all_posts",
  },
  all_posts_30_default: {
    endpoint: "/Content/",
    section: "all_posts_30_default",
  },
  god_view_trending_organic_posts: {
    endpoint: "/Content/",
    section: "god_view_trending_organic_posts",
  },
  god_view_trending_influencer_posts: {
    endpoint: "/Content/",
    section: "god_view_trending_influencer_posts",
  },
  god_view_trending_owned_posts: {
    endpoint: "/Content/",
    section: "god_view_trending_owned_posts",
  },
  get_brand_comment_replied_comments: {
    endpoint: "/ContentComment/",
    pooling: false,
    throwOriginalError: false,
    section: "get_brand_comment_replied_comments",
  },
  mass_get_content_comments: {
    endpoint: "/ContentComment/",
    pooling: false,
    throwOriginalError: false,
    section: "mass_get_content_comments",
  },
  content_stats: {
    section: "content_stats",
    endpoint: "/Content/content_stats/",
    pooling: false,
    throwOriginalError: false,
  },
  data_point_specific_content: {
    section: "data_point_specific_content",
    endpoint: "/Content/",
  },
  mass_engage_owned: {
    section: "mass_engage_owned",
    endpoint: "/Content/engage_contents/",
  },
  mass_engage_archive: {
    section: "mass_engage_archive",
    endpoint: "/Content/engage_contents/",
  },
  mass_engage_all: {
    section: "mass_engage_all",
    endpoint: "/Content/engage_contents/",
  },
  mass_engage_failed: {
    section: "mass_engage_failed",
    endpoint: "/Content/engage_contents/",
  },
  mass_engage_pending: {
    section: "mass_engage_pending",
    endpoint: "/Content/engage_contents/",
  },
  mass_engage_answered: {
    section: "mass_engage_answered",
    endpoint: "/Content/engage_contents/",
  },
  mass_engage_open: {
    section: "mass_engage_open",
    endpoint: "/Content/engage_contents/",
  },
  discovery_analytics_all_posts_influencers: {
    section: "discovery_analytics_all_posts_influencers",
    endpoint: "/Content/content_stats/",
    filter: {
      is_influencer_content: true,
      // timeframe: 30,
      mention_type__not: 2,
      fe_section: "discovery_analytics_all_posts_influencers",
    },
  },
  discovery_analytics_all_posts_owned: {
    section: "discovery_analytics_all_posts_owned",
    endpoint: "/Content/content_stats/",
    filter: {
      is_owned_by_brand: true,
      // timeframe: 30,
      mention_type__not: 2,
      fe_section: "discovery_analytics_all_posts_owned",
    },
  },
  discovery_analytics_all_owned: {
    section: "discovery_analytics_all_owned",
    endpoint: "/Content/content_stats/",
    filter: {
      // is_influencer_content: false,
      timeframe: 30,
      is_owned_by_brand: true,
      mention_type__not: 2,
      fe_section: "discovery_analytics_all_owned",
    },
  },
  discovery_analytics_all_posts: {
    section: "discovery_analytics_all_posts",
    endpoint: "/Content/content_stats/",
    filter: {
      is_influencer_content: false,
      // timeframe: 30,
      is_owned_by_brand: false,
      mention_type__not: 2,
      // fe_section:"discovery_analytics_all_posts",
    },
  },
  campaign_detail_list: {
    section: "campaign_detail_list",
    endpoint: "/Campaign/",
  },
  get_brand_comment_replied_content: {
    section: "get_brand_comment_replied_content",
    endpoint: "/Content/engage_contents/",
  },
  content_modal_stats_details: {
    section: "content_modal_stats_details",
    _endpoint_example: "/Content/${data?.ecosystem_selected_content?.content?.id}/details/",
  },
  selected_board_content: {
    section: "selected_board_content",
    _endpoint_example: "/Board/${data?.selected_board_for_viewing?.id}/contents",
  },
  selected_board_details: {
    section: "selected_board_details",
    _endpoint_example: "/Board/${data?.selected_board_for_viewing?.id}/",
  },
  all_board_list: {
    section: "all_board_list",
    endpoint: "/Board/",
  },
  trending_issues: {
    section: "trending_issues",
    endpoint: "/BrandAnalytics/aggregate/count_and_engagement/",
  },
  comment_restrictions: {
    section: "comment_restrictions",
    endpoint: "/CommentRestrictionStatus/",
  },
  comment_status_count: {
    section: "comment_restrictions",
    endpoint: "/Comment/status_count/",
  },
  key_performance_metrics: {
    section: "key_performance_metrics",
    endpoint: "/BrandAnalytics/key_performance_metrics/",
  },
  key_performance_metrics: {
    section: "key_performance_metrics",
    endpoint: "/BrandAnalytics/key_performance_metrics/",
  },
  best_performing_owned_posts: {
    section: "best_performing_owned_posts",
    endpoint: "/BrandAnalytics/best_performing_owned_contents_kpm/",
  },
  worst_performing_owned_posts: {
    section: "worst_performing_owned_posts",
    endpoint: "/BrandAnalytics/worst_performing_owned_contents_kpm/",
  },

  brand_tags: {
    section: "brand_tags",
    endpoint: "/BrandTags/",
  },
  // comment stats
  comment_stats: {
    section: "comment_stats",
    endpoint: "/Comment/posted_comment_key_performance_metrics",
  },
  brand_daily_comment_stats: {
    section: "key_performance_metrics",
    endpoint: "/BrandDailyCommentStats/",
  },
  comment_stats_time_series: {
    section: "comment_stats_time_series",
    endpoint: "/Comment/posted_comment_timeseries_analytics/",
  },
  comment_stats_user_leaderboard: {
    section: "comment_stats_user_leaderboard",
    endpoint: "/Comment/user_leaderboard",
  },
  comment_stats_target_vs_progress: {
    section: "comment_stats_target_vs_progress",
    endpoint: "/Comment/target_vs_posted_analytics/",
  },

  // brand stats timeframe

  timeseries_brand_total_engagement: {
    section: "timeseries_brand_total_engagement",
    endpoint: "/BrandAnalytics/time_series/total_engagement/",
  },
  timeseries_followers: {
    section: "timeseries_followers",
    endpoint: "/BrandAnalytics/time_series/followers/",
  },
  total_people_posted: {
    section: "total_people_posted",
    endpoint: "/BrandAnalytics/time_series/total_people_posted/",
  },
  timeseries_brand_mentions: {
    section: "timeseries_brand_mentions",
    endpoint: "/BrandAnalytics/time_series/mentions/",
  },
  timeseries_brand_engagement: {
    section: "timeseries_brand_engagement",
    endpoint: "/BrandAnalytics/time_series/engagement/",
  },
  timeseries_nps: {
    section: "timeseries_nps",
    endpoint: "/BrandAnalytics/time_series/nps/",
  },
}

const MUTATION_CALLS = {
  remove_from_ecosystem: {
    section: "remove_from_ecosystem",
    endpoint: "/CreatorEcoSystem/remove/",
    method: "post",
  },
  add_creators_to_board: {
    section: "add_creators_to_board",
    endpoint: `/Board/add_creator/`,
  },
  active_as_volunteer: {
    section: "active_as_volunteer",
    endpoint: `/User/political/user_brand/`,
  },
  update_brand_tags: {
    section: "update_brand_tags",
    endpoint: "/BrandTags/",
    method: "post",
  },
  update_sub_brand: {
    section: "update_sub_brand",
    _endpoint_example: "/Brand/${data?.sub_brand_id}/",
    method: "patch",
  },
  update_brand: {
    section: "update_brand",
    _endpoint_example: "/Brand/${data?.me?.brand?.id}/",
    method: "patch",
  },
  create_new_brand: {
    section: "create_new_brand",

    endpoint: "/Brand/",
    method: "post",
  },
}
// sortAndTimeFrame: without any apply any change of this will make api call
// filters: need to apply any filter to make api call

const DISCOVERY_POST_SECTION_CONFIG = {
  trending_posts: {
    sortAndTimeFrame: {
      sort_by: "impact",
      // timeframe: DEFAULT_CONTENT_FILTER_TIMEFRAME,
      // is_from_organic_search: true,
      // exc_comment_mentioned:true,
      // total_engagement__gte__or:1000,
      is_archived_from_trending_posts: false,
      views__gte__or: 10000,
      // exclusion_type:0,
      is_owned_by_brand: false,
      mention_type__not: 2, // no comment mention
    },
    filters: {
      // views__gte:10000
    },
  },
  god_view_trending_organic_posts: {
    sortAndTimeFrame: {
      sort_by: "impact",
      is_archived_from_trending_posts: false,
      views__gte__or: 10000,
      is_owned_by_brand: false,
      mention_type__not: 2, // no comment mention
    },
    filters: {
    },
  },
  god_view_trending_influencer_posts: {
    sortAndTimeFrame: {
      sort_by: "impact",
      is_archived_from_trending_posts: false,
      views__gte__or: 10000,
      is_owned_by_brand: false,
      mention_type__not: 2, // no comment mention
    },
    filters: {
    },
  },
  god_view_trending_owned_posts: {
    sortAndTimeFrame: {
      sort_by: "impact",
      is_archived_from_trending_posts: false,
      views__gte__or: 10000,
      is_owned_by_brand: false,
      mention_type__not: 2, // no comment mention
    },
    filters: {
    },
  },
  owned_posts: {
    sortAndTimeFrame: {
      sort_by: "impact",
      // timeframe: DEFAULT_CONTENT_FILTER_TIMEFRAME,
      // exc_comment_mentioned:true,
      // total_engagement__gte__or:1000,
      is_archived_from_trending_posts: false,
      mention_type__not: 2, // no comment mention
      is_owned_by_brand: true,
    },
    filters: {
      is_owned_by_brand: true,
      // views__gte:10000
    },
  },
  mass_engage_all: {
    sortAndTimeFrame: {
      timeframe: "-1",
      sort_by: "scraped_timestamp,timestamp",
      exc_comment_mentioned: true,
      // has_ai_generated_comment:true,
      is_archived_from_ai_comment: false,
      exclude_competitors: true,
    },
    filters: {
      // views__gte:10000
      // ai_comment_filter:"has_ai_generated_comment" , // special mass engage
    },
  },
  mass_engage_answered: {
    sortAndTimeFrame: {
      timeframe: "-1",
      sort_by: "scraped_timestamp,timestamp",
      exc_comment_mentioned: true,
      // has_ai_generated_comment:true,
      is_archived_from_ai_comment: false,
      exclude_competitors: true,
      // has_posted_comment: true,
      posting_state: "POSTED",
    },
    filters: {
      // views__gte:10000
    },
  },
  mass_engage_failed: {
    sortAndTimeFrame: {
      timeframe: "-1",
      sort_by: "scraped_timestamp,timestamp",
      exc_comment_mentioned: true,
      // has_ai_generated_comment:true,
      is_archived_from_ai_comment: false,
      exclude_competitors: true,
      // has_posted_comment: true,
      posting_state: "COMMENT_FAILED",
    },
    filters: {
      // views__gte:10000
    },
  },
  mass_engage_pending: {
    sortAndTimeFrame: {
      timeframe: "-1",
      sort_by: "scraped_timestamp,timestamp",
      exc_comment_mentioned: true,
      // has_ai_generated_comment:true,
      is_archived_from_ai_comment: false,
      exclude_competitors: true,
      // has_posted_comment: true,
      posting_state: "IS_SCHEDULED_OR_IN_QUEUE",
    },
    filters: {
      // views__gte:10000
    },
  },
  mass_engage_open: {
    sortAndTimeFrame: {
      timeframe: "-1",
      sort_by: "scraped_timestamp,timestamp",
      exc_comment_mentioned: true,
      // has_ai_generated_comment:true,
      is_archived_from_ai_comment: false,
      exclude_competitors: true,
      has_posted_comment: false,
    },
    filters: {
      // views__gte:10000,
      ai_comment_filter: "has_ai_generated_comment",
    },
  },
  mass_engage_owned: {
    sortAndTimeFrame: {
      timeframe: "-1",
      sort_by: "timestamp",
      is_owned_by_brand: true,
      // has_ai_generated_comment:true,
      // is_archived_from_ai_comment:false,
    },
    filters: {
      // views__gte:10000
    },
  },
  mass_engage_new: {
    sortAndTimeFrame: {
      timeframe: "-1",
      sort_by: "scraped_timestamp,timestamp",
      exc_comment_mentioned: true,
      // has_ai_generated_comment:true,
      is_archived_from_ai_comment: false,
      is_new: true,
      exclude_competitors: true,
    },
    filters: {
      // views__gte:10000
    },
  },
  mass_engage_trending: {
    sortAndTimeFrame: {
      timeframe: "-1",
      sort_by: "scraped_timestamp,timestamp",
      exc_comment_mentioned: true,
      total_engagement__gte__or: 1000,
      is_archived_from_trending_posts: false,
      views__gte__or: 10000,
      // has_ai_generated_comment:true,
      is_archived_from_ai_comment: false,
      exclude_competitors: true,
    },
    filters: {
      // views__gte:10000
    },
  },
  mass_engage_negative: {
    sortAndTimeFrame: {
      timeframe: "-1",
      sort_by: "scraped_timestamp,timestamp",
      exc_comment_mentioned: true,
      // has_ai_generated_comment:true,
      is_archived_from_ai_comment: false,
    },
    filters: {
      nps_score: "-1",
    },
  },
  mass_engage_archive: {
    sortAndTimeFrame: {
      sort_by: "impact",
      timeframe: "-1",
      sort_by: "timestamp",
      // has_ai_generated_comment:true,
      is_archived_from_ai_comment: true,
    },
    filters: {},
  },
  all_posts: {
    sortAndTimeFrame: {
      sort_by: "impact",
      // timeframe: "-1",
    },
  },
  all_posts_30_default: {
    sortAndTimeFrame: {
      sort_by: "impact",
      // timeframe: "30",
    },
  },
  negative_nps_posts: {
    sortAndTimeFrame: {
      sort_by: "timestamp",
      // timeframe: "-1",
      exclusion_type: 0,
    },
    filters: {
      nps_score: "-1",
    },
  },
  social_priority_posts: {
    sortAndTimeFrame: {
      sort_by: "timestamp",
      // timeframe: DEFAULT_CONTENT_FILTER_TIMEFRAME,
      // is_from_organic_search: true,
      // exc_comment_mentioned:true,
      // total_engagement__gte__or:1000,
      is_archived_from_new_posts: false,
      // views__gte__or:10000,
      // exclusion_type:0,
      is_owned_by_brand: false,
      mention_type__not: 2, // no comment mention
    },
    filters: {
      // views__gte:10000
    },
  },
  community_posts: {
    sortAndTimeFrame: {
      sort_by: "timestamp",
      // timeframe: "-1",
      // is_tagged_post:true,
      mention_type: 1,
      is_archived_from_new_posts: false,
      is_from_organic_search: false,
    },
    filters: {},
  },
  community_posts_comment_only: {
    sortAndTimeFrame: {
      sort_by: "timestamp",
      // timeframe: "-1",
      // is_tagged_post:true,
      mention_type: 2,
      is_from_organic_search: false,
      is_archived_from_new_posts: false,
      exclusion_type__not: 0,
    },
    filters: {},
  },
  influencer_posts: {
    sortAndTimeFrame: {
      sort_by: "timestamp",
      // timeframe: "30",
      is_archived_from_new_posts: false,
      is_influencer_content: true,
    },
    filters: {},
  },
  competitor_owned_posts: {
    sortAndTimeFrame: {
      sort_by: "timestamp",
      // timeframe: "30",
      is_archived_from_new_posts: false,
      is_owned_by_brand: true,
    },
    filters: {},
  },
  competitor_influencer_posts: {
    sortAndTimeFrame: {
      sort_by: "timestamp",
      // timeframe: "30",
      is_archived_from_new_posts: false,
      is_influencer_content: true,
    },
    filters: {},
  },
  competitor_organic_posts: {
    sortAndTimeFrame: {
      sort_by: "timestamp",
      // timeframe: "30",
      is_archived_from_new_posts: false,
      is_from_organic_search: true,
    },
    filters: {},
  },
}

const BRAND_FE_CONFIG = {
  1143: {
    view_ai_generated_comment_only: true,
    view_sub_brand_ai_generated_comment: true,
  },
  1026: {
    connect_youtube_enabled: true,
  },
  // 11: {
  //     connect_youtube_enabled:true
  // }
}

//[-1: AllTime, 30: 30Days, 60: 60Days, 365: 1 Year, 7: 7Day]
const TIME_FRAME = [
  { title: "7 days", value: "7" },
  { title: "30 days", value: "30" },
  { title: "60 days", value: "60" },
  { title: "365 days", value: "365" },
  { title: "All time", value: "-1" },
]

const SORT_BY_CREATOR = [
  { title: "Impact", value: "impact" },
  { title: "Views", value: "views" },
  { title: "Total engagement", value: "total_engagement" },
  { title: "Engagement Rate", value: "engagement_rate" },
  { title: "Total posts", value: "total_posts" },
]

const SORT_BY_CONTENT = [
  { title: "Impact", value: "impact" },
  { title: "Views", value: "views" },
  { title: "Total engagement", value: "total_engagement" },
  { title: "Engagement Rate", value: "engagement_rate" },
  { title: "Time", value: "timestamp" },
]

const COMMENT_STATE_MESSAGES = {
  SCHEDULED_FOR_POSTING: "Scheduled for posting",
  COMMENT_POSTING_IN_QUEUE: "Comment is in queue",
}

const POLITICAL_CANDIDATE_NAME_SERIAL = ["Kamala Harris", "Donald Trump", "JD Vance", "Joe Biden"]

const POLITICAL_VP_CANDIDATES = [
  "Mark Kelly",
  "Josh Shapiro",
  "Andy Beshear",
  "Gretchen Whitmer",
  "JB Pritzker",
  "Gavin Newsom",
  "Wes Moore",
  "Pete Buttigieg",
  "Roy Cooper",
  "Tim Walz",
]

const MASS_ENGAGE_SORT_KEY = [
  "likes",
  "views",
  "shares",
  "total_engagement",
  "id",
  "comments",
  "engagement_of_views",
  "engagement_of_followers",
  "thumbnail_url_stable",
  "profile_picture_url_stable",
  "creator_username",
  "creator_external_id",
  "creator_external_url",
  "creator_name",
  "creator_email",
  "-likes",
  "-views",
  "-shares",
  "-total_engagement",
  "-id",
  "-comments",
  "-engagement_of_views",
  "-engagement_of_followers",
  "-thumbnail_url_stable",
  "-profile_picture_url_stable",
  "-creator_username",
  "-creator_external_id",
  "-creator_external_url",
  "-creator_name",
  "-creator_email",
]

export {
  PIN_CLIENT_ID,
  TT_CLIENT_KEY,
  YT_CLIENT_ID,
  PP_CLIENT_ID,
  IG_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_KEY,
  AUTH0_AUDIENCE,
  AUTH0_REDIRECT_TO,
  TIKAPI_OAUTH_ID,
  STRIPE_KEY,
  CURRENCY,
  platform_list,
  platforms,
  payouts,
  licenses,
  right_durations,
  media_types,
  ANIMATION_DURATION,
  AUTH0_TOKEN_CONF,
  DEFAULT_CACHE_VALIDITY,
  API_BASE,
  API_TIMEOUT,
  ENV,
  ROLES,
  LOCALSTORAGE_PREFIX,
  CACHE_PREFIX,
  LOG_PROMISE_RESOLVES,
  PUBLIC_URL,
  REF_URL,
  LINK_TO_TERMS,
  GA_ID,
  DEBUG,
  DEFAULT_ITEMS_PER_PAGE,
  LINK_TO_REF_TERMS,
  FB_GRAPH_APP_ID,
  UPLOAD_FORMATS,
  platform_list_real,
  platform_list_search,
  POLL_INTERVAL_MS,
  POLL_MAX_COUNT,
  DEFAULT_CREATOR_FILTER_TIMEFRAME,
  NEW_CREATOR_TIMEFRAME,
  DISCOVERY_POST_SECTION_CONFIG,
  DEFAULT_CONTENT_FILTER_TIMEFRAME,
  FORCE_ACCOUNT_CONNECT_ONBOARDING,
  BRAND_FE_CONFIG,
  TIME_FRAME,
  SORT_BY_CONTENT,
  COMMENT_STATE_MESSAGES,
  POLITICAL_CANDIDATE_NAME_SERIAL,
  POLITICAL_VP_CANDIDATES,
  MASS_ENGAGE_SORT_KEY,
  GET_CALLS,
  MUTATION_CALLS,
}
