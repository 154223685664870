import { Card, Collapsible, ExpandButtonInline, StatBlock, WrappedInLoader } from "../../dumbs"
import { ProfilePictureCloud } from "../../dumbs"
import { BEContentList, BECreatorList, BELocalContentList, CreatorOverviewSection, CreatorPayoutTableOverviewSection } from "./beSections"
import { useContext, useEffect, useState } from "react"
import { dataContext } from "../../../contexts/datacontext"
import { useBDBoundData } from "../../../hooks/useBoundData"

import {
  cents2USD,
  convertToPercentage,
  cloneObject,
  getFileNameFromURL,
  getCampaignLink,
} from "../../../helpers/utils"
import { Input } from "./../../input"
import { Button } from "./../../button"
import { Checkbox } from "./../../checkbox"
import dayjs from "dayjs"
import { apiContext } from "../../../contexts/apicontext"
import { UPLOAD_FORMATS } from "./../../../consts"
import {
  UploadIcon,
  DocumentIcon,
  EyesIcon,
  TimesIcons,
  PlusLargeFilledMono,
  CopyMediumFilledMono,
  CopyMediumOutlinedMono,
  ChevronBackwardSmallOutlinedMono,
} from "./../../Icons"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useCampaignHelpers, useCopyToClipBoard } from "../../../hooks/helperHooks"
import { GET_CALLS } from "./../../../consts"

const SectionTitle = ({ title, children }) => (
  <div className="button-tiny-font nc50-fg margin-bottom flex vcenter">
    {title}
    {children}
  </div>
)

function CampaignDetailPage() {
  const [data, dispatch] = useContext(dataContext)
  const [candidates, setCandidates] = useState([])
  const [uploaded, setUploaded] = useState([])
  const api = useContext(apiContext)
  let { campaign_id } = useParams()
  const navigate = useNavigate()
  const { loader, payload: campaignDetails } = useBDBoundData({
    section: data?.selected_campaign_for_viewing?.id ? "campaign_summary" : "",
  })
  const [detailOpen, sDO] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const { copyToClipboard } = useCopyToClipBoard()
  const { clearCampaign } = useCampaignHelpers()

  const closeCampaignView = () => {
    navigate(-1)
    clearCampaign()
  }

  useEffect(() => {
    if (!campaign_id) {
      navigate("/")
    }
    let _id = Number(campaign_id)

    if (data?.selected_campaign_for_viewing?.id === _id) return
    dispatch({
      selected_campaign_for_viewing: {
        id: _id,
      },
    })
    return () => {
      const _sec = ["campaign_summary", "campaign_contents", "campaign_creators_stats"]
      api.brand.resetSection()
    }
  }, [campaign_id])

  const updateCampaignForm = async (e, field, forceValue) => {
    const newForm = { ...cloneObject(data?.draft_campaign_brief_form) }

    let val = forceValue ?? e.target.value
    if (field === "url") {
      val = encodeURI(val)
    }
    if (field.match("_date")) {
      val = dayjs(val).format("YYYY-MM-DD")
      const today = dayjs(new Date()).format("YYYY-MM-DD")
      // can't set date smaller than today
      if (val < today) {
        return
      }
    }
    // console.log(val);
    if ((e || forceValue !== undefined) && field) newForm[field] = val

    dispatch({ draft_campaign_brief_form: newForm })
    await api.brand.addBrief(newForm)
    // setUploadForm(newForm)
  }

  const upload = async (e) => {
    if (!e.target.files.length) return false
    const file = e.target.files[0]
    const newCandidate = [...candidates, file.name]
    setCandidates(newCandidate)
    const uploadDone = await api.common.upload(file, file.name)
    if (uploadDone) {
      const fileDetail = { ...uploadDone, filename: file.name }
      const newUploadedList = [...uploaded, fileDetail]

      setUploaded(newUploadedList)

      updateCampaignForm(true, "url", uploadDone.file)
    }
  }

  const openAddCreatorsDialog = () => {
    api?.alert?.addCreatorsInDraftCampaignDirectly()
  }
  const onCopyInviteLink = () => {
    if (!campaignDetails) return
    copyToClipboard(getCampaignLink(data?.me?.brand?.ref_code, campaignDetails, data?.me?.brand?.title))
  }

  return (
    <div className="campaign-detail-page padding-y-8x">
      <div className="campaign-title-section headline-large flex a-center j-space-between">
        <div className="flex a-center " onClick={closeCampaignView}>
          <div className="cursor-pointer  margin-right-2x nc50-fg campaign-detail-back-button ">
            {ChevronBackwardSmallOutlinedMono}
          </div>
          {campaignDetails?.name}
        </div>
        <Button
          className="transparent-bg-override filter-none-important"
          type="tertiary"
          size="large"
          onClick={openAddCreatorsDialog}
          beforeElement={<div className="primary-fg flex a-center j-center">{PlusLargeFilledMono}</div>}
        >
          Add creators to campaign
        </Button>
      </div>
      <Card className={`margin-top-5x`}>
        <WrappedInLoader data={campaignDetails} loader={loader}>
          <div className="campaign-detail-top-summary-section flex spreaded padding-top-5x padding-x-5x">
            <div className="headline-medium-highlight">Summary</div>
            <ProfilePictureCloud images={campaignDetails?.creator_profile_picture_urls} />
          </div>
          <div
            className={`summary-sections-holder-campaign-detail flex j-space-between margin-top-6x padding-x-5x  margin-bottom-6x }`}
          >
            <div className="flex">
              <StatBlock
                className="padding-right-4x"
                title={
                  <>
                    Creators
                    <br /> total
                  </>
                }
                data={campaignDetails?.creator_count}
              ></StatBlock>
              <StatBlock
                className="padding-x-4x"
                title={
                  <>
                    Posts
                    <br /> total
                  </>
                }
                data={campaignDetails?.content_count}
              ></StatBlock>
              <StatBlock
                className="padding-x-4x"
                title={
                  <>
                    Total <br /> costs
                  </>
                }
                data={campaignDetails?.total_cost}
                isCent={true}
                hip={false}
              ></StatBlock>
              <StatBlock
                className="padding-x-4x"
                title={
                  <>
                    Total
                    <br /> views
                  </>
                }
                data={campaignDetails?.total_views}
              ></StatBlock>
              <StatBlock
                className="padding-x-4x"
                title={
                  <>
                    Total
                    <br /> engagement
                  </>
                }
                data={campaignDetails?.total_engagement}
              ></StatBlock>
              <StatBlock
                className="padding-x-4x"
                separator={true}
                hip={false}
                title={
                  <>
                    Cost per
                    <br /> engagement
                  </>
                }
                data={campaignDetails?.cost_per_engagement}
                isCent={true}
              ></StatBlock>
              <StatBlock
                className="padding-x-4x"
                separator={false}
                hip={false}
                title={<>CPM</>}
                data={campaignDetails?.cost_per_view ? campaignDetails?.cost_per_view * 1000 : null}
                isCent={true}
              ></StatBlock>
            </div>
            <div className="flex column j-end a-end">
              <ProfilePictureCloud
                total={campaignDetails?.creator_count}
                images={campaignDetails?.creators?.map((x) => x?.profile_picture_url)}
              />
              {campaignDetails ? (
                <div className="margin-top-2x">
                  <Button type="secondary" onClick={onCopyInviteLink} beforeElement={CopyMediumOutlinedMono}>
                    Copy invite link
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </WrappedInLoader>
        <div className="padding-3x txt-right">
          <ExpandButtonInline trigger={detailOpen} onClick={() => sDO(!detailOpen)} />
        </div>
        <Collapsible expanded={detailOpen}>
          <CreatorOverviewSection section="campaign_creators_stats" showAllStats />
        </Collapsible>
      </Card>
      {data?.bd_sec_campaign_creators?.length ? null : (
        <Card className="padding-10x flex a-center j-center flex column a-center margin-y-6x">
          <p className="headline-small-regular margin-bottom-6x">
            Add creators to your campaign and send your brief to get started with content creation!
          </p>
          <Button size="large" onClick={openAddCreatorsDialog}>
            {" "}
            <span className="padding-x-8x">Add creators to campaign</span>
          </Button>
        </Card>
      )}

      <Card className={` margin-y-6x`}>
        <div className="padding-y-5x padding-x-6x">
          <h3>Creators</h3>
        </div>
        <CreatorPayoutTableOverviewSection openAddCreatorsDialog={openAddCreatorsDialog} />
      </Card>

      {!!data?.selected_campaign_for_viewing?.id && <BELocalContentList showViewAll={false} section={GET_CALLS?.campaign_contents.section} customSectionDetailsOverwrite={{ endpoint: `/Campaign/${data?.selected_campaign_for_viewing?.id}/contents/` }} className="margin-top-6x" title={`Contents`} />}
    </div>
  )
}

export { CampaignDetailPage }
