import { useElements, useStripe, CardNumberElement } from "@stripe/react-stripe-js"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useServer } from "../../hooks/useServer"
import { dataContext } from "../datacontext"
import { useLoading } from "../loaderContext"
import { useSystemAlerts } from "./alerts"
import { _js } from "../../helpers/utils"

function usePaymentAPIS() {
  const server = useServer()
  const { actions } = useLoading()
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()
  const [data, dispatch] = useContext(dataContext)
  const alert = useSystemAlerts()

  const stripeIntent = async () => {
    actions.loading("stripe_intent")
    const intent = await server.get("/Cart/create_payment_intent/")
    actions.notLoading("stripe_intent")
    if (!intent) return null
    return intent
  }

  const success = (source) => {
    dispatch({ purchase_confirmed: true })
    setTimeout(() => {
      // healthy delay for state update
      actions.notLoading("payment_processing")
    }, 500)
  }
  const error = (source = "card") => {
    actions.notLoading("payment_processing")
    alert.paymentError(source)
  }

  const completeStripeCheckout = async () => {
    if (!stripe || !elements) return null
    const intent = await stripeIntent()
    if (!intent || !intent.stripe_payment_intent_client_secret) return error("card")
    const secret = intent.stripe_payment_intent_client_secret
    // Fill in details
    const details = {}
    if (data?.authUser?.email) details.email = data.authUser.email
    if (data?.authUser.name) details.name = data.authUser.name
    const paymentResult = await stripe.confirmCardPayment(secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: details,
      },
    })
    if (paymentResult.error) return null
    else return true
  }

  return {
    success,
    error,
    stripeIntent,
    completeStripeCheckout,
  }
}

export { usePaymentAPIS }
