import React, { useCallback, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import OnboardingImage from "../../assets/onboarding-steps.jpg"
import { Button } from "./../button"
import { Spacer } from "./../spacer"
import { apiContext } from "../../contexts/apicontext"

export const OnbaordingStepsPage = (props) => {
  const navigate = useNavigate()
  const api = useContext(apiContext)

  const onContinueClick = useCallback(() => {
    navigate("/start-connect-socials")
  }, [])

  useEffect(() => {
    api.analytics.page("/start-creator", "Creator Onboarding: Welcome")
  }, [])

  return (
    <div className="onboarding-page-container full-min-height-without-header-and-footer-onboarding">
      <div className="onboarding-mobile-background hide-on-desktop bg-yellow"></div>
      <div className="onboarding-card">
        <div className="onboarding-left-side ">
          <div className="onboarding-img-container yellow-left-side-shadow">
            <img className="onboarding-img" alt="steps-banner" src={OnboardingImage} loading="lazy" />
          </div>
        </div>

        <div className="onboarding-right-side">
          <Spacer className="hide-on-mobile" height={72} />
          <Spacer className="hide-on-desktop" height={64} />
          <div className="onboarding-max-header-container flex column a-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="57" fill="none" viewBox="0 0 56 57">
              <path
                fill="#1D65E9"
                d="M37.427 18.713L23.615 33.635l-5.042-5.504c-.262-.227-.682-.227-.893 0l-1.523 1.646c-.21.226-.21.68 0 .964l7.038 7.546c.262.284.63.284.892 0l15.756-17.021c.21-.227.21-.68 0-.965l-1.524-1.588c-.21-.284-.63-.284-.892 0z"
              />
              <rect width="53" height="53" x="1.5" y="2" stroke="#1D65E9" strokeWidth="3" rx="26.5" />
            </svg>
            <Spacer className="hide-on-mobile" height={24} />
            <Spacer className="hide-on-desktop" height={16} />
            <h3 className="headline-large nc100-fg txt-center onboading-steps-header ">Welcome Onboard!</h3>
            <Spacer className="hide-on-mobile" height={24} />
            <Spacer className="hide-on-desktop" height={16} />
            <p className="body-regular-font txt-center nc50-fg onboading-steps-header-description">
              We’ve verified your e-mail address. Two more steps to complete setting up your profile.
            </p>
          </div>

          <Spacer className="hide-on-mobile" height={32} />
          <Spacer className="hide-on-desktop" height={48} />

          <div className="onboarding-group flex column j-center a-center">
            <div className="flex column a-start">
              <div className="onboarding-steps">
                <span className="onboarding-steps-number margin-right-2">1</span>
                <span>Connect Your Social Accounts</span>
              </div>
              <div className="dotted-line-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="17" fill="none" viewBox="0 0 28 17">
                  <path stroke="#4C69FF" strokeDasharray="2 2" d="M18 .5v16" />
                </svg>
              </div>

              <div className="onboarding-steps">
                <span className="onboarding-steps-number margin-right-2">2</span>
                <span>Connect Your Payout Accounts</span>
              </div>
            </div>
            <Spacer className="hide-on-mobile" height={48} />
            <Spacer className="hide-on-desktop" height={32} />
            <Button fluid size="large" onClick={onContinueClick}>
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

OnbaordingStepsPage.propTypes = {}
