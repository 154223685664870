import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { FullLoader } from "../loaders"
import { dataContext } from "../../contexts/datacontext"
import { _empty, _lsd } from "../../helpers/utils"
import { apiContext } from "../../contexts/apicontext"
function DetectDestination() {
  const navigate = useNavigate()
  const [data] = useContext(dataContext)
  const api = useContext(apiContext)

  useEffect(() => {
    if (data.role && data.me) {
      if (data.role === "creator") {
        console.log("logged in creator")
        _lsd("last_visited_creator")
        if (!_empty(data.me.connected_accounts)) {
          if (data.me?.username) {
            navigate(`/creator-dashboard`)
          }
          return
        }
        navigate("/start-description")
      } else if (data?.role === "brand") {
        _lsd("user_choice_username")
        if (data?.me?.brand?.name) {
          console.log("brand redirecting")
          if (data?.me?.brand?.settings?.is_political_engage_enabled) {
            if (data?.me?.user_group == 1) {
              api.brand.next("/brand-dashboard/comments")
            } else {
              api.brand.next("/engage")
            }
          } else {
            api.brand.next("/brand-dashboard")
          }
        } else navigate("/start-brand")
      }
    }
  }, [data.role])

  console.log(data?.role)

  return <FullLoader />
}

export { DetectDestination }
