import { useState } from "react"

function useWindowResize() {
  const [width, setWidth] = useState("0px")
  const [height, setHeight] = useState("0px")
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  })
  return {
    width,
    height,
  }
}

export { useWindowResize }
