import { createContext, useReducer } from "react"
const dataContext = createContext()

const init_data = {
  paypal_buttons_loaded: false,
  walletpay_button_loaded: false,
  creator_current_platform: "",
  creator_platform_content: "",
  hardware: "desktop", // enum: desktop, mobile
  is_mobile: false,
  creator_name: "",
  fold: null,
  role: "",
  me: null,
  creator: null,
  is_me_creator: false,
  on_creator_dashboard: false,
  cart: null,
  purchase_confirmed: false,
  creator_tour_step: 0,
  show_creator_tour: false,
  deleted_temp: [],
  restored_temp: [],
  creator_mobile_tab: "content",
  social_reconnect: "",
  show_graph_api_minitour: false,
  graph_api_steps_completed: 0, // should be 2 if user already have facebook page linked to their instagram account ,1 if instagram is professinal account
  graph_api_minitour_pre_dialog_show: false,
  onboarding_previous_profile_price_dialog_show: false,
  book_a_call_dialog: false,
  booking_content_mobile_cart_summary_dialog_show: false,
  create_only_index: 0, // default: 0
  create_and_post_index: 0,
  creator_homepage_tab: "license", //license, book, stats default: license,
  search_tab: "license", // license, book
  search_platform: "tiktok",
  search_query: "",
  creator_search_filter: null,
  creator_content_search_raw: "",
  brand_proposal_brand: null,
  bp_name_draft: "",
  dialog_button_disabled: false,
  dialog_payload: null,
  brand_proposal_list: [],
  sample_contents: [],
  is_guest: false,
  gc_form: {},
  selected_campaign_for_viewing: null,
  brand_ecosystem_invite: null,
  brand_ecosystem_brand: null,
  newly_added_campaign: null,
  new_campaign_draft: "",
  ecosystem_selected_content: null,
  content_modal_content_array: [], // content array for modal,
  content_modal_content_index: 0,
  // current campaign
  current_campaign_invite_cart: {},
  discovery_section_platform: "tiktok",
  guest_user_email_input: "",

  // brand tags
  discovery_section_content_form: "VIDEO",
  seen_content_from_modal: {},
  comment_generation_for_post: {},
  engage_tab_section_name: "mass_engage_all",
  client_remove_from_circle: [],
  deleted_from_discovery: [],
  fe_archive_from_new_posts: [],
  fe_archive_from_trending_posts: [],
}

function dataContextReducer(state, action) {
  if (!action) return state
  return { ...state, ...action }
}

function DataContextProvider({ children }) {
  return <dataContext.Provider value={useReducer(dataContextReducer, init_data)}>{children}</dataContext.Provider>
}

export { dataContext, DataContextProvider }
