import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import { Tab } from "./tab"
import { CheckSmallFilledMono } from "./Icons"

// tabs could be replaces by just a div with flex box because individual tab is handles all the things nessessary, but we need tabs conmponent for future uses like nested tabs

const Tabs = ({ children, className = "", direction = "horizontal" }) => {
  return <div className={`tabs ${className} ${direction}`}>{children}</div>
}

const FullWidthSwipeableTabsFromList = ({ className = "", list = [], activeIndex = 0, onItemClick }) => {
  const [mySwiperInstance, setMySwiperInstance] = useState()
  useEffect(() => {
    if (mySwiperInstance) {
      mySwiperInstance.slideTo(activeIndex)
    }
  }, [mySwiperInstance, activeIndex])
  return (
    <div>
      <Swiper
        slidesPerView={"auto"}
        className={`tabs-swiper ${className} `}
        onInit={(ev) => {
          setMySwiperInstance(ev)
        }}
      >
        {list.map((item, index) => (
          <SwiperSlide key={index}>
            <Tab
              type="underlined"
              title={item}
              icon={
                <div
                  className={`swipe-tab-icon-container no-flex-shrink j-center flex a-center ${
                    index === activeIndex ? "ultralight-bg" : "nc05-bg"
                  } round`}
                >
                  {index < activeIndex ? (
                    CheckSmallFilledMono
                  ) : (
                    <span className="small-font-highlight">{index + 1}</span>
                  )}
                </div>
              }
              className="full-width swipe-tab"
              selected={index === activeIndex}
              onChange={() => {
                onItemClick && onItemClick(index, item)
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

Tabs.propTypes = {
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  children: PropTypes.any,
  className: PropTypes.string,
}

export { Tabs, FullWidthSwipeableTabsFromList }
