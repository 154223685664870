import { useContext, useEffect, useState } from "react"
import Chart from "react-apexcharts"
import { formatDate, getSubtractedDate, num2Hip } from "../../helpers/utils"
import { useLocalBoundData } from "../../hooks/useBoundData"
import { ArrowDown } from "../Icons"
import { Card, WrappedInLoader } from "../dumbs"
import { Select } from "../select"
import { BarChart, NotEnoughData } from "../ui/graphs"
import { ProgressBar } from "../ui/progressBar"
import { dataContext } from "../../contexts/datacontext"
import { ScrollSpy } from "../scrollspy"
import { DateRangePicker } from "../input"
import dayjs from "dayjs"
import { GET_CALLS } from "../../consts"

const monthMap = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
}

const dummyOnePercent = (total) => {
  return parseFloat((total / 100).toFixed(2))
}

const targetVsProgressParser = (data, fromDate, toDate) => {
  if (!data) return
  const target = []
  const progress = []
  const category = []
  const targetBar = []

  const dateCount = dayjs(toDate).diff(dayjs(fromDate), "day") + 1
  for (let i = 0; i < dateCount; i++) {
    const date = new Date(fromDate)
    date.setDate(date.getDate() + i)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const dateStr = `${year}-${month}-${day}`
    category.push(dateStr)

    const dataForDate = data.find((x) => x.date === dateStr)

    if (dataForDate) {
      const missedTarget = dataForDate.total_posted_comment > 300 ? 0 : 300 - dataForDate.total_posted_comment // 300 is the target

      target.push(missedTarget)
      // target.push(dataForDate.total_contents - dataForDate.total_posted_comment)
      progress.push(dataForDate.total_posted_comment)
      targetBar.push(dummyOnePercent(missedTarget + dataForDate.total_posted_comment))
    } else {
      target.push(300)
      progress.push(0)
      targetBar.push(dummyOnePercent(300))
    }
  }

  return {
    series: [
      {
        name: "Posted",
        data: progress,
      },
      {
        name: "Target",
        data: target,
      },
      {
        name: "dummy",
        data: targetBar,
      },
    ],
    category,
  }
}

const commentTimeSeriesParser = (allData, fromDate, toDate, activeLegends, isPolitical = false) => {
  if (!allData) return
  const ownedComments = []
  const brandComments = []
  const competitorsComments = []
  const themesComments = []
  const otherComments = []
  const category = []
  const rollingAverage = []

  // allData.forEach( (element, i ) => {
  //     ownedComments.push(element.total_owned_reply_comments)
  //     brandComments.push(element.total_brand_content_comments)
  //     competitorsComments.push(element.total_competitive_content_comments)
  //     themesComments.push(element.total_theme_content_comments)

  //     category.push(element.date)

  //     if(activeLegends.length === 4){
  //         rollingAverage.push((element.rolling_avg_total_owned_reply_comments + element.rolling_avg_total_brand_content_comments + element.rolling_avg_total_competitive_content_comments + element.rolling_avg_total_theme_content_comments)?.toFixed(2))
  //     }else if(activeLegends.length === 3){
  //         if(activeLegends.includes(0) && activeLegends.includes(1) && activeLegends.includes(2)){
  //             rollingAverage.push((element.rolling_avg_total_owned_reply_comments + element.rolling_avg_total_brand_content_comments + element.rolling_avg_total_competitive_content_comments)?.toFixed(2))
  //         }else if(activeLegends.includes(0) && activeLegends.includes(1) && activeLegends.includes(3)){
  //             rollingAverage.push((element.rolling_avg_total_owned_reply_comments + element.rolling_avg_total_brand_content_comments + element.rolling_avg_total_theme_content_comments)?.toFixed(2))
  //         }else if(activeLegends.includes(0) && activeLegends.includes(2) && activeLegends.includes(3)){
  //             rollingAverage.push((element.rolling_avg_total_owned_reply_comments + element.rolling_avg_total_competitive_content_comments + element.rolling_avg_total_theme_content_comments)?.toFixed(2))
  //         }else if(activeLegends.includes(1) && activeLegends.includes(2) && activeLegends.includes(3)){
  //             rollingAverage.push((element.rolling_avg_total_brand_content_comments + element.rolling_avg_total_competitive_content_comments + element.rolling_avg_total_theme_content_comments)?.toFixed(2))
  //         }
  //     }else if(activeLegends.length === 2){
  //         if(activeLegends.includes(0) && activeLegends.includes(1)){
  //             rollingAverage.push((element.rolling_avg_total_owned_reply_comments + element.rolling_avg_total_brand_content_comments)?.toFixed(2))
  //         }else if(activeLegends.includes(0) && activeLegends.includes(2)){
  //             rollingAverage.push((element.rolling_avg_total_owned_reply_comments + element.rolling_avg_total_competitive_content_comments)?.toFixed(2))
  //         }else if(activeLegends.includes(0) && activeLegends.includes(3)){
  //             rollingAverage.push((element.rolling_avg_total_owned_reply_comments + element.rolling_avg_total_theme_content_comments)?.toFixed(2))
  //         }else if(activeLegends.includes(1) && activeLegends.includes(2)){
  //             rollingAverage.push((element.rolling_avg_total_brand_content_comments + element.rolling_avg_total_competitive_content_comments)?.toFixed(2))
  //         }else if(activeLegends.includes(1) && activeLegends.includes(3)){
  //             rollingAverage.push((element.rolling_avg_total_brand_content_comments + element.rolling_avg_total_theme_content_comments)?.toFixed(2))
  //         }else if(activeLegends.includes(2) && activeLegends.includes(3)){
  //             rollingAverage.push((element.rolling_avg_total_competitive_content_comments + element.rolling_avg_total_theme_content_comments)?.toFixed(2))
  //         }
  //     }else if(activeLegends.length === 1){
  //         if(activeLegends.includes(0)){
  //             rollingAverage.push(element.rolling_avg_total_owned_reply_comments?.toFixed(2))
  //         }else if(activeLegends.includes(1)){
  //             rollingAverage.push(element.rolling_avg_total_brand_content_comments?.toFixed(2))
  //         }else if(activeLegends.includes(2)){
  //             rollingAverage.push(element.rolling_avg_total_competitive_content_comments?.toFixed(2))
  //         }else if(activeLegends.includes(3)){
  //             rollingAverage.push(element.rolling_avg_total_theme_content_comments?.toFixed(2))
  //         }
  //     }else{
  //         rollingAverage.push(0)
  //     }
  // })

  const time = dayjs(toDate).diff(dayjs(fromDate), "day") + 1

  // days from from to to
  for (let i = 0; i < time; i++) {
    const date = new Date(fromDate)
    date.setDate(date.getDate() + i)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const dateStr = `${year}-${month}-${day}`
    category.push(dateStr)

    const dataForDate = allData?.find((x) => x.date === dateStr)

    if (dataForDate) {
      ownedComments.push(dataForDate.total_owned_reply_comments || 0)
      brandComments.push(dataForDate.total_brand_content_comments || 0)
      competitorsComments.push(dataForDate.total_competitive_content_comments || 0)
      themesComments.push(dataForDate.total_theme_content_comments || 0)
      otherComments.push(dataForDate.total_others_content_comments || 0)
      // rolling average
    } else {
      ownedComments.push(0)
      brandComments.push(0)
      competitorsComments.push(0)
      themesComments.push(0)
      otherComments.push(0)
      // rolling average
    }
  }

  // dummy data
  // for (let i = 0; i < 31; i++) {
  //     category.push(i + 1)
  //     totalLikes.push(Math.floor(Math.random() * 1000000) + 1)
  //     totalComments.push(Math.floor(Math.random() * 1000000) + 1)
  //     totalShares.push(Math.floor(Math.random() * 1000000) + 1)
  //     rollingAverage.push(Math.floor(Math.random() * 1000000) + 1)
  // }

  const ownedTitle = isPolitical ? "Owned" : "Owned"
  const brandTitle = isPolitical ? "Brand" : "Brand"
  const competitorsTitle = isPolitical ? "Kamala" : "Competitors"
  const themesTitle = isPolitical ? "Issues" : "Themes"
  const otherTitle = isPolitical ? "Priority Posts" : "Others"

  const data = {
    series: [
      {
        name: ownedTitle,
        data: ownedComments,
        type: "bar",
      },
      {
        name: brandTitle,
        data: brandComments,
        type: "bar",
      },
      {
        name: competitorsTitle,
        data: competitorsComments,
        type: "bar",
      },
      {
        name: themesTitle,
        data: themesComments,
        type: "bar",
      },
      {
        name: otherTitle,
        data: otherComments,
        type: "bar",
      },
      // {
      //     name: "Rolling Average",
      //     data: rollingAverage,
      //     type: 'line',
      // }
    ],
    category,
  }

  if (isPolitical) {
    // remove 0, 1, 3 index from series
    data.series = data.series.filter((_, i) => [2, 4].includes(i))
  } else {
    // remove 4 index from series
    data.series = data.series.filter((_, i) => i !== 4)
  }

  return data
}

const processKeyPerformanceMetrics = (data, isPolitical = false) => {
  if (!data) {
    return {
      comment_posted: [],
      comment_likes: [],
      comment_replies: [],
    }
  }
  const ownedTitle = isPolitical ? "Owned" : "Owned"
  const brandTitle = isPolitical ? "Brand" : "Brand"
  const competitorsTitle = isPolitical ? "Kamala" : "Competitors"
  const themesTitle = isPolitical ? "Issues" : "Themes"
  const otherTitle = isPolitical ? "Priority Posts" : "Others"

  const comment_posted = [
    // {x:ownedTitle,y:data?.total_owned_comment || 0},
    // {x:brandTitle,y:data?.total_brand_comment || 0},
    { x: competitorsTitle, y: data?.total_competitors_comment || 0 },
    { x: otherTitle, y: data?.total_other_comment || 0 },
    // {x:themesTitle,y:data?.total_themes_comment || 0}
  ]
  const comment_likes = [
    // {x:ownedTitle,y:data?.total_owned_like || 0},
    // {x:brandTitle,y:data?.total_brand_like || 0},
    { x: competitorsTitle, y: data?.total_competitors_like || 0 },
    { x: otherTitle, y: data?.total_other_like || 0 },
    // {x:themesTitle,y:data?.total_themes_like || 0}
  ]
  const comment_replies = [
    // {x:ownedTitle,y:data?.total_owned_replies || 0},
    // {x:brandTitle,y:data?.total_brand_replies || 0},
    { x: competitorsTitle, y: data?.total_competitors_replies || 0 },
    { x: otherTitle, y: data?.total_other_replies || 0 },
    // {x:themesTitle,y:data?.total_themes_replies || 0}
  ]

  if (!isPolitical) {
    comment_posted.unshift({ x: brandTitle, y: data?.total_brand_comment || 0 })
    comment_posted.unshift({ x: ownedTitle, y: data?.total_owned_comment || 0 })
    comment_posted.pop() // remove others
    comment_posted.push({ x: themesTitle, y: data?.total_themes_comment || 0 })

    comment_likes.unshift({ x: brandTitle, y: data?.total_brand_like || 0 })
    comment_likes.unshift({ x: ownedTitle, y: data?.total_owned_like || 0 })
    comment_likes.pop() // remove others
    comment_likes.push({ x: themesTitle, y: data?.total_themes_like || 0 })

    comment_replies.unshift({ x: brandTitle, y: data?.total_brand_replies || 0 })
    comment_replies.unshift({ x: ownedTitle, y: data?.total_owned_replies || 0 })
    comment_replies.pop() // remove others
    comment_replies.push({ x: themesTitle, y: data?.total_themes_replies || 0 })
  }

  return {
    comment_posted,
    comment_likes,
    comment_replies,
  }
}

const KeyPerformanceMetrics = ({
  fromDate,
  toDate,
}) => {
  const [data, dispatch] = useContext(dataContext)

  const { payload, loader: comment_stats_loading } = useLocalBoundData({
    ...GET_CALLS.comment_stats,
    filter: {
      post_date__gte: fromDate,
      post_date__lte: toDate
    }
  })

  const { comment_posted, comment_likes, comment_replies } = processKeyPerformanceMetrics(
    payload,
    data?.me?.brand?.settings?.is_political_engage_enabled,
  )

  return (
    <>
      <div className="flex j-space-between gap-3 margin-top-5x margin-bottom-4x">
        <h2 className="headline-medium-large">Key performance metrics</h2>
      </div>
      <div className="grid grid-cols-3 gap-4 margin-bottom-4x">
        <WrappedInLoader data={comment_posted} loader={comment_stats_loading}>
          <BarChart title="Comments posted" data={comment_posted} />
        </WrappedInLoader>
        <WrappedInLoader data={comment_likes} loader={comment_stats_loading}>
          <BarChart
            title="Comments likes"
            data={comment_likes}
            options={{
              dataLabels: {
                offsetY: -20,
                style: {
                  fontSize: "12px",
                  colors: ["#767B8A"],
                },
                formatter: function (val, opts) {
                  return (
                    num2Hip(val) + " (" + ((val / comment_posted[opts.dataPointIndex].y || 0) * 100).toFixed(0) + "%)"
                  )
                },
              },
            }}
          />
        </WrappedInLoader>
        <WrappedInLoader data={comment_replies} loader={comment_stats_loading}>
          <BarChart
            title="Comments replies"
            data={comment_replies}
            options={{
              dataLabels: {
                offsetY: -20,
                style: {
                  fontSize: "12px",
                  colors: ["#767B8A"],
                },
                formatter: function (val, opts) {
                  return (
                    num2Hip(val) + " (" + ((val / comment_posted[opts.dataPointIndex].y || 0) * 100).toFixed(0) + "%)"
                  )
                },
              },
            }}
          />
        </WrappedInLoader>

        {/* <BarChart title='Comments likes' subtitle='(last 30d)' data={[{x:"Owned",y:21},{x:"Brand",y:10},{x:"Competitors",y:15},{x:"Themes",y:25}]} />
				<BarChart title='Comments replies' subtitle="(last 30d)" data={[{x:"Owned",y:21},{x:"Brand",y:10},{x:"Competitors",y:15},{x:"Themes",y:25}]} /> */}
        {/* <BarChart title='Avg comment position' subtitle="(last 30d)" data={[{x:"Owned",y:21},{x:"Brand",y:10},{x:"Competitors",y:15},{x:"Themes",y:25}]} />
				<BarChart title='Views on comments' subtitle="(estimated - last 30d)" data={[{x:"Owned",y:21},{x:"Brand",y:10},{x:"Competitors",y:15},{x:"Themes",y:25}]} /> */}
      </div>
    </>
  )
}

const CommentTimeSeries = ({
  title = "Time Series",
  className = "",
  fromDate,
  toDate,
  sectionDetails = GET_CALLS.comment_stats_time_series, //comment_stats_time_series
  filter,
  fieldName = "total_engagement",
  delay = 200,
  onMarkerClick,
}) => {
  const [data, dispatch] = useContext(dataContext)
  const { payload, loader } = useLocalBoundData({
    ...sectionDetails,
    filter: {
      post_date__gte: fromDate,
      post_date__lte: toDate
    }
  })
  const [showGraph, setShowGraph] = useState(false)
  const [series, setSeries] = useState([])
  const [categories, setCategories] = useState([])
  const [activeLegends, setActiveLegends] = useState([0, 1, 2, 3])

  useEffect(() => {
    setShowGraph(false)
    const timer = setTimeout(() => {
      if (!payload) {
        return
      }
      setShowGraph(true)
      setActiveLegends([0, 1, 2, 3])
    }, delay) // 2 seconds delay

    return () => clearTimeout(timer) // cleanup timer on component unmount
  }, [payload, filter])

  const onLegendClick = (chartContext, seriesIndex, config) => {
    setActiveLegends(
      activeLegends.includes(seriesIndex)
        ? activeLegends.filter((x) => x !== seriesIndex)
        : [...activeLegends, seriesIndex],
    )
  }

  useEffect(() => {
    const _value = commentTimeSeriesParser(
      payload,
      fromDate,
      toDate,
      activeLegends,
      data?.me?.brand?.settings?.is_political_engage_enabled,
    )
    setSeries(_value?.series)
    setCategories(_value?.category)
  }, [payload, activeLegends])

  return (
    <Card className={`padding-y-5x padding-x-5x full margin-bottom-4x ${className}`}>
      <div className="flex vcenter">
        <h3 className="headline-small-highlight">{title}</h3>
        {/* <Select
          type="content"
          styleType="ghost"
          onChange={(e) => setDuration(e.target.value)}
          value={duration}
          className="sort-filter margin-x-2x margin-left-auto"
          presentationalBitClass="padding-bottom-2x-important padding-top-2x-important  nc50-fg-override"
          noBorder
          dropdownIcon={ArrowDown}
        >
          {[
            { value: "7", title: "Last 7 days" },
            { value: "30", title: "Last 30 days" },
            { value: "60", title: "Last 60 days" },
            { value: "90", title: "Last 90 days" },
          ].map((_srt) => (
            <option key={_srt?.value} value={_srt?.value}>
              {_srt?.title}
            </option>
          ))}
        </Select> */}
      </div>
      <WrappedInLoader data={payload} loader={loader || !showGraph}>
        {!payload || !payload?.length || !showGraph ? (
          <NotEnoughData />
        ) : (
          <Chart
            options={{
              chart: {
                type: "line",
                stacked: true,
                toolbar: {
                  show: false,
                },
                animations: {
                  enabled: false,
                },
                zoom: {
                  enabled: false,
                },
                events: {
                  click: () => { },
                  legendClick: onLegendClick,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "60%",
                },
              },
              xaxis: {
                categories: categories,
              },
              colors: ["#663FF1", "#9273FF", "#C5B4FF", "#E3DBFF"],
              stroke: {
                width: [0, 0, 0, 0, 3],
                // colors: ['#fff', '#fff', '#fff', "#663FF1"]
              },
              yaxis: {
                title: {
                  text: "Total Comments",
                  offsetX: 3,
                },
                labels: {
                  formatter: (x) => num2Hip(x),
                },
              },
              dataLabels: {
                enabled: false,
              },
              legend: {
                formatter: (val, _) => (val === "Rolling Average" ? "" : val),
                markers: {
                  width: [12, 12, 12, 12, 0],
                },
              },
            }}
            series={series}
            height={350}
          />
        )}
      </WrappedInLoader>
    </Card>
  )
}

const UserLeaderBoard = ({
  fromDate,
  toDate,
  sectionDetails = GET_CALLS?.comment_stats_user_leaderboard
}) => {

  const [data, dispatch] = useContext(dataContext)
  const [_time, setTime] = useState("30")
  const { payload, pagination, loader } = useLocalBoundData({
    ...sectionDetails,
    filter: {
      timeframe: _time > 0 ? _time : _time * -1,
      range_type: "hour"

    },
  })

  const isPoliticalEngageEnabled = data?.me?.brand?.settings?.is_political_engage_enabled

  return (
    <>
      <div className="flex vcenter gap-3 margin-bottom-3x">
        <h3 className="headline-small-highlight">User leaderboard</h3>
        <Select
          type="content"
          styleType="ghost"
          onChange={(e) => setTime(e.target.value)}
          value={_time}
          className="sort-filter margin-x-2x"
          presentationalBitClass="padding-bottom-2x-important padding-top-2x-important nc50-fg-override"
          noBorder
          dropdownIcon={ArrowDown}
        >
          {[
            { value: "-4", title: "Last 4 hours" },
            { value: "-8", title: "Last 8 hours" },
            { value: "-12", title: "Last 12 hours" },
            { value: "-16", title: "Last 16 hours" },
            { value: "-24", title: "Last 24 hours" },
            { value: "7", title: "Last 7 days" },
            { value: "30", title: "Last 30 days" },
            { value: "90", title: "Last 90 days" },
            { value: "180", title: "Last 180 days" },
            { value: "365", title: "Last 365 days" },
            // {value:"-1",title:"All time"}
          ].map((_srt) => (
            <option key={_srt?.value} value={_srt?.value}>
              {_srt?.title}
            </option>
          ))}
        </Select>
        {/* <button
                    type="button"
                    onClick={(e)=>{
                        _nopropagate(e)
                    }}
                    className="margin-left-auto border-none-important font-medium primary-fg transparent-bg cursor-pointer flex gap-x-1"
                >
                    Set Targets
                </button> */}
      </div>
      <div className={`overflow-auto padding-y border-1 nc10-border border-radius-8`}>
        <div className={`comment-leader-board-table-grid body-small-font txt-center nc50-fg`}>
          <div className=" txt-left">
            <span className=" margin-right-2x">#</span>
            username
          </div>
          <div className="">target</div>
          <div className="">comments</div>
          <div className="">share of target</div>
          {/* <div className="">likes</div> */}
          <div className="">replies</div>
          {/* <div className="">impacts</div> */}
        </div>
        <div className={``}>
          <WrappedInLoader data={payload} loader={loader} silent={pagination?.page >= 1}>
            {!payload || !payload?.length ? (
              <NotEnoughData />
            ) : (
              <>
                {payload.map((_stats_by_approver, i) => {
                  // _time negative means hours, positive means days, 300 is the daily target
                  let calculatedTarget = 0
                  if (isPoliticalEngageEnabled) {
                    calculatedTarget = _time > 0 ? _time * 200 : (200 / 24) * _time * -1
                  } else {
                    calculatedTarget =
                      _time > 0
                        ? Math.round(
                          (_stats_by_approver.total_contents > 300 * _time ? _stats_by_approver.total_contents : 300 * _time) / pagination.total,
                        )
                        : Math.round(
                          (_stats_by_approver.total_contents > (300 / 24) * _time * -1
                            ? _stats_by_approver.total_contents
                            : (300 / 24) * _time * -1) / pagination.total,
                        )
                  }
                  return (
                    <div
                      key={i}
                      className={`comment-leader-board-table-grid body-small-font txt-center border-top-1 nc10-border`}
                    >
                      <div className=" txt-left ">
                        <span className="nc50-fg margin-right-3x">{i + 1}</span>
                        {_stats_by_approver.approved_by_user.first_name || _stats_by_approver.approved_by_user.last_name ? (
                          <span className="nc50-fg margin-right-3x">
                            {_stats_by_approver.approved_by_user.first_name + " " + _stats_by_approver.approved_by_user.last_name}
                          </span>
                        ) : (
                          <span className="nc50-fg margin-right-3x">{_stats_by_approver.approved_by_user.name?.split("@")?.[0]}</span>
                        )}
                      </div>
                      <div className="">{Math.round(calculatedTarget)}</div>
                      <div className="">{Math.round(_stats_by_approver.comments_posted || 0)}</div>
                      <div className="flex center">
                        <ProgressBar
                          className="full max-width-190"
                          showPercent
                          height={5.6}
                          progress={((_stats_by_approver.comments_posted / calculatedTarget) * 100).toFixed(2)}
                        />
                      </div>
                      {/* <div className="">
                                                { data.likes || 0 }
                                            </div> */}
                      <div className="">{_stats_by_approver.total_replies || 0}</div>
                      {/* <div className="">
                                                { data.impacts || 0 }
                                            </div> */}
                    </div>
                  )
                })}
              </>
            )}
          </WrappedInLoader>

          {!!pagination?.hasNext && <ScrollSpy
            className="full-span-grid-column"
            rebindTrigger={pagination?.next}
            loader={loader}
            onEngage={pagination.loadNext}
          />}
        </div>
      </div>
    </>
  )
}

const CommentTargetVsProgress = ({
  fromDate,
  toDate,
}) => {
  // const [_time, setTime] = useState("30")
  const [data, setData] = useState([])
  const { payload, loader: loading } = useLocalBoundData({
    ...GET_CALLS.comment_stats_target_vs_progress,
    filter: {
      post_date__gte: fromDate,
      post_date__lte: toDate
    }

  })

  const _data = targetVsProgressParser(payload, fromDate, toDate)

  return (
    <>
      <div className="flex vcenter j-space-between">
        <h3 className="headline-small-highlight">Comment target vs progress</h3>
        {/* <Select
          type="content"
          styleType="ghost"
          onChange={(e) => setTime(e.target.value)}
          value={_time}
          className="sort-filter margin-x-2x"
          presentationalBitClass="padding-bottom-2x-important padding-top-2x-important  nc50-fg-override"
          noBorder
          dropdownIcon={ArrowDown}
        >
          {[
            { value: "7", title: "Last 7 days" },
            { value: "30", title: "Last 30 days" },
            { value: "60", title: "Last 60 days" },
            { value: "90", title: "Last 90 days" },
          ].map((_srt) => (
            <option key={_srt?.value} value={_srt?.value}>
              {_srt?.title}
            </option>
          ))}
        </Select> */}
      </div>
      <WrappedInLoader data={payload} loader={loading}>
        {!payload ? (
          <NotEnoughData />
        ) : (
          <>
            <Chart
              series={_data?.series}
              options={{
                chart: {
                  type: "bar",
                  height: 350,
                  stacked: true,
                  stackType: "100%",
                  toolbar: {
                    show: false,
                  },
                  animations: {
                    enabled: false,
                  },
                },
                colors: ["#663FF1", "#E5DDFF", "#F51677"],
                xaxis: {
                  categories: _data?.category,
                },
                yaxis: {
                  title: {
                    text: "share of target achieved",
                    offsetX: 3,
                  },
                },
                fill: {
                  opacity: 1,
                },
                dataLabels: {
                  enabled: false,
                  style: {
                    colors: ["#000"],
                  },
                },
                legend: {
                  // position: 'bottom',
                  show: false,
                },
                // stroke: {
                //     width: [3,3],
                //     colors: ['transparent', 'red']
                // },
                tooltip: {
                  shared: true,
                  intersect: false,
                  y: {
                    formatter: (val, a) => {
                      if (a.seriesIndex === 2) {
                        return ""
                      } else if (a.seriesIndex === 1) {
                        return a.series[0][a.dataPointIndex] + a.series[1][a.dataPointIndex]
                      } else {
                        return val
                      }
                    },
                    title: {
                      formatter: (seriesName) => (seriesName === "dummy" ? "" : seriesName),
                    },
                  },
                  // x: {
                  //     formatter: (val, { series, seriesIndex, dataPointIndex, w }) => {
                  //         return `Day ${val}`;
                  //     }
                  // },
                  marker: {
                    show: false,
                  },
                },
              }}
              type="bar"
              height={350}
            />
            <div className="grid grid-cols-3 gap-3 vcenter margin-bottom-5x margin-top-2x hide-on-desktop">
              <div className="flex vcenter">
                <span className="headline-huge">450</span>
                <span className="nc50-fg">/700</span>
                <ProgressBar
                  progress={60}
                  height={5}
                  showPercent
                  percentPosition="right"
                  percentClassName="nc50-fg"
                  className="full max-width-170 margin-left-4x"
                />
              </div>
              <div className="flex center gap-3 nc50-fg">
                <button className="cursor-pointer flex gap-2 vcenter transparent-bg transparent-border">
                  <span className="min-height-8 min-width-8 rb100-bg border-radius-2"></span>
                  <span>Comments Posted</span>
                </button>
                <button className="cursor-pointer flex gap-2 vcenter transparent-bg transparent-border">
                  <span className="min-width-16 min-height-2 rb-pink-bg"></span>
                  <span>Target</span>
                </button>
              </div>
              <div>
                <ul className="flex vcenter j-end gap-2">
                  <li>
                    <button className="cursor-pointer transparent-bg transparent-border ">All</button>
                  </li>
                  <span className="nc10-fg body-small-font">/</span>
                  <li>
                    <button className="cursor-pointer transparent-bg transparent-border nc30-fg">Owned</button>
                  </li>
                  <span className="nc10-fg body-small-font">/</span>
                  <li>
                    <button className="cursor-pointer transparent-bg transparent-border nc30-fg">Brand</button>
                  </li>
                  <span className="nc10-fg body-small-font">/</span>
                  <li>
                    <button className="cursor-pointer transparent-bg transparent-border nc30-fg">Competitors</button>
                  </li>
                  <span className="nc10-fg body-small-font">/</span>
                  <li>
                    <button className="cursor-pointer transparent-bg transparent-border nc30-fg">Themes</button>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </WrappedInLoader>
    </>
  )
}

const BrandCommentStats = () => {
  const [fromDate, setFromDate] = useState(getSubtractedDate(new Date(), 29, 'YYYY-MM-DD'))
  const [toDate, setToDate] = useState(formatDate(new Date(), 'YYYY-MM-DD'))
  return (
    <>
      <div className="flex j-space-between a-center margin-top-8x margin-bottom-5x">
        <h2 className="headline-large">Comment Statistics</h2>
        <DateRangePicker fromDate={fromDate} toDate={toDate} onChange={(from, to) => { setFromDate(from); setToDate(to) }} />
      </div>

      <KeyPerformanceMetrics fromDate={fromDate} toDate={toDate} />
      <CommentTimeSeries fromDate={fromDate} toDate={toDate} />
      <Card className={`padding-y-5x padding-x-5x full`}>
        <CommentTargetVsProgress fromDate={fromDate} toDate={toDate} />
        <UserLeaderBoard fromDate={fromDate} toDate={toDate} />
      </Card>
    </>
  )
}

export { BrandCommentStats }
