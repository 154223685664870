import React from "react"
import PropTypes from "prop-types"

export const Toggle = ({ value, onChange, className = "", ...rest }) => {
  return (
    <div className={`toggle-wrapper ${className} ${value ? "active" : ""}`}>
      <div className="toggle-circle"></div>
      <input className="native-checkbox" type="checkbox" checked={value} onChange={onChange} {...rest} />
    </div>
  )
}

export const ToggleWithTitle = ({ className = "", firstTitle = "", lastTitle = "", onClick, value }) => {
  return (
    <div className={`flex a-center button-medium-font ${className}`} onClick={onClick}>
      <span className={!value ? `nc70-fg` : `nc30-fg`}>{firstTitle}</span>
      <Toggle className="margin-x-2x nc75-bg-override" value={value} onChange={() => {}} />
      <span className={value ? `nc70-fg` : `nc30-fg`}>{lastTitle}</span>
    </div>
  )
}

Toggle.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
}
