import { useEffect, useRef } from "react"
import { FullLoader } from "./loaders"

function ScrollSpy({ onEngage, rebindTrigger, loader = false, className = "" }) {
  const el = useRef(null)
  const ob = new IntersectionObserver((entry) => {
    if (entry?.[0]?.isIntersecting) {
      return onEngage()
    }
  })
  useEffect(() => {
    if (el) ob.observe(el.current)
    return () => {
      ob.disconnect()
    }
  }, [el, rebindTrigger])
  return (
    <div className={`cs-scroll-spy ${className}`} ref={el}>
      {loader ? <FullLoader /> : null}
    </div>
  )
}

export { ScrollSpy }
