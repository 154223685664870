import React from "react"
import PropTypes from "prop-types"

const Radio = ({
  label,
  tailText,
  selected,
  onClick,
  value,
  justify = "space-between",
  disabled = false,
  className,
}) => {
  return (
    <div
      role="label"
      className={`radio ${selected ? "selected body-highlight-font" : "body-regular-font"} ${disabled ? "disabled" : ""} ${justify} ${className}`}
      onClick={(e) => {
        !disabled && onClick && onClick(value)
      }}
    >
      <div className="head">
        <input type="radio" disabled={disabled} className={`radio-input ${selected ? "selected" : ""}`} value={value} />
        {label ? <span className="radio-label">{label}</span> : null}
      </div>
      {tailText ? <span className="radio-tail">{tailText}</span> : null}
    </div>
  )
}

Radio.propTypes = {
  selected: PropTypes.bool,
  label: PropTypes.string,
  tailText: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  justify: PropTypes.oneOf(["center", "space-between"]),
  disabled: PropTypes.bool,
}

const IconRadio = ({ icon, tailText, selected, onClick, value, disabled = false, className = "" }) => {
  return (
    <div
      role="label"
      className={`line-height-5-important ${selected ? "selected body-highlight-font" : "body-regular-font"} ${disabled ? "disabled" : ""} ${className}`}
      onClick={(e) => {
        !disabled && onClick && onClick(value)
      }}
    >
      <input
        type="radio"
        hidden
        disabled={disabled}
        className={`radio-input ${selected ? "selected" : ""}`}
        value={value}
      />
      {icon && <span className="display-inline-block">{icon}</span>}
      {tailText && <span className="radio-tail"> {tailText} </span>}
    </div>
  )
}

export { Radio, IconRadio }
