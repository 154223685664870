import React, { useCallback, useContext, useEffect } from "react"
import OnboardingImage from "../../assets/onboarding-steps.jpg"
import { Button } from "./../button"
import { LINK_TO_REF_TERMS, REF_URL } from "../../consts"
import { CopyableInput } from "../copyableInput"
import clipboard from "clipboardy"
import { useToast } from "../../hooks/useToast"
import { IfCreator } from "../functional/auth_components"
import { dataContext } from "../../contexts/datacontext"
import { apiContext } from "../../contexts/apicontext"
import { shareUrlViaWebshare } from "../../helpers/utils"

export const ReferralPage = (props) => {
  const [data] = useContext(dataContext)
  const api = useContext(apiContext)
  const toast = useToast()

  const shareAbleLink = `https://${REF_URL}/${data?.me?.referral_code}`

  const handleShare = useCallback(() => {
    // not moved this to utils because we need to have a backup if webshare is not supported
    let success = shareUrlViaWebshare({
      url: shareAbleLink,
      title: "Refer Zelf To friends",
    })
    if (success) {
      api.analytics.action("creator:referrals", "webshare_referral_code", data?.me?.referral_code)
      return true
    }
    clipboard
      .write(shareAbleLink)
      .then((status) => {
        api.analytics.action("creator:referrals", "copy_referral_code", data?.me?.referral_code)
        toast("Copied to clipboard")
      })
      .catch((e) => console.error(`Error accessing clipboard`))
  }, [shareAbleLink])

  useEffect(() => {
    api.analytics.page("/create-your-referral", "Referrals page")
  }, [])

  return (
    <IfCreator>
      <div className="onboarding-page-container full-min-height-without-header-and-footer-onboarding mobile-nc00-bg referral-page-container">
        <div className="onboarding-card">
          <div className="onboarding-left-side ">
            <div className="onboarding-img-container yellow-left-side-shadow">
              <img className="onboarding-img" alt="steps-banner" src={OnboardingImage} loading="lazy" />
            </div>
          </div>

          <div className="onboarding-right-side">
            <div className="full-width  referral-content">
              <div className="referral-invite-banner referral-content-max-width">
                <div className="padding-x-4x">
                  <h3 className="headline-large txt-center margin-bottom-2x ">Invite Your Friends</h3>
                  <p className="nc00-fg txt-center headline-small-regular">
                    We’ll pay you 10% of what they earn on <span className="txt-bold">Zelf.</span> for a full year.
                  </p>
                  <p className="nc00-fg txt-center headline-small-regular margin-top">
                    They still earn the full amount, we pay you directly on top!
                  </p>
                </div>
              </div>
              <div className="full-width margin-top-6x referral-content-max-width">
                <CopyableInput fixedPart={REF_URL} textPart={data?.me?.referral_code} copyableText={shareAbleLink} />
              </div>
              <Button fluid className="margin-top-3x hide-on-desktop" size="large" onClick={handleShare}>
                Share
              </Button>
              <h5 className="txt-center caption-highlight nc30-fg margin-top-5x">Eligibility</h5>
              <p className="txt-center nc75-fg margin-y-2x body-regular-font">
                Invite creators with over 10k followers. Make sure they add their Zelf. link to their LinkTree or
                link-in-bio solution.
              </p>

              <a
                href={LINK_TO_REF_TERMS}
                rel="noreferrer"
                target="_blank"
                className="txt-center block-component primary-fg-override body-highlight-font cursor-pointer"
              >
                Terms &amp; Conditions
              </a>
            </div>
          </div>
        </div>
      </div>
    </IfCreator>
  )
}
