import { Header } from "./structural/header"
import { Footer } from "./footer"
import { useNavigate } from "react-router-dom"
import { useContext, useState } from "react"
import { apiContext } from "../contexts/apicontext"
import { Button } from "./button"
import { getQueryParam } from "../helpers/utils"
import { Loader } from "./loaders"
function GuestWelcome({ brandID, onGuestProcessEnd }) {
  const api = useContext(apiContext)
  const navigate = useNavigate()
  const [ld, setLd] = useState(false)
  const brand_name = String.prototype.split.call(getQueryParam(window.location.search, "brand"), "-")?.[2]

  const getGuestCreds = async () => {
    localStorage.clear() // clear local storage
    setLd(true)
    const guestCreds = await api.brand.requestGuestToken(brandID)
    setTimeout(() => {
      onGuestProcessEnd()
    }, 1000)
  }
  return (
    <>
      <Header />
      <div className="guest-welcome-cover">
        <div className="guest-welcome-dialog-outer">
          <div className="guest-welcome-dialog-inner padding-x-15x padding-y-15x border-radius-4 box-shadow-decorative">
            <div className="welcome-dialog-content margin-x-15x flex vertical vcenter">
              <div className="headline-large txt-center">
                Welcome to your <span className="txt-capitalize">{brand_name}</span> dashboard to find your biggest fans
                on TikTok
              </div>
              <div className="caption-regular-font nc50-fg margin-y-15x txt-center">
                Click the following button to preview your dashboard
              </div>
              <Button disabled={ld} onClick={getGuestCreds} size="large">
                {ld ? <Loader className="margin-right" /> : null}
                Take me to my dashboard
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export { GuestWelcome }
