import React, { useContext, useEffect, useState } from "react"
import OnboardingImage from "../../assets/onboarding-steps.jpg"
import { Button } from "../button"
import { Spacer } from "../spacer"
import { Input } from "../input"
import { Select } from "../select"
import validator from "validator"
import { apiContext } from "../../contexts/apicontext"
import { useLoading } from "../../contexts/loaderContext"
import { Loader, FullLoader } from "../loaders"
import { TermsLink } from "../dumbs"
import { useNavigate } from "react-router-dom"
const options = [
  { text: "50 or less employees", val: "SMALL" },
  { text: "50+ employees", val: "BIG" },
]

export const OnbaordingBrandDetails = (props) => {
  const api = useContext(apiContext)
  const [name, setName] = useState("")
  const [domain, setDomain] = useState("")
  const [size, setSize] = useState("SMALL")
  const { loaders } = useLoading()
  const navigate = useNavigate()

  useEffect(() => {
    api.analytics.page("/start-brand", "Brand Onboarding: Welcome")
  }, [])

  useEffect(() => {
    ;(async () => {
      const me = await api.common.me()
      if (me?.brand) {
        navigate("/")
      }
    })()
  }, [])

  const onContinueClick = async () => {
    const updated = await api.brand.update({ name, domain, size })
    if (updated) return api.brand.next("/brand-dashboard")
  }
  if (loaders["auth0me"]) {
    return <FullLoader />
  }

  return (
    <div className="onboarding-page-container full-min-height-without-header-and-footer-onboarding">
      <div className="onboarding-mobile-background hide-on-desktop bg-yellow"></div>
      <div className="onboarding-card">
        <div className="onboarding-brand-left-side onboarding-left-side ">
          <div className="onboarding-img-container yellow-left-side-shadow">
            <img alt="brand onboarding" className="onboarding-img" src={OnboardingImage} loading="lazy" />
          </div>
        </div>

        <div className="onboarding-right-side">
          <Spacer className="hide-on-mobile" height={48} />
          <Spacer className="hide-on-desktop" height={16} />
          <div className="onboarding-brand-max-header-container  flex column a-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="57" fill="none" viewBox="0 0 56 57">
              <path
                fill="#1D65E9"
                d="M37.427 18.713L23.615 33.635l-5.042-5.504c-.262-.227-.682-.227-.893 0l-1.523 1.646c-.21.226-.21.68 0 .964l7.038 7.546c.262.284.63.284.892 0l15.756-17.021c.21-.227.21-.68 0-.965l-1.524-1.588c-.21-.284-.63-.284-.892 0z"
              />
              <rect width="53" height="53" x="1.5" y="2" stroke="#1D65E9" strokeWidth="3" rx="26.5" />
            </svg>
            <Spacer className="hide-on-mobile" height={24} />
            <Spacer className="hide-on-desktop" height={16} />
            <h3 className="headline-large nc100-fg txt-center onboading-steps-header ">Welcome Onboard!</h3>
            <Spacer className="hide-on-mobile" height={24} />
            <Spacer className="hide-on-desktop" height={16} />
            <p className="body-regular-font txt-center nc50-fg mobile-nc75-fg onboading-steps-header-description margin-bottom-2x">
              Please provide your company information to allocate acquired licenses/ usage rights.
            </p>
            <p className="caption nc30-fg mobile-nc75-fg txt-center">
              (If you are an agency, you can allocate licenses to clients upon purchase)
            </p>
          </div>

          <Spacer className="hide-on-mobile" height={24} />
          <Spacer className="hide-on-desktop" height={48} />

          <div className="onboarding-group flex column  ">
            <Input
              className="margin-bottom-2x"
              placeholder="Your Company Name"
              fluid={true}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              className="margin-bottom-2x"
              placeholder="www.domain.com"
              fluid={true}
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
            />
            <Select value={size} onChange={(e) => setSize(e.target.value)} placeholder="Select Number of Employees ">
              {options.map((option, index) => (
                <option key={option.val} value={option.val}>
                  {option.text}
                </option>
              ))}
            </Select>
            <Spacer className="hide-on-mobile" height={24} />
            <Spacer className="hide-on-desktop" height={16} />

            <Button
              disabled={!name || !validator.isURL(domain) || !size || loaders?.brand_update}
              fluid
              size="large"
              onClick={onContinueClick}
            >
              {loaders?.brand_update ? <Loader /> : "Complete Set Up"}
            </Button>
            <p className="caption nc30-fg txt-center hide-on-desktop margin-top-3x">
              By clicking Complete Set Up I accept the <TermsLink />
            </p>
          </div>
          <p className="hide-on-mobile onboarding-brand-desktop-terms-and-services caption nc30-fg txt-center">
            By clicking Complete Set Up I accept the <TermsLink />
          </p>
        </div>
      </div>
    </div>
  )
}

OnbaordingBrandDetails.propTypes = {}
