import React, { useState, useEffect } from "react"
import { TimesLargeFilledMono } from "../../components/Icons"
import { _nopropagate } from "../../helpers/utils"

const BaseDialog = ({
  show,
  dismissDialog,
  content,
  showHeader = true,
  dialogBodyClassName = "cs-dialog-body",
  headerElement = null,
  headerContainerClass = "cs-dialog-header flex j-space-between a-center nc00-bg padding-x-5x padding-y-3x border-bottom-1 nc05-border headline-medium-highlight",
  contentClassName = "cs-dialog-content full nc00-bg padding-y-3x txt-center padding-x-3x",
  showFooter = true,
  footerElement = null,
  footerClassName = "cs-dialog-footer padding-3x nc00-bg border-top-1 nc05-border",
}) => {
  const [visibleInternal, setVisibleInternal] = useState(false)
  const [animationState, setAnimationState] = useState("")

  useEffect(() => {
    if (show) {
      setVisibleInternal(true)
      // if(animationState !== "entry") setAnimationState("entry");
    } else {
      // setAnimationState("exit");
      setTimeout(() => {
        setVisibleInternal(false)
      }, 200)
    }
  }, [show])

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden"
      return
    }
    document.body.style.overflow = "unset"
  }, [show])

  return visibleInternal ? (
    <div
      onClick={dismissDialog}
      className={`cs-dialog darker-dialogoverlay cs-dialog-cover flex vcenter hcenter ${animationState}`}
    >
      <div className={dialogBodyClassName} onClick={_nopropagate}>
        {showHeader ? (
          <div className={headerContainerClass}>
            <div>{headerElement}</div>
            <div onClick={dismissDialog} className="dialog-close-button nc50-fg flex a-center cursor-pointer">
              {TimesLargeFilledMono}
            </div>
          </div>
        ) : null}

        <div className={contentClassName}>{content}</div>

        {showFooter ? <div className={footerClassName}>{footerElement}</div> : null}
      </div>
    </div>
  ) : null
}

export { BaseDialog }
