import React, { useContext, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { Button } from "./button"
import { TimesLargeFilledMono } from "../components/Icons"
import { dataContext } from "../contexts/datacontext"

//images for mobile devices
import EditYourProfileUrlImg from "./../assets/tour/edit-your-profile.png"
import SetPriceImg from "./../assets/tour/set-price.png"
import DeletedUnwatchImg from "./../assets/tour/delete-unwanted-content.png"
import EditYourProfileImg from "././../assets/tour/edit-profile-info.png"
import { apiContext } from "../contexts/apicontext"

const UserTourSteps = ({ currentStep = 4, totalStep = 4 }) => {
  let steps = []
  for (let i = 0; i < totalStep; i++) {
    steps.push(<div key={i} className={`user-tour-step ${i === currentStep - 1 ? "active" : ""}`}></div>)
  }

  return <div className="flex a-center">{steps}</div>
}

// steps

// current step = 0
const WelcomeStep = () => (
  <>
    <h4 className="headline-xlarge margin-bottom-5x txt-center">Welcome to your profile!</h4>
    <p className="body-regular-font nc75-fg txt-center">
      Excited to have you on board! Let us show you in 4 quick steps how to make the most of your profile{" "}
    </p>
  </>
)

// current step = 1
const EditYourUserProfile = () => (
  <div>
    <img
      className="user-tour-img hide-on-desktop margin-bottom-3x"
      src={EditYourProfileImg}
      alt="Edit your profile Img"
    />
    <h4 className="headline-medium-highlight txt-center nc75-fg hide-on-mobile">
      You can edit your profile info here (Coming Soon!)
    </h4>
    <h4 className="headline-small-highlight txt-center nc75-fg hide-on-desktop margin-bottom-2x margin-x-2x">
      You can edit your profile info at the top of your Account Settings
    </h4>
  </div>
)

// current step = 2
const SetPrice = () => (
  <div>
    <img className="user-tour-img hide-on-desktop margin-bottom-3x" src={SetPriceImg} alt="Edit your profile Img" />
    <div className="hide-on-mobile">
      <h4 className="headline-medium-highlight txt-center nc75-fg margin-bottom-3x">
        This is where you set your rates.
      </h4>
      <p className="body-regular-font txt-center nc75-fg">
        Rates are set as fixed rates (for social media repost and website integration) or by month (for advertising).
        From your monthly rate we automatically calculate daily, quarterly, and annual rates, using industry standard
        discounts, so you only have to fill out your monthly rate.
      </p>
    </div>
    <div className="hide-on-desktop">
      <h4 className="headline-medium-highlight txt-center nc75-fg margin-bottom-2x">
        Your prices can be set at “License Rates”
      </h4>
      <p className="body-regular-font txt-center nc75-fg">
        You only have to set single use rates and monthly costs and we automatically calculate the rest using industry
        standard discounts
      </p>
    </div>
  </div>
)

// current step = 3
const DeleteUnwatedContent = () => (
  <div>
    <img
      className="user-tour-img hide-on-desktop margin-bottom-5x"
      src={DeletedUnwatchImg}
      alt="Delete Unwanted Content"
    />
    <h4 className="headline-medium-highlight txt-center nc75-fg hide-on-mobile">
      If there are any posts you would prefer not to have on your <span style={{ fontWeight: 700 }}>Zelf.</span> profile
      for licensing, delete them using the trash can icon
    </h4>
    {/* mobile */}
    <div className="hide-on-desktop">
      <h4 className="headline-medium-highlight txt-center nc75-fg margin-bottom-2x">
        Delete posts you don’t want to sell
      </h4>
      <p className="body-regular-font txt-center nc75-fg">
        If you don’t want any of the auto-uploaded posts to be sold, go to your account preview, tap the picture, and
        then click the trash can; this will remove it from your licensable feed
      </p>
    </div>
  </div>
)

// current step = 4
const ChangeUserName = () => (
  <div>
    <img
      className="user-tour-img hide-on-desktop margin-bottom-5x"
      src={EditYourProfileUrlImg}
      alt="Delete Unwanted Content"
    />
    <h4 className="headline-medium-highlight txt-center nc75-fg margin-bottom-3x">Final step: Broadcast to brands</h4>
    <p className="body-regular-font txt-center nc75-fg">
      Once you have completed the previous steps, you can copy your gallery link here and add it to your LinkTree or
      other link-in-bio solution.
    </p>

    <p className="body-regular-font txt-center nc75-fg hide-on-mobile margin-top-3x">
      This will allow brands to find your licensable content directly. When a brand asks to license your content you can
      also easily send them to this link.
    </p>
  </div>
)

// END STEPS

const ArrowIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="28" fill="none" viewBox="0 0 9 28">
    <path
      fill="#fff"
      d="M1.748 15.347a2.667 2.667 0 0 1 0-3.771L5.943 7.38A10.438 10.438 0 0 0 9 0v28-.983a10.67 10.67 0 0 0-3.124-7.543l-4.128-4.128Z"
    />
  </svg>
)

// BACKDROP
const UserTourDialogBackDrop = ({ onClick }) => <div onClick={onClick} className="user-tour-dialog-backdrop"></div>

export const UserTourDialog = ({
  currentStep = 2,
  totalStep = 4,
  onBack,
  onNext,
  onDismiss,
  show = true,
  className = "",
}) => {
  const ref = useRef(null)
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [currentStep])
  if (!show) {
    return null
  }

  return (
    <div className={className}>
      <UserTourDialogBackDrop onClick={onDismiss} />
      <div
        ref={ref}
        className={`user-tour-dialog-container flex column ${currentStep !== 0 ? "element-based-positioned mobile-fixed-center" : "fixed-center"}`}
      >
        {currentStep !== 0 ? <div className="user-tour-arrow hide-on-mobile"> {ArrowIcon} </div> : null}

        <header className="user-tour-dialog-header flex j-space-between a-center nc05-border">
          <h3 className="headline-medium-highlight">
            {currentStep !== 0 ? `Step ${currentStep} of ${totalStep}` : "Welcome to Zelf"}
          </h3>
          <div className="nc50-fg cursor-pointer" onClick={onDismiss}>
            {TimesLargeFilledMono}
          </div>
        </header>
        <div className="user-tour-dialog-content">
          {currentStep === 0 ? <WelcomeStep /> : null}
          {currentStep === 1 ? <EditYourUserProfile /> : null}
          {currentStep === 2 ? <SetPrice /> : null}
          {currentStep === 3 ? <DeleteUnwatedContent /> : null}
          {currentStep === 4 ? <ChangeUserName /> : null}
        </div>
        <footer className="user-tour-dialog-footer flex j-space-between a-center">
          <div>{currentStep !== 0 ? <UserTourSteps currentStep={currentStep} totalStep={totalStep} /> : null}</div>
          <div className="flex">
            {currentStep !== totalStep && currentStep !== 0 ? (
              <Button className="margin-right-2x" type="secondary" size="medium" onClick={onBack}>
                {" "}
                Back
              </Button>
            ) : null}
            <Button type="primary" size="medium" onClick={onNext}>
              {currentStep !== totalStep ? "Next" : "Done"}{" "}
            </Button>
          </div>
        </footer>
      </div>
    </div>
  )
}

// using wrapper here because otherwise code would be repeated
export const UserTourDialogWithDataContextWrapper = ({ className, visibleInStep }) => {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const onDismiss = () => {
    api.creator.dismissTour()
  }
  const step = data.creator_tour_step

  const setStep = (step) => {
    dispatch({ creator_tour_step: step })
  }

  const onBack = () => {
    // console.log(step)
    if (step <= 0) {
      setStep(0)
      return
    }
    setStep(step - 1)
  }

  const onNext = () => {
    // console.log(step)
    if (step <= 3) {
      setStep(step + 1)
    }
    if (step === 4) {
      api.creator.dismissTour()
    }
  }
  return (
    <UserTourDialog
      className={className}
      visibleInStep={visibleInStep}
      currentStep={data.creator_tour_step}
      show={data.show_creator_tour && data.creator_tour_step === visibleInStep}
      onDismiss={onDismiss}
      onNext={onNext}
      onBack={onBack}
    />
  )
}

UserTourDialog.propTypes = {
  currentStep: PropTypes.number,
  totalStep: PropTypes.number,
  show: PropTypes.bool,
}
