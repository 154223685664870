import { createContext, useContext, useEffect } from "react"
import { dataContext } from "./datacontext"
import { hardwareProbe, _lsd, _lsr, _lsw } from "../helpers/utils"
import { useWindowResize } from "../hooks/useWindowResize"
import { useCreator } from "./apis/creator"
import { usePaymentAPIS } from "./apis/payment"
import { useSystemAlerts } from "./apis/alerts"
import { useAuth0 } from "@auth0/auth0-react"
import { LOCALSTORAGE_PREFIX } from "../consts"
import { useBrand } from "./apis/brand"
import { useCommonAPIS } from "./apis/common"
import { ROLES } from "../consts"
import { useAnalytics, usePageAnalytics } from "./apis/analytics"
import { CommonDialog } from "../hooks/useDialog"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuthHelper } from "../hooks/useAuthHelper"

const apiContext = createContext()
function APIContextProvider({ children }) {
  const [data, dispatch] = useContext(dataContext)
  const w = useWindowResize()
  const creator = useCreator()
  const brand = useBrand()
  const common = useCommonAPIS()
  const payment = usePaymentAPIS()
  const alert = useSystemAlerts()
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  usePageAnalytics() // pageview analytics
  const { isAuthenticated } = useAuthHelper()
  const { loginWithRedirect, logout } = useAuth0()

  const _logout = (explaination = "") => {
    console.log(`Logout because: ${explaination}`)
    analytics.action("user", "logout")
    // Clear cache
    Object.keys(localStorage).forEach((_item) => {
      if (_item.match(LOCALSTORAGE_PREFIX)) _lsd(_item, false)
    })
    // Call Auth0
    logout({ returnTo: window.location.origin })
  }

  const _login = (isSignup = false) => {
    analytics.action("user", isSignup ? "signup" : "login")
    const config = isSignup ? { screen_hint: "signup" } : {}
    loginWithRedirect(config)
  }

  useEffect(() => {
    dispatch(hardwareProbe())
  }, [w.width])

  useEffect(() => {
    if (isAuthenticated) {
      // All initial setup for the authenticated user
      ; (async () => {
        const me = await common.me()
        if (!me) return _logout("No Auth0 Me")
        let role = ""
        let is_agency = false
        if (me.role) {
          if (me.role === "BRAND_USER" && me?.brand) {
            // navigate("/brand-dashboard")
            dispatch({ role: "brand" })
            return
          }
          if (me?.role === "CREATOR") {
            dispatch({ role: "creator" })
            return
          }
          return
        } else {
          let _role = _lsr("cs_last_set_mode")
          role = _role ? _role : "CREATOR"
          _lsd("cs_last_set_mode")
          // patch for agency
          if (_role === "brand") {
            _role = "BRAND_USER"
          } else if (_role == "creator") {
            _role = "CREATOR"
          } else if (_role === "agency") {
            _role = "BRAND_USER"
            is_agency = true
          }
          // if(!_role || ROLES.indexOf(_role) === -1) return _logout("Role missing");
          const refcode = _lsr("user_referral_code")
          const createNew = _role === "CREATOR" ? await creator.create() : await brand.create({ is_agency })
          if (!createNew) return _logout(`Could not create new ${_role} account`)

          _lsd("user_referral_code")
          analytics.action("user", "signup_success")
          if (refcode) analytics.action("user", "signup_using_referral_code", refcode)
        }
      })()
    }
  }, [isAuthenticated])

  useEffect(() => {
    dispatch({ is_me_creator: Boolean(data?.creator?.id && data?.me?.id && data?.me?.id === data?.creator?.id) })
  }, [data.me, data.creator])

  useEffect(() => {
    const brand_eco_invite = _lsr("brand-ecosystem-invite-refcode")
    if (pathname.startsWith("/start-from-brand-invite")) return
    if (brand_eco_invite) creator.getBrandInfoFromInviteIDOrBrandID(brand_eco_invite)
  }, [])


  useEffect(() => {
    // set last creator for logged in user
    if (!isAuthenticated && data.creator) _lsw("last_visited_creator", data?.creator?.username)
  }, [data.creator, isAuthenticated])
  // capture last visited creator before it gets deleted
  const last_visited_creator = _lsr("last_visited_creator")
  if (last_visited_creator) {
    analytics.action("page", "visit_main_profile_return", last_visited_creator)
    _lsd("last_visited_creator")
  }
  const apis = {
    payment,
    creator,
    brand,
    common,
    alert,
    analytics,
    login: _login,
    logout: _logout,
  }
  return (
    <apiContext.Provider value={apis}>
      <CommonDialog />
      {children}
    </apiContext.Provider>
  )
}

export { APIContextProvider, apiContext }
