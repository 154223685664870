import { createContext, useContext, useEffect, useState } from "react"
import { CheckLargeFilledMono } from "../components/Icons"
import { ANIMATION_DURATION } from "../consts"
const toastContext = createContext()
function ToastProvider({ children }) {
  const [toast, setToastContent] = useState("")
  const [state, setAnimState] = useState("")
  const [icon, setToastIcon] = useState(null)
  const [tailElement, setToastTailElement] = useState(null)
  const [visible, setVisible] = useState(false)
  const [_visible, setInternalVisible] = useState(false)
  useEffect(() => {
    if (visible) {
      setInternalVisible(true)
      setAnimState("entry")
    } else {
      setAnimState("exit")
      setTimeout(() => {
        setInternalVisible(false)
        setToastContent("")
        setToastIcon(null)
      }, ANIMATION_DURATION)
    }
  }, [visible])
  const ctxData = {
    setToastContent,
    setVisible,
    setToastIcon,
    setToastTailElement,
  }
  return (
    <toastContext.Provider value={ctxData}>
      {_visible ? (
        <div
          className={`cs-toast  animation-fade ${state} flex a-center button-large-font primary-fg border-radius-4 ultralight-bg light-border border-1  `}
        >
          <div className={`flex a-center padding-y-2x ${icon ? "padding-right-5x padding-left-3x" : "padding-x-5x"}`}>
            <span className="flex a-center h-center margin-right-2x">{icon}</span> <span>{toast}</span>
          </div>
          {tailElement ? (
            <>
              <div className="vertical-divider  light-bg"></div>
              {tailElement}
            </>
          ) : null}
        </div>
      ) : null}
      {children}
    </toastContext.Provider>
  )
}
function useToast() {
  const actions = useContext(toastContext)
  return function (message, icon = CheckLargeFilledMono, duration = 3000, tailElement = null) {
    if (!message) return console.error("Cannot show toast! content is empty")
    actions.setToastContent(message)
    icon && actions.setToastIcon(icon)
    actions.setVisible(true)
    actions.setToastTailElement(tailElement)
    if (duration !== 0) {
      setTimeout(() => {
        actions.setVisible(false)
      }, duration)
    }
  }
}

export { useToast, ToastProvider }
