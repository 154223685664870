import React, { useContext, useState } from "react"

import { dataContext } from "../../contexts/datacontext"
import { apiContext } from "../../contexts/apicontext"
import { EmailIndicator, PlatformIcon, SavedOnBoardStatus } from "../dumbs"
import { num2Hip, createHlink, getExternalUrlFromSocialAccount, getCreatorName } from "../../helpers/utils"
import { useToast } from "../../hooks/useToast"
import { useAuthHelper } from "../../hooks/useAuthHelper"

export const BasicSocialDetails = ({
  platform,
  socialAccountData,
  showFollowers = false,
  index,
  total,
  skipSeparator = false,
}) => {
  const [_data] = useContext(dataContext)
  const api = useContext(apiContext)
  const followLink = () => {
    const external_url = getExternalUrlFromSocialAccount(socialAccountData, platform)
    if (external_url) {
      api.analytics.link("Clicks_Profile_Link_External", platform)
      const a = createHlink(external_url)
      a.click()
    }
  }

  return (
    <div className="social-icons-details-outer-container">
      <div className="social-icon-detail-desktop flex vcenter">
        <div className="detail-inner-desktop flex a-center">
          <div className="social-details-line-one flex vcenter">
            {!skipSeparator ? <span className="dot-separator margin-x-2x"></span> : null}
            <PlatformIcon color state="active" platform={platform} />
            <div
              className="desktop-username headline-small-highlight nc50-fg margin-left  cursor-pointer"
              onClick={followLink}
            >
              @{socialAccountData?.username || socialAccountData?.name}
            </div>
          </div>
          {showFollowers && socialAccountData?.followers ? (
            <>
              <span className="dot-separator margin-x-2x"></span>{" "}
              <div className="social-detail-line-2 nc30-fg body-regular-font ">
                {num2Hip(socialAccountData?.followers)} followers
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export const SocialIconsWithDetails = ({ platform, data, index, total }) => {
  const [active, setActive] = useState(false)
  const [_data] = useContext(dataContext)
  const api = useContext(apiContext)
  const isAllSocialFollowerPresent = (_data) => {
    return _data?.creator_socials?.every?.((item) => {
      if (item.followers || item?.followers === 0) return true
      return false
    })
  }

  const followLink = () => {
    if (data?.external_url) {
      api.analytics.link("Clicks_Profile_Link_External", platform)
      const a = createHlink(data.external_url)
      a.click()
    }
  }
  const show_followers = isAllSocialFollowerPresent(_data)
  const changeActiveState = (state) => {
    setActive(state)
  }
  return (
    <div className="social-icons-details-outer-container">
      {_data.is_mobile ? (
        <div className="social-icons-details-inner-container nc30-fg" onClick={() => changeActiveState(!active)}>
          <PlatformIcon platform={platform} color={active} />
          <div className="social-icons-details nc100-fg">
            <div className={`social-icon-hidden-details flex vcenter ${active ? "active" : ""}`}>
              <span className="social-icons-details-text cursor-pointer" onClick={followLink}>
                {data?.username || data?.name}
              </span>
              {show_followers ? <div className="center-dot"></div> : null}
            </div>
            {show_followers ? <span className="social-icons-details-text">{num2Hip(data?.followers)}</span> : null}
          </div>
        </div>
      ) : (
        <div className="social-icon-detail-desktop flex vcenter">
          <div className="detail-inner-desktop">
            <div className="social-details-line-one flex vcenter">
              <PlatformIcon platform={platform} color={true} />
              <div className="desktop-username margin-left-2x body-regular-font cursor-pointer" onClick={followLink}>
                {data?.username || data?.name}
              </div>
            </div>
            {show_followers ? (
              <div className="social-detail-line-2 nc30-fg body-regular-font margin-top">
                {num2Hip(data?.followers)} followers
              </div>
            ) : null}
          </div>
          {index < total - 1 ? (
            <div className="social-icon-desktop-separator nc05-border border-right-1 margin-left-4x margin-right-4x"></div>
          ) : null}
        </div>
      )}
    </div>
  )
}

function PublicCreatorProfile({ className = "" }) {
  const [data] = useContext(dataContext)
  return (
    <div className={`creator-profile-full flex ${className}`}>
      <img
        src={data?.creator?.profile_picture_url}
        className="creator-profile-avatar margin-right-5x image-with-small-outline"
        alt={data?.creator?.name || data?.creator?.username}
      />
      <div className="creator-public-details">
        <div className="headline-huge margin-top-2x creator-name-public">{getCreatorName(data?.creator)}</div>
        {/* <div className="headline-small-regular margin-top-2x">{data?.creator?.text || "Content Creator"}</div> */}
        {data?.creator_socials_real_platforms?.length ? (
          <div className="creator-public-social margin-top-4x">
            {data.creator_socials_real_platforms.map((_p, idx) => (
              <SocialIconsWithDetails
                key={_p.platform}
                index={idx}
                total={data.creator_socials_real_platforms.length}
                platform={_p.platform}
                data={_p}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

function NotRegisteredPublicCreatorProfile({ className = "", creator, force_show_follower, tailElement = null }) {
  const [data] = useContext(dataContext)
  const toast = useToast()
  const api = useContext(apiContext)
  const { isGuest } = useAuthHelper()
  return (
    <div className={`creator-profile-full flex  ${className} a-center `}>
      <img
        src={creator?.profile_picture_url}
        className="creator-profile-avatar margin-right-5x image-with-small-outline"
        alt={creator?.name || creator?.username}
      />
      <div>
        <div className="creator-public-details ">
          <div className="flex a-center">
            <div className="headline-huge  creator-name-public">{creator?.name || creator?.username}</div>
            <span className="dot-separator margin-left-2x margin-right"></span>
            <EmailIndicator id={creator?.id} email={creator?.email} toast={toast} api={api} isGuest={isGuest} />
            {creator?.board_ids?.length ? <span className="dot-separator margin-left-2x margin-right"></span> : null}
            <SavedOnBoardStatus creator={creator} />
          </div>
          <div className="flex a-center margin-top-2x">
            {Object.keys(creator?.connected_accounts ?? {}).map((x, i) => (
              <BasicSocialDetails
                skipSeparator={i === 0}
                force_show_follower={force_show_follower}
                socialAccountData={{ ...creator?.connected_accounts[x], followers: creator?.followers }}
                key={x}
                platform={x}
                followers={creator.followers}
              />
            ))}
          </div>
        </div>
        {tailElement}
      </div>
    </div>
  )
}

export { PublicCreatorProfile, NotRegisteredPublicCreatorProfile }
