import React, { useContext, useEffect } from "react"
import { apiContext } from "../../contexts/apicontext"
import { dataContext } from "../../contexts/datacontext"
import { SearchList } from "../structural/searchContents"
import { Footer } from "../footer"
import { useNavigate } from "react-router-dom"
import { getQueryParam } from "../../helpers/utils"
import { Tab } from "../tab"
import { platforms, platform_list_search } from "../../consts"
import { PlatformIcon } from "../dumbs"
import { useLoading } from "../../contexts/loaderContext"
import { SearchCreators } from "../structural/searchCreators"

const DesktopTab = ({ selected = false, title, onClick }) => {
  return (
    <div
      onClick={() => onClick()}
      className={`${selected ? "selected" : ""} full creator-homepage-main-tab txt-center padding-y-4x body-regular-font cursor-pointer nc02-bg`}
    >
      {title}
    </div>
  )
}

const MobileTab = ({ selected = false, title, onClick }) => {
  return (
    <div
      onClick={() => onClick()}
      className={`${selected ? "selected" : ""} full creator-homepage-main-tab txt-center padding-y-3x body-regular-font cursor-pointer nc02-bg`}
    >
      {title}
    </div>
  )
}

function SearchResult() {
  const api = useContext(apiContext)
  const [data, dispatch] = useContext(dataContext)
  const navigate = useNavigate()
  const { loaders } = useLoading()
  // use later
  useEffect(() => {
    const query = getQueryParam(window.location.href, "q")
    if (!query) navigate("/")
    dispatch({ search_query: decodeURIComponent(query), search_text_raw: decodeURIComponent(query) })
  }, [data?.search_platform, data.search_query])
  useEffect(() => {
    dispatch({})
    if (window.location.href.match("mode=creator")) dispatch({ search_tab: "book" })
    return () => dispatch({ search_query: "", search_text_raw: "" })
  }, [])
  const changeTab = (search_tab) => {
    dispatch({ search_tab })
  }
  if (!data.search_query) return null
  return (
    <>
      <div className="content-area creator-homepage-wrapper section-spanned-window-height">
        <div className="page-root creator-page-root flex">
          <div className={`creator-public-area full`}>
            <div className="flex vertical a-center margin-top-8x cs-homepage-shrinked-top-padding margin-bottom-3x padding-3x">
              <div className="headline-giant margin-bottom-4x">Search results: {data?.search_query}</div>
              <div className="headline-medium-highlight nc50-fg">
                Discover top performing content and creators for{" "}
                <strong className="nc75-fg">{data?.search_query}</strong>
              </div>
            </div>
            <div className="creator-homepage-main-section border-1 nc10-border border-radius-dynamic overflow-hidden margin-top-13x">
              {data?.on_creator_dashboard ? null : (
                <div className="creator-homepage-tabs flex border-bottom-1 nc10-border hide-on-mobile">
                  <DesktopTab
                    onClick={() => changeTab("license")}
                    selected={data.search_tab === "license"}
                    title="Contents"
                  />
                  <DesktopTab
                    onClick={() => changeTab("book")}
                    selected={data.search_tab === "book"}
                    title="Creators"
                  />
                </div>
              )}
              {data?.on_creator_dashboard ? null : (
                <div className="creator-homepage-tabs flex border-bottom-1 nc10-border hide-on-desktop">
                  <MobileTab
                    onClick={() => changeTab("license")}
                    selected={data.search_tab === "license"}
                    title="Contents"
                  />
                  <MobileTab onClick={() => changeTab("book")} selected={data.search_tab === "book"} title="Creators" />
                </div>
              )}
              <div className="search-platforms-tab flex vcenter hcenter padding-x-4x padding-top-4x">
                {platform_list_search.map((item) => (
                  <Tab
                    key={item}
                    disabled={loaders[`content_search`]}
                    className="social-tab"
                    type="capsules"
                    icon={PlatformIcon({ platform: item, color: data?.search_platform === item })}
                    title={platforms[item].title}
                    selected={data?.search_platform === item}
                    onChange={() => dispatch({ search_platform: item })}
                  />
                ))}
              </div>
              {data.search_tab === "license" ? (
                <div className="creator-homepage-inner padding-4x">
                  <SearchList contents={data?.search_result} />
                </div>
              ) : null}
              {data.search_tab === "book" ? (
                <div className="creator-search-inner">
                  <SearchCreators />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { SearchResult }
