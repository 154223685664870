import { useContext, useEffect } from "react"
import { dataContext } from "../../contexts/datacontext"
import { useLoading } from "../../contexts/loaderContext"
import { SearchThumbnail } from "../contentThumbnail"
import { HourglassLoader } from "../loaders"
import { ScrollSpy } from "../scrollspy"
import { cloneObject, countGridColumns, contentSeparatorReducer } from "../../helpers/utils"
import { Separator } from "../dumbs"
import { apiContext } from "../../contexts/apicontext"

function Item({ content, ...props }) {
  if (content?.type === "group") return <Separator content={content} />
  else return <SearchThumbnail content={content.content} creator={content.creator} {...props} />
}

function SearchList({ contents = null }) {
  const [data, dispatch] = useContext(dataContext)
  const { loaders } = useLoading()
  const api = useContext(apiContext)
  // release content memory on exit
  useEffect(() => {
    ;(async () => {
      await api.common.search(data.search_query, data?.search_platform)
    })()
  }, [data.search_query, data.search_platform])
  useEffect(() => {
    api.analytics.action("search", `visit_content_search_page`, data?.search_query)
    return () => dispatch({ search_result: null, fold: null })
  }, [])
  const setContentFold = (content) => {
    dispatch({ ecosystem_selected_content: content })
  }
  const uniQieKey = (item, idx) => {
    switch (item.type) {
      case "fold":
        return "fold"
      case "group":
        return `g_${idx}`
      default:
        return item.content.uuid
    }
  }
  const searchNextPage = (platform, next) => {
    if (!platform || !next) return false
    api.common.search(data.search_query, platform, next)
  }
  let _contents = cloneObject(contents)?.reduce((arr, item) => contentSeparatorReducer?.(arr, item, "date"), [])
  if (data?.fold && contents?.length) {
    const placement = _contents?.[data.fold.index]?.index_till_last_separator ?? data.fold.index
    const cols = countGridColumns(".creator-content-area").cols
    let foldIndex = data.fold.index + (cols - (placement % cols))
    if (!_contents[foldIndex] || _contents[data.fold.index].group !== _contents[foldIndex].group) {
      let deviation = 0
      for (let c = 1; c < cols; c++) {
        if (
          !_contents[foldIndex - c] ||
          !_contents[foldIndex - c]?.group ||
          _contents[foldIndex - c].group !== _contents[data.fold.index].group
        ) {
          deviation++
        } else break
      }
      if (deviation) foldIndex = foldIndex - deviation
    }
    _contents.splice(foldIndex, 0, data.fold)
  }
  return (
    <>
      <div
        className={`creator-content-area content-search-result margin-top-6x cs-homepage-shrinked-top-padding margin-bottom-11x contents-${data?.search_platform}`}
      >
        {!_contents?.length && !loaders[`content_search`] ? (
          <div className="margin-y-11x full-grid-width txt-center nc75-fg body-regular-font">No results found</div>
        ) : null}
        {!loaders[`content_search`] && _contents?.length
          ? _contents.map((_c, _i) => (
              <Item
                onDismiss={() => dispatch({ fold: null })}
                onClick={() => setContentFold(_c)}
                key={uniQieKey(_c, _i)}
                platform={data?.search_platform}
                content={_c}
              />
            ))
          : null}
      </div>
      {loaders[`content_search`] || loaders[`search_next_page`] ? (
        <div className="flex hcenter vcenter margin-y-11x nc50-fg full-grid-width">
          <HourglassLoader className="margin-right-2x" /> Loading
        </div>
      ) : null}
      {_contents?.length && data?.search_result_raw?.pagination?.next ? (
        <ScrollSpy
          rebindTrigger={data?.search_result_raw?.pagination?.next}
          onEngage={() => searchNextPage(data?.search_platform, data?.search_result_raw?.pagination?.next)}
        />
      ) : null}
    </>
  )
}

export { SearchList }
