import React from "react"
import PropTypes from "prop-types"
import { CheckSmallFilledMono } from "./Icons"

export const Checkbox = ({ value, onChange, className }) => {
  return (
    <label className={`checkbox-wrapper ${className} ${value ? "active" : ""}`}>
      <div className="checkbox-icon">{CheckSmallFilledMono}</div>
      <input className="native-checkbox" type="checkbox" value={value} onChange={onChange} />
    </label>
  )
}

export const MultiSelection = ({
  labelElement,
  value,
  onChange,
  tailElement,
  className = "",
  primaryBorderOnSelection = false,
  children = null,
  innerClassName = "",
  labelClassName = "",
  ghost = false,
}) => {
  return (
    <div
      className={`padding-3x  ${ghost ? "" : "border-1 nc00-bg border-radius-4"} ${value && primaryBorderOnSelection ? "primary-border" : "nc10-border"}  body-highlight-font  ${className}`}
    >
      <div className={`flex a-center j-space-between ${innerClassName}`}>
        <label className="flex a-center checkbox-label-container  cursor-pointer ">
          <Checkbox value={value} onChange={onChange} className="margin-right-2x" />
          <span className={labelClassName}>{labelElement}</span>
        </label>
        {tailElement ? tailElement : null}
      </div>
      {children}
    </div>
  )
}

Checkbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
}

export const EmptyCheckBoX = ({ isChecked = false, onChange, className = "", children }) => {
  return (
    <label className={`position-relative ${className} ${isChecked ? "active" : ""}`} onClick={onChange}>
      <input className="native-checkbox" type="checkbox" checked={isChecked} onChange={() => {}} />
      {children}
    </label>
  )
}
