import { LINK_TO_TERMS } from "../consts"
import { InstagramLargeFilledMono, TiktokLargeFilledMono } from "./Icons"
function Footer() {
  return (
    <div className="nc00-bg border-top-1 nc05-border">
      <div className="content-area cs-footer padding-y-5x flex vcenter">
        <div className="footer-menu margin-right-auto">
          <a href={LINK_TO_TERMS} rel="noreferrer" target="_blank" className="margin-right-6x button-large-font">
            Terms of Service
          </a>
          <a
            href="https://hellozelf.com/wp-content/uploads/2022/03/Zelf.-Privacy-Policy.pdf"
            rel="noreferrer"
            target="_blank"
            className="margin-right-6x button-large-font"
          >
            Privacy Policy
          </a>
          <a
            href="https://hellozelf.com/wp-content/uploads/2022/04/Zelf.-DMCA.pdf"
            rel="noreferrer"
            target="_blank"
            className="button-large-font"
          >
            DMCA
          </a>
        </div>
        <div className="button-large-font footer-allright-reserved margin-right-11x">
          © All rights reserved by Zelf. 2024
        </div>
        <div className="footer-icons">
          <a href="https://www.instagram.com/hello.zelf/" className="margin-right-4x nc50-fg-override">
            {InstagramLargeFilledMono}
          </a>
          <a href="https://www.tiktok.com/@hellozelf" className="margin-right-4x nc50-fg-override">
            {TiktokLargeFilledMono}
          </a>
          {/* <a className="nc50-fg-override" href="https://www.facebook.com/ZelfLive-109584901344145/">{FacebookLargeFilledMono}</a> */}
        </div>
      </div>
    </div>
  )
}

export { Footer }
