import { useContext, useState } from "react"
import { Button } from "../../button"
import { Card, Collapsible, ExpandButtonInline, ProfilePictureCloud, StatBlock, WrappedInLoader } from "../../dumbs"
import { BECreatorList, SubtitleCount, CreatorOverviewSection } from "./beSections"
import { apiContext } from "../../../contexts/apicontext"
import { DownCaret } from "../../Icons"
import { dataContext } from "../../../contexts/datacontext"
import { useBDBoundData } from "../../../hooks/useBoundData"
function MyCreatorCircle() {
  const api = useContext(apiContext)
  const [detailOpen, sDO] = useState(false)
  const [data] = useContext(dataContext)
  const { payload, loader } = useBDBoundData({
    section: "creator_circle_stats",
    skipBrandRefresh: true,
    skipDiscoveryPlatformRefresh: true,
  })
  const removedList = data?.client_remove_from_circle ?? []
  return (
    <div className="creator-circle-body padding-y-6x">
      <Card className={"circle-stat-block"}>
        <WrappedInLoader data={payload} loader={loader}>
          <div className="circle-stat-inner flex vcenter margin-top-7x margin-x-5x">
            <div className="stat-headline headline-medium-highlight full">Creator circle summary</div>
          </div>
          <div className="creator-circle-actions flex spreaded vcenter margin-top-4x margin-bottom-7x margin-x-5x">
            <div className="creator-circle-stats flex margin-right-2x">
              <StatBlock
                className="padding-right-4x"
                title={
                  <>
                    Creators
                    <br /> total
                  </>
                }
                data={payload?.creator_count}
              />
              <StatBlock
                className="padding-x-4x"
                title={
                  <>
                    Posts
                    <br /> total
                  </>
                }
                data={payload?.content_count}
              />
              <StatBlock
                className="padding-x-4x"
                title={
                  <>
                    Total
                    <br /> costs
                  </>
                }
                data={payload?.total_cost}
                isCent={true}
                hip={false}
              />
              <StatBlock
                className="padding-x-4x"
                separator={true}
                title={
                  <>
                    Total
                    <br /> engagement
                  </>
                }
                data={payload?.total_engagement}
              />
              <StatBlock
                className="padding-x-4x"
                separator={true}
                hip={false}
                title={
                  <>
                    Cost per
                    <br /> engagement
                  </>
                }
                data={payload?.cost_per_engagement}
                isCent={true}
              ></StatBlock>
              <StatBlock
                className="padding-x-4x"
                separator={false}
                hip={false}
                title={<>CPM</>}
                data={payload?.cost_per_view ? payload?.cost_per_view * 1000 : null}
                isCent={true}
              ></StatBlock>
            </div>
            <div className="creator-circle-actions flex vcenter">
              {/* <Button onClick={api?.alert?.emailCreatorsInCircle} className="margin-right-2x" type="tertiary">Email creators</Button> */}
              <Button onClick={api?.alert?.addCreatorsToCampaign} type="secondary">
                Add creators to campaign
              </Button>
            </div>
          </div>
        </WrappedInLoader>
        <div className="padding-3x txt-right">
          <ExpandButtonInline trigger={detailOpen} onClick={() => sDO(!detailOpen)} />
        </div>
        <Collapsible expanded={detailOpen}>
          <CreatorOverviewSection section="creator_circle_creator_stats_list" showAddToCampaign />
        </Collapsible>
      </Card>

      <BECreatorList
        cardMode="circle"
        mini
        cutoffAt={8}
        section="all_circle_creators"
        itemFilterFunction={(creator) => !removedList.some((removed_creator_id) => removed_creator_id == creator.id)}
        className="margin-top-6x"
        title="Circle creators"
        showDiscoveryPlatform={false}
        showTailIcon
        onTailIconClick={(c) => api?.alert?.removeFromCreatorCircle(c.id)}
      />
    </div>
  )
}

export { MyCreatorCircle }
