import { createContext, useContext, useReducer } from "react"
const loaderContext = createContext()

const initLoaders = {}

function loaderReducer(state, action) {
  return { ...state, ...action }
}

function GlobalLoadingProvider({ children }) {
  return <loaderContext.Provider value={useReducer(loaderReducer, initLoaders)}>{children}</loaderContext.Provider>
}

function useLoading() {
  const [loaders, dispatch] = useContext(loaderContext)

  const batchLoading = (list) => {
    if (!Array.isArray(list)) throw new Error("Pass an array")
    let obj = {}
    for (let item of list) {
      obj[item] = true
    }
    dispatch(obj)
  }

  const batchNotLoading = (list) => {
    if (!Array.isArray(list)) throw new Error("Pass an array")
    let obj = {}
    for (let item of list) {
      obj[item] = false
    }
    dispatch(obj)
  }

  const loading = (ref) => {
    dispatch({ [ref]: true })
  }

  const notLoading = (ref) => {
    dispatch({ [ref]: false })
  }
  return {
    loaders,
    actions: {
      loading,
      notLoading,
      batchLoading,
      batchNotLoading,
    },
  }
}

export { useLoading, GlobalLoadingProvider }
