import { PlatformIcon, DotSeparator, StatBlock, EmailIndicator, NewnessIndicator, SavedOnBoardStatus } from "./dumbs"
import { num2Hip, _nopropagate, getCreatorName } from "../helpers/utils"
import { Fragment, useContext, useEffect, useRef } from "react"
import { dataContext } from "../contexts/datacontext"
import { Button } from "./button"
import { CampaignPopup, useCampaignPopupHelper } from "./structural/BrandEcosystem/campaignPopup"
import { apiContext } from "../contexts/apicontext"
import { useToast } from "../hooks/useToast"
import { useState } from "react"
import { Loader } from "./loaders"
import { useLoading } from "../contexts/loaderContext"
import {
  BoardSaveLargeFilledMono,
  BoardSaveLargeOutLineMono,
  EmailNegative,
  EmailPositiveBlue,
  ExitIcon,
  Lock,
  PageIcon,
  SmallInfluencerColor,
  ThreeDots,
  TrashLargeFilledMono,
} from "./Icons"
import { Checkbox } from "./checkbox"
import { IfGuest, IfNotGuest } from "./functional/auth_components"
import ReactTooltip from "react-tooltip"
import { useAuthHelper } from "../hooks/useAuthHelper"
import { copyToClipboard } from "../helpers/utils"
import { useSearchParams } from "react-router-dom"
function CardActions({ mode, creator, onActionComplete }) {
  const [popup, setPopup] = useState(false) //false
  const [data] = useContext(dataContext)
  const api = useContext(apiContext)
  const [loader, setL] = useState(false)
  const _cmp = data?.selected_campaign_for_viewing
  const openPopup = (e) => {
    _nopropagate(e)
    setPopup(true)
  }

  const removeAction = async (type) => {
    let removed = null
    setPopup(false)
    setL(true)
    switch (type) {
      case "ecosystem":
        removed = await api.brand.removeFromEcosystem(creator)
        break
      case "campaign":
        removed = await api.brand.removeCreatorFromCampaign(creator, _cmp)
        break
      case "circle":
        removed = await api.brand.removeFromEcosystem(creator)
        break
      default:
        console.log("unknown param")
    }
    setL(false)
    if (removed) onActionComplete()
  }
  return (
    <div onClick={openPopup} className="creator-card-threedots nc00-bg nc50-fg cursor-pointer flex hcenter vcenter">
      {loader ? <Loader /> : ThreeDots}
      {popup ? (
        <div
          onClick={(e) => _nopropagate(e)}
          className="card-action-popup-body border-radius-dynamic border-1 nc10-border nc00-bg padding-top"
        >
          {/* {_cmp ? <div className="card-action-secondary padding-x-2x padding-y flex vcenter cursor-pointer margin-bottom">
                <span className="margin-right flex hcenter vcenter">{PageIcon}</span> Send brief
            </div> : null} */}
          {mode === "circle" ? (
            <div
              onClick={() => removeAction("circle")}
              className="card-action-secondary padding-x-2x padding-y flex vcenter cursor-pointer margin-bottom"
            >
              <span className="margin-left margin-right flex hcenter vcenter">{TrashLargeFilledMono}</span> Remove
              creator from circle
            </div>
          ) : null}
          {_cmp ? (
            <div
              onClick={() => removeAction("campaign")}
              className="card-action-secondary padding-x-2x padding-y flex vcenter cursor-pointer margin-bottom"
            >
              <span className="margin-left margin-right flex hcenter vcenter">{TrashLargeFilledMono}</span> Remove from
              campaign
            </div>
          ) : null}
          {/* <div onClick={()=>removeAction("ecosystem")} className="card-action-secondary padding-x-2x padding-y flex vcenter cursor-pointer margin-bottom">
            <span className="margin-right-2x flex hcenter vcenter">{ExitIcon}</span> Remove from ecosystem
            </div> */}
        </div>
      ) : null}
    </div>
  )
}
function CreatorCard({ creator, mode = "circle", onClick }) {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const toast = useToast()
  const { loaders } = useLoading()
  const [added, updateA] = useState(false)
  const [deleted, setD] = useState(false)
  const popup = useCampaignPopupHelper(false)
  const addToCampaign = async (campaign) => {
    const added = api.brand.addCreatorsToCampaign([creator], campaign?.id)
    if (added) toast("Added to campaign")
  }
  const saveDraft = () => {
    dispatch({
      pending_campaign_add: {
        creators: [creator],
      },
    })
  }
  const _addToCampaignPopup = (e) => {
    _nopropagate(e)
    return popup.show(e)
  }
  const cardSecondaryAction = (action) => {
    setD(true)
  }
  if (deleted) return null
  return (
    <div className="single-creator-card border-radius-dynamic border-1 nc10-border nc00-bg">
      <div className="creator-card-cover-thumbs position-relative">
        <CardActions creator={creator} mode={mode} onActionComplete={cardSecondaryAction} />
        {creator?.thumbnails?.slice?.(0, 4)?.map((_thumb, _idx) => (
          <img className="has-broken-fallback" src={_thumb} alt={_thumb} key={`${_thumb}_${_idx}`} />
        ))}
      </div>
      <div className="creator-card-creator-avatar-holder flex hcenter">
        <a href={`/${creator.username}`} rel="noreferrer" target="_blank">
          <img
            className="creator-card-avatar nc00-border border-2"
            alt={creator?.username}
            src={creator?.profile_picture_url}
          />
        </a>
      </div>
      <div className="creator-card-info-basic flex hcenter vcenter margin-y-3x padding-x-4x">
        <a
          target="_blank"
          rel="noreferrer"
          href={`/${creator?.username}`}
          className="headline-large creator-card-username-block"
        >
          {getCreatorName(creator)}
        </a>
        <DotSeparator className="margin-x-2x" />
        <div className="card-basic-socials flex vcenter nc30-fg">
          {creator?.connected_accounts?.length
            ? creator?.connected_accounts?.map((_social_account) => (
              <a key={_social_account.platform} target="_blank" rel="noreferrer" href={_social_account?.external_url}>
                <PlatformIcon platform={_social_account?.platform} color={false} />
              </a>
            ))
            : null}
        </div>
        <DotSeparator className="margin-x-2x" />
        <div className="totalFollowers headline-small-highlight nc50-fg">{num2Hip(creator?.total_follower_count)}</div>
      </div>
      <div className="creator-card-media-kit-fragments flex spreaded margin-bottom-5x padding-x-4x">
        <StatBlock
          className="fluid-block"
          data={creator.posts_about}
          title={`Posts about ${data?.me?.brand?.name ? data?.me?.brand?.name : "Brand"}`}
        />
        <StatBlock className="fluid-block" data={creator?.total_impressions} title={`Total impressions`} />
        <StatBlock
          className="fluid-block"
          separator={false}
          data={creator.total_engagement}
          title={`Total engagement`}
        />
      </div>
      <div className="single-creator-card-actions padding-x-4x margin-bottom-4x flex">
        {/* {mode !== "circle" ? <Button size="tiny" disabled={loaders[`creator-${creator.uuid}-to-circle`] || (mode !=="circle" && (creator?.creator_circle || added))} onClick={_addToCircle} type="tertiary" className="margin-right position-relative full">
                {loaders[`creator-${creator.uuid}-to-circle`] ? <Loader/> : <>
                    {(creator.creator_circle || added) ? `${added? "Added to": "In"} creator circle` : "Add to creator circle"}
                </>}
            </Button> : null} */}
        <Button
          size="tiny"
          onClick={_addToCampaignPopup}
          type={`${mode === "circle" ? "tertiary" : "secondary"}`}
          className="margin-right position-relative full"
        >
          Add To Campaign
          {popup.shown ? (
            <CampaignPopup
              onDismiss={popup.hide}
              className="capped right-mounted"
              onCampaignClick={addToCampaign}
              onAddNewClick={saveDraft}
              kind="CREATOR"
            />
          ) : null}
        </Button>
      </div>
    </div>
  )
}

function CreatorCardMini({
  creator,
  onClick,
  showTailIcon = false,
  onTailIconClick,
  tailIcon = TrashLargeFilledMono,
  totalPostKey = "total_posts",
  timeFramePostKey = "total_posts_last_60_days",
  metricsTitle = "views",
  totalMetricsKey = "total_views",
  timeFrameMetricsKey = "total_views_last_60_days",
  timeFrame = "60",
  isCreatorCircle = false,
}) {
  const api = useContext(apiContext)
  const { isGuest } = useAuthHelper()
  const [searchParams] = useSearchParams()
  const toast = useToast()
  const copyEmailAddress = (e) => {
    e?.stopPropagation?.()
    if (!isGuest && creator.email) copyToClipboard(creator.email, () => toast("Copied to clipboard"))
    else if (isGuest && creator.email) api?.alert?.unlockFullExperience()
  }

  // if(!creator?.followers) return null;
  return (
    <div
      className="mini-creator-card position-relative padding-y-4x padding-x-5x border-radius-dynamic border-1 nc10-border nc00-bg flex gap-3 vcenter cursor-pointer"
      onClick={onClick}
    >
      <div className="mini-creator-card-indicators position-absolute flex full-width  j-end">
        {creator?.is_new && <NewnessIndicator className="padding-x margin-bottom" />}
        <SavedOnBoardStatus creator={creator} className="translate-y--2" />
      </div>
      <div className="flex-grow flex vcenter gap-2">
        <img className="creator-mini-card-profilepic" src={creator?.profile_picture_url} alt={creator?.username} />
        <div className="creator-mini-card-info full">
          <div className="headline-medium-highlight mini-card-username margin-bottom">{getCreatorName(creator)}</div>
          <div className="flex gap-x-2 vcenter nc30-fg">
            {creator?.connected_accounts?.length > 0 &&
              creator?.connected_accounts?.map((_social_account) => (
                <Fragment key={_social_account.platform}>
                  <a
                    className="line-height-0 icon-container-small"
                    target="_blank"
                    rel="noreferrer"
                    href={_social_account?.external_url}
                  >
                    <PlatformIcon platform={_social_account?.platform} color={false} />
                  </a>
                  <DotSeparator />
                </Fragment>
              ))}
            {creator?.connected_accounts?.length > 0 && creator?.followers && (
              <>
                <div className="totalFollowers headline-small-highlight nc50-fg">{num2Hip(creator?.followers)}</div>
                <DotSeparator />
              </>
            )}
            <EmailIndicator
              className="flex a-center j-center"
              isGuest={isGuest}
              id={creator?.id}
              email={creator?.email}
              toast={toast}
              api={api}
            />

            {creator?.is_influencer && (
              <>
                <DotSeparator />
                {SmallInfluencerColor}
              </>
            )}

            <IfNotGuest>
              <DotSeparator />
              <button
                type="button"
                onClick={(e) => {
                  _nopropagate(e)
                  api?.alert?.ContactCreator(creator)
                }}
                className="border-none-important font-medium primary-fg transparent-bg cursor-pointer flex gap-x-1"
              >
                Contact
              </button>
            </IfNotGuest>
            <IfGuest>
              <DotSeparator />
              <button
                type="button"
                onClick={(e) => {
                  _nopropagate(e)
                  api?.alert?.unlockFullExperience()
                }}
                className="border-none-important font-medium primary-fg transparent-bg cursor-pointer flex gap-x-1"
              >
                <span>{Lock}</span>
                Contact
              </button>
            </IfGuest>
          </div>
        </div>
      </div>
      <div className="mini-creator-card-stat flex vertical gap-3 max-width-318 min-width-248">
        <div className="grid grid-cols-3 gap-3 txt-center">
          <div className=""></div>
          <div className="button-small-font nc30-fg">posts</div>
          <div className="button-small-font nc30-fg">{metricsTitle}</div>
        </div>
        {isCreatorCircle ? (
          <div className="grid grid-cols-3 gap-3 txt-center">
            <div className="button-small-font nc30-fg">all time</div>
            <div className="">{num2Hip(creator?.[totalPostKey] ?? 0)}</div>
            <div className="">{num2Hip(creator?.[totalMetricsKey] ?? 0)}</div>
          </div>
        ) : (
          <>
            <div className="grid grid-cols-3 gap-3 txt-center">
              <div className="button-small-font nc30-fg">last {timeFrame}d</div>
              <div className="">{num2Hip(creator?.[timeFramePostKey] ?? 0)}</div>
              <div className="">
                {num2Hip(parseFloat(creator?.[timeFrameMetricsKey]?.toFixed(2) ?? 0))}
                {metricsTitle === "engagement rate" && "%"}
              </div>
            </div>
            <div className="grid grid-cols-3 gap-3 txt-center">
              <div className="button-small-font nc30-fg">all time</div>
              <div className="">{num2Hip(creator?.[totalPostKey] ?? 0)}</div>
              <div className="">
                {num2Hip(parseFloat(creator?.[totalMetricsKey]?.toFixed(2) ?? 0))}
                {metricsTitle === "engagement rate" && "%"}
              </div>
            </div>
          </>
        )}
      </div>
      <IfNotGuest>
        {showTailIcon && (
          <div
            className="flex a-center j-center nc30-fg padding-left"
            onClick={(e) => {
              _nopropagate(e)
              onTailIconClick(creator)
            }}
          >
            {tailIcon}
          </div>
        )}
      </IfNotGuest>
    </div>
  )
}

function CreatorCardTiny({
  creator,
  value,
  onChange,
  className,
  skipCheckbox = false,
  showFollowers = false,
  showEmail = false,
}) {
  return (
    <div
      onClick={onChange}
      className={`creator-card-tiny padding-y-3x padding-x-3x flex vcenter nc00-bg cursor-pointer nc05-border border-bottom-1 ${className}`}
    >
      {skipCheckbox ? null : <Checkbox className="margin-right-2x" value={value} />}
      <img className="creator-tiny-avatar" src={creator?.profile_picture_url} alt={creator?.username} />
      <div className=" nano-creator-card-username margin-left-2x flex">{getCreatorName(creator)}</div>
      {showEmail && creator?.email ? (
        <span className="nc50-fg caption-regular-font margin-left-2x"> - {creator?.email}</span>
      ) : null}
      {showFollowers && (creator?.followers || creator?.total_follower_count) ? (
        <span className="nc50-fg caption-regular-font margin-left-2x">
          ({num2Hip(creator?.followers ?? creator?.total_follower_count)}{" "}
          {creator?.followers === 1 ? "follower" : "followers"})
        </span>
      ) : null}
    </div>
  )
}

export { CreatorCard, CreatorCardMini, CreatorCardTiny }
