import React, { Component } from "react"
import ReactGA from 'react-ga4';

const logException = (description, fatal = false) => {
  ReactGA.event({
    category: 'Exception',
    action: "error",
    value: description,
    label: fatal ? "Fetal" : "non-Fetal"
  });
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI.
    return { hasError: true, error: error }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logException(`${error.toString()} - ${errorInfo.componentStack}`, true);
    console.error("ErrorBoundary caught an error", error, errorInfo)
    // Update state with the error info
    this.setState({ errorInfo: errorInfo })
  }

  render() {
    if (this.state.hasError) {
      // If a custom fallback UI is provided via props, render it with error info
      if (this.props.fallback) {
        return React.cloneElement(this.props.fallback, {
          error: this.state.error,
          errorInfo: this.state.errorInfo,
        })
      }

      // Otherwise, render the default fallback UI
      return <div className="padding-8x">
        <h1 className="headline-large">Something went wrong.</h1>
        <div className="margin-y-10x">
          <h4>Error Stack</h4>
          <div className="nc50-fg body-regular-font margin-y-10x">
            {this.state.error?.toString()}
          </div>
          <div className="nc50-fg body-regular-font margin-y-10x">
            {JSON.stringify(this?.state?.errorInfo)}
          </div>
        </div>
      </div>

    }

    return this.props.children
  }
}

export { ErrorBoundary }
