import { Card, ContentCloud, ProfilePictureCloud, StatBlock } from "../../dumbs"
import { convertToPercentage } from "../../../helpers/utils"

function BoardSummary({ board, className = "", onClick }) {
  return (
    <Card onClick={onClick} className={`padding-5x cursor-pointer ${className}`}>
      <div className="campaign-summary-top-section flex spreaded">
        <div className="headline-medium-highlight">{board.name}</div>
        <ProfilePictureCloud total={board.total_creator} images={board?.profile_pictures ?? []} />
      </div>
      <div className="campaign-summary-content-stats-section margin-top-6x flex spreaded">
        <div>
          <ContentCloud total={board.total_posts} cutoff={5} images={board?.thumbnails ?? []} />
        </div>
        <div>
          <div className="stat-section-campaign-summary flex vcenter">
            <StatBlock
              className="padding-right-4x"
              title={
                <>
                  Total <br />
                  creators
                </>
              }
              data={board?.total_creators}
            />
            <StatBlock
              className="padding-x-4x"
              title={
                <>
                  Total <br />
                  post
                </>
              }
              data={board?.total_posts}
            />
            <StatBlock
              className="padding-x-4x"
              title={
                <>
                  Total <br />
                  views
                </>
              }
              data={board?.total_views}
            />
            <StatBlock
              className="padding-left-4x"
              title={
                <>
                  Total <br />
                  engagement
                </>
              }
              data={board?.total_engagement}
            />
            <StatBlock
              className="padding-left-4x"
              separator={false}
              title={
                <>
                  Engagement <br />
                  rate
                </>
              }
              percentage
              data={board?.engagement_rate}
            />
            {/* <StatBlock title="Ads total" data={campaign?.total_ads}/> */}
            {/* <StatBlock title="Total views" data={86666}/> */}
          </div>
          <p className="txt-right nc30-fg caption-regular-font margin-top-2x">
            (does not include data for YouTube content)
          </p>
        </div>
      </div>
    </Card>
  )
}

export { BoardSummary }
