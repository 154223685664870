import React, { useContext } from "react"
import { IfCreator } from "../functional/auth_components"
import { Button } from "../button"
import { AlertIcon } from "../Icons"
import { dataContext } from "../../contexts/datacontext"
import { platforms } from "../../consts"
import { useSocial } from "../../hooks/useSocial"

const InstagramDisconnectedBanner = ({ className, show }) => {
  const [data] = useContext(dataContext)
  const { reconnect } = useSocial()

  if (!data?.social_reconnect) return null
  const platform = data.social_reconnect
  return (
    <IfCreator>
      <div
        className={`${className} banner-serpated-mobile-desktop instagram-disconnected-banner negative-bg flex j-space-between nc00-fg a-center`}
      >
        <div className="flex a-center">
          <div>{AlertIcon}</div>
          <p className="button-large-font margin-left-2x txt-center">{platforms[platform].title} disconnected</p>
        </div>
        <Button onClick={() => reconnect(platform)} type="ghost" size="small" colorTheme="negative">
          Reconnect
        </Button>
      </div>
    </IfCreator>
  )
}

export { InstagramDisconnectedBanner }
