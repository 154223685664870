import { useAuth0 } from "@auth0/auth0-react"
import { _lsr } from "../helpers/utils"

function useAuthHelper() {
  const { isAuthenticated, isLoading, getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()
  const guestToken = _lsr("guest_access_token")
  const _isAuthenticated = Boolean(guestToken || isAuthenticated)
  const isGuest = Boolean(guestToken)
  const _getAccessToken = async () => {
    if (guestToken) return guestToken
    return await getAccessTokenSilently()
  }
  const _user = isGuest ? { picture: "/missing_pp.jpeg" } : user
  return {
    isAuthenticated: _isAuthenticated,
    getAccessTokenSilently: _getAccessToken,
    isLoading,
    getAccessTokenWithPopup,
    isGuest,
    user: _user,
  }
}

export { useAuthHelper }
