import { useContext, useEffect, useRef, useState } from "react"
import { POLL_INTERVAL_MS, POLL_MAX_COUNT } from "../consts"
import { apiContext } from "../contexts/apicontext"
import { dataContext } from "../contexts/datacontext"
import { useLoading } from "../contexts/loaderContext"
import { useServer } from "./useServer"
import { extractQueryParamsString } from "../helpers/utils"

function useBaseBoundData({
  section,
  polling = false,
  shallowConnect = false,
  apiFunction,
  dataPrefix = "default_sec_",
  defaultLoaderPrefix = "default-section-",
  isFirstCallManualExecute = false,
  extraData,
  requestType = "GET",
  uniqueSectionPostfix = "",
}) {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const { loaders } = useLoading()
  const timeoutRef = useRef(null)
  const [filt, setFilt] = useState(false)
  const [pollingStatus, setPolling] = useState("init") //init, run, end
  const resetNextPoll = () => {
    try {
      if (timeoutRef.current) clearTimeout(timeoutRef)
    } catch (e) {
      console.log("no timeout to cleanup")
    }
  }

  const _section = section + uniqueSectionPostfix

  const executeCall = async (data) => {
    // console.log(loaders)
    if (requestType === "POST") {
      if (apiFunction) return await apiFunction({ section: section, payload: data ?? extraData, uniqueSectionPostfix })
      return
    }
    if (apiFunction) return await apiFunction({ section: section, extraData: data ?? extraData, uniqueSectionPostfix })
    else return false
  }
  const pollData = async () => {
    await executeCall(undefined, "poll")

    let numPolled = parseInt(sessionStorage.getItem(`num_polled_${_section}`))
    if (numPolled < POLL_MAX_COUNT) {
      sessionStorage.setItem(`num_polled_${_section}`, numPolled + 1)
      resetNextPoll()
      timeoutRef.current = setTimeout(pollData, POLL_INTERVAL_MS)
    } else setPolling("end")
  }

  useEffect(() => {
    ;(async () => {
      if (polling) sessionStorage.setItem(`num_polled_${_section}`, 0)
      const shouldLoadData = !shallowConnect || (shallowConnect && !data[`bd_sec_${_section}`])
      if (shouldLoadData && !isFirstCallManualExecute) {
        await executeCall()
        if (polling) {
          resetNextPoll()
          setPolling("run")
          timeoutRef.current = setTimeout(pollData, POLL_INTERVAL_MS)
        }
      }
    })()
    return () => {
      //cleanup
      sessionStorage.removeItem(`num_polled_${_section}`)
      if (!shallowConnect) {
        dispatch({
          [`${dataPrefix}${_section}`]: null,
          [`${dataPrefix}${_section}_raw`]: null,
          [`${dataPrefix}filter_${_section}`]: null,
          [`${dataPrefix}page_${_section}`]: 1,
        })
      }
      resetNextPoll()
    }
  }, [_section])

  useEffect(() => {
    const shouldLoadData = !shallowConnect || (shallowConnect && !data[`${dataPrefix}${_section}`])
    ;(async () => {
      if (data[`${dataPrefix}filter_${_section}`] && shouldLoadData) {
        if (apiFunction)
          await apiFunction({ section: section, filter: data[`${dataPrefix}filter_${_section}`], uniqueSectionPostfix })
        setFilt(true)
      } else if (data[`${dataPrefix}filter_${_section}`] === "" && shouldLoadData && filt) {
        await executeCall()
        setFilt(false)
      }
    })()
  }, [data[`${dataPrefix}filter_${_section}`]])
  useEffect(() => {
    // console.log("React requested next page");
    ;(async () => {
      if (data[`${dataPrefix}${_section}_page`] && data[`${dataPrefix}${_section}_page`] > 1) {
        console.log("loading next page")
        await executeCall()
      }
    })()
  }, [data[`${dataPrefix}${_section}_page`]])
  useEffect(() => {
    ;(async () => {
      if (!isFirstCallManualExecute) {
        await executeCall()
      }
    })()
  }, [isFirstCallManualExecute])
  const loadNextPage = () =>
    dispatch({ [`${dataPrefix}${_section}_page`]: data[`${dataPrefix}${_section}_raw`]?.pagination?.next })
  return {
    loader: polling
      ? !data[`${dataPrefix}${_section}`]?.length && loaders[`${defaultLoaderPrefix}${_section}`]
      : loaders[`${defaultLoaderPrefix}${_section}`],
    payload: data[`${dataPrefix}${_section}`],
    pagination: {
      hasNext: data[`${dataPrefix}${_section}_raw`]?.pagination?.next,
      loadNext: loadNextPage,
    },
    pollingStatus,
    executeCall,
  }
}

function useCRBoundData({
  section,
  polling = false,
  shallowConnect = false,
  isFirstCallManualExecute = false,
  extraData,
}) {
  const api = useContext(apiContext)
  const obj = useBaseBoundData({
    section: section,
    polling,
    shallowConnect,
    defaultLoaderPrefix: "cr-section-",
    dataPrefix: "cr_sec_",
    isFirstCallManualExecute,
    apiFunction: api.creator.creatorGetSections,
    extraData,
  })

  return obj
}

function useBDPostData({ section, defaultData, uniqueSectionPostfix = "" }) {
  const api = useContext(apiContext)
  const obj = useBaseBoundData({
    section: section,
    defaultLoaderPrefix: "bd-section-",
    dataPrefix: "bd_sec_",
    isFirstCallManualExecute: true,
    apiFunction: api.brand.brandPostRequestHelper,
    extraData: defaultData,
    requestType: "POST",
    uniqueSectionPostfix,
  })

  return obj
}

function useCRPostData({ section, defaultData }) {
  const api = useContext(apiContext)
  const obj = useBaseBoundData({
    section: section,
    defaultLoaderPrefix: "cr-section-",
    dataPrefix: "cr_sec_",
    isFirstCallManualExecute: true,
    apiFunction: api.creator.creatorPostHelper,
    extraData: defaultData,
    requestType: "POST",
  })

  return obj
}

function useBDBoundData({
  section,
  polling = false,
  shallowConnect = false,
  triggerReloadBy = [],
  queryFilter = "",
  skipBrandRefresh = false,
  skipDiscoveryPlatformRefresh = false,
  uniqueSectionPostfix = "",
}) {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const { loaders } = useLoading()
  const timeoutRef = useRef(null)
  const [filt, setFilt] = useState(false)
  const [pollingStatus, setPolling] = useState("init") //init, run, end
  const _section = section + uniqueSectionPostfix
  const resetNextPoll = () => {
    try {
      if (timeoutRef.current) clearTimeout(timeoutRef)
    } catch (e) {
      console.log("no timeout to cleanup")
    }
  }
  const pollData = async () => {
    if (api.brand.dashboardSection)
      await api.brand.dashboardSection({
        section: section,
        filter: queryFilter,
        uniqueSectionPostfix: uniqueSectionPostfix,
      })
    else return false
    let numPolled = parseInt(sessionStorage.getItem(`num_polled_${_section}`))
    if (numPolled < POLL_MAX_COUNT) {
      sessionStorage.setItem(`num_polled_${_section}`, numPolled + 1)
      resetNextPoll()
      timeoutRef.current = setTimeout(pollData, POLL_INTERVAL_MS)
    } else setPolling("end")
  }
  useEffect(() => {
    if (!section) return
    ;(async () => {
      if (polling) sessionStorage.setItem(`num_polled_${_section}`, 0)
      const shouldLoadData = !shallowConnect || (shallowConnect && !data[`bd_sec_${_section}`])
      if (shouldLoadData) {
        if (api.brand.dashboardSection)
          await api.brand.dashboardSection({
            section: section,
            filter: queryFilter,
            uniqueSectionPostfix: uniqueSectionPostfix,
          })
        if (polling) {
          resetNextPoll()
          setPolling("run")
          timeoutRef.current = setTimeout(pollData, POLL_INTERVAL_MS)
        }
      }
    })()
    return () => {
      if (!section) return
      //cleanup
      console.log(`cleanup: ${_section}`)
      sessionStorage.removeItem(`num_polled_${_section}`)
      if (!shallowConnect) {
        dispatch({
          [`bd_sec_${_section}`]: null,
          [`bd_sec_${_section}_raw`]: null,
          [`bd_sec_filter_${_section}`]: null,
          [`bd_sec_page_${_section}`]: 1,
        })
      }
      resetNextPoll()
    }
  }, [_section, queryFilter, ...triggerReloadBy])

  useEffect(() => {
    if (!section) return
    const shouldLoadData = !shallowConnect || (shallowConnect && !data[`bd_sec_${_section}`])
    ;(async () => {
      if (data[`bd_sec_filter_${_section}`] && shouldLoadData) {
        if (api.brand.dashboardSection)
          await api.brand.dashboardSection({
            section: section,
            filter: data[`bd_sec_filter_${_section}`],
            uniqueSectionPostfix: uniqueSectionPostfix,
          })
        setFilt(true)
      } else if (data[`bd_sec_filter_${_section}`] === "" && shouldLoadData && filt) {
        if (api.brand.dashboardSection)
          await api.brand.dashboardSection({
            section: section,
            filter: undefined,
            uniqueSectionPostfix: uniqueSectionPostfix,
          })
        setFilt(false)
      }
    })()
  }, [data[`bd_sec_filter_${_section}`]])
  useEffect(() => {
    if (!section) return // console.log("React requested next page");
    ;(async () => {
      if (data[`bd_sec_${_section}_page`] && data[`bd_sec_${_section}_page`] > 1) {
        console.log("loading next page")
        if (data?.discovery_section_platform)
          await api.brand.dashboardSection({
            section: _section,
            filter: queryFilter,
            uniqueSectionPostfix: uniqueSectionPostfix,
          })
      }
    })()
  }, [data[`bd_sec_${_section}_page`]])
  useEffect(() => {
    ;(async () => {
      if (data?.discovery_section_platform) {
        if (skipDiscoveryPlatformRefresh) return
        api.brand.resetSection(_section)
        await api.brand.dashboardSection({
          section: section,
          filter: queryFilter,
          uniqueSectionPostfix: uniqueSectionPostfix,
        })
      }
    })()
  }, [data?.discovery_section_platform, data?.discovery_section_content_form])

  useEffect(() => {
    ;(async () => {
      if (!section) return
      if (skipBrandRefresh) {
        return
      }
      api.brand.resetSection(_section)
      await api.brand.dashboardSection({
        section: section,
        filter: queryFilter,
        uniqueSectionPostfix: uniqueSectionPostfix,
      })
    })()
  }, [data?.sub_brand_id])

  const loadNextPage = () => dispatch({ [`bd_sec_${_section}_page`]: data[`bd_sec_${_section}_raw`]?.pagination?.next })
  return {
    loader: polling
      ? !data[`bd_sec_${_section}`]?.length && loaders[`bd-section-${_section}`]
      : loaders[`bd-section-${_section}`],
    payload: data[`bd_sec_${_section}`],
    pagination: {
      hasNext: data[`bd_sec_${_section}_raw`]?.pagination?.next,
      loadNext: loadNextPage,
      total: data[`bd_sec_${_section}_raw`]?.pagination?.total_items,
    },
    pollingStatus,
    setPolling,
    internalSectionName: _section,
  }
}

function useLocalBoundData({
  endpoint,
  section,
  filter = {},
  overrideStringFilter = "",
  sub_brand_id = null,
  platform = null,
  throwOriginalError = false,
  skip = false,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [payload, setPayload] = useState()
  const [rawData, setRawData] = useState()
  const [error, setError] = useState()
  const [pagination, setPagination] = useState()
  const [refetchCount, setRefetchCount] = useState(0)
  const server = useServer()

  const resetSection = () => {
    setPayload(null)
    setError(null)
    setPagination(null)
    setIsLoading(false)
  }
  const refetch = () => {
    console.log("refetching" + section)
    setRefetchCount((s) => s + 1)
  }

  const getEndpoint = ({ page }) => {
    if (skip) return
    if (!endpoint) console.error(`endpoint missing in ${section}`)
    let { query, endpoint: _endpoint } = extractQueryParamsString(endpoint)
    const _filter = {
      ...Object.fromEntries(new URLSearchParams(query).entries()),
      ...filter,
    }
    if (page) {
      _filter.page = page
    }
    if (sub_brand_id) {
      _filter.brand_id = sub_brand_id
    }
    if (platform) {
      _filter.platform = platform
    }

    const filterString = new URLSearchParams(_filter).toString()
    if (filterString && !overrideStringFilter) {
      _endpoint = _endpoint + `?${filterString}`
      return _endpoint
    }

    if (overrideStringFilter) {
      _endpoint = _endpoint + `?${overrideStringFilter}`
    }

    return _endpoint
  }

  let _endpoint = getEndpoint({})

  const loadNextPage = async () => {
    if (!pagination?.next) {
      console.error("this page has no pagination ")
      return
    }

    setPagination((p) => {
      return {
        ...p,
        onReloadChange: true,
      }
    })
  }

  useEffect(() => {
    let ignore = false
    resetSection()
    if (skip || !section) {
      if (!section) {
        console.log("section missing")
      }
      return
    }

    const fetchData = async () => {
      setIsLoading(true)

      try {
        //   console.log(`calling endpoint ${_endpoint}` )
        const res = await server.get(_endpoint, true, {}, throwOriginalError)
        if (ignore) {
          console.log("ignoring call")
        }
        if (!ignore) {
          if (!res?.pagination) {
            setPayload(res)
          } else {
            setRawData(res)
            setPagination(res?.pagination)
            setPayload(res?.data)
          }

          setError(undefined)
        }
      } catch (e) {
        if (!ignore) {
          setError(e)
          setPayload(undefined)
        }
      } finally {
        if (!ignore) {
          setIsLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      ignore = true
    }
  }, [_endpoint, section, skip, refetchCount])

  useEffect(() => {
    if (!pagination?.onReloadChange) {
      return
    }
    if (!pagination?.next) {
      console.log(`there is no next page to load :${section} `)
      return
    }
    ;(async () => {
      setIsLoading(true)
      try {
        const res = await server.get(getEndpoint({ page: pagination?.next }), true, {}, throwOriginalError)
        if (res) {
          setRawData(res)
          setPayload((s) => [...s, ...res?.data])
          setPagination({
            ...res?.pagination,
            onReloadChange: false,
          })
        }
      } catch (e) {
        console.error(e)
        setError(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [pagination?.onReloadChange])

  return {
    payload,
    rawData,
    error,
    refetch,
    pagination: {
      ...pagination,
      hasNext: pagination?.next,
      loadNext: loadNextPage,
      total: pagination?.total_items,
    },
    resetSection,
    loader: isLoading,
  }
}

function useLocalDataMutation({
  endpoint,
  section,
  filter = {},
  overrideStringFilter = "",
  sub_brand_id = null,
  platform = null,
  throwOriginalError = false,
  setLocalError = false,
  skip = false,
  method = "post",
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [payload, setPayload] = useState()
  const [error, setError] = useState()
  const server = useServer()

  const resetSection = () => {
    setPayload(null)
    setError(null)
    setIsLoading(false)
  }

  const getEndpoint = () => {
    if (skip) return
    if (!endpoint) console.error(`endpoint missing in ${section}`)
    let { query, endpoint: _endpoint } = extractQueryParamsString(endpoint)
    const _filter = {
      ...Object.fromEntries(new URLSearchParams(query).entries()),
      ...filter,
    }
    if (sub_brand_id) {
      _filter.brand_id = sub_brand_id
    }
    if (platform) {
      _filter.platform = platform
    }

    const filterString = new URLSearchParams(_filter).toString()
    if (filterString && !overrideStringFilter) {
      _endpoint = _endpoint + `?${filterString}`
      return _endpoint
    }

    if (overrideStringFilter) {
      _endpoint = _endpoint + `?${overrideStringFilter}`
    }

    return _endpoint
  }

  let _endpoint = getEndpoint({})

  console.log(isLoading)
  const executeCall = async (data) => {
    let response
    setIsLoading(true)
    try {
      if (method === "deleteReq") {
        response = await server.deleteReq(_endpoint, data)
      } else {
        response = await server?.[method](_endpoint, data, true, method, {}, throwOriginalError)
      }

      setPayload(response)
    } catch (e) {
      if (setLocalError) {
        setError(e)
      } else {
        throw e
      }
    } finally {
      setIsLoading(false)
      return response
    }
  }

  return {
    payload,
    error,
    resetSection,
    executeCall,
    loader: isLoading,
  }
}

export { useBDBoundData, useCRBoundData, useBDPostData, useCRPostData, useLocalBoundData, useLocalDataMutation }
