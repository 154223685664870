import { useContext, useState } from "react"
import { BaseDialog } from "./baseDialog"
import { Button } from "../button"
import InstagramReconnectImage from "../../assets/dialog/instagram-reconnect.png"
import { dataContext } from "../../contexts/datacontext"
import { platforms } from "../../consts"
import { useSocial } from "../../hooks/useSocial"
const InstagramReconnectDialog = () => {
  const [data] = useContext(dataContext)
  const [localDismiss, setLocalDismiss] = useState(false)
  const { reconnect } = useSocial()
  const dismissDialog = () => setLocalDismiss(true)
  const onReconnectClick = () => {
    reconnect(platform)
    dismissDialog()
  }
  const platform = data?.social_reconnect
  if (!platform) return null
  return (
    <BaseDialog
      show={data.social_reconnect && !localDismiss}
      dismissDialog={dismissDialog}
      headerElement={<div>Reconnect to {platforms[platform].title}</div>}
      content={
        <div>
          <p className="body-regular-font txt-center">
            Hey, seems like you have changed your {platforms[platform].title} password. Please reconnect to your{" "}
            {platforms[platform].title} to keep using {platforms[platform].title} with{" "}
            <span className="body-highlight-font">Zelf. </span>
          </p>
          <img alt="reconnectexample" className="full-width margin-top-3x" src={InstagramReconnectImage} />
        </div>
      }
      dialogBodyClassName="instagram-reconnect-dialog-body"
      footerElement={
        <div className="flex j-end a-center">
          <Button
            className="margin-right-2x"
            type="secondary"
            size="medium"
            colorTheme="negative"
            onClick={dismissDialog}
          >
            Dissmiss
          </Button>
          <Button onClick={onReconnectClick} type="primary" size="medium" colorTheme="negative">
            Reconnect Now
          </Button>
        </div>
      }
    />
  )
}

export { InstagramReconnectDialog }
