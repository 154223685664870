import { useContext, useEffect, useState } from "react"
import { Button } from "../components/button"
import { TimesLargeFilledMono } from "../components/Icons"
import { dataContext } from "../contexts/datacontext"
import { _nopropagate } from "../helpers/utils"
const ANIMATION_DURATION = 200 //change it in CSS too. this is in milleseconds

function CommonDialog() {
  const [state, setDialogState] = useState("")
  const [data, dispatch] = useContext(dataContext)
  const dismissDialog = () => {
    dispatch({ dialog_payload: null })
  }
  const dismissButton = () => {
    if (data?.dialog_payload.callback) data?.dialog_payload.callback()
    dismissDialog()
  }
  if (!data.dialog_payload) return null
  return (
    <div onClick={dismissDialog} className={`cs-dialog cs-dialog-cover flex hcenter vcenter`}>
      <div
        className={
          data?.dialog_payload?.forceDialogBodyClassName ??
          `cs-dialog-body closes-other-popups flex column ${data?.dialog_payload?.additionalBodyClass ?? ""}`
        }
        onClick={_nopropagate}
      >
        {data?.dialog_payload?.title ? (
          <div className="flex cs-dialog-header padding-y-3x padding-x-5x nc00-bg border-bottom-1 nc05-border">
            <div className="headline-medium-highlight full">{data?.dialog_payload?.title}</div>
            <div onClick={dismissDialog} className="dialog-close-button nc50-fg cursor-pointer">
              {TimesLargeFilledMono}
            </div>
          </div>
        ) : null}
        <div
          className={
            data?.dialog_payload?.forceDialogContentClassName ??
            `cs-dialog-content full nc00-bg padding-y-${data?.dialog_payload?.paddingY ?? 10}x txt-${data?.dialog_payload?.align ?? "center"} padding-x-5x ${data?.dialog_payload?.additionalContentClass ?? ""}`
          }
        >
          {data?.dialog_payload?.icon ? <div className="cs-dialog-icon">{data?.dialog_payload?.icon}</div> : null}
          {data?.dialog_payload?.subtitle ? (
            <div className="dialog-subtitle headline-xlarge margin-top-5x text-center">
              {data?.dialog_payload?.subtitle}
            </div>
          ) : null}
          {data?.dialog_payload?.body ? (
            <div
              className={
                data?.dialog_payload?.forceinnerContentBodyClassName ??
                "margin-top-5x body-regular-font nc50-fg text-center"
              }
            >
              {data?.dialog_payload?.body}
            </div>
          ) : null}
          {data?.dialog_payload?.picture ? (
            <img
              className="cs-dialog-image margin-y-2x"
              src={data?.dialog_payload?.picture}
              alt={data?.dialog_payload?.title}
            />
          ) : null}
        </div>
        {data?.dialog_payload?.hideFooter ? null : (
          <div
            className={
              data?.dialog_payload?.forceDialogActionClassName ??
              `cs-ns-tag-dialog-footer flex j-end border-radius-bottom-left-4 border-radius-bottom-right-4 padding-4x nc00-bg border-top-1 nc05-border ${data?.dialog_payload?.additionalFooterClass ?? ""}`
            }
          >
            {data?.dialog_payload?.hideDefaultDismissButton ? null : (
              <Button
                className={data?.dialog_payload?.action ? "margin-right" : ""}
                onClick={dismissButton}
                size="medium"
                type={data?.dialog_payload?.action ? "secondary" : "primary"}
              >
                {data?.dialog_payload?.buttonText ?? "Dismiss"}
              </Button>
            )}
            {data?.dialog_payload?.action}
          </div>
        )}
      </div>
    </div>
  )
}
function useDialog() {
  const [, dispatch] = useContext(dataContext)
  return function ({ body, ...payload }) {
    if (!body) return console.error("Cannot show dialog! content is empty")
    dispatch({
      dialog_payload: {
        body,
        ...payload,
      },
    })
    return true
  }
}

export { CommonDialog, useDialog }
