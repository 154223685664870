import React, { useCallback, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import OnboardingImage from "../../assets/onboarding-payment.png"
import { Button } from "../button"
import { Spacer } from "../spacer"
import { PaypalLargeFilledMono, ChevronBackwardLargeOutlinedMono, CheckLargeFilledMono } from "../Icons"
import { dataContext } from "../../contexts/datacontext"
import { Navigate } from "react-router-dom"
import { useSocial } from "../../hooks/useSocial"
import { _lsd, _lsw } from "../../helpers/utils"
import { useLoading } from "../../contexts/loaderContext"
import { FullLoader } from "../loaders"
import { TermsLink } from "../dumbs"
import { apiContext } from "../../contexts/apicontext"

function AfterButton() {
  return <div className="primary-fg">{CheckLargeFilledMono}</div>
}

export const OnBoardingConnectPaymentPage = (props) => {
  const navigate = useNavigate()
  const { connect } = useSocial()
  const [data] = useContext(dataContext)
  const { loaders } = useLoading()
  const api = useContext(apiContext)

  useEffect(() => {
    _lsd("cs_last_login_step")
    api.analytics.page("/start-connect-payment", "Creator Onboarding: Connect Payment")
  }, [])

  const connectClick = () => {
    _lsw("cs_last_login_step", "/start-connect-payment")
    connect("paypal")
  }

  const onContinueClick = useCallback(() => {
    navigate(`/creator-dashboard`)
  }, [])

  const onBackClick = useCallback(() => {
    navigate("/start-connect-socials")
  }, [])

  const isPaypalConnected = Boolean(data?.me?.payment_settings?.paypal)
  if (data?.me && !data?.me?.username) return <Navigate to="/start-connect-socials" />

  return (
    <div className="onboarding-page-container full-min-height-without-header-and-footer-onboarding">
      <div className="onboarding-mobile-background hide-on-desktop bg-purple"></div>
      <div className="onboarding-card">
        <div className="onboarding-left-side ">
          <div className="onboarding-img-container purple-left-side-shadow">
            <img className="onboarding-img" alt="connect payment graphic" src={OnboardingImage} loading="lazy" />
          </div>
        </div>
        <div className="onboarding-right-side">
          <Spacer className="hide-on-mobile" height={96} />
          <Spacer className="hide-on-desktop" height={64} />
          <div className="onboarding-connect-payment-header-container">
            <div className="onboboading-payment-heading-with-back">
              <div className="onboarding-back-button hide-on-mobile" role="button" onClick={onBackClick}>
                {ChevronBackwardLargeOutlinedMono}
              </div>
              <h3 className="headline-large txt-center onboading-connect-payment-header  ">
                Set Up Your Payout Account
              </h3>
              <div className="hide-on-mobile"></div>
            </div>
            <Spacer className="hide-on-mobile" height={24} />
            <Spacer className="hide-on-desktop" height={12} />
            <p className=" onboarding-max-header-container margin-x-auto body-regular-font txt-center nc100-fg onboading-connect-payment-header-description ">
              Connect your PayPal account for secure and swift payout of earnings
            </p>
          </div>
          <Spacer className="hide-on-mobile" height={24} />
          <Spacer className="hide-on-desktop" height={48} />
          <div className="onboarding-group">
            {loaders.me ? (
              <FullLoader />
            ) : (
              <Button
                fluid
                size="large"
                type="tertiary"
                beforeElement={<div className="flex a-center j-center nc100-fg">{PaypalLargeFilledMono}</div>}
                className={`${isPaypalConnected ? "after-element-at-the-end" : ""}`}
                afterElement={isPaypalConnected ? <AfterButton cond={isPaypalConnected} /> : null}
                onClick={connectClick}
              >
                {isPaypalConnected ? "Paypal Connected" : "Connect PayPal"}
              </Button>
            )}
            <p className="txt-center caption nc50-fg margin-top-2x">
              (Not Required, but we can’t pay you without Paypal)
            </p>
            <Spacer className="hide-on-mobile" height={40} />
            <Spacer className="hide-on-desktop" height={24} />
            {isPaypalConnected ? null : (
              <Button fluid size="large" type="ghost" onClick={onContinueClick}>
                Skip
              </Button>
            )}
            <Spacer height={8} />
            <Button disabled={!isPaypalConnected} fluid size="large" type="primary" onClick={onContinueClick}>
              Complete Set Up
            </Button>
            <Spacer className="hide-on-mobile" height={0} />
            <Spacer className="hide-on-desktop" height={24} />
            <p className="hide-on-desktop caption nc30-fg txt-center">
              By clicking Skip or Complete Set Up I accept the <TermsLink />
            </p>
          </div>
          <p className="hide-on-mobile caption nc30-fg txt-center">
            By clicking Skip or Complete Set Up I accept the <TermsLink />
          </p>
        </div>
      </div>
    </div>
  )
}

OnBoardingConnectPaymentPage.propTypes = {}
