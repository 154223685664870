import PropTypes from "prop-types"

// type :["primary","secondary","tertiary"]
function Button({
  children,
  size = "medium",
  type = "primary",
  disabled = false,
  fluid = false,
  afterElement,
  beforeElement,
  className = "",
  onlyIconElement,
  colorTheme = "normal",
  ...rest
}) {
  // could have done `$icon-container-{$size}` in the className but declared an object for flexibility
  const IconClassesObject = {
    small: "icon-container-small",
    medium: "icon-container-medium",
    large: "icon-container-large",
  }

  const IconClass = IconClassesObject[size] ?? "icon-container"

  return (
    <button
      disabled={disabled}
      className={`button ${className} ${size} ${type} ${colorTheme} ${fluid ? "fluid" : ""} ${onlyIconElement ? "only-icon-button" : ""}`}
      {...rest}
    >
      {onlyIconElement ? (
        <span className={`only-icon-container ${IconClass}`}> {onlyIconElement} </span>
      ) : (
        <>
          {beforeElement ? <span className={`before-element-container ${IconClass}`}>{beforeElement}</span> : null}
          {children}
          {afterElement ? <span className={`after-element-container ${IconClass}`}>{afterElement}</span> : null}
        </>
      )}
    </button>
  )
}

export { Button }
