import { atom, useAtom } from "jotai"
import { useEffect } from "react"

const engageStatsAtom = atom(null)
const dailyStatsAtom = atom(null)
const restrictionAtom = atom(false)

const useLogAtoms = (atoms) => {
  const atomEntries = Object.entries(atoms).map(([name, atom]) => [name, useAtom(atom)])

  useEffect(() => {
    if (window.__ALLOW_PROD_JOTAI_LOGGING) {
      console.group("Logging atoms")
      atomEntries.forEach(([name, [value]]) => {
        console.log(`${name}:`, value)
      })
      console.groupEnd()
    }
  }, [atomEntries, window.__ALLOW_PROD_JOTAI_LOGGING])
  useEffect(() => {
    if (window.__ALLOW_PROD_JOTAI_LOGGING) {
      console.log("now prod debug is enabled")
    }
  }, [window.__ALLOW_PROD_JOTAI_LOGGING])
}

const AtomLogger = () => {
  useLogAtoms({ engageStatsAtom, dailyStatsAtom, restrictionAtom })

  return null
}

export { engageStatsAtom, dailyStatsAtom, restrictionAtom, useLogAtoms, AtomLogger }
