import { useContext, useEffect } from "react"
import { apiContext } from "../../contexts/apicontext"
import { Loader } from "../loaders"
import { getQueryParam, _lsw } from "../../helpers/utils"

function StartWithName() {
  const api = useContext(apiContext)
  useEffect(() => {
    const username = getQueryParam(window.location.search, "username")
    _lsw("cs_last_set_mode", "creator")
    if (username) {
      _lsw("user_choice_username", username)
      api.analytics.action("user", "signup_using_preset_username", username)
    }
    api.login(true)
  }, [])
  return (
    <div className="callback-page flex hcenter margin-10x">
      <Loader className="margin-right-2x" />
      <div className="wait-text txt-center">Please wait...</div>
    </div>
  )
}

export { StartWithName }
