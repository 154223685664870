import React from "react"
import PropTypes from "prop-types"
import { DropdownLargeFilledMono } from "./Icons"

function getDisplayedValue(value, children) {
  const childArray = React.Children.toArray(children)
  const selectedChild = childArray.find((child) => child.props.value === value)
  return selectedChild?.props?.children
}

export const Select = ({
  children,
  value,
  onChange,
  type = "fluid",
  placeholder = "Not Selected",
  selectTextClass = "",
  presentationalBitClass = "",
  styleType = "regular",
  className = "",
  noBorder,
  error,
  customSelectText,
  dropdownIcon = DropdownLargeFilledMono,
}) => {
  let selected
  if (children) {
    selected = getDisplayedValue(value, children, placeholder)
  }
  return (
    <div className={`select-wrapper ${type} ${styleType} ${className}`}>
      <select className="native-select" value={value} onChange={onChange}>
        {children}
      </select>
      <div
        className={`select-presentation-bit ${presentationalBitClass} ${noBorder ? "border-none-important" : ""}  ${styleType === "capsule" ? "border-radius-capsule-important line-shadow nc10-border-override max-height-44 min-height-44" : styleType} ${selected ? "selected" : ""} ${error ? "negative-border-override" : ""}`}
      >
        <span className={`margin-right-2x ${selectTextClass}`}>{customSelectText ?? selected ?? placeholder}</span>
        <div className="select-icon-wrapper flex a-center j-center nc100-fg ">{dropdownIcon}</div>
      </div>
    </div>
  )
}

export const ContentShowingSelect = ({
  children,
  value,
  onChange,
  type = "fluid",
  placeholder = "Not Selected",
  styleType = "regular",
  className = "",
  error,
}) => {
  return (
    <div className={`select-wrapper ${type} ${styleType} ${className}`}>
      <select className="native-select" onChange={onChange}>
        {children}
      </select>
      <div
        className={`select-presentation-bit ${styleType} ${value ? "selected" : ""} ${error ? "negative-border-override" : ""}`}
      >
        {value ?? placeholder}
        <div className="select-icon-wrapper icon-container-medium nc100-fg">
          <div className="select-icon-wrapper flex a-center j-center nc100-fg">{DropdownLargeFilledMono}</div>
        </div>
      </div>
    </div>
  )
}

Select.propTypes = {
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  styleType: PropTypes.oneOf(["shadow", "regular", "ghost", `capsule`]),
  type: PropTypes.oneOf(["fluid", "content"]),
  className: PropTypes.string,
}
