import { Input } from "../input"
import { Button } from "../button"
import { PlaneLargeFilledMono } from "../Icons"
import { useContext } from "react"
import { dataContext } from "../../contexts/datacontext"
import { apiContext } from "../../contexts/apicontext"
import { validateEmail } from "../../helpers/utils"
import { useLoading } from "../../contexts/loaderContext"
import { Loader } from "../loaders"
function ThankYouForSubmission({ compact = false }) {
  const [data, dispatch] = useContext(dataContext)
  const handleSubmit = () => {
    dispatch({ guest_user_submitted_email: false })
  }
  return (
    <div
      className={`unlock-ui ${compact ? "padding-x-6x padding-top-10x padding-bottom limit-width-unlock-ui" : "padding-6x"}`}
    >
      <div className="headline-large primary-fg">Thank you for contacting Zelf.</div>
      <div className="caption-regular-font nc50-fg margin-top-3x">
        Someone from Zelf will get in touch very soon to discuss all the exciting features zelf has to offer.
      </div>
    </div>
  )
}
function UnlockFullAction({ className = "" }) {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const { loaders } = useLoading()
  const handleSubmit = async () => {
    if (!validateEmail(data?.guest_user_email_input)) {
      console.error("not a valid email")
      return
    }
    // the query param
    const filter = new URLSearchParams({
      email: data?.guest_user_email_input,
    }).toString()
    await api.brand.dashboardSection({ section: `send_admin_email`, filter: filter })
    dispatch({ dialog_payload: null })
    dispatch({ guest_user_submitted_email: true, guest_user_email_input: "" })
  }
  if (data.guest_user_submitted_email) return null
  return (
    <Button
      disabled={
        loaders["bd-section-send_admin_email"] ||
        !data?.guest_user_email_input?.length ||
        !validateEmail(data?.guest_user_email_input)
      }
      onClick={handleSubmit}
      className={className}
      beforeElement={PlaneLargeFilledMono}
    >
      {loaders["bd-section-send_admin_email"] ? <Loader /> : "Submit"}
    </Button>
  )
}
function UnlockFullExperience({ action = false, compact = false }) {
  const [data, dispatch] = useContext(dataContext)

  if (data?.guest_user_submitted_email) return <ThankYouForSubmission compact={compact} />
  return (
    <div className={`unlock-ui ${compact ? "padding-x-6x padding-top-2x padding-bottom" : "padding-6x"}`}>
      <div className="headline-large primary-fg">Unlock the full experience</div>
      <div className="caption-regular-font nc50-fg margin-top-3x">
        share your email below and we'll reach out to get you full access
      </div>
      <Input
        value={data?.guest_user_email_input ?? ""}
        onChange={(e) => dispatch({ guest_user_email_input: e.target.value })}
        className="full margin-top-3x"
        placeholder="your email id..."
      />
      {action ? <UnlockFullAction className="margin-top-3x full-width" /> : null}
    </div>
  )
}

export { UnlockFullExperience, UnlockFullAction }
