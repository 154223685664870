import zlogo from "../assets/zelflogo.png"
function NotAvailableOnMobile() {
  return (
    <div className="not-available-on-mobile padding-x-4x padding-top-15x padding-bottom-4x">
      <div className="flex hcenter margin-top-5x">
        <img src={zlogo} alt="Zelf" />
      </div>
      <h1 className="txt-center nc75-fg margin-top-10x">This feature is not available on mobile devices yet.</h1>
      <div className="margin-top-10x nc50-fg txt-center">
        Sorry but the dashboard is only available on a desktop or a tablet. we are working hard to make it available on
        other devices very soon.
      </div>
      <div className="margin-top-10x caption-regular-font txt-center nc50-fg">
        for more information, please visit: <br />
        <br />{" "}
        <b>
          <a className="primary-fg" href="https://hellozelf.com">
            hellozelf.com
          </a>
        </b>
      </div>
    </div>
  )
}

export { NotAvailableOnMobile }
