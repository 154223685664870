import { DotSeparator, EmailIndicator, PlatformIcon, RoundButton, StatBlock, WrappedInLoader } from "../dumbs"
import { MediaSelect } from "../media"
import dayjs from "dayjs"
import { ExpandableText, InfoIcon } from "../dumbs"
import {
  LightningBolt,
  URLIcon,
  CopyLargeFilledMono,
  InfoMediumFilledMono,
  LeftArrow,
  DownloadMediumOutlinedMono,
  TrashLargeFilledMono,
  HeartLargeFilledMono,
  HeartLargeOutlinedMono,
  BoardSaveLargeOutLineMono,
  BoardSaveLargeFilledMono,
  ArchiveLargeMonoOutline,
  Lock,
  SmallInfluencerColor,
  SmallInfluencerMono,
  NeutralIcon,
  LikeContent,
  SendComment,
  SmileyFaceMono,
  AngryFaceMono,
  PokerFaceMono,
  PostOnlyMention,
  PostMentionIndicator,
  CommentMentionIndicator,
  InfoLargeFilledMono,
  TranscriptionIndicator,
  ChevronDownMediumFilledMono,
  ChevronUpMediumFilledMono,
  RegenerateSmall,
  CheckMediumFilledMono,
  ChatgptIcon,
  BanSolid,
  PlusLargeFilledMono,
  PencilSmallFilledMono,
} from "../Icons"
import { Button } from "../button"
import { useContext, useEffect, useState } from "react"
import { dataContext } from "../../contexts/datacontext"
import { _nopropagate, openLink, convertToPercentage } from "../../helpers/utils"
import { useCampaignPopupHelper } from "../structural/BrandEcosystem/campaignPopup"
import { apiContext } from "../../contexts/apicontext"
import ReactTooltip from "react-tooltip"
import { useToast } from "../../hooks/useToast"
import { BoardPopup } from "../structural/BrandEcosystem/boardPopup"
import { useLoading } from "../../contexts/loaderContext"
import { Loader } from "../loaders"
import {  useBDPostData, useLocalBoundData } from "../../hooks/useBoundData"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAuthHelper } from "../../hooks/useAuthHelper"
import { IfGuest, IfNotGuest } from "../functional/auth_components"
import { AutoHeightTextarea } from "../input"
import {
  useAIComment,
  useBrandConfig,
  useClickAway,
  useLikeContent,
  useNavigateToCreatorProfile,
  useDataContextManyObjectType,
} from "../../hooks/helperHooks"
import EmojiPicker from "emoji-picker-react"
import { CommentThread } from "../structural/commentThread"
import { GET_CALLS } from "../../consts"

import TrumpImg from "../../assets/political/trump.png"
import BidenImg from "../../assets/political/biden.png"
import KamalaImg from "../../assets/political/kennedy.png"
import RFKImg from "../../assets/political/rfk.png"
import VanceImg from "../../assets/political/vance.png"
import WalzImg from "../../assets/political/Walz.png"

function ContentAndCreatorBasicInfo({
  content,
  hideInfluencer = false,
  onInfluentialContentChange,
  latestInfluencerValue,
}) {
  const showProfile = useNavigateToCreatorProfile()
  const showCreatorProfile = (_creator) => {
    showProfile(content?.creator)
    // closeModal()
  }

  const is_influencer_content = latestInfluencerValue ?? content?.content?.is_influencer_content
  return (
    <div className="content-modal-creator-info flex vcenter margin-bottom-5x" onClick={showCreatorProfile}>
      <img
        className="content-modal-creator-avatar"
        src={content.creator.profile_picture_url}
        alt={content.creator.username}
      />
      <a
        target="_blank"
        rel="noreferrer"
        href={`/${content.creator.username}`}
        className="content-modal-creator-name margin-left-2x nc100-fg headline-medium-highlight"
      >
        {content.creator.username || content?.creator?.name}
      </a>
      <DotSeparator className="margin-x-2x" />
      <div className="headline-small-regular nc50-fg">{dayjs(content.content.timestamp).fromNow()}</div>
      <>
        {!hideInfluencer && (
          <>
            <DotSeparator className="margin-x-2x" />
            <span
              onClick={(e) => {
                _nopropagate(e)
                onInfluentialContentChange()
              }}
              className={`flex cursor-pointer ${is_influencer_content ? "primary-fg" : "nc50-fg"}`}
            >
              {SmallInfluencerMono}
            </span>
          </>
        )}
        {content?.content?.mention_type === 1 ? (
          <>
            <DotSeparator className="margin-x-2x" />
            <span className="flex primary-fg">{PostMentionIndicator}</span>
          </>
        ) : null}
        {content?.content?.mention_type === 2 ? (
          <>
            <DotSeparator className="margin-x-2x" />
            <span className="flex primary-fg">{CommentMentionIndicator}</span>
          </>
        ) : null}
      </>
    </div>
  )
}

function PostTitleSection({ content, className = "" }) {
  return (
    <div className="content-description-text">
      <ExpandableText
        lines={3}
        className={`media-detail-expand body-regular-font nc100-fg ${className}`}
        showLessClasses="txt-bold margin-top"
        showMoreClasses="txt-bold"
        text={content.content.content_platform === "tiktok" ? content.content.title : content.content.text}
      />
    </div>
  )
}

const CandidatesNps = ({ className = "", candidates = {} }) => {
  const candidatesImg = {
    harris: {
      name: "Harris",
      img: KamalaImg,
    },
    trump: {
      name: "Trump",
      img: TrumpImg,
    },
    rfk: {
      name: "RFK",
      img: RFKImg,
    },
    walz: {
      name: "Walz",
      img: WalzImg,
    },
    vance: {
      name: "Vance",
      img: VanceImg,
    },
    biden: {
      name: "Biden",
      img: BidenImg,
    },
  }

  return (
    <div className={`flex vcenter ${className}`}>
      {
        //  clear null values before mapping
        Object.entries(candidates)
          .filter(([key, value]) => value !== null)
          .map(([key, value], i) => (
            <div className="flex" key={i}>
              <img height={26} width={26} src={candidatesImg[key]?.img} className="border-radius-round" />
              {value == 1 ? (
                <span className="flex vcenter j-center min-height-26 min-width-26 border-1 nc10-border round margin-left-negative-2x nc00-bg positive-fg">
                  {SmileyFaceMono}
                </span>
              ) : value == -1 ? (
                <span className="flex vcenter j-center min-height-26 min-width-26 border-1 nc10-border round margin-left-negative-2x nc00-bg negative-fg">
                  {AngryFaceMono}
                </span>
              ) : (
                <span className="flex vcenter j-center min-height-26 min-width-26 border-1 nc10-border round margin-left-negative-2x nc00-bg attention-fg">
                  {PokerFaceMono}
                </span>
              )}
            </div>
          ))
      }
    </div>
  )
}

function PostIssuesAndSentiment({ content, stats, isLoading = true }) {
  const [data] = useContext(dataContext)
  const api = useContext(apiContext)
  // console.log("date_specific_dialog_data",data?.date_specific_dialog_data, stats, content)

  const issues = stats?.brands?.filter((i) => i?.sub_brand_type == 2) || []
  const nps_score = data?.date_specific_dialog_data?.is_competitor ? content?.content?.nps_score : null
  const isPoliticalEngageEnabled = data?.me?.brand?.settings?.is_political_engage_enabled

  const candidateNps = [
    {
      name: "Harris",
      sentiment:
        (!isLoading && data?.date_specific_dialog_data?.originalValue?.brand_title === "Kamala Harris"
          ? nps_score
          : null) ?? stats?.kamala_nps,
      img: KamalaImg,
    },
    {
      name: "Trump",
      sentiment:
        (!isLoading && data?.date_specific_dialog_data?.originalValue?.brand_title === "Donald Trump"
          ? nps_score
          : null) ?? stats?.trump_nps,
      img: TrumpImg,
    },
    {
      name: "RFK",
      sentiment:
        (!isLoading && data?.date_specific_dialog_data?.originalValue?.brand_title === "RFK jr" ? nps_score : null) ??
        stats?.rfk_nps,
      img: RFKImg,
    },
    {
      name: "Walz",
      sentiment:
        (!isLoading && data?.date_specific_dialog_data?.originalValue?.brand_title === "Tim Walz" ? nps_score : null) ??
        stats?.tim_nps,
      img: WalzImg,
    },
    {
      name: "Vance",
      sentiment:
        (!isLoading && data?.date_specific_dialog_data?.originalValue?.brand_title === "JD Vance" ? nps_score : null) ??
        stats?.vance_nps,
      img: VanceImg,
    },
    {
      name: "Biden",
      sentiment:
        (!isLoading && data?.date_specific_dialog_data?.originalValue?.brand_title === "Joe Biden"
          ? nps_score
          : null) ?? stats?.biden_nps,
      img: BidenImg,
    },
  ].filter((e) => e.sentiment !== null)

  const handleEditIssues = () => {
    api?.alert?.EditIssuesAndSentiment(content, stats)
  }

  return (
    <div className="margin-bottom-6x">
      <div className="margin-bottom-2x caption-highlight nc50-fg">Issues & Sentiment</div>
      {isLoading ? (
        <div className="flex vcenter j-center margin-top-4x">
          <Loader />
        </div>
      ) : (
        <>
          {issues.length > 0 && (
            <div className="flex gap-2 vcenter wrapped margin-bottom-4x">
              {issues?.map((item, i) => (
                <Button key={i} className="capsule-button" type="tertiary" size="small">
                  {item?.brand_title}
                </Button>
              ))}
            </div>
          )}
          <div className="flex j-space-between">
            <div className="flex vcenter gap-2">
              {candidateNps.length > 0 ? (
                <>
                  {candidateNps.map((item, i) => (
                    <div className="flex" key={i}>
                      <img height={34} width={34} src={item?.img} className="border-radius-round" />
                      {item.sentiment === 1 ? (
                        <span className="flex vcenter j-center min-height-34 min-width-34 border-1 nc10-border round margin-left-negative-2x nc00-bg positive-fg">
                          {SmileyFaceMono}
                        </span>
                      ) : item.sentiment === -1 ? (
                        <span className="flex vcenter j-center min-height-34 min-width-34 border-1 nc10-border round margin-left-negative-2x nc00-bg negative-fg">
                          {AngryFaceMono}
                        </span>
                      ) : (
                        <span className="flex vcenter j-center min-height-34 min-width-34 border-1 nc10-border round margin-left-negative-2x nc00-bg attention-fg">
                          {PokerFaceMono}
                        </span>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex vcenter j-center nc50-fg">No sentiment data available</div>
              )}
            </div>
            {!isPoliticalEngageEnabled && (
              <button
                onClick={handleEditIssues}
                className="nc00-bg max-height-34 min-height-34 min-width-34 max-width-34 border-radius-round flex a-center j-center primary-fg border-1 nc10-border line-shadow cursor-pointer"
              >
                {PencilSmallFilledMono}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  )
}

function OriginalPostRef({ content, tailElement }) {
  const [data] = useContext(dataContext)
  const api = useContext(apiContext)
  if (!content?.content?.external_url) return null
  return (
    <div className=" flex">
      <div
        rel="noreferrer"
        className="padding-x padding-y-2x full primary-fg-override flex j-center a-center  button-small-font cursor-pointer"
        onClick={() => openLink(content?.content?.external_url)}
      >
        {URLIcon}&nbsp; original post
      </div>

      <IfNotGuest>
        {!data?.me?.brand?.settings?.is_political_engage_enabled && (
          <div
            onClick={() => api?.alert?.ContactCreator(content.creator)}
            className="padding-y-2x primary-fg border-left-1 nc10-border full flex j-center a-center  cursor-pointer button-small-font"
          >
            {/* <div className="flex a-center margin-right">
                        {InfoLargeFilledMono}
                    </div> */}
            Contact Creator
          </div>
        )}
        {tailElement}
      </IfNotGuest>
    </div>
  )
}

function OutsideEcosystemContent({ content }) {
  const [data] = useContext(dataContext)
  return (
    <div className="content-modal-content-actions margin-top margin-bottom-4x flex vcenter">
      <div
        className="negative-fg-override flex vcenter margin-right button-small-font margin-right-2x"
        href={content?.content?.external_url}
      >
        Post isn't from a {data?.me?.brand?.name} creator&nbsp;
        {InfoMediumFilledMono}
      </div>
      <div className="nc50-fg cursor-pointer button-small-font flex vcenter">
        Copy invite link&nbsp; {CopyLargeFilledMono}
      </div>
    </div>
  )
}

function ContentModelsActions({ children, title = "", onClick, className = "" }) {
  const id = title.trim().toLowerCase()

  return (
    <div className={className}>
      <div data-tip={id} data-for={id} onClick={onClick}>
        <div className="border-radius-round border-2 nc10-border flex a-center j-center content-modal-content-action  cursor-pointer">
          {children}
        </div>
      </div>
      {id ? (
        <ReactTooltip id={id} effect="solid">
          <span>{title}</span>
        </ReactTooltip>
      ) : null}
    </div>
  )
}

const NPSScore = ({ currentNPS }) => {
  const [showSelector, setShowSelector] = useState(false)
  const [data, dispatch] = useContext(dataContext)
  const { payload: newChange, executeCall: updateNPS } = useBDPostData({ section: "update_content_brand" })
  const api = useContext(apiContext)
  const toast = useToast()

  const updateNPSScore = async (nps_score) => {
    api.common.updateManyObjectTypeInDataContent({
      id: data?.ecosystem_selected_content?.content?.id,
      dataContextVariableName: "seen_content_from_modal",
      newValue: { nps_score },
    })
    const res = await updateNPS({
      nps_score,
    })
    if (!res) {
      toast("Something went wrong while updating the nps...")
    }
  }

  const _nps_score =
    data?.seen_content_from_modal?.[data?.ecosystem_selected_content?.content?.id]?.nps_score ?? currentNPS

  const npsIconMap = {
    1: <div className="flex a-center j-center positive-fg">{SmileyFaceMono}</div>,
    0: (
      <div
        style={{
          color: "#FFBA33",
        }}
        className="flex a-center j-center positive-fg "
      >
        {PokerFaceMono}
      </div>
    ),
    "-1": (
      <div onClick={() => updateNPSScore(-1)} className="flex a-center j-center negative-fg">
        {AngryFaceMono}
      </div>
    ),
  }

  return (
    <div
      onMouseEnter={() => setShowSelector(true)}
      onMouseLeave={() => setShowSelector(false)}
      className="flex column a-center j-center  padding-2x padding-right-4x cursor-pointer position-relative"
    >
      {showSelector ? (
        <div
          onMouseEnter={() => setShowSelector(true)}
          className="border-radius-capsule padding nps-set-score-holder flex border-2 nc10-border nc00-bg animate"
        >
          <div
            onClick={() => updateNPSScore(1)}
            className="flex a-center j-center positive-fg padding margin-right  scale-up-on-hover"
          >
            {SmileyFaceMono}
          </div>
          <div
            onClick={() => updateNPSScore(0)}
            style={{
              color: "#FFBA33",
            }}
            className="flex a-center j-center positive-fg padding margin-right  scale-up-on-hover"
          >
            {PokerFaceMono}
          </div>
          <div
            onClick={() => updateNPSScore(-1)}
            className="flex a-center j-center negative-fg padding  scale-up-on-hover"
          >
            {AngryFaceMono}
          </div>
        </div>
      ) : null}
      <div className="margin-bottom">
        {npsIconMap[String(_nps_score)] ? (
          npsIconMap[String(_nps_score)]
        ) : (
          <div className="nc75-fg flex a-center">{NeutralIcon}</div>
        )}
      </div>
      <div className="txt-center caption-regular-font nc50-fg">
        NPS
        <br />
        Score
      </div>
    </div>
  )
}

function ContentStats({ content, boostDisabled, onBoostClick, isNpsDisabled = false }) {
  return (
    <div className="flex j-space-between a-center  nc10-border padding-y-4x padding-x-2x">
      {!isNpsDisabled && <NPSScore currentNPS={content?.content?.nps_score} />}
      <StatBlock className="fluid-block" separator={false} data={content.content.views} title="Total views" />
      <StatBlock className="fluid-block" separator={false} data={content.content.likes} title="Total likes" />
      <StatBlock className="fluid-block" separator={false} data={content.content.comments} title="Total comments" />
      <StatBlock
        className="fluid-block"
        hip={false}
        separator={false}
        data={convertToPercentage(
          content.content.engagement_of_views
            ? content.content.engagement_of_views
            : content.content.engagement_of_followers,
          1,
        )}
        title={content.content.engagement_of_views ? "Engagement rate" : "Engagement rate by followers"}
      />
      {/* {!boostDisabled ? <ContentModelsActions title="Boost post" onClick={onBoostClick}>
            <div className="flex a-center j-center primary-fg">
                {LightningBolt}
            </div>
                   
        </ContentModelsActions>:null} */}
    </div>
  )
}

function ContentModal(props) {
  const [data, dispatch] = useContext(dataContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [buy, setBuy] = useState(false)
  const [showTranscription, setShowTranscription] = useState(false)
  const content = props.content
  const popup = useCampaignPopupHelper()
  const { payload: extra_stats, loader: extra_stats_loader } = useLocalBoundData({
    ...GET_CALLS?.content_modal_stats_details,
    endpoint: `/Content/${content?.content?.id}/details/`,
  })
  const { payload: newChange, executeCall: updateNewStatus } = useBDPostData({ section: "update_content_brand" })
  const api = useContext(apiContext)
  const boardPopup = useCampaignPopupHelper()
  const toast = useToast()
  const {
    onLikeContent,
    clientSideLikeValue,
    loader: likeLoader,
  } = useLikeContent({ content_id: content?.content?.id, content_platform: content?.content?.content_platform })
  const { isGuest } = useAuthHelper()
  const { loaders } = useLoading()
  const navigate = useNavigate()

  const closeModal = () => {
    dispatch({
      ecosystem_selected_content: null,
      content_modal_content_array: [],
      content_modal_content_index: 0,
      opened_from: null,
    })
    popup.hide()
    setBuy(false)
  }
  const _navigate_post = (e, direction, additionalDispatchData = {}) => {
    _nopropagate(e)
    switch (direction) {
      case "forward":
        if (data.content_modal_content_index + 1 < data?.content_modal_content_array?.length) {
          dispatch({
            ecosystem_selected_content: data.content_modal_content_array[data.content_modal_content_index + 1],
            content_modal_content_index: data.content_modal_content_index + 1,
            ...additionalDispatchData,
          })
        }
        break
      case "backwards":
        if (data.content_modal_content_index - 1 >= 0) {
          dispatch({
            ecosystem_selected_content: data.content_modal_content_array[data.content_modal_content_index - 1],
            content_modal_content_index: data.content_modal_content_index - 1,
            ...additionalDispatchData,
          })
        }
        break
      default:
        break
    }
  }

  const handleKeyboardNavigation = (e) => {
    if (e.keyCode === 37) {
      // left arrow
      e.preventDefault()
      _navigate_post(e, "backwards")
      return
    }
    if (e.keyCode === 39) {
      // right arrow
      e.preventDefault()
      _navigate_post(e, "forward")
      return
    }
  }

  useEffect(() => {
    // if editing the sentiment or issues then don't add the event listener
    if (data?.edit_issue_sentiment) return
    // console.log("add event listener")
    window.addEventListener("keydown", handleKeyboardNavigation)

    return () => {
      //   console.log("removing")
      window.removeEventListener("keydown", handleKeyboardNavigation)
    }
  }, [data.content_modal_content_index, data.ecosystem_selected_content, data?.edit_issue_sentiment])
  useEffect(() => {
    return () => {
      console.log("removing")
      closeModal()
    }
  }, [])

  useEffect(() => {
    //   api.brand?.dashboardSection({section: "content_modal_stats_details"})
  }, [data?.ecosystem_selected_content])
  useEffect(() => {
    setShowTranscription(false)
  }, [data?.ecosystem_selected_content])
  useEffect(() => {
    ; (async () => {
      if (data?.ecosystem_selected_content?.content?.is_new) {
        await updateNewStatus({
          is_new: false,
        })
        api.common.updateManyObjectTypeInDataContent({
          id: data?.ecosystem_selected_content?.content?.id,
          dataContextVariableName: "seen_content_from_modal",
          newValue: { is_new: false },
        })
      }
    })()
  }, [data?.ecosystem_selected_content])

  const saveADDDraft = () => {
    dispatch({ pending_campaign_add: { contents: [props.content.content] } })
  }
  if (!content) return null

  const onUndoRemove = async (e) => {
    // console.log(" undoing ", content.content.id)
    toast("Restoring post...")
    const response = await api.brand.removeOrUndoContent({ id: content?.content?.id, undo: true })
    if (response) {
      toast("Post restored.")
      api.brand.hardRefreshDashboardSections({
        sectionsArray: [
          "discovery_analytics_public",
          "social_priority_posts",
          "community_posts",
          "",
          "all_post",
          "discovery_analytics_owned",
        ],
      })
    }
  }

  const onInfluentialContentChange = async (id) => {
    const client_only_influencer_modification = { ...data?.client_only_influencer_modification }
    if (client_only_influencer_modification[id] == undefined) {
      client_only_influencer_modification[id] = content?.content?.is_influencer_content
    }
    const res = await api.brand.changeArchiveState({
      id,
      is_influencer_content: !client_only_influencer_modification[id],
    })

    client_only_influencer_modification[id] = !client_only_influencer_modification[id]
    dispatch({
      client_only_influencer_modification,
    })
    if (res) {
      toast(
        client_only_influencer_modification[id] ? "Added to influencer content." : "Removed from influencer content.",
      )
    }
  }

  const isFav =
    Boolean(extra_stats?.is_favourite) || (data?.frontend_only_favorites ?? []).some((e) => e === content.content.id)
  const isSavedBoard =
    Boolean(extra_stats?.boards?.length) ||
    (data?.frontend_only_content_board ?? []).some((e) => e === content.content.id)

  const contentFilterFunction = (item) => item.content.id !== content.content.id

  const filterContentFromDataContextList = ({ context_name_list = [], filterFunction = contentFilterFunction }) => {
    const obj = {}
    for (let sectionName of context_name_list) {
      obj[sectionName] = (data?.[sectionName] ?? []).filter(filterFunction)
    }
    return obj
  }

  const content_modal_delete_hint = {
    selected_board_content: "Remove from board",
  }

  const postDeleteAction = (needsToBeUpdateContextObj) => {
    if (needsToBeUpdateContextObj?.content_modal_content_array[data?.content_modal_content_index]) {
      dispatch({
        ...needsToBeUpdateContextObj,
        ecosystem_selected_content:
          needsToBeUpdateContextObj?.content_modal_content_array[data?.content_modal_content_index],
      })
    } else {
      dispatch({ ...needsToBeUpdateContextObj })
      closeModal()
    }
  }
  const onRemoveContent = async (e) => {
    // dispatching for hard refresh
    // dispatch({bd_sec_discovery_analytics_public_page:null ,bd_sec_discovery_analytics_public:null})

    if (data?.opened_from === "selected_board_content") {
      const res = await api.brand.addOrRemoveContentsToBoard(
        [content.content],
        data?.selected_board_for_viewing?.id,
        true,
      )
      const needsToBeUpdateContextObj = filterContentFromDataContextList({
        context_name_list: ["content_modal_content_array"],
        filterFunction: contentFilterFunction,
      })

      postDeleteAction({ ...needsToBeUpdateContextObj, [`need_refresh_${data?.opened_from}`]: true })

      // refreshing the board list because we don't use old board list data to calculate the stats
      // api.brand.hardRefreshDashboardSections({ sectionsArray: ["selected_board_details"] })
      // closeModal()
      return
    }

    // removing the content from everywhere in the frontend // might be temporary
    const needsToBeUpdateContextObj = filterContentFromDataContextList({
      context_name_list: ["content_modal_content_array"],
      filterFunction: contentFilterFunction,
    })

    postDeleteAction({
      deleted_from_discovery: [...data?.deleted_from_discovery, content.content.id],
      ...needsToBeUpdateContextObj,
    })

    const response = await api.brand.removeOrUndoContent({ id: content.content.id, undo: false })

    // if(response){
    //     toast("Post Removed from discovery.",TrashLargeFilledMono, 4000,<div className="padding-x-3x txt-underlined cursor-pointer" onClick={onUndoRemove}>undo</div>)
    // }

    if (response) {
      toast("Post Removed from discovery.", TrashLargeFilledMono)
    }

    // navigate(e,"forward",{content_modal_content_array})
  }
  const onBoardClick = (board) => {
    api.brand.addOrRemoveContentsToBoard([content.content], board.id)
  }

  const saveAddContentDraft = () => {
    dispatch({
      pending_board_add: { contents: [content.content] },
    })
  }

  let showArchive =
    data?.opened_from === "trending_posts" ||
    data?.opened_from === "new_posts" ||
    data?.opened_from === "community_posts" ||
    data?.opened_from === "social_priority_posts"
  if (
    data?.opened_from === "all_posts" &&
    (content.content?.is_archived_from_trending_posts || content.content?.is_archived_from_new_posts)
  ) {
    showArchive = true
  }
  const onArchiveClick = async () => {
    if (data?.opened_from === "all_posts" && showArchive) {
      const response = await api.brand.changeArchiveState({
        id: content.content.id,
        is_archived_from_new_posts: false,
        is_archived_from_trending_posts: false,
      })
      if (response) {
        toast("Post restored")
      }
      api.brand.hardRefreshDashboardSections({
        sectionsArray: ["community_posts", "social_priority_posts", "trending_posts"],
      })
      return
    }

    const payload = {
      id: content.content.id,
    }
    const updateConTextNameArray = ["content_modal_content_array"]

    if (data?.opened_from === "trending_posts") {
      payload["is_archived_from_trending_posts"] = true
      updateConTextNameArray.push("bd_sec_trending_posts")
    }

    if (data?.opened_from === "community_posts" || data?.opened_from === "social_priority_posts") {
      payload["is_archived_from_new_posts"] = true
      updateConTextNameArray.push("bd_sec_new_posts")
    }
    const response = await api.brand.changeArchiveState(payload)

    if (response) {
      toast("Post archived")
      const _needsToBeUpdated = filterContentFromDataContextList({
        context_name_list: updateConTextNameArray,
        filterFunction: contentFilterFunction,
      })

      if (_needsToBeUpdated?.content_modal_content_array[data?.content_modal_content_index]) {
        dispatch({
          ..._needsToBeUpdated,
          [`need_refresh_${data?.opened_from}`]: true,
          ecosystem_selected_content: _needsToBeUpdated?.content_modal_content_array[data?.content_modal_content_index],
        })
      } else {
        dispatch({ ..._needsToBeUpdated, [`need_refresh_${data?.opened_from}`]: true })
        // closeModal()
      }

      api.brand.hardRefreshDashboardSections({ sectionsArray: ["all_posts"] })
    }
  }

  const _onLikeContent = async ({ is_like }) => {
    await onLikeContent({ is_like })
    await api.brand.dashboardSection({ section: "content_modal_stats_details" })
  }
  const current_is_like = clientSideLikeValue ?? extra_stats?.is_liked
  const isPoliticalEnabled = data?.me?.brand?.settings?.is_political_dashboard_enabled
  const isPoliticalEngageEnabled = data?.me?.brand?.settings?.is_political_engage_enabled
  const isTrending = searchParams.get("trending")

  return (
    <div className="creator-content-modal-for-brand hcenter vcenter flex" onClick={closeModal}>
      <div
        onClick={(e) => _navigate_post(e, "backwards")}
        className={`modal-navigation-buttons nc00-fg nc75-fg-onhover padding-3x cursor-pointer ${data.content_modal_content_index - 1 >= 0 && data?.content_modal_content_array?.length ? "" : "hidden-disabled"}`}
      >
        {LeftArrow}
      </div>
      <div
        className="content-modal-inner closes-other-popups nc00-bg border-radius-dynamic overflow-hidden"
        onClick={_nopropagate}
        tabIndex={0}
      >
        <div className="content-modal-content creator-modal-max-overflow nc100-bg flex hcenter vcenter">
          <MediaSelect platform={content.content.content_platform} content={content.content} showVideo />
        </div>
        <div className="content-modal-description creator-modal-max-overflow flex vertical nc10-border border-left-1 padding-6x">
          <ContentAndCreatorBasicInfo
            content={content}
            onInfluentialContentChange={() =>onInfluentialContentChange(content?.content?.id)}
            latestInfluencerValue={data?.client_only_influencer_modification?.[content?.content?.id]}
            hideInfluencer={isTrending}
          />
          <PostTitleSection className="padding-bottom-4x" content={content} />
          {(isPoliticalEnabled || isPoliticalEngageEnabled) && (
            <PostIssuesAndSentiment content={content} stats={extra_stats} isLoading={extra_stats_loader} />
          )}
          <div className="border-1 nc10-border border-radius-8 margin-top-3x">
            <OriginalPostRef
              content={content}
              tailElement={
                <>
                  {isPoliticalEngageEnabled && content?.content?.ai_issue_summary ? (
                    <div
                      onClick={() => {
                        if (!content?.content?.transcription) {
                          return
                        }
                        setShowTranscription(!showTranscription)
                      }}
                      className={`full flex border-left-1 padding-y-2x nc10-border j-center a-center  ${content?.content?.transcription ? "primary-fg cursor-pointer" : "nc30-fg"} `}
                    >
                      {/* {TranscriptionIndicator} */}
                      <span className="button-small-font margin-x">AI Summary</span>
                      {showTranscription ? ChevronUpMediumFilledMono : ChevronDownMediumFilledMono}
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        if (!content?.content?.transcription) {
                          return
                        }
                        setShowTranscription(!showTranscription)
                      }}
                      className={`full flex border-left-1 padding-y-2x nc10-border j-center a-center  ${content?.content?.transcription ? "primary-fg cursor-pointer" : "nc30-fg"} `}
                    >
                      {/* {TranscriptionIndicator} */}
                      <span className="button-small-font margin-x">Transcript</span>
                      {showTranscription ? ChevronUpMediumFilledMono : ChevronDownMediumFilledMono}
                    </div>
                  )}
                </>
              }
            />
            <hr className="hr nc10-border-important" />
            {showTranscription ? (
              <>
                {isPoliticalEngageEnabled && content?.content?.ai_issue_summary ? (
                  <div className="border-1 nc10-border border-radius-8 nc10-border  body-regular-font padding-5x min-height-248 max-height-248 overflow-auto">
                    {content?.content?.ai_issue_summary}
                  </div>
                ) : (
                  <div className="border-1 nc10-border border-radius-8 nc10-border  body-regular-font padding-5x min-height-248 max-height-248 overflow-auto">
                    {content?.content?.transcription}
                  </div>
                )}
              </>
            ) : (
              <ContentStats content={content} isNpsDisabled={isPoliticalEnabled || isPoliticalEngageEnabled || isTrending} />
            )}
          </div>
          {!isTrending && (
            <>
              <IfNotGuest>
                <div className="creator-content-modal-actions padding-top-4x padding-bottom-2x flex  margin-top-auto">
                  <div className="flex a-center full">
                    <RoundButton
                      icon={LikeContent}
                      onClick={() => _onLikeContent({ is_like: !current_is_like })}
                      className={`min-height-56 margin-right-auto min-width-56 border-1 nc10-border ${likeLoader ? "action-disabled-faded" : ""}  ${content?.content?.content_platform !== "tiktok" ? "nc30-fg-override" : !current_is_like ? ` nc50-fg-override nc00-bg-override` : "primary-fg-override nc00-bg-override"}`}
                    ></RoundButton>

                    {!isPoliticalEngageEnabled && (
                      <div className="position-relative">
                        <ContentModelsActions
                          className="margin-left-3x"
                          title={boardPopup.shown ? "" : "Add to board"}
                          onClick={boardPopup.show}
                        >
                          {loaders["content_to_board"] || extra_stats_loader ? (
                            <Loader />
                          ) : (
                            <>
                              <div className="flex a-center j-center positive-fg">
                                {!isSavedBoard ? BoardSaveLargeOutLineMono : BoardSaveLargeFilledMono}
                              </div>
                            </>
                          )}
                        </ContentModelsActions>
                        {boardPopup.shown ? (
                          <BoardPopup
                            className="top-left-mounted"
                            hasDraft={true}
                            onAddNewClick={saveAddContentDraft}
                            onBoardClick={onBoardClick}
                            onDismiss={() => {
                              boardPopup.hide()
                            }}
                          />
                        ) : null}
                      </div>
                    )}
                    <>
                      {showArchive ? (
                        <ContentModelsActions
                          className="margin-left-3x"
                          title={
                            data?.opened_from === "all_posts"
                              ? "Restore post"
                              : `Seen, archive from ${data?.opened_from === "community_posts" || data?.opened_from === "social_priority_posts" ? "new posts" : "trending posts"}`
                          }
                          onClick={onArchiveClick}
                        >
                          <div className="flex a-center j-center nc75-fg">
                            {true ? ArchiveLargeMonoOutline : ArchiveLargeMonoOutline}
                          </div>
                        </ContentModelsActions>
                      ) : null}
                    </>
                  </div>
                  {((!isPoliticalEngageEnabled) || (isPoliticalEngageEnabled && data?.me?.user_group === 1)) && (
                    <div className="margin-left-3x">
                      <ContentModelsActions
                        title={content_modal_delete_hint[data?.opened_from] ?? "Irrelevant, remove post from discovery"}
                        onClick={onRemoveContent}
                      >
                        <div className="flex a-center j-center negative-fg">{TrashLargeFilledMono}</div>
                      </ContentModelsActions>
                    </div>
                  )}
                </div>
              </IfNotGuest>
              <IfGuest>
                <div className="flex hcenter nc75-fg icon-equal-text margin-top-auto">
                  Unlock the full version to create a collection, start a campaign or exclude this content from analysis.
                </div>
              </IfGuest>
            </>
          )}
        </div>
        <div style={{ maxHeight: "95vh" }} className="border-left-1 nc10-border  ">
          <CommentThread
            contentPayload={content}
            filter={`${data?.me?.brand?.settings?.is_user_tiktok_connection_enabled ? "is_user_comment=true" : ""}`}
            isReadOnly={isTrending}
          />
        </div>
      </div>
      <div
        onClick={(e) => _navigate_post(e, "forward")}
        className={`modal-navigation-buttons button-right nc00-fg nc75-fg-onhover padding-3x cursor-pointer ${data.content_modal_content_index + 1 < data?.content_modal_content_array?.length ? "" : "hidden-disabled"}`}
      >
        {LeftArrow}
      </div>
    </div>
  )
}

function useModalHelper() {
  const [data, dispatch] = useContext(dataContext)
  const show = (item = null, list = [], index = 0, opened_from) => {
    if (!item) return false
    dispatch({
      ecosystem_selected_content: item || list[index],
      content_modal_content_array: list,
      content_modal_content_index: index,
      opened_from: opened_from, // new_posts / trending_posts /
    })
  }
  return show
}

export { ContentModal, useModalHelper, CandidatesNps }
