import { BECampaignList } from "./beSections"
function MyCampaigns({ tab }) {
  return (
    <div className="campaign-list-inner padding-top-6x">
      <BECampaignList title={`Campaigns`} section={`campaign_detail_list`} />
    </div>
  )
}

export { MyCampaigns }
