import { useContext } from "react"
import { useServer } from "../../hooks/useServer"
import { dataContext } from "../datacontext"
import { useLoading } from "../loaderContext"
import {
  _lsd,
  _lsr,
  _lsw,
  cloneObject,
  combineUrlSearchParamString,
  extractQueryParamsString,
} from "../../helpers/utils"
import { useNavigate } from "react-router-dom"
import { apiContext } from "../apicontext"
import { useCommonAPIS } from "./common"
import { useCampaignHelpers } from "../../hooks/helperHooks"
import { useToast } from "../../hooks/useToast"

function useBrand() {
  const { actions } = useLoading()
  const server = useServer()
  const [data, dispatch] = useContext(dataContext)
  const navigate = useNavigate()
  const common = useCommonAPIS()
  const toast = useToast()
  const { setCampaignQueryParam } = useCampaignHelpers()

  const BrandQueryParamHelper = ({ endpoint, skipSubBrand = false, skipOriginalQuery = false }) => {
    const sub_brand_id = data?.sub_brand_id

    const { query, endpoint: _endpoint } = extractQueryParamsString(endpoint)
    let _params = skipOriginalQuery ? {} : Object.fromEntries(new URLSearchParams(query).entries())

    if (!skipSubBrand && sub_brand_id) {
      _params.brand_id = sub_brand_id
    }

    const strParams = new URLSearchParams(_params).toString()
    return strParams ? _endpoint + "?" + strParams : _endpoint
  }

  const me = async () => {
    actions.batchLoading(["me", "brand_me"])
    const brand = await common.me()
    actions.batchNotLoading(["me", "brand_me"])
    if (!brand) return null
    dispatch({ me: brand, role: "brand" })
    return brand
  }

  const create = async (payload = {}) => {
    actions.batchLoading(["user_create", "brand_create"])
    // const brand = await server.post("/Brand/", {brand: payload});
    const brand = await common.patchMe({ role: "BRAND_USER" })
    actions.batchNotLoading(["user_create", "brand_create"])
    if (!brand) return null
    dispatch({ me: brand, role: "brand" })
    return brand
  }

  const update = async (payload = {}) => {
    actions.batchLoading(["user_update", "brand_update"])
    const brand = await server.post("/Brand/update_brand/", payload)
    actions.batchNotLoading(["user_update", "brand_update"])
    if (!brand) return null
    dispatch({ me: brand, role: "brand" })
    return brand
  }

  const addTikapiSession = async (payload = {}) => {
    actions.batchLoading(["tiktok_session_update", "brand_update"])
    const status = await server.post("/Brand/tikapi/", payload)
    if (status) {
      toast("Connecting Tiktok...")
      await me()
    }
    actions.batchNotLoading(["tiktok_session_update", "brand_update"])
  }

  const next = (destination) => {
    // const last_visited_creator = _lsr("last_visited_creator");
    // if(last_visited_creator) _lsd("last_visited_creator")
    // if(last_visited_creator) {
    //     return navigate(`/${last_visited_creator}`)
    // };
    navigate(destination)
  }

  const getGuestToken = async () => {
    actions.batchLoading(["guest_token"])
    const token = await server.get(`/GuestUser/token/`)
    actions.batchNotLoading(["guest_token"])
    if (!token) return null
    // console.log(token);
    sessionStorage.setItem("guest_access_token", token.access)
    dispatch({
      me: { ...token.user, brand: {} },
      role: "brand",
      is_guest: true,
    })
    return token
  }

  const updateGuestInfo = async () => {
    const _draft = JSON.parse(sessionStorage.getItem("gc_form_draft"))
    if (!_draft) return null
    const payload = {
      name: _draft.full_name,
      guest_email: _draft.email_address,
      brand_user: {
        brand: {
          name: _draft.company_name,
        },
      },
    }
    actions.batchLoading(["guest_user_info_update", "project_common"])
    const updated = await server.post("/GuestUser/update_user/", payload)
    actions.batchNotLoading(["guest_user_info_update", "project_common"])
    if (!updated) return null
    return updated
  }

  const clearGuestUser = () => {
    dispatch({
      me: null,
      role: "",
      is_guest: false,
    })
    sessionStorage.removeItem("guest_access_token")
    sessionStorage.removeItem("gc_form_draft")
  }

  async function addBoostingToSelectedContent() {
    const creator = data?.ecosystem_selected_content?.creator?.uuid
    const social_network = data?.ecosystem_selected_content?.content?.content_platform

    const content = data?.ecosystem_selected_content?.content?.uuid

    const campaign = data?.boost_target_campaign?.uuid

    if (!campaign) return null

    const payload = {
      creator,
      social_network,
      content,
      campaign,
    }
    if (data?.boosting_post_dialog_selected_item && data?.boosting_post_dialog_selected_item?.pricing_id) {
      if (data?.boosting_post_dialog_selected_item?.is_brand) {
        payload.brand_pricing = data?.boosting_post_dialog_selected_item?.pricing_id
      } else {
        payload.creator_pricing = data?.boosting_post_dialog_selected_item?.pricing_id
      }

      actions.batchLoading(["boosting_content"])
      const added = await server.post("/BoostContent/", payload)
      actions.batchNotLoading(["boosting_content"])

      return added
    }

    return null
  }

  async function addCreatorsToCampaign(creators, campaign) {
    const payload = creators.map((_cr) => {
      return {
        creator: _cr?.id ? _cr.id : _cr,
        campaign: campaign,
      }
    })
    actions.batchLoading(["creator-to-campaign", "stuff-to-campaign"])
    const temp_payload = payload[0]
    const added = await server.post(`/Campaign/creators/`, payload)
    actions.batchNotLoading(["creator-to-campaign", "stuff-to-campaign"])
    return added
  }

  async function removeCreatorFromCampaign(_creator, campaign) {
    const payload = {
      creator: _creator?.id ? _creator.id : _creator,
      campaign: campaign,
    }
    actions.batchLoading([
      "creator-remove-campaign",
      "remove-from-campaign",
      `remove-creator-${_creator?.id}-from-campaign`,
    ])
    const added = await server.delete("/Campaign/remove_creator/", payload)
    actions.batchNotLoading([
      "creator-remove-campaign",
      "remove-from-campaign",
      `remove-creator-${_creator?.id}-from-campaign`,
    ])
    return added
  }

  async function addContentsToCampaign(contents, campaign) {
    const contentProcessed = contents.reduce(
      (a, b) => {
        return { ...a, [`${b.content_platform}_contents`]: [...a[`${b.content_platform}_contents`], b.id] }
      },
      { instagram_contents: [], tiktok_contents: [], youtube_contents: [] },
    )
    const payload = {
      campaign,
      ...contentProcessed,
    }
    actions.batchLoading(["content-to-campaign", "stuff-to-campaign"])
    const added = await server.post("Campaign/add_content/", payload)
    actions.batchNotLoading(["content-to-campaign", "stuff-to-campaign"])
    return added
  }
  async function addOrRemoveContentsToBoard(contents, boardID, remove = false) {
    const payload = contents.map((x) => ({ content: x.id, board: boardID }))
    actions.batchLoading(["content_to_board", "stuff_to_board"])
    const endpoint = remove ? "/Board/remove_content/" : "/Board/add_content/"
    const added = await server.post(endpoint, payload)
    actions.batchNotLoading(["content_to_board", "stuff_to_board"])
    if (added && !remove) {
      const frontend_only = data?.frontend_only_content_board ?? []
      contents.map((x) => frontend_only.push(x.id))
      dispatch({
        frontend_only_content_board: frontend_only,
      })
    }
    return added
  }

  async function removeContentFromCampaign(_content, campaign) {
    const payload = {
      creator: _content?.uuid ? _content.uuid : _content,
      campaign: campaign,
    }
    actions.batchLoading([
      "creator-remove-campaign",
      "remove-from-campaign",
      `remove-content-${_content.uuid}-from-campaign`,
    ])
    const added = await server.delete("/Campaign/remove_content/", payload)
    actions.batchNotLoading([
      "creator-remove-campaign",
      "remove-from-campaign",
      `remove-content-${_content.uuid}-from-campaign`,
    ])
    return added
  }
  async function addToCreatorCircle(creator) {
    actions.batchLoading(["creator-to-circle", "stuff-to-circle", `creator-${creator.uuid}-to-circle`])
    const added = await server.post("/CreatorCircle/add/", { creator: creator.uuid })
    actions.batchNotLoading(["creator-to-circle", "stuff-to-circle", `creator-${creator.uuid}-to-circle`])
    return added
  }
  async function removeFromCreatorCircle(creator) {
    actions.batchLoading(["remove-from-creator-circle", `remove-creator-${creator.uuid}-from-circle`])
    const removed = await server.delete("/CreatorCircle/remove/", { creator: creator.uuid })
    actions.batchNotLoading(["remove-from-creator-circle", `remove-creator-${creator.uuid}-from-circle`])
    return removed
  }
  async function removeFromEcosystem(creator) {
    actions.batchLoading(["remove-from-ecosystem", `remove-creator-${creator.uuid}-from-ecosystem`])
    const removed = await server.delete("/CreatorEcoSystem/remove/", { creator: creator.id })
    actions.batchNotLoading(["remove-from-ecosystem", `remove-creator-${creator.uuid}-from-ecosystem`])
    hardRefreshDashboardSections(["all_circle_creators"])
    return removed
  }

  async function createNewCampaign(name, kind) {
    actions.batchLoading(["campaign_create"])
    const created = await server.post("/Campaign/", { name })
    actions.batchNotLoading(["campaign_create"])
    if (created && data.pending_campaign_add) {
      if (data.pending_campaign_add.creators)
        await addCreatorsToCampaign(data?.pending_campaign_add.creators, created.id)
      else if (data?.pending_campaign_add?.contents)
        await addContentsToCampaign(data?.pending_campaign_add.contents, created.id)
    }
    // setCampaignQueryParam(created)
    navigate("/discover-creator/campaigns/" + created?.id)
    dispatch({
      pending_campaign_add: null,
      newly_added_campaign: created,
      selected_campaign_for_viewing: created,
    })
    return created
  }
  async function createOrUpdateNewBoard(name, hashtags = [], id, type) {
    actions.batchLoading(["board_create"])
    const additional_payload = {}
    if (hashtags?.length || type == "tracking") {
      additional_payload.board_type = 2
    }
    if (!data?.ecosystem_selected_content?.content && data?.board_category) {
      additional_payload.category = data?.board_category == "creator" ? 2 : 1
    }
    let res
    if (id) {
      res = await server.patch(`/Board/${id}/`, { name, hashtags, ...additional_payload })
    } else {
      res = await server.post("/Board/", { name, hashtags, ...additional_payload })
    }
    const additional_dispatch = {}
    if (data?.add_board_from_creator_ecosystem?.length || data?.add_board_from_unregistered_creator?.length) {
      const _res = await brandPostRequestHelper({
        section: "add_creators_to_board",
        payload: {
          board: res?.id,
          onboarded_creators: data?.add_board_from_creator_ecosystem ?? [],
          other_creators: data?.add_board_from_unregistered_creator ?? [],
          for_brand: data?.sub_brand_id ? Number(data?.sub_brand_id) : data?.me?.brand?.id,
        },
      })
    }

    actions.batchNotLoading(["board_create"])
    if (res && data.pending_board_add) {
      if (data.pending_board_add.contents) await addOrRemoveContentsToBoard(data.pending_board_add.contents, res.id)
    }
    dispatch({
      pending_board_add: null,
      newly_added_board: res,
      add_board_from_creator_ecosystem: [],
      add_board_from_unregistered_creator: [],

      // selected_board_for_viewing : created
    })
    return res
  }
  async function scheduleFetchSearch(query) {
    const terms = query.split(",")
    if (!terms.length) return null
    const updatedTerms = terms.map((_trm) => _trm.replace(/^\#+/, ""))
    actions.batchLoading([`brand-search-by-tag`])
    const _data = await server.post(`/SearchScrape/`, { search_term: updatedTerms })
    actions.batchNotLoading([`brand-search-by-tag`])
    dispatch({ search_by_tag_session: null })
    if (_data) setTimeout(() => dispatch({ search_by_tag_session: _data }), 10)
    return _data
  }
  async function getDashboardcontent({
    endpoint,
    section,
    filter = "",
    skipDiscoveryPlatform = false,
    skipSubBrand = false,
    ignore,
  }) {
    const page = data[`bd_sec_${section}_page`]
    const pagePart = page ? `page=${page}` : ""
    const _platf = data?.discovery_section_platform ? data?.discovery_section_platform : "tiktok"
    const content_form = data?.discovery_section_content_form ?? "VIDEO"
    const sub_brand_id = data?.sub_brand_id
    actions.batchLoading([`brand-dashboard-section`, `bd-section-${section}`])
    const { query, endpoint: _endpoint } = extractQueryParamsString(endpoint)
    const filtObject = {
      ...Object.fromEntries(new URLSearchParams(query).entries()),
      ...Object.fromEntries(new URLSearchParams(filter ?? "").entries()),
    }
    if (page) {
      filtObject.page = page
    }
    if (data?.discovery_section_content_form && _platf === "instagram") {
      filtObject.content_form = content_form
    }
    if (sub_brand_id && !skipSubBrand) {
      filtObject.brand_id = sub_brand_id
    }
    if (!skipDiscoveryPlatform) {
      filtObject.platform = _platf
    }
    const e = _endpoint + (Object.keys(filtObject).length ? `?${new URLSearchParams(filtObject).toString()}` : "")
    // const _data = await server.get(`${endpoint}${fullFilter}`);
    const _data = await server.get(e)

    if (ignore) {
      console.log(`ignoring section: ${section}`)
      return
    }
    actions.batchNotLoading([`brand-dashboard-section`, `bd-section-${section}`])
    if (!data) return null

    const listData =
      page && page > 1 && data[`bd_sec_${section}`]?.length
        ? [...data[`bd_sec_${section}`], ..._data?.data]
        : _data?.data
    const payload = _data?.pagination
      ? { [`bd_sec_${section}`]: listData, [`bd_sec_${section}_raw`]: _data }
      : { [`bd_sec_${section}`]: _data }
    dispatch({
      ...payload,
    })
    return _data?.data ? _data.data : _data
  }

  const uploadBrief = async () => {
    actions.batchLoading(["brief"])
    // bd_sec_campaign_creators
    const campaign = data?.bd_sec_campaign_summary?.id
    const payload = { ...data?.draft_campaign_brief_form, campaign }

    const brief = await server.post("/Campaign/send_brief/", payload)
    actions.batchNotLoading(["brief"])
    if (!brief) return null

    return brief
  }

  const addBrief = async (payload_info) => {
    actions.batchLoading(["brief"])
    // bd_sec_campaign_creators
    const campaign = data?.bd_sec_campaign_summary?.id
    const payload = { ...payload_info, campaign }

    const brief = await server.post("/Campaign/add_brief/", payload)
    actions.batchNotLoading(["brief"])
    if (!brief) return null

    return brief
  }

  function dashboardSection({ section, filter = "", uniqueSectionPostfix = "", ignore = false }) {
    const _section = section + uniqueSectionPostfix
    switch (section) {
      case "all_ecosystem_creators":
        return getDashboardcontent({ endpoint: "/CreatorEcoSystem/all_creators", section: _section, filter, ignore })
      case "all_circle_creators":
        return getDashboardcontent({
          endpoint: "/CreatorEcoSystem/all_creators",
          section: _section,
          filter: "is_included_in_creator_circle=true",
          ignore,
        })
      case "all_creators_by_brand":
        return getDashboardcontent({ endpoint: "/SocialAccount/", section: _section, filter, ignore })
      case "top_creators_by_brand":
        return getDashboardcontent({ endpoint: "/SocialAccount/", section: _section, filter: filter, ignore })
      case "new_creators_by_brand":
        return getDashboardcontent({ endpoint: "/SocialAccount/", section: _section, filter: filter, ignore })
      case "influencers_by_brand":
        return getDashboardcontent({ endpoint: "/SocialAccount/", section: _section, filter: filter, ignore })
      case "discover_all_creator":
        return getDashboardcontent({
          endpoint: "/Discovery/invited_creators/",
          section: _section,
          filter: filter,
          ignore,
        })
      case "invited_ecosystem_creators":
        return getDashboardcontent({
          endpoint: "/Discovery/invited_creators/",
          section: _section,
          filter: filter,
          ignore,
        })
      case "trending_posts":
        console.log("some one calling treind")
        return getDashboardcontent({ endpoint: "/Content/", section: _section, filter: filter, ignore })
      case "trending_posts_influencers":
        return getDashboardcontent({
          endpoint: "/Discovery/trending_posts",
          section: _section,
          filter: "content_filter=influencers",
          ignore,
        })
      case "trending_posts_public":
        return getDashboardcontent({
          endpoint: "/Discovery/trending_posts",
          section: _section,
          filter: "content_filter=public",
          ignore,
        })
      // case "new_posts":
      //     return getDashboardcontent("/Discovery/new_posts", section, filter);
      case "community_posts":
        return getDashboardcontent({ endpoint: "/Content/", section: _section, filter: filter, ignore })
      case "negative_nps_posts":
        return getDashboardcontent({ endpoint: "/Content/", section: _section, filter: filter })
      case "community_posts_comment_only":
        return getDashboardcontent({ endpoint: "/Content/", section: _section, filter, ignore })
      case "mass_engage_all":
        return getDashboardcontent({
          endpoint: "/Content/engage_contents/",
          section: _section,
          filter,
          skipDiscoveryPlatform: false,
          skipSubBrand: false,
          ignore,
        })
      case "mass_engage_answered":
        return getDashboardcontent({
          endpoint: "/Content/engage_contents/",
          section: _section,
          filter,
          skipDiscoveryPlatform: false,
          skipSubBrand: false,
          ignore,
        })
      case "mass_engage_open":
        return getDashboardcontent({
          endpoint: "/Content/engage_contents/",
          section: _section,
          filter,
          skipDiscoveryPlatform: false,
          skipSubBrand: false,
          ignore,
        })
      case "mass_engage_owned":
        return getDashboardcontent({
          endpoint: "/Content/engage_contents/",
          section: _section,
          filter,
          skipDiscoveryPlatform: false,
          skipSubBrand: false,
          ignore,
        })
      case "mass_engage_new":
        return getDashboardcontent({
          endpoint: "/Content/engage_contents/",
          section: _section,
          filter,
          skipDiscoveryPlatform: false,
          skipSubBrand: false,
          ignore,
        })
      case "mass_engage_trending":
        return getDashboardcontent({
          endpoint: "/Content/engage_contents/",
          section: _section,
          filter,
          skipDiscoveryPlatform: false,
          skipSubBrand: false,
          ignore,
        })
      case "mass_engage_negative":
        return getDashboardcontent({
          endpoint: "/Content/engage_contents/",
          section: _section,
          filter,
          skipDiscoveryPlatform: false,
          skipSubBrand: false,
          ignore,
        })
      case "mass_engage_archive":
        return getDashboardcontent({
          endpoint: "/Content/engage_contents/",
          section: _section,
          filter,
          skipDiscoveryPlatform: false,
          skipSubBrand: false,
          ignore,
        })
      // comments stats
      case "comment_stats_posted":
        return getDashboardcontent({
          endpoint: "/Comment/posted_comment_performance_metrics",
          section: _section,
          skipDiscoveryPlatform: true,
          filter,
          ignore,
        })
      case "comment_stats_replies":
        return getDashboardcontent({
          endpoint: "/Comment/posted_comment_replies_performance_metrics",
          section: _section,
          skipDiscoveryPlatform: true,
          filter,
          ignore,
        })
      case "comment_stats_likes":
        return getDashboardcontent({
          endpoint: "/Comment/posted_comment_likes_performance_metrics",
          section: _section,
          skipDiscoveryPlatform: true,
          filter,
          ignore,
        })
      case "comment_stats_target_vs_progress":
        return getDashboardcontent({
          endpoint: "/Comment/target_vs_posted_analytics",
          section: _section,
          skipDiscoveryPlatform: true,
          filter,
          ignore,
        })
      // political stats
      case "political_stats":
        return getDashboardcontent({
          endpoint: "/BrandAnalytics/aggregate/nps",
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          skipSubBrand: true,
          ignore,
        })
      case "trending_issues":
        return getDashboardcontent({
          endpoint: "/BrandAnalytics/aggregate/count_and_engagement",
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          skipSubBrand: true,
          ignore,
        })
      case "candidates_comparative_attention":
        return getDashboardcontent({
          endpoint: "/BrandAnalytics/rolling_avg/nps_and_engagement",
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          skipSubBrand: true,
          ignore,
        })
      case "engagement_per_issue":
        return getDashboardcontent({
          endpoint: "/BrandAnalytics/political/topic/analytics",
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          skipSubBrand: true,
          ignore,
        })

      // stats
      case "key_performance_metrics":
        return getDashboardcontent({
          endpoint: "/BrandAnalytics/key_performance_metrics/",
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          skipSubBrand: true,
          ignore,
        })
        
      case "social_priority_posts":
        return getDashboardcontent({ endpoint: "/Content/", section: _section, filter: filter, ignore })
      case "social_priority_posts_influencers":
        return getDashboardcontent({
          endpoint: "/Discovery/social_priority_posts/",
          section: _section,
          filter: "content_filter=influencers",
          ignore,
        })
      case "social_priority_posts_public":
        return getDashboardcontent({
          endpoint: "/Discovery/social_priority_posts/",
          section: _section,
          filter: "content_filter=public",
          ignore,
        })
      case "all_posts":
        return getDashboardcontent({ endpoint: "/Content/", section: _section, filter, ignore })
      case "data_point_specific_content":
        return getDashboardcontent({ endpoint: "/Content/", section: _section, filter, ignore })
      case "discovery_counts":
        return getDashboardcontent({
          endpoint: "/Content/content_creator_count/",
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          ignore,
        })
      case "campaign_detail_list":
        return getDashboardcontent({
          endpoint: "/Campaign/",
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          ignore,
        })
      case "board_list":
        return getDashboardcontent({
          endpoint: "/Board/",
          section: _section,
          filter: "board_type=1",
          skipDiscoveryPlatform: true,
          skipSubBrand: true,
          ignore,
        })
      case "board_list_tracking":
        return getDashboardcontent({
          endpoint: "/Board/",
          section: _section,
          filter: "board_type=2",
          skipDiscoveryPlatform: true,
          skipSubBrand: true,
          ignore,
        })
      case "board_list_content":
        return getDashboardcontent({
          endpoint: "/Board/",
          section: _section,
          filter: "board_type=1&category=1",
          skipDiscoveryPlatform: true,
          skipSubBrand: true,
          ignore,
        })
      case "board_list_creator":
        return getDashboardcontent({
          endpoint: "/Board/",
          section: _section,
          filter: "board_type=1&category=2",
          skipDiscoveryPlatform: true,
          skipSubBrand: true,
          ignore,
        })
      case "board_list_all":
        return getDashboardcontent({
          endpoint: "/Board/",
          section: _section,
          filter: filter,
          skipDiscoveryPlatform: true,
          skipSubBrand: true,
          ignore,
        })
      case "selected_board_creator":
        if (data?.selected_board_for_viewing)
          return getDashboardcontent({
            endpoint: `/Board/${data?.selected_board_for_viewing?.id}/creators`,
            section: _section,
            filter: filter,
            skipDiscoveryPlatform: true,
            skipSubBrand: true,
            ignore,
          })
        else break
      case "selected_board_creator_email":
        if (data?.selected_board_for_viewing)
          return getDashboardcontent({
            endpoint: `/Board/${data?.selected_board_for_viewing?.id}/creators/emails`,
            section: _section,
            filter: "has_email=true",
            skipDiscoveryPlatform: true,
            skipSubBrand: true,
            ignore,
          })
        else break
      case "selected_board_creator_no_email":
        if (data?.selected_board_for_viewing)
          return getDashboardcontent({
            endpoint: `/Board/${data?.selected_board_for_viewing?.id}/creators/emails`,
            section: _section,
            filter: "has_email=false",
            skipDiscoveryPlatform: true,
            skipSubBrand: true,
            ignore,
          })
        else break
      case "selected_board_added_creator":
        if (data?.selected_board_for_viewing)
          return getDashboardcontent({
            endpoint: `/Board/${data?.selected_board_for_viewing?.id}/added_creators/`,
            section: _section,
            filter: filter,
            skipDiscoveryPlatform: true,
            skipSubBrand: true,
            ignore,
          })
        else break
      case "campaign_list_compact":
        return getDashboardcontent({ endpoint: "/Campaign/", section: _section, filter, ignore })
      case "creator_circle_stats":
        return getDashboardcontent({ endpoint: "/CreatorEcoSystem/stats/", section: _section, filter, ignore })
      case "creator_circle_creator_stats_list":
        return getDashboardcontent({ endpoint: "CreatorEcoSystem/creator_details", section: _section, filter, ignore })
      case "creator_circle_summary":
        return getDashboardcontent({ endpoint: "/CreatorCircle/details", section: _section, filter: filter, ignore })
      case "creator_circle_contents":
        return getDashboardcontent({ endpoint: "/CreatorCircle/contents", section: _section, filter, ignore })
      case "creator_circle_creators":
        return getDashboardcontent({ endpoint: "/CreatorCircle/creators", section: _section, filter, ignore })
      case "creator_circle_creator_emails":
        return getDashboardcontent({ endpoint: "/CreatorCircle/creator_emails", section: _section, filter, ignore })
      case "creator_public_not_zelf_profile_content":
        return getDashboardcontent({
          endpoint: "/Content/",
          section: _section,
          filter: new URLSearchParams({
            brand: data?.sub_brand_id ?? data?.me?.brand?.id,
            account: data?.discover_selected_creator_id,
            sort_by: "timestamp",
          }).toString(),
          skipDiscoveryPlatform: true,
          ignore,
        })
      case "content_stats":
        return getDashboardcontent({ endpoint: "/Content/content_stats/", section: _section, filter, ignore })
      case "creator_stats":
        return getDashboardcontent({ endpoint: "/SocialAccount/stats/", section: _section, filter, ignore })
      case "creator_public_not_zelf_profile_content_stats":
        return getDashboardcontent({
          endpoint: "/Content/content_stats",
          section: _section,
          filter: new URLSearchParams({
            brand: data?.sub_brand_id ?? data?.me?.brand?.id,
            account: data?.discover_selected_creator_id,
          }).toString(),
          skipDiscoveryPlatform: true,
          ignore,
        })
      case "campaign_counts":
        return getDashboardcontent({ endpoint: "/Campaign/campaign_count/", section: _section, ignore })
      case "campaign_contents":
        if (data.selected_campaign_for_viewing)
          return getDashboardcontent({
            endpoint: `/Campaign/${data?.selected_campaign_for_viewing?.id}/contents/`,
            section: _section,
            filter: filter,
            ignore,
          })
        else break
      case "campaign_creators":
        if (data.selected_campaign_for_viewing)
          return getDashboardcontent({
            endpoint: `/Campaign/${data?.selected_campaign_for_viewing?.id}/creators/`,
            section: _section,
            filter,
            skipDiscoveryPlatform: true,
            ignore,
          })
      case "campaign_creators_stats":
        if (data.selected_campaign_for_viewing)
          return getDashboardcontent({
            endpoint: `/Campaign/${data?.selected_campaign_for_viewing?.id}/creator_details/`,
            section: _section,
            filter,
            skipDiscoveryPlatform: true,
            ignore,
          })
        else break
      case "campaign_summary":
        if (data.selected_campaign_for_viewing)
          return getDashboardcontent({
            endpoint: `/Campaign/${data?.selected_campaign_for_viewing?.id}/`,
            section: _section,
            filter: filter,
            ignore,
          })
        else break
      case "campaign_creator_details":
        if (data.selected_campaign_for_viewing)
          return getDashboardcontent({
            endpoint: `/Campaign/${data?.selected_campaign_for_viewing?.id}/creator_details/`,
            section: _section,
            ignore,
          })
        else break
      case "search_hashtag_content_all":
        return getDashboardcontent({
          endpoint: `/SearchScrape/${data?.search_by_tag_session?.id}/all_posts/`,
          section: _section,
          ignore,
        })
      case "search_hashtag_content_new":
        return getDashboardcontent({
          endpoint: `/SearchScrape/${data?.search_by_tag_session?.id}/new_posts/`,
          section: _section,
          ignore,
        })
      case "search_hashtag_content_trending":
        return getDashboardcontent({
          endpoint: `/SearchScrape/${data?.search_by_tag_session?.id}/trending_posts/`,
          section: _section,
          ignore,
        })
      case "search_hashtag_creator":
        return getDashboardcontent({
          endpoint: `/SearchScrape/${data?.search_by_tag_session?.id}/creators/`,
          section: _section,
          ignore,
        })

      // analytics
      case "discovery_analytics_all_posts":
        return getDashboardcontent({
          endpoint: `/Content/content_stats`,
          section: _section,
          filter: "is_influencer_content=false&timeframe=30&is_owned_by_brand=false&mention_type__not=2",
          ignore,
        })
      case "discovery_analytics_all_owned":
        return getDashboardcontent({
          endpoint: `/Content/content_stats`,
          section: _section,
          filter: "is_influencer_content=false&timeframe=30&is_owned_by_brand=true&mention_type__not=2",
          ignore,
        })
      // case "discovery_analytics_new_posts":
      //     return getDashboardcontent(`/Discovery/content_analytics/`, section, "type=new_posts");
      case "discovery_analytics_trending_posts":
        return getDashboardcontent({
          endpoint: `/Discovery/content_analytics/`,
          section: _section,
          filter: "type=trending_posts",
          ignore,
        })
      case "discovery_analytics_trending_posts_public":
        return getDashboardcontent({
          endpoint: `/Discovery/content_analytics/`,
          section: _section,
          filter: "type=trending_posts_public",
          ignore,
        })
      case "discovery_analytics_trending_posts_influencers":
        return getDashboardcontent({
          endpoint: `/Discovery/content_analytics/`,
          section: _section,
          filter: "type=trending_posts_influencers",
          ignore,
        })
      case "discovery_analytics_community_posts_public":
        return getDashboardcontent({
          endpoint: `/Discovery/content_analytics/`,
          section: _section,
          filter: "type=community_posts_public",
          ignore,
        })
      case "discovery_analytics_community_posts_influencers":
        return getDashboardcontent({
          endpoint: `/Discovery/content_analytics/`,
          section: _section,
          filter: "type=community_posts_influencers",
          ignore,
        })
      case "discovery_analytics_social_priority_posts_public":
        return getDashboardcontent({
          endpoint: `/Discovery/content_analytics/`,
          section: _section,
          filter: "type=social_priority_posts_public",
          ignore,
        })
      case "discovery_analytics_social_priority_posts_influencers":
        return getDashboardcontent({
          endpoint: `/Discovery/content_analytics/`,
          section: _section,
          filter: "type=social_priority_posts_influencers",
          ignore,
        })
      case "discovery_analytics_all_posts_influencers":
        return getDashboardcontent({
          endpoint: `/Content/content_stats`,
          section: _section,
          filter: "is_influencer_content=true&timeframe=30&mention_type__not=2",
          ignore,
        })

      // posts
      case "discovery_analytics_owned":
        return getDashboardcontent({
          endpoint: `/Content/`,
          section: _section,
          filter: "is_owned_by_brand=true&timeframe=30",
          ignore,
        })
      case "discovery_analytics_influencers":
        return getDashboardcontent({
          endpoint: `/Content/`,
          section: _section,
          filter: "is_influencer_content=true&timeframe=30&mention_type__not=2",
          ignore,
        })
      case "discovery_analytics_public":
        return getDashboardcontent({
          endpoint: `/Content/`,
          section: _section,
          filter: "is_owned_by_brand=false&is_influencer_content=false&timeframe=30&mention_type__not=2",
          ignore,
        })
      case "discover_selected_creator":
        return getDashboardcontent({
          endpoint: `/SocialAccount/${data?.discover_selected_creator_id}`,
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          ignore,
        })
      case "content_modal_stats_details":
        if (data?.ecosystem_selected_content?.content?.id) {
          return getDashboardcontent({
            endpoint: `/Content/${data?.ecosystem_selected_content?.content?.id}/details/`,
            section: _section,
            filter,
            ignore,
          })
        }
        return
      case "content_modal_comments":
        if (uniqueSectionPostfix) {
          return getDashboardcontent({
            endpoint: `/ContentComment/?content_id=${uniqueSectionPostfix}&is_brand_comment=true`,
            section: _section,
            filter,
            ignore,
          })
        }
        return

      case "priority_posts":
        return getDashboardcontent({
          endpoint: `/Content/priority_content/?brand_id=${data?.sub_brand_id}`,
          section: _section,
          filter,
          ignore,
        })

      case "related_hashtag_analytics":
        return getDashboardcontent({
          endpoint: `/Content/hashtag_analytics`,
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          skipSubBrand: false,
          ignore,
        })
      case "hashtag_analytics":
        return getDashboardcontent({
          endpoint: `/Content/hashtag_analytics`,
          section: _section,
          filter: "is_brand_tags=true",
          skipDiscoveryPlatform: true,
          skipSubBrand: false,
          ignore,
        })
      case "brand_tags":
        return getDashboardcontent({
          endpoint: `/BrandTags/`,
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          ignore,
        })
      case "send_admin_email":
        return getDashboardcontent({
          endpoint: `/Brand/send_admin_email`,
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          ignore,
        })
      case "email_templates":
        return getDashboardcontent({
          endpoint: "/EmailTemplate/",
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          skipSubBrand: true,
          ignore,
        })
      case "get_ai_generated_comment_list":
        return getDashboardcontent({
          endpoint: `/AIGeneratedComments/?content_id=${uniqueSectionPostfix}`,
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          skipSubBrand: false,
          ignore,
        })
      case "get_single_ai_generated_comment":
        return getDashboardcontent({
          endpoint: `/AIGeneratedComments/${data?.ai_comment_pull_set?.[uniqueSectionPostfix]?.id}/`,
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          skipSubBrand: false,
          ignore,
        })
      case "get_single_ai_generated_reply":
        return getDashboardcontent({
          endpoint: `/AIGeneratedComments/${uniqueSectionPostfix}/`,
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          skipSubBrand: false,
          ignore,
        })
      case "mass_get_content_comments":
        return getDashboardcontent({
          endpoint: `/ContentComment/?content_id=${uniqueSectionPostfix}&is_brand_comment=false`,
          section: _section,
          filter,
          skipDiscoveryPlatform: true,
          skipSubBrand: true,
          ignore,
        })
      case "get_brand_comment_replied_content":
        return getDashboardcontent({
          endpoint: "/Content/engage_contents/",
          section: _section,
          filter: "has_posted_comment=true&has_brand_comment_replies=true",
          skipDiscoveryPlatform: true,
        })
      case "get_brand_comment_replied_comments":
        return getDashboardcontent({
          endpoint: "/ContentComment/",
          section: _section,
          filter: filter,
          skipDiscoveryPlatform: true,
        })
      default:
        return null
    }
  }

  const resetSection = (section) => {
    dispatch({
      [`bd_sec_${section}_page`]: null,
      ["bd_sec_" + section]: null,
      ["bd_sec_" + section + "_raw"]: null,
    })
  }
  const hardRefreshDashboardSections = ({ sectionsArray = [] }) => {
    for (let _section of sectionsArray) {
      resetSection(_section)
      dashboardSection({ section: _section })
    }
  }

  const updateManyObjectTypeInDataContent = common.updateManyObjectTypeInDataContent

  const sendInviteForCampaign = async ({ payload = [] }) => {
    actions.batchLoading(["sending_invite"])
    // bd_sec_campaign_creators

    const brief = await server.post("/Campaign/send_invite/", payload)
    actions.batchNotLoading(["sending_invite"])
    if (!brief) return null

    return brief
  }

  const removeOrUndoContent = async ({ id, undo = false }) => {
    let endpoint = `Content/${id}/ContentBrand/`
    endpoint = BrandQueryParamHelper({ endpoint })
    actions.batchLoading(["remove_or_undo_content"])
    // Undo = True, means the content is restored and is again active
    // Undo = False, means the content is removed from the dashboard
    const content_state = await server.patch(endpoint, { is_active_on_zelf: undo })
    actions.batchNotLoading(["remove_or_undo_content"])
    return content_state
  }

  const updateContentIssueAndSentiment = async (payload) => {
    let endpoint = `Content/political_nps/`
    endpoint = BrandQueryParamHelper({ endpoint })
    actions.batchLoading(["update_content_issue_sentiment"])

    // add delay to call below api call
    await new Promise((resolve) => setTimeout(resolve, 1000))

    const content_state = await server.post(endpoint, payload)
    actions.batchNotLoading(["update_content_issue_sentiment"])
    return content_state
  }

  const changeArchiveState = async ({ id, ...rest }) => {
    const endpoint = BrandQueryParamHelper({ endpoint: `/Content/${id}/ContentBrand/` })
    actions.batchLoading(["remove_or_undo_content"])
    const content_state = await server.put(endpoint, rest)
    actions.batchNotLoading(["remove_or_undo_content"])
    return content_state
  }

  const _brandPostHelper = ({
    endpoint,
    payload,
    section = "",
    skipLoading,
    requestType = "post",
    skipSubBrand = false,
    uniqueSectionPostfix = "",
  }) => {
    return common.postRequestHelper({
      endpoint: BrandQueryParamHelper({ endpoint, skipSubBrand }),
      section: section + uniqueSectionPostfix,
      payload,
      skipLoading,
      loaders: ["bd-section-" + section + uniqueSectionPostfix],
      dataContextPrefix: "bd_sec_",
      requestType: requestType,
    })
  }
  const brandPostRequestHelper = ({ section = "", payload, uniqueSectionPostfix = "" }) => {
    switch (section) {
      case "update_single_campaign":
        if (!uniqueSectionPostfix) {
          return console.error("No campaign id found")
        }
        return _brandPostHelper({
          endpoint: `/Campaign/${uniqueSectionPostfix}/`,
          section,
          payload,
          uniqueSectionPostfix,
          requestType: "patch",
        })
      case "change_creator_payout_campaign":
        return _brandPostHelper({ endpoint: "/Campaign/creators/", section, payload, uniqueSectionPostfix })
      case "add_content_to_campaign":
        return _brandPostHelper({ endpoint: "/Campaign/contracts/", section, payload, uniqueSectionPostfix })
      case "archive_creator":
        return _brandPostHelper({ endpoint: `/SocialAccount/archive/`, section, payload, uniqueSectionPostfix })
      case "add_creators_to_board":
        return _brandPostHelper({
          endpoint: `/Board/add_creator/`,
          section,
          payload,
          skipSubBrand: true,
          uniqueSectionPostfix,
        })
      case "remove_creators_from_board":
        return _brandPostHelper({ endpoint: `/Board/remove_creator/`, section, payload, uniqueSectionPostfix })
      case "add_comment_content":
        return _brandPostHelper({ endpoint: `/BrandComment/`, section, payload, uniqueSectionPostfix })
      case "like_content":
        return _brandPostHelper({ endpoint: `/Content/like/`, section, payload, uniqueSectionPostfix })
      case "unlike_content":
        return _brandPostHelper({ endpoint: `/Content/unlike/`, section, payload, uniqueSectionPostfix })
      case "update_brand_inclution_exluclution":
        return _brandPostHelper({
          endpoint: `/BrandTags/${data?.sub_brand_id ? data?.sub_brand_id : data?.me?.brand?.id}/inclusion_exclusion/`,
          section,
          payload,
          skipSubBrand: true,
          uniqueSectionPostfix,
        })
      case "delete_subbrand":
        return _brandPostHelper({
          endpoint: `/Brand/${data?.sub_brand_id}/`,
          section,
          payload,
          requestType: "deleteReq",
          skipSubBrand: true,
          uniqueSectionPostfix,
        })
      case "send_email":
        return _brandPostHelper({ endpoint: `/Mail/`, section, payload, skipSubBrand: true, uniqueSectionPostfix })
      case "update_content_brand":
        return _brandPostHelper({
          endpoint: data?.sub_brand_id
            ? `/Content/${data?.ecosystem_selected_content?.content?.id}/ContentBrand/?brand_id=${data?.sub_brand_id}`
            : `/Content/${data?.ecosystem_selected_content?.content?.id}/ContentBrand/`,
          section,
          payload,
          requestType: "put",
          uniqueSectionPostfix,
        })
      case "update_content_brand_unique":
        return _brandPostHelper({
          endpoint: `Content/${uniqueSectionPostfix}/ContentBrand/`,
          section,
          payload,
          requestType: "put",
          uniqueSectionPostfix,
        })
      case "ai_generated_comment":
        return _brandPostHelper({
          endpoint: `/AIGeneratedComments/`,
          section,
          payload,
          skipSubBrand: false,
          uniqueSectionPostfix,
        })
      case "mark_as_not_interesting":
        return _brandPostHelper({
          endpoint: `/CreatorBrand/`,
          section,
          payload,
          skipSubBrand: false,
          uniqueSectionPostfix,
        })
      case "like_a_comment":
        return _brandPostHelper({
          endpoint: `/ContentComment/like/`,
          section,
          payload,
          skipSubBrand: false,
          uniqueSectionPostfix,
        })
      case "unlike_a_comment":
        return _brandPostHelper({
          endpoint: `/ContentComment/unlike/`,
          section,
          payload,
          skipSubBrand: false,
          uniqueSectionPostfix,
        })
      case "create_brand_email_template":
        return _brandPostHelper({
          endpoint: `/EmailTemplate/`,
          section,
          payload,
          skipSubBrand: false,
          uniqueSectionPostfix,
        })
      case "update_brand_email_template":
        return _brandPostHelper({
          endpoint: `/EmailTemplate/${uniqueSectionPostfix}/`,
          section,
          payload,
          skipSubBrand: false,
          uniqueSectionPostfix,
          requestType: "patch",
        })
      default:
        return null
    }
  }

  const requestGuestToken = async (brand_id) => {
    actions.batchLoading(["guest_token_request"])
    const guestAccess = await server.post("/GuestUser/token/", { brand: brand_id })
    actions.batchLoading(["guest_token_request"])
    if (guestAccess) _lsw("guest_access_token", guestAccess?.access)
    return guestAccess
  }

  return {
    me,
    create,
    update,
    next,
    addTikapiSession,
    getGuestToken,
    updateGuestInfo,
    clearGuestUser,
    dashboardSection,
    addCreatorsToCampaign,
    createNewCampaign,
    addContentsToCampaign,
    addToCreatorCircle,
    removeFromCreatorCircle,
    removeCreatorFromCampaign,
    removeContentFromCampaign,
    addBoostingToSelectedContent,
    removeFromEcosystem,
    scheduleFetchSearch,
    uploadBrief,
    addBrief,
    updateManyObjectTypeInDataContent,
    sendInviteForCampaign,
    removeOrUndoContent,
    updateContentIssueAndSentiment,
    hardRefreshDashboardSections,
    resetSection,
    createOrUpdateNewBoard,
    addOrRemoveContentsToBoard,
    changeArchiveState,
    requestGuestToken,
    brandPostRequestHelper,
  }
}

export { useBrand }
