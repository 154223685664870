import React, { useState, useContext, useEffect } from "react"

import { Button } from "../button"
import { OnboardingCreatorContainer } from "../onboadingCommon"
import { BreadCrumbs } from "../breadCrumbs"
import { Select } from "../select"
import { Input } from "../input"
import { PaypalLargeFilledMono, ChevronBackwardLargeOutlinedMono, CheckLargeFilledMono, BanSolid } from "../Icons"
import { apiContext } from "../../contexts/apicontext"
import { dataContext } from "../../contexts/datacontext"
import countryData from "../../assets/country-states.json"
import { useNavigate } from "react-router-dom"
import { useSocial } from "../../hooks/useSocial"
import { _lsd, _lsw, scrollToTop, makeUSPhoneNumberDefault, _lsr } from "../../helpers/utils"
import { useLoading } from "../../contexts/loaderContext"
import { Loader } from "../loaders"
import StripeIcon from "./../../assets/stripe-icon.png"

import { useStripe } from "@stripe/react-stripe-js"
import { useCRBoundData, useCRPostData } from "../../hooks/useBoundData"
import { WrappedInLoader } from "../dumbs"
import { useToast } from "../../hooks/useToast"

function AfterButton() {
  return <div className="primary-fg">{CheckLargeFilledMono}</div>
}

const DescriptionPage = ({ onContinueClick }) => {
  const [data] = useContext(dataContext)
  return (
    <>
      <div className=" margin-bottom-2x onboarding-header-mobile-spacing">
        <div className="onboarding-steps-number ">3</div>
      </div>
      <div className="max-width-526 nc75-fg">
        <h2 className="headline-large max-width-328 txt-center  nc100-fg margin-x-auto margin-bottom-5x">
          Personal & payment details
        </h2>
        <p className="body-regular-font margin-bottom-10x max-width-495 nc50-fg txt-center">
          Give your profile a headline and a short bio. Provide your address so that{" "}
          {data?.brand_ecosystem_brand?.name ?? "Brands"} can send you products to try, and connect your payment account
          for secure and swift payout of earnings.
        </p>
      </div>
      <Button onClick={onContinueClick} className="max-width-495 margin-bottom-3x" fluid size="large">
        Continue
      </Button>
    </>
  )
}

const BreadCrumList = ["Name & address", "Payment details"]

const HeadlineAndBio = ({ formData, updateField, showError }) => {
  return (
    <div className="flex full-width a-center column  mobile-onboarding-min-height">
      <h2 className="headline-large txt-center  nc100-fg margin-x-auto margin-bottom-3x">Your headline and bio</h2>
      <p className="body-regular-font nc50-fg txt-center margin-bottom-6x ">
        Your headline and bio will appear on your homepage as an intro to the brand visiting your profile.
      </p>
      <form onSubmit={(e) => e.preventDefault()} className="full-width">
        <h5 className="button-medium-font margin-bottom-2x">Headline</h5>
        <Input
          placeholder="Your headline*"
          className={`margin-bottom`}
          error={showError && !formData?.headline}
          value={formData?.headline}
          onChange={(e) => updateField(e.target.value, "headline")}
        ></Input>
        <small className="nc50-fg body-small-font margin-bottom-3x">100 characters max</small>
        <h5 className="button-medium-font margin-bottom-2x">bio</h5>
        <textarea
          placeholder="Your bio* "
          className={`margin-bottom input-element button-medium-font margin-bottom text-area-resize-none ${showError && !formData?.bio ? "negative-border-override" : ""} `}
          value={formData?.bio}
          onChange={(e) => updateField(e.target.value, "bio")}
        ></textarea>
        <small className="nc50-fg body-small-font margin-bottom-3x">300 characters max</small>
        <p className="nc50-fg button-medium-font margin-top-5x"> *required fields</p>
      </form>
    </div>
  )
}
const NameAndAddress = ({ formData, updateField, showError, errors }) => {
  const [data] = useContext(dataContext)
  useEffect(() => {
    updateField("", "state", true)
  }, [formData?.user_address?.country])
  return (
    <div className="flex full-width a-center column  mobile-onboarding-min-height">
      <h2 className="headline-large txt-center  nc100-fg margin-x-auto margin-bottom-3x">Your name and address</h2>
      <p className="body-regular-font nc50-fg txt-center margin-bottom-6x ">
        Your name and address are required to be able to process your rewards.
      </p>
      <div className="full-width">
        <h5 className="button-medium-font margin-bottom-2x">Name & address</h5>
        <div className="flex margin-bottom-2x">
          <Input
            placeholder="First name*"
            className=" full margin-right-2x"
            error={showError && !formData?.first_name}
            value={formData?.first_name}
            onChange={(e) => updateField(e.target.value, "first_name", false)}
          ></Input>
          <Input
            placeholder="Last name*"
            className="full"
            error={showError && !formData?.last_name}
            value={formData?.last_name}
            onChange={(e) => updateField(e.target.value, "last_name", false)}
          ></Input>
        </div>
        <Input
          placeholder="Phone number*"
          className="margin-bottom-2x"
          error={
            showError &&
            (!formData?.user_address?.phone_number ||
              Boolean(errors?.extra?.fields?.user_address?.message?.phone_number))
          }
          value={formData?.user_address?.phone_number}
          onChange={(e) => updateField(e.target.value, "phone_number", true)}
        ></Input>
        <Input
          placeholder="Address line 1*"
          className="margin-bottom-2x"
          error={showError && !formData?.user_address?.address_line_1}
          value={formData?.user_address?.address_line_1}
          onChange={(e) => updateField(e.target.value, "address_line_1", true)}
        ></Input>
        <Input
          placeholder="Address line 2*"
          className="margin-bottom-2x"
          value={formData?.user_address?.address_line_2}
          onChange={(e) => updateField(e.target.value, "address_line_2", true)}
        ></Input>
        <Select
          placeholder="Country*"
          className="margin-bottom-2x"
          error={showError && !formData?.user_address?.country}
          value={formData?.user_address?.country}
          onChange={(e) => {
            if (!e.target.value === formData?.user_address?.country) {
            }
            updateField(e.target.value, "country", true)
          }}
        >
          {Object.keys(countryData).map((countryCode, index) => (
            <option key={index} value={countryCode}>
              {countryData?.[countryCode]?.name} {countryData?.[countryCode]?.emoji}
            </option>
          ))}
        </Select>
        <Input
          placeholder="City"
          value={formData?.user_address?.city}
          error={showError && !formData?.user_address?.city}
          onChange={(e) => updateField(e.target.value, "city", true)}
          className="margin-bottom-2x"
        ></Input>
        <div className="flex margin-bottom-5x">
          <Select
            placeholder="State*"
            className="margin-right-2x"
            error={showError && !formData?.user_address?.state}
            value={formData?.user_address?.state}
            onChange={(e) => updateField(e.target.value, "state", true)}
          >
            {countryData?.[formData?.user_address?.country]?.states?.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Select>
          <Input
            placeholder="Zip code*"
            className=""
            error={showError && !formData?.user_address?.zip_code}
            value={formData?.user_address?.zip_code}
            onChange={(e) => updateField(e.target.value, "zip_code", true)}
          />
        </div>

        <p className="nc50-fg button-medium-font"> *required fields</p>
      </div>
    </div>
  )
}

const PaymentSetup = ({}) => {
  const [data] = useContext(dataContext)
  const api = useContext(apiContext)
  const [_stripeConnected, setStripeConnected] = useState(false)
  const { payload, loader } = useCRBoundData({ section: "creator_payment" })
  const isPaypalConnected = Boolean(payload?.paypal)
  const { connect } = useSocial()
  const stripe = useStripe()

  let stripeConnected = _stripeConnected || data?.me?.payment_settings?.stripe

  const connectClick = () => {
    _lsw("cs_last_login_step", "/start-basic-info")
    _lsw("cs_last_basic_setup_page_step", "1")
    connect("paypal")
  }

  const connectACHWithStripe = async () => {
    const res = await api.creator.getStripeKey()
    console.log(res)
    const stripInfo = await stripe.collectFinancialConnectionsAccounts({
      clientSecret: res?.client_secret,
    })

    if (stripInfo?.financialConnectionsSession?.accounts?.length) {
      setStripeConnected(true)
    }
    await api.creator.postStripeToBackend(stripInfo)
    return
  }

  return (
    <div className="flex full-width a-center column  mobile-onboarding-min-height">
      <WrappedInLoader loader={loader} data={payload}>
        <h2 className="headline-large txt-center  nc100-fg margin-x-auto margin-bottom-3x">Payment details</h2>
        <p className="body-regular-font nc50-fg txt-center margin-bottom-8x ">
          Connect your PayPal account or provide your bank/ ACH details via Stripe Connect for secure and swift payout
          of earnings
        </p>
        <div className="full-width margin-bottom-10x">
          <Button
            fluid
            size="large"
            type="tertiary"
            beforeElement={<div className="flex a-center j-center nc100-fg">{PaypalLargeFilledMono}</div>}
            className={`${isPaypalConnected ? "after-element-at-the-end" : ""} margin-bottom-2x`}
            afterElement={isPaypalConnected ? <AfterButton cond={isPaypalConnected} /> : null}
            onClick={connectClick}
          >
            {isPaypalConnected ? "Paypal Connected" : "Connect PayPal"}
          </Button>

          {/* <p className="caption-regular-font nc50-fg txt-center  margin-y-3x ">
                        or,
                    </p>

                  
                    
                    <Button
                        
                        className={`${stripeConnected ? "after-element-at-the-end" : ""} `}
                        afterElement={stripeConnected ? <AfterButton cond={stripeConnected}/>:null}
                        
                        fluid size='large' type='tertiary' 
                        onClick={connectACHWithStripe}
                        >
                        <div className='flex a-center'>
                            <span>Get paid through ACH via</span> <img className="margin-left" style={{ width:"45px" }} src={StripeIcon}/>
                        </div>
                    </Button> */}
        </div>
      </WrappedInLoader>
    </div>
  )
}

const Scheduling = ({ link, onLinkChange }) => {
  return (
    <div className="flex full-width a-center column  mobile-onboarding-min-height">
      <h2 className="headline-large txt-center  nc100-fg margin-x-auto margin-bottom-3x">Scheduling</h2>
      <p className="body-regular-font nc50-fg txt-center margin-bottom-8x ">
        If you have a calendar booking link (e.g. Calendly), please provide it here so the brand can book a call with
        you to discuss possible campaigns
      </p>
      <div className="full-width">
        <Input
          placeholder="paste your booking link here"
          value={link}
          onChange={onLinkChange}
          className="margin-bottom-2x"
        ></Input>
      </div>
    </div>
  )
}

const OnboardingCreatorBioAndAddressInfo = (props) => {
  const [data] = useContext(dataContext)
  const [isDescriptionPage, setIsDescriptionPage] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const { loaders } = useLoading()
  const api = useContext(apiContext)
  const navigate = useNavigate()
  const { payload, loader, executeCall: addToCampaign } = useCRPostData({ section: "add_creator_to_campaign" })
  const invite_config = _lsr("invite_config")
  const brand_eco_invite = _lsr("brand-ecosystem-invite-refcode")

  const [showError, setShowError] = useState({
    headlineAndBio: false,
    nameAndAddress: false,
  })
  const [headlineAndBio, setHeadlineAndBio] = useState({
    headline: "",
    bio: "",
  })

  const [bookingLink, setBookingLink] = useState("")
  const [errors, setErrors] = useState()
  const toast = useToast()

  const [nameAndAddress, setNameAndAddress] = useState({
    first_name: "",
    last_name: "",
    user_address: {
      country: "US",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      phone_number: "",
      zip_code: "",
    },
  })

  const updateHeadLineAndBio = (newValue, fieldName) => {
    setHeadlineAndBio({
      ...headlineAndBio,
      [fieldName]: newValue,
    })
  }

  const updateAddress = (newValue, fieldName, isAddress) => {
    const _nameAndAddress = { ...nameAndAddress }
    const newAddress = {
      ..._nameAndAddress?.user_address,
    }
    if (isAddress) {
      newAddress[fieldName] = newValue
    } else {
      _nameAndAddress[fieldName] = newValue
    }
    setNameAndAddress({
      ..._nameAndAddress,
      user_address: newAddress,
    })
  }

  const onBreadCrumbClick = (index) => {
    setPageIndex(index)
  }
  const acceptInviteFromBrand = async () => {
    const creator_id = data?.me?.id
    const brandInfo = await api.creator.getBrandInfoFromInviteIDOrBrandID(brand_eco_invite)
    if (creator_id && brandInfo?.id) {
      const r = await api.creator.acceptBrandInfoFromInviteId(brandInfo?.id, creator_id, invite_config?.circle)
      if (r) {
        toast("Added to brand's creator circle")
      } else {
        toast("Something went wrong with adding creators to brand's creator circle", BanSolid)
      }
    }
    if (!brandInfo?.id) {
      console.error("brand refcode is missing or invalid")
      toast("brand refcode is missing or invalid")
      api.logout()
    }
    console.log(invite_config)
    if (invite_config?.camp) {
      const _camp = await addToCampaign([{ campaign: invite_config?.camp }])
      if (_camp) {
        toast("Added to a brand campaign")
      }
    }
  }

  const onContinueClick = async ({ skip = false }) => {
    scrollToTop()
    if (!BreadCrumList[pageIndex + 1]) {
      // _lsd("brand-ecosystem-invite-refcode")
      // data?.me_social_list?.indexOf("instagram") !== -1 ? navigate("/start-graph") : navigate("/start-thank-you")

      navigate("/start-thank-you")
    }

    if (pageIndex === 0) {
      setShowError({ ...showError, nameAndAddress: true })
      const nameValid = ["first_name", "last_name"].every((e) => nameAndAddress[e])
      const addressValid = ["country", "address_line_1", "city", "zip_code", "state"].every(
        (e) => nameAndAddress?.user_address?.[e],
      )

      if (!nameValid || !addressValid) {
        return
      }
      // doing it this was because backend has very strict phone number verification, if error pops up in backend, using the backing verification for phone number
      let phone_number = nameAndAddress?.user_address?.phone_number
      phone_number = makeUSPhoneNumberDefault(phone_number)
      const payload = { ...nameAndAddress }
      payload.user_address.phone_number = phone_number

      let [res, error] = await api.creator.updateAndValidateAddressInfo(payload)
      updateAddress(phone_number, "phone_number", false)
      if (error) {
        console.log(error)
        setErrors(error)
        if (errors?.extra?.fields?.user_address?.message?.phone_number) {
          toast(errors?.extra?.fields?.user_address?.message?.phone_number[0], BanSolid)
          return
        }
        toast(error?.message ?? "Some went wrong", BanSolid)
        console.log(errors?.extra?.fields?.user_address?.message?.phone_number)
        return
      }
      await acceptInviteFromBrand()
      setErrors(null)

      setPageIndex(pageIndex + 1)
      return nameAndAddress
    }
    setPageIndex(pageIndex + 1)
  }

  const getContinueText = () => {
    if (!BreadCrumList[pageIndex + 1]) {
      return "Complete payment details"
    }
    return `Continue to ${BreadCrumList[pageIndex + 1]}`
  }

  const progress = Math.max(pageIndex / BreadCrumList.length + 0.05, 0.1) * 100 + "%"

  useEffect(() => {
    if (_lsr("cs_last_basic_setup_page_step") == "1") {
      setPageIndex(1)
      setIsDescriptionPage(false)
      _lsd("cs_last_login_step")
      _lsd("cs_last_basic_setup_page_step")
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      const me = await api.common.me()

      setNameAndAddress({
        first_name: me?.first_name ?? nameAndAddress.first_name,
        last_name: me?.last_name ?? nameAndAddress.last_name,
        user_address: {
          ...nameAndAddress?.user_address,
          ...me?.user_address,
        },
      })
    })()
  }, [])

  const hasPaymentConnected = data?.cr_sec_creator_payment?.paypal || data?.creator_payout_connected
  return (
    <>
      {false ? (
        <>
          <div className="padding-3x">
            <BreadCrumbs
              className="  hide-on-desktop"
              list={BreadCrumList}
              scrollableBreadcrumb
              onItemClick={onBreadCrumbClick}
              activeIndex={pageIndex}
            />
          </div>
          <div className="progress-bar hide-on-desktop" style={{ width: progress }}></div>
        </>
      ) : null}
      <OnboardingCreatorContainer cardClassName="padding-y-11x">
        {false ? (
          <BreadCrumbs
            className="margin-x-auto margin-bottom-10x hide-on-mobile"
            onItemClick={onBreadCrumbClick}
            list={BreadCrumList}
            activeIndex={pageIndex}
          />
        ) : null}

        {isDescriptionPage ? (
          <DescriptionPage
            onContinueClick={() => {
              setIsDescriptionPage(false)
            }}
          />
        ) : (
          <>
            <div className="full-width max-width-400 ">
              {/* {pageIndex==0 ?<HeadlineAndBio formData={headlineAndBio} showError={showError?.headlineAndBio} updateField={updateHeadLineAndBio}/>:null} */}
              {pageIndex == 0 ? (
                <NameAndAddress
                  formData={nameAndAddress}
                  showError={showError?.nameAndAddress}
                  errors={errors}
                  updateField={updateAddress}
                />
              ) : null}
              {/* {pageIndex==2 ?<Scheduling link={bookingLink} onLinkChange={(e)=>setBookingLink(e.target.value)} />:null} */}
              {pageIndex == 1 ? <PaymentSetup /> : null}

              <Button
                className={`${pageIndex >= 1 ? "margin-y-2x" : "margin-y-10x"}`}
                disabled={pageIndex == 1 && !hasPaymentConnected}
                onClick={onContinueClick}
                fluid
                size="large"
              >
                {loaders["user_create"] || loaders["creator_update"] || loaders["onboarding_common"] ? (
                  <Loader />
                ) : (
                  getContinueText()
                )}
              </Button>
              {/* {pageIndex>=2 ?<Button className="margin-bottom-10x" onClick={()=>onContinueClick({skip:true})}  type={"ghost"}  fluid size="large">{pageIndex==2 ? "Skip, continue to payment details": "I’ll add payment details later" }</Button>:null} */}
            </div>
          </>
        )}
      </OnboardingCreatorContainer>
    </>
  )
}

export { OnboardingCreatorBioAndAddressInfo }
