import React, { useContext, useEffect, useState } from "react"
import { dataContext } from "../../../contexts/datacontext"
import { Button } from "../../button"
import { ArrowRight, CheckMediumFilledMono } from "../../Icons"
import { Tab } from "../../tab"
import { platforms } from "../../../consts"
import { NewLineParser, PlatformIcon } from "../../dumbs"
import { apiContext } from "../../../contexts/apicontext"
import { useLoading } from "../../../contexts/loaderContext"
import { Media, MediaSelect } from "../../media"
import { FullLoader, Loader } from "../../loaders"
import { num2Hip, _nopropagate } from "../../../helpers/utils"
import { useTabPageSpecificAnalytics } from "../../../contexts/apis/analytics"
import { CampaignPopup, useCampaignPopupHelper } from "../BrandEcosystem/campaignPopup"
import { useToast } from "../../../hooks/useToast"
import { useCRBoundData } from "../../../hooks/useBoundData"

function BookingRatesAndActions({ className = "" }) {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const toast = useToast()
  const { loaders } = useLoading()
  const popup = useCampaignPopupHelper()
  const isBrand = data?.role !== "brand"
  const addToCampaign = async (campaign) => {
    const added = api.brand.addCreatorsToCampaign([data?.creator], campaign?.id)
    if (added) toast("Added to campaign")
  }
  const saveDraft = () => {
    dispatch({
      pending_campaign_add: {
        creators: [data?.creator],
      },
    })
  }
  return (
    <div className={`booking-featured-list border-1 line-shadow border-radius-4 nc10-border   ${className}`}>
      <div className="padding-5x">
        <div className="flex a-center margin-bottom-2x" size="larges">
          <Button
            className="full "
            type="tertiary"
            size="large"
            onClick={() => api?.alert?.ContactCreator(data?.creator)}
          >
            Message creator
          </Button>
        </div>
        <Button
          disabled={data?.role !== "brand" || data?.is_guest}
          onClick={popup.show}
          className="position-relative"
          fluid
          type="secondary"
          size="large"
        >
          Add to campaign
          {popup.shown ? (
            <CampaignPopup
              onDismiss={popup.hide}
              className="fluid"
              onCampaignClick={addToCampaign}
              onAddNewClick={saveDraft}
              kind="CREATOR"
            />
          ) : null}
        </Button>
      </div>
    </div>
  )
}

const EngagementNumberElement = ({ className, title, subTitle }) => {
  return (
    <div className={`flex column a-center ${className}`}>
      <h5 className={`headline-medium-regular margin-bottom ${title ? "headline-medium-regular " : "nc10-fg"}`}>
        {title ? title : "N/A"}
      </h5>
      <div className="caption-regular-font txt-center nc30-fg">{subTitle}</div>
    </div>
  )
}

const EngagementNumbers = ({ className = "", total_follower_count, total_impressions, engagement_rate }) => {
  return (
    <div
      className={`engagement-numbers border-1 nc10-border line-shadow padding-x-6x padding-y-5x flex j-space-between border-radius-4 ${className}`}
    >
      <EngagementNumberElement
        title={total_follower_count ? num2Hip(total_follower_count) : null}
        className="full border-right-1 nc05-border"
        subTitle={
          <>
            Total
            <br /> followers
          </>
        }
      />
      <EngagementNumberElement
        title={total_impressions ? num2Hip(total_impressions) : null}
        className="full border-right-1 nc05-border"
        subTitle={
          <>
            Total
            <br /> impressions
          </>
        }
      />
      <EngagementNumberElement
        title={engagement_rate ? engagement_rate.toFixed(2) * 100 + "%" : null}
        className="full"
        subTitle={
          <>
            engagement
            <br /> rate
          </>
        }
      />
    </div>
  )
}

const getTextFromConnectedAccount = (connected_accounts = {}) => {
  for (const _cr in connected_accounts ?? {}) {
    if (connected_accounts?.[_cr]?.text) {
      return connected_accounts?.[_cr]?.text
    }
  }
  return ""
}

function BookingLanding() {
  const [data] = useContext(dataContext)
  const api = useContext(apiContext)
  const [contentTab, changeContentTab] = useState("")
  const [sIndex, setSIndex] = useState(0)
  const {
    payload: samples,
    executeCall,
    loader,
  } = useCRBoundData({ section: "top_contents", isFirstCallManualExecute: true })
  useTabPageSpecificAnalytics("Booking_Tab")
  const updateContentTab = (item) => {
    api.analytics.button("Clicks_Platform_Preview_Videos", item)
    changeContentTab(item)
  }
  useEffect(() => {
    if (data?.creator_social_list?.length && !contentTab) changeContentTab(data.creator_social_list[0])
  }, [data.creator_social_list])

  useEffect(() => {
    if (contentTab) {
      ; (async () => {
        const contents = await executeCall({ platform: contentTab })
        setSIndex(0)
      })()
    }
  }, [contentTab])

  const creatorText = data?.creator?.text
    ? data?.creator?.text
    : data?.creator?.bio
      ? data?.creator?.bio
      : getTextFromConnectedAccount(data?.creator?.connected_accounts)
  return (
    <>
      <div className="booking-landing-page-root padding-x-4x padding-top-5x">
        <h3 className="hide-on-desktop headline-large margin-bottom-6x margin-top-2x txt-center">
          {data?.creator?.headline}
        </h3>
        <p className="nc30-fg headline-small-highlight txt-center hide-on-desktop margin-bottom-3x">
          Book me for new content creation
        </p>
        <div className="booking-featured-list">
          <EngagementNumbers
            total_follower_count={data?.creator?.total_follower_count}
            total_impressions={data?.creator?.total_impressions}
            engagement_rate={data?.creator?.engagement_rate}
            className="margin-bottom-4x"
          />
          <BookingRatesAndActions />
        </div>
        <div className="rightsection-bookinglanding  padding-right-10x padding-y-4x">
          <img
            className="content-booking-avater-desktop margin-bottom-3x hide-on-mobile"
            alt={data?.creator?.username}
            src={data?.creator?.profile_picture_url}
          />
          <div className="headline-large hide-on-mobile  margin-bottom-5x">{data?.creator?.headline}</div>
          <div className="flex a-center">
            <img
              className="content-booking-avater-mobile margin-bottom-3x margin-top-3x margin-x-auto hide-on-desktop"
              alt={data?.creator?.username}
              src={data?.creator?.profile_picture_url}
            />
          </div>
          <div className="create-new-content-description nc75-fg margin-bottom-10x headline-small-regular">
            {creatorText ? <NewLineParser value={creatorText} /> : creatorText}
          </div>
          <hr className="hr hide-on-desktop margin-bottom-10x mobile-content-booking-different-background-section-negative-margin" />

          <div className="newcontent-landing-header-past-work headline-small-highlight   margin-bottom-4x">
            Here are some contents I published about {data?.me?.brand?.name ? data?.me?.brand?.name : "your brand"}
          </div>

          <div className="content-booking-tabbar flex">
            {contentTab
              ? data?.creator_social_list.map((item) => (
                <Tab
                  key={item}
                  innert={loader}
                  className="social-tab"
                  type="capsules"
                  icon={PlatformIcon({ platform: item, color: contentTab === item })}
                  title={platforms[item].title}
                  selected={contentTab === item}
                  onChange={() => updateContentTab(item)}
                />
              ))
              : null}
          </div>

          <div className="content-booking-featured-contents margin-top-4x">
            {loader ? <FullLoader /> : null}
            {samples?.length && !loader ? (
              <div className="content-booking-sample-inner">
                <div className="foldout-media-container nc100-bg flex hcenter border-radius-4">
                  <MediaSelect platform={contentTab} content={samples[sIndex].content} showVideo />
                </div>
                <div className="sample-content-thumbs margin-top">
                  {samples.slice(0, 5).map((item, index) => (
                    <div
                      key={item?.content?.uuid}
                      onClick={() => setSIndex(index)}
                      className="sample-thumb-holder cursor-pointer border-radius-4"
                    >
                      <Media
                        className={index === sIndex ? "" : "semi-transparent"}
                        platform={contentTab}
                        content={item?.content}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export { BookingLanding }
