import {
  PaypalLargeFilledMono,
  PencilSmallFilledMono,
  CopyMediumOutlinedMono,
  CheckSmallFilledMono,
  TimesSmallFilledMono,
} from "./Icons"
import React, { useContext, useEffect, useState, useRef } from "react"
import { useToast } from "../hooks/useToast"
import { hotTextbox } from "../helpers/utils"
import { RoundButton, TabSeparator } from "./dumbs"
import { Loader } from "./loaders"
import { Button } from "./button"
import clipboard from "clipboardy"
export const CopyableInput = ({
  fixedPart,
  textPart,
  editable,
  loading,
  copyableText,
  updateText,
  onEditButtonClick,
  currentlyEditing,
}) => {
  const txtRef = useRef(null)
  const [newText, setNewText] = useState(textPart)
  const toast = useToast()
  const copyURL = () => {
    clipboard
      .write(copyableText)
      .then((status) => {
        toast("Copied to clipboard")
      })
      .catch((e) => {
        console.error(`Error accessing clipboard`)
      })
  }
  const editHandler = () => {
    updateText(newText)
  }

  useEffect(() => {
    if (currentlyEditing && txtRef?.current) hotTextbox(txtRef.current)
  }, [currentlyEditing])

  return (
    <>
      <div className="your-profile-url button-medium-font flex vcenter nc05-border border-2 border-radius-dynamic padding-x-3x padding-y-2x">
        {fixedPart ? <div className="fixedpart">{fixedPart}/</div> : null}
        {currentlyEditing ? (
          <input
            ref={txtRef}
            type="text"
            value={newText}
            onChange={(e) => setNewText(e.target.value)}
            className="bare-input button-medium-font full margin-right"
          />
        ) : (
          <div className="textpart full">{textPart}</div>
        )}
        {loading ? (
          <Loader />
        ) : (
          <>
            {currentlyEditing ? (
              <>
                <Button type="ghost" onClick={editHandler} size="small">
                  Save
                </Button>
              </>
            ) : (
              <>
                {editable ? <RoundButton onClick={onEditButtonClick} icon={PencilSmallFilledMono} /> : null}
                <RoundButton onClick={copyURL} className="margin-left-2x" icon={CopyMediumOutlinedMono} />
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}
