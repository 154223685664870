import { useContext, useDebugValue, useEffect, useLayoutEffect } from "react"
import { dataContext } from "../../../contexts/datacontext"
import { useState } from "react"

import { useBDBoundData, useLocalBoundData, useLocalDataMutation } from "../../../hooks/useBoundData"
import {
  _nopropagate,
  getArrayFromSeperatorString,
  num2Hip,
  combineSimpleArrayAndRemoveDuplicates,
  moveToSubBrandPage,
} from "../../../helpers/utils"
import { Button } from "../../button"
import { apiContext } from "../../../contexts/apicontext"
import { Card, WrappedInLoader } from "../../dumbs"
import {
  ThinTimesLargeMono,
  ThinTimesMediumMono,
  SearchSetting,
  Lock,
  TimesLargeFilledMono,
  TrashLargeFilledMono,
} from "../../Icons"
import { Input } from "../../input"
import { Toggle } from "../../toggle"
import { InfoIcon } from "../../dumbs"
import { Select } from "../../select"
import { languages } from "../../../dumbData"
import { createSearchParams, useNavigate } from "react-router-dom"
import { useAuthHelper } from "../../../hooks/useAuthHelper"
import { useToast } from "../../../hooks/useToast"
import { EditableTextfield } from "../../editablePrice"
import { GET_CALLS, MUTATION_CALLS } from "../../../consts"

const Tab = ({ selected = false, title, onClick, href = "" }) => {
  const clickAction = (e) => {
    e.preventDefault()
    onClick()
  }
  return (
    <div
      onClick={clickAction}
      href={href}
      className={`${selected ? "selected" : ""} full creator-homepage-main-tab txt-center padding-y-3x nc50-fg body-regular-font mobile-body-highlight-font cursor-pointer nc02-bg`}
    >
      {title}
    </div>
  )
}

const ChipWithDelete = ({
  className = "margin-right-2x margin-bottom-2x",
  onRemove,
  title = "",
  hideRemoveIcon = false,
}) => {
  return (
    <div className={`chip  flex a-center border-1 nc10-border ${className}`}>
      <div className="flex a-center">
        <span className="padding-left hide-extra-text button-medium-font padding-right-half">{title}</span>
        {!hideRemoveIcon && (
          <div className="flex a-center nc50-fg translate-y-1 translate-x-1 cursor-pointer" onClick={onRemove}>
            {ThinTimesMediumMono}
          </div>
        )}
      </div>
    </div>
  )
}

const useSearchTerms = ({ fieldName, updateClientSideBrandTags, brand_tags }) => {
  const [searchTermInput, setSearchTermInput] = useState("")
  const hashtagArray = (brand_tags?.[fieldName] ?? [])?.sort()
  const handleTermChange = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      updateClientSideBrandTags({
        [fieldName]: combineSimpleArrayAndRemoveDuplicates(
          hashtagArray ?? [],
          getArrayFromSeperatorString(searchTermInput),
        ),
      })
      setSearchTermInput("")
    }
  }

  const removeTerm = (index) => {
    updateClientSideBrandTags({ [fieldName]: hashtagArray.filter((item, i) => i !== index) })
  }

  return { handleTermChange, searchTermInput, setSearchTermInput, hashtagArray, removeTerm }
}

const Inclusion = ({
  className = "",
  updateClientSideBrandTags,
  data: data_to_safe,
  showTiktokUsername = true,
  tiktokUsername,
  updateTiktokUsername,
  newBrand,
  brand_tags,
  fromPopup,
  readOnly = false,
  hideSearchTerms = false,
  searchTermInput,
  setSearchTermInput
}) => {

  const [selectedValue, setSelectedValue] = useState("")

  const temp_combine_hashtag_search_term = (brand_tags?.temp_combine_hashtag_search_term ?? []).sort()

  const allowed_languages = brand_tags?.allowed_languages ?? []
  const is_search_inclusion = brand_tags?.is_search_inclusion ?? true

  const handleSearchTerm = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      getArrayFromSeperatorString(searchTermInput)
      updateClientSideBrandTags({
        temp_combine_hashtag_search_term: combineSimpleArrayAndRemoveDuplicates(
          temp_combine_hashtag_search_term ?? [],
          getArrayFromSeperatorString(searchTermInput),
        ),
      })
      setSearchTermInput("")
    }
  }

  const removeSearchTerm = (index) => {
    updateClientSideBrandTags({
      temp_combine_hashtag_search_term: temp_combine_hashtag_search_term.filter((item, i) => i !== index),
    })
  }

  const handleLanguageAdd = (e) => {
    const arr = combineSimpleArrayAndRemoveDuplicates(
      allowed_languages ?? [],
      getArrayFromSeperatorString(e.target.value),
    )
    if (arr.includes("all")) {
      updateClientSideBrandTags({
        allowed_languages: ["all"], // remove every language if it contians all
      })
      return
    }
    updateClientSideBrandTags({
      allowed_languages: arr,
    })
  }
  const removeLanguage = (index) => {
    updateClientSideBrandTags({ allowed_languages: allowed_languages.filter((item, i) => i !== index) })
  }

  const changeIsSearchInclusion = () => {
    updateClientSideBrandTags({
      is_search_inclusion: !is_search_inclusion,
    })
  }

  return (
    <div className={`${className} `}>
      <div>
        {showTiktokUsername ? (
          <>
            <h4 className=" margin-bottom-2x headline-small-highlight">TIKTOK username </h4>
            <EditableTextfield
              placeholder={`TIKTOK username`}
              defaultEditMode={!!newBrand || !tiktokUsername}
              value={tiktokUsername}
              readOnly={readOnly}
              onChange={updateTiktokUsername}
              className="margin-bottom-4x"
            />
          </>
        ) : null}

        {hideSearchTerms ? null : (
          <>
            <h4 className=" margin-bottom-2x headline-small-highlight">Search terms</h4>
            {!readOnly && (
              <Input
                value={searchTermInput}
                onKeyDown={handleSearchTerm}
                onChange={(e) => setSearchTermInput(e.target.value)}
                placeholder={`type and hit enter to add terms...`}
                className="margin-bottom-4x"
              />
            )}

            <div className="flex wrapped">
              {temp_combine_hashtag_search_term.map((x, i) => (
                <ChipWithDelete
                  hideRemoveIcon={readOnly}
                  key={i}
                  title={`#${x}`}
                  onRemove={() => {
                    removeSearchTerm(i)
                  }}
                />
              ))}
            </div>
          </>
        )}

        {!readOnly && (
          <div className="flex  margin-top-4x margin-bottom-2x  a-center">
            <Toggle
              className=""
              value={is_search_inclusion}
              onChange={changeIsSearchInclusion}
              onClick={changeIsSearchInclusion}
              readOnly={false}
            />
            <div className="button-medium-font margin-left-2x nc75-fg ">
              <span>Exclude search results that don't match any of these terms.</span>
              <span className="display-inline-block ">
                <InfoIcon
                  className={"translate-y-5"}
                  location="bottom"
                  dark
                  text={`TikTok search results are elastic and will show you also "related posts"; for easy of assessing your customer's content we recommend to keep this turned on"`}
                ></InfoIcon>
              </span>
            </div>
          </div>
        )}
        {fromPopup ? null : (
          <>
            <h4 className=" margin-bottom-2x headline-small-highlight margin-top-5x">Languages</h4>
            {!readOnly && (
              <Select
                className="margin-bottom-4x"
                value={""}
                onChange={handleLanguageAdd}
                placeholder="Select languages..."
              >
                <option value=" "> &nbsp; Select languages...</option>
                {Object.keys(languages).map((languageCode, index) => (
                  <option key={index} value={languageCode}>
                    {" "}
                    &nbsp; {languages[languageCode]}
                  </option>
                ))}
              </Select>
            )}
            <div className="flex wrapped ">
              {allowed_languages.map((x, i) => (
                <ChipWithDelete
                  key={i}
                  title={`${languages[x]}`}
                  hideRemoveIcon={readOnly}
                  onRemove={() => {
                    removeLanguage(i)
                  }}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const Exclusion = ({ className = "", updateClientSideBrandTags, brand_tags, readOnly = false }) => {
  const { hashtagArray, searchTermInput, handleTermChange, setSearchTermInput, removeTerm } = useSearchTerms({
    fieldName: "exclusions",
    updateClientSideBrandTags: updateClientSideBrandTags,
    brand_tags: brand_tags
  })

  return (
    <div className={`${className} `}>
      <div>
        <h4 className=" margin-bottom-2x headline-small-highlight">Exclusion terms</h4>
        {!readOnly && (
          <Input
            value={searchTermInput}
            onKeyDown={handleTermChange}
            onChange={(e) => setSearchTermInput(e.target.value)}
            placeholder={`type and hit enter to add terms...`}
            className="margin-bottom-4x"
          />
        )}
        <div className="flex wrapped ">
          {hashtagArray.map((x, i) => (
            <ChipWithDelete
              key={i}
              title={`#${x}`}
              onRemove={() => {
                removeTerm(i)
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const Influencer = ({ className = "", updateClientSideBrandTags, brand_tags, readOnly = false }) => {
  const { hashtagArray, searchTermInput, handleTermChange, setSearchTermInput, removeTerm } = useSearchTerms({
    fieldName: "influencer_terms",
    updateClientSideBrandTags: updateClientSideBrandTags,
    brand_tags
  })

  return (
    <div className={`${className} `}>
      <div>
        <h4 className=" margin-bottom-2x headline-small-highlight">Influencer terms</h4>
        {!readOnly && (
          <Input
            value={searchTermInput}
            onKeyDown={handleTermChange}
            onChange={(e) => setSearchTermInput(e.target.value)}
            placeholder={`type and hit enter to add terms...`}
            className="margin-bottom-4x"
          />
        )}
        <div className="flex wrapped margin-bottom-2x ">
          {hashtagArray.map((x, i) => (
            <ChipWithDelete
              key={i}
              title={`#${x}`}
              onRemove={() => {
                removeTerm(i)
              }}
            />
          ))}
        </div>
        <div className="body-regular-font  nc50-fg  ">
          The following terms will standard trigger a post as an influencer / paid partnership post: #sponsored, #ad,
          #partner, #squad, #ambassador.
          <br />
          <br />
          Any post that has a "paid partnership" tag will also be considered an influencer/ paid partnership post.
          <br />
          <br />
          Please indicate above any additional brand-specific terms that would indicate a post is from a brand partner.
        </div>
      </div>
    </div>
  )
}

const SelfServeSettings = ({
  toggleShowSettings,
  isSidebar = false,
  showBrandNameINput,
  newBrand,
  fromPopup = false,
  showBrandInputText = false,
}) => {
  const [data, dispatch] = useContext(dataContext)
  const { payload: brandTagsServer, loader: brandTagsServerLoader } = useLocalBoundData({ ...GET_CALLS.brand_tags, sub_brand_id: data?.sub_brand_id, skip: newBrand })
  const { executeCall: updateBrandTags, loader: updateBrandTagsLoader } = useLocalDataMutation(MUTATION_CALLS.update_brand_tags)
  const { executeCall: updateSubbrand, loader: updateSubbrandLoader } = useLocalDataMutation({ ...MUTATION_CALLS.update_sub_brand, endpoint: `/Brand/${data?.sub_brand_id}/` })
  const { executeCall: updateMainBrand, loader: updateMainBrandLoader } = useLocalDataMutation({ ...MUTATION_CALLS.update_brand, endpoint: `/Brand/${data?.me?.brand?.id}/` })
  const { executeCall: createNewSubBrand, loader: createNewSubBrandLoader } = useLocalDataMutation(MUTATION_CALLS.create_new_brand)
  const [selectedTab, setSelectedTab] = useState(0)
  const [brandTagsClient, setBrandTagClient] = useState({
    search_terms: [],
    exclusions: [],
    allowed_languages: [],
    temp_combine_hashtag_search_term: [],
  })
  const { isGuest } = useAuthHelper()
  const toast = useToast()
  const [brandType, setBrandType] = useState(1)
  const [brandName, setBrandName] = useState("")
  const api = useContext(apiContext)
  const navigate = useNavigate()
  const [tiktokUsername, setTiktokUsername] = useState("")
  const [socialAccountID, setSocialAccountID] = useState("")
  // inclution section values 
  const [searchTermInput, setSearchTermInput] = useState("")

  const updateClientSideBrandTags = (updateObj) => {
    setBrandTagClient({
      ...brandTagsClient,
      ...updateObj
    })
  }

  const _id = Number(data?.sub_brand_id)
  const _sub_brand = data?.me?.brand?.sub_brands?.find((x) => x.id === _id)
  let showUsername =
    (fromPopup && brandType === 2) ||
    (fromPopup && brandType === 3) ||
    (!fromPopup && _sub_brand?.sub_brand_type === 2) ||
    (!fromPopup && _sub_brand?.sub_brand_type === 3)
  useEffect(() => {
    if (newBrand) {
      return
    }
    if (!data?.sub_brand_id) {
      setTiktokUsername(data?.me?.brand?.social_account?.tiktok?.username)
      if (data?.me?.brand?.social_account?.tiktok?.id) {
        setSocialAccountID(data?.me?.brand?.social_account?.tiktok?.id)
      }
      return
    }

    if (data?.sub_brand_id) {
      setTiktokUsername(_sub_brand?.social_account?.tiktok?.username)
      if (_sub_brand?.social_account?.tiktok?.id) {
        setSocialAccountID(_sub_brand?.social_account?.tiktok?.id)
      }
    }
  }, [data?.me?.brand])

  const onTiktokChange = (e) => {
    const value = e.target.value
    if (!value) {
      setTiktokUsername("")
      return
    }
    const regex = /^@?([a-zA-Z0-9_.]{1,24})$/
    const match = value.match(regex)
    if (match) {
      setTiktokUsername(match[1])
    }
  }

  const tabs = [
    {
      title: "Inclusions",
      component: (
        <Inclusion
          fromPopup={fromPopup}
          showTiktokUsername={!showUsername}
          tiktokUsername={tiktokUsername}
          updateTiktokUsername={onTiktokChange}
          updateClientSideBrandTags={updateClientSideBrandTags}
          brand_tags={brandTagsClient}
          className=""
          newBrand={newBrand}
          searchTermInput={searchTermInput}
          setSearchTermInput={setSearchTermInput}
          readOnly={
            (data?.me?.user_group == null && data?.me?.brand?.settings?.is_term_edit_enabled !== true) ||
            (fromPopup && brandType === 3)
          }
          hideSearchTerms={fromPopup && brandType === 3}
        />
      ),
    },
    {
      title: "Exclusions",
      component: (
        <Exclusion
          updateClientSideBrandTags={updateClientSideBrandTags}
          brand_tags={brandTagsClient}
          className=""
          readOnly={data?.me?.user_group == null && data?.me?.brand?.settings?.is_term_edit_enabled !== true}
        />
      ),
    },
    {
      title: "Influencers",
      component: (
        <Influencer
          updateClientSideBrandTags={updateClientSideBrandTags}
          brand_tags={brandTagsClient}
          className=""
          readOnly={data?.me?.user_group == null && data?.me?.brand?.settings?.is_term_edit_enabled !== true}
        />
      ),
    },
  ]

  useEffect(() => {
    if (newBrand) return

    if (brandTagsServer) {
      const temp_combine_hashtag_search_term = combineSimpleArrayAndRemoveDuplicates(
        brandTagsServer?.search_terms ?? [],
        brandTagsServer?.hashtags ?? [],
      )
      updateClientSideBrandTags({ ...brandTagsServer, temp_combine_hashtag_search_term })
    }
  }, [brandTagsServer])




  const sendToServer = async () => {
    const payload = {
      ...brandTagsClient,
      is_search_inclusion: brandTagsClient?.is_search_inclusion ?? true, // default true
      search_terms: brandTagsClient?.temp_combine_hashtag_search_term,
    }
    delete payload["temp_combine_hashtag_search_term"]

    const subBrandPayloadCommon = {
      is_sub_brand: true,

      brand_tags: payload,
    }
    const mainBrandPayload = { brand_tags: payload }
    if (data?.sub_brand_id) {
      subBrandPayloadCommon.brand_tags.brand = Number(data?.sub_brand_id)
    }
    let social_account
    if (tiktokUsername) {
      social_account = {
        tiktok: {
          id: socialAccountID,
          username: tiktokUsername,
        },
      }
      subBrandPayloadCommon.social_account = social_account
      mainBrandPayload.social_account = social_account
    }

    if (!subBrandPayloadCommon?.brand_tags?.brand) {
      delete subBrandPayloadCommon.brand_tags.brand
    }

    if (fromPopup || newBrand) {
      delete subBrandPayloadCommon.brand_tags.brand
    }

    if (newBrand) {
      if (!brandName) {
        toast("search name can't be empty ", TimesLargeFilledMono)
        return false
      }
      const subBrand = await createNewSubBrand({ ...subBrandPayloadCommon, sub_brand_type: brandType, title: brandName })

      if (!subBrand) return
      await api.common.me()
      // moveToSubBrandPage(dispatch,navigate,subBrand,overallSpace)

      return true
    }


    if (!payload?.brand) {
      payload.brand = data?.me?.brand?.id
    }
    if (data?.sub_brand_id) {
      const res = await updateSubbrand(subBrandPayloadCommon)
      if (!res) {
        toast("Something went wrong while updating hashtags and search terms")
        return false
      }
    } else {
      const res = await updateBrandTags(payload)
      if (!res) {
        toast("Something went wrong while updating hashtags and search terms")
        return false
      }
    }
    // update social account for the main brand
    if (!data?.sub_brand_id && tiktokUsername) {
      await updateMainBrand({ social_account })
    }
    await api.brand.me()

    return true
  }

  const saveHandler = async () => {
    if (isGuest) {
      api?.alert?.unlockFullExperience()
      return
    }

    const success = await sendToServer()
    if (success) {
      api?.alert?.SearchSettings()
    }

    dispatch({
      show_self_serve_brand_settings: false,
    })
  }

  const deleteHandler = async () => {
    if (isGuest) {
      api?.alert?.unlockFullExperience()
      return
    }

    api?.alert?.deleteSubBrand()

    dispatch({
      show_self_serve_brand_settings: false,
    })
  }

  const onDiscoverClick = () => {
    dispatch({ show_self_serve_brand_settings: false })
    navigate(
      !data?.sub_brand_id ? "/discover-hashtags-new" : "/discover-hashtags-new?" + "sub_brand_id=" + data?.sub_brand_id,
    )
  }

  const titleBrandName = _sub_brand?.title ?? data?.me?.brand?.title

  const isPolitical = data?.me?.brand?.settings?.is_political_dashboard_enabled
  const isPoliticalEngage = data?.me?.brand?.settings?.is_political_engage_enabled

  useLayoutEffect(() => {
    if (data?.me?.brand?.settings?.is_political_engage_enabled) {
      setBrandType(3)
    }
  }, [data?.me?.brand?.settings?.is_political_engage_enabled])

  return (
    <>
      {" "}
      {showBrandNameINput ? (
        <div className="padding-y-4x padding-x-5x">
          <input
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
            className="bear-input nc30-placeholder full-width button-medium-font"
            placeholder="give your search a name"
          />
        </div>
      ) : null}
      <WrappedInLoader data={brandTagsServer ?? {}} loader={brandTagsServerLoader}>
        {isSidebar ? (
          <div className="padding-x-6x flex a-center j-space-between">
            <h3 className="headline-medium-highlight"> Settings - {titleBrandName}</h3>
            <div className="flex a-center j-center nc50-fg cursor-pointer" onClick={toggleShowSettings}>
              {ThinTimesLargeMono}
            </div>
          </div>
        ) : null}

        {fromPopup ? (
          <>
            <h4 className="margin-bottom-2x headline-small-highlight">Type of space</h4>
            <div className="flex margin-bottom-2x">
              {!isPoliticalEngage && (
                <>
                  <Button
                    size="small"
                    type="tertiary"
                    className={`capsule-button margin-right-2x ${brandType === 1 ? "selected" : ""}`}
                    onClick={() => setBrandType(1)}
                  >
                    Competitor
                  </Button>
                  <Button
                    size="small"
                    type="tertiary"
                    className={`capsule-button margin-right-2x ${brandType === 2 ? "selected" : ""}`}
                    onClick={() => setBrandType(2)}
                  >
                    themes
                  </Button>
                </>
              )}
              {isPolitical || isPoliticalEngage ? (
                <Button
                  size="small"
                  type="tertiary"
                  className={`capsule-button margin-right-2x ${brandType === 3 ? "selected" : ""}`}
                  onClick={() => setBrandType(3)}
                >
                  {isPolitical ? "VP Candidates" : "Priority Post"}
                </Button>
              ) : null}
            </div>
          </>
        ) : (
          <div
            className={`${isSidebar ? "margin-top-6x" : ""}  creator-homepage-tabs flex border-bottom-1 border-top-1 nc05-border `}
          >
            {tabs.map((x, i) => (
              <Tab title={x.title} selected={i === selectedTab} key={i} onClick={() => setSelectedTab(i)} />
            ))}
            <Tab title={"Hashtags"} onClick={onDiscoverClick} />
          </div>
        )}

        {showBrandInputText ? (
          <div className="margin-bottom-2x">
            <h4 className=" margin-bottom-2x headline-small-highlight">Give your space a name</h4>
            <Input
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              className="nc30-placeholder full-width button-medium-font"
              placeholder="give your search a name"
            />
          </div>
        ) : null}
        <div className={`full  ${fromPopup ? "" : "padding-x-6x padding-top-6x"}  overflow-auto`}>
          {tabs[selectedTab].component}
        </div>

        <div
          className={`flex a-center no-flex-shrink ${fromPopup ? "" : "padding-x-6x margin-top-2x fade-gradient-top"}  j-space-between `}
        >
          {data?.sub_brand_id &&
            !newBrand &&
            (data?.me?.user_group != null || data?.me?.brand?.settings?.is_sub_brand_creation_enabled) && (
              <Button type="ghost" className="no-flex-shrink negative-fg-override" onClick={deleteHandler}>
                <div className="flex a-center j-center margin-right-2x">{TrashLargeFilledMono}</div>
                Delete Page
              </Button>
            )}

          {!data?.sub_brand_id && (data?.me?.user_group != null || data?.me?.brand?.settings?.is_term_edit_enabled) && (
            <div className="button-medium-font margin-right-2x nc30-fg">
              It might take up to five minutes to reflect the changes
            </div>
          )}

          {(data?.me?.user_group != null || data?.me?.brand?.settings?.is_term_edit_enabled) && (
            <>
              <Button className={`no-flex-shrink  ${fromPopup ? "margin-bottom-2x" : ""}`} onClick={saveHandler}>
                {isGuest ? <div className="flex a-center j-center margin-right-2x ">{Lock}</div> : null}
                {fromPopup ? "Create Space" : "Save changes"}
              </Button>
            </>
          )}
        </div>
      </WrappedInLoader>
    </>
  )
}

const SelfServeSideBar = ({ toggleShowSettings, isSubBrand, overallSpace }) => {
  return (
    <div className="inclution-exclution-settings-overlay darker-dialogoverlay " onClick={toggleShowSettings}>
      <div className="inclution-exclution-settings nc00-bg padding-y-7x flex column" onClick={_nopropagate}>
        <SelfServeSettings
          toggleShowSettings={toggleShowSettings}
          isSidebar
          isSubBrand={isSubBrand}
          overallSpace={overallSpace}
        />
      </div>
    </div>
  )
}

const SelfServeSidebarPage = ({ className, showBrandNameINput, overallSpace }) => {
  return (
    <Card className={`max-width-816 margin-x-auto margin-y-5x padding-bottom-5x overflow-hidden ${className}`}>
      <SelfServeSettings showBrandNameINput={showBrandNameINput} overallSpace={overallSpace} newBrand />
    </Card>
  )
}

function SearchSettingsSideBarContainer({ titleText = "Search settings", className = "", isSubBrand, overallSpace }) {
  const [data, dispatch] = useContext(dataContext)
  const toggleShowSettings = () => {
    dispatch({
      show_self_serve_brand_settings: !data?.show_self_serve_brand_settings,
    })
  }

  return (
    <>
      {" "}
      <div
        className={`flex a-center j-center padding-x-4x padding-y-2x cursor-pointer ${className}`}
        onClick={toggleShowSettings}
      >
        <div className="flex a-center j-center margin-right-2x">{SearchSetting}</div>
        <div className="headline-small-highlight nc75-fg">{titleText}</div>
      </div>
    </>
  )
}

const SelfServePopupHolder = () => {
  return (
    <div className="nc100-fg">
      <SelfServeSettings fromPopup={true} newBrand={true} showBrandInputText={true} />
    </div>
  )
}

const VideoLinkEdit = () => { }

const PriorityPostSettings = () => {
  const [linkInput, setLinkInput] = useState("")
  const [linkError, setLinkError] = useState(false)
  const [links, setLinks] = useState([])
  const [usernameInput, setUsernameInput] = useState("")
  const [userNames, setUserNames] = useState([])

  const [isUsernames, setIsUsernames] = useState(false)

  const { isGuest } = useAuthHelper()

  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const { payload, loader } = useBDBoundData({ section: "priority_posts" })

  const handleInputChange = (event) => {
    if (!event.target.value) {
      setLinkError(false)
    }
    setLinkInput(event.target.value ?? "")
  }
  const handleLinkEnterInput = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      // check if it is a valid link https:// or http://
      // const link = linkInput.trim();
      // if (!link.match(/^(http|https):\/\//)) {
      //     setLinkError(true);
      //     return;
      // }
      let inputLinks = getArrayFromSeperatorString(linkInput, ",", true)

      if (inputLinks.length === 0) {
        return
      }

      if (inputLinks.some((x) => !x.match(/^(http|https):\/\//))) {
        setLinkError(true)
        return
      } else {
        setLinkError(false)
      }

      // check payload?.external_urls has any link from inputLinks and remove it from inputLinks
      const tempLinks = inputLinks.filter((x) => !payload?.external_urls?.includes(x))
      setLinks(combineSimpleArrayAndRemoveDuplicates([...tempLinks, ...links]))

      setLinkInput("")
    }
  }

  const handleUsernameEnterInput = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      const inputNames = getArrayFromSeperatorString(usernameInput, ",", true)

      if (inputNames.length === 0) {
        return
      }

      const tempUserNames = inputNames.filter((x) => !payload?.tiktok_profiles?.includes(x))
      setUserNames(combineSimpleArrayAndRemoveDuplicates([...tempUserNames, ...userNames]))

      setUsernameInput("")
    }
  }

  const toggleShowSettings = () => {
    dispatch({
      show_priority_post_settings: !data?.show_priority_post_settings,
    })
  }

  const saveHandler = async () => {
    if (!data?.sub_brand_id) return

    const section = "brand_priority_posts"

    const subBrandPayloadCommon = {
      is_sub_brand: true,
      tiktok_profiles: [...userNames, ...(payload?.tiktok_profiles ?? [])],
      external_urls: links,
    }

    api.common
      .postRequestHelper({
        endpoint: `/Brand/${data?.sub_brand_id}/`,
        section: section,
        payload: subBrandPayloadCommon,
        dataContextPrefix: "bd_sec_",
        loaders: ["bd-section-default", `bd-section-${section}`],
        requestType: "patch",
      })
      .then((res) => {
        if (res) {
          api?.alert?.SearchSettings()
          dispatch({
            show_priority_post_settings: false,
          })
        }
      })

    // const payload = {
    //     external_urls: links,
    //     brand_id: data?.sub_brand_id
    // }
    // const section = 'brand_priority_posts'
    // api.common.postRequestHelper({
    //     endpoint: "/Content/priority_content/",
    //     section: section,
    //     payload: { ...payload },
    //     dataContextPrefix:"bd_sec_",
    //     loaders:["bd-section-default",`bd-section-${section}`],
    //     requestType: "post"
    // }).then(res=>{
    //     if(res){
    //         api?.alert?.SearchSettings()
    //         dispatch({
    //             show_priority_post_settings:false
    //         })
    //     }
    // })
  }
  const deleteHandler = async () => {
    if (isGuest) {
      api?.alert?.unlockFullExperience()
      return
    }
    api?.alert?.deleteSubBrand()

    dispatch({
      show_priority_post_settings: false,
    })
  }

  const removeLinks = (index) => {
    setLinks(links.filter((item, i) => i !== index))
  }
  const removeUsernames = (index) => {
    setUserNames(userNames.filter((item, i) => i !== index))
  }

  return (
    <div className="inclution-exclution-settings-overlay darker-dialogoverlay " onClick={toggleShowSettings}>
      <div className="inclution-exclution-settings nc00-bg padding-y-7x flex column" onClick={_nopropagate}>
        <WrappedInLoader data={payload ?? {}} loader={loader}>
          <div className="padding-x-6x flex a-center j-space-between">
            <h3 className="headline-medium-highlight">Settings - Priority Posts</h3>
            <div className="flex a-center j-center nc50-fg cursor-pointer" onClick={toggleShowSettings}>
              {ThinTimesLargeMono}
            </div>
          </div>
          <div className={`margin-top-6x creator-homepage-tabs flex border-bottom-1 border-top-1 nc05-border `}>
            <Tab title={"Video links"} selected={!isUsernames} onClick={() => setIsUsernames(false)} />
            <Tab title={"Tiktok usernames"} selected={isUsernames} onClick={() => setIsUsernames(true)} />
          </div>
          {isUsernames ? (
            <div className={`full padding-x-6x padding-top-6x overflow-auto`}>
              <h4 className=" margin-bottom-2x headline-small-highlight">Tiktok Usernames</h4>
              <Input
                value={usernameInput}
                onKeyDown={handleUsernameEnterInput}
                onChange={(e) => setUsernameInput(e.target.value)}
                placeholder={`type and hit enter to add username...`}
                className="margin-bottom-2x"
              />
              <div className="flex wrapped margin-top-4x gap-2">
                {userNames.map((x, i) => (
                  <ChipWithDelete
                    className="min-width-0"
                    hideRemoveIcon={false}
                    key={i}
                    title={`${x}`}
                    onRemove={() => {
                      removeUsernames(i)
                    }}
                  />
                ))}
                {payload?.tiktok_profiles?.map((x, i) => (
                  <ChipWithDelete className="min-width-0" hideRemoveIcon={true} key={i} title={`${x}`} />
                ))}
              </div>
            </div>
          ) : (
            <div className={`full padding-x-6x padding-top-6x overflow-auto`}>
              <h4 className=" margin-bottom-2x headline-small-highlight">Video links</h4>
              <Input
                value={linkInput}
                onKeyDown={handleLinkEnterInput}
                onChange={handleInputChange}
                placeholder={`type and hit enter to add video link...`}
                className="margin-bottom-2x"
              />
              {linkError && (
                <div className="negative-fg small-font-highlight margin-bottom-2x">
                  Please enter valid links starting with http:// or https://
                </div>
              )}
              <div className="flex wrapped margin-top-4x gap-2">
                {links.map((x, i) => (
                  <ChipWithDelete
                    className="min-width-0"
                    hideRemoveIcon={false}
                    key={i}
                    title={`${x}`}
                    onRemove={() => {
                      removeLinks(i)
                    }}
                  />
                ))}
                {payload?.external_urls?.map((x, i) => (
                  <ChipWithDelete className="min-width-0" hideRemoveIcon={true} key={i} title={`${x}`} />
                ))}
              </div>
            </div>
          )}

          <div className={`flex a-center no-flex-shrink padding-x-6x margin-top-2x fade-gradient-top j-space-between `}>
            {data?.sub_brand_id ? (
              <Button type="ghost" className="no-flex-shrink negative-fg-override" onClick={deleteHandler}>
                <div className="flex a-center j-center margin-right-2x">{TrashLargeFilledMono}</div>
                Delete
              </Button>
            ) : (
              <div className="button-medium-font margin-right-2x nc30-fg">
                It might take up to five minutes to reflect the changes
              </div>
            )}

            <Button className={`no-flex-shrink margin-bottom-2x`} onClick={saveHandler}>
              Save changes
            </Button>
          </div>
        </WrappedInLoader>
      </div>
    </div>
  )
}

export {
  SelfServeSideBar,
  SelfServeSettings,
  SelfServeSidebarPage,
  SearchSettingsSideBarContainer,
  SelfServePopupHolder,
  PriorityPostSettings,
}
