import React from "react"
import PropTypes from "prop-types"

export const Tab = ({
  value,
  title,
  onChange,
  selected,
  subTitle,
  type = "regular",
  icon,
  className = "",
  innert = false,
  mobileStyle = "tab-change-on-mobile",
}) => {
  const activeClass = selected ? "selected" : ""
  return (
    <button
      className={`tab ${className} ${innert ? "innert" : ""} ${subTitle ? "sub-title" : ""} ${activeClass} ${type} ${icon ? "with-icon" : ""} ${mobileStyle}`}
      onClick={() => onChange(value)}
    >
      {icon ? <div className="icon-container-large no-flex-shrink tab-icon">{icon}</div> : null}
      <div className="content">
        <div className="main-text">
          {title ? title : value} {subTitle ? <span className="sub-text">({subTitle})</span> : null}
        </div>
      </div>
    </button>
  )
}

Tab.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(["capsules", "regular", "underlined"]),
  icon: PropTypes.node,
}
