import React, { useContext, useEffect } from "react"
import { BookingLanding } from "./newContentBooking/bookingLandingPage"
import { dataContext } from "../../contexts/datacontext"
import { apiContext } from "../../contexts/apicontext"
function BookingPage(props) {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)

  return <BookingLanding />
}

export { BookingPage }
