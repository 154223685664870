import React, { useState } from "react"
import PropTypes from "prop-types"
import ConfettiImage from "./../../assets/confetti.jpg"
import zlogo from "../../assets/zelflogo.png"
import { Button } from "../button"
import { OnboardingCreatorContainer } from "../onboadingCommon"
import { useNavigate } from "react-router-dom"
import { useContext, useEffect } from "react"
import { FullLoader, Loader } from "../loaders"
import { getQueryParam, _lsw, _lsd, _uri_atob, _jp, decodeDoubleEncodedBase64 } from "../../helpers/utils"
import { apiContext } from "../../contexts/apicontext"
import { dataContext } from "../../contexts/datacontext"
import { useLoading } from "../../contexts/loaderContext"
import { NewLineParser } from "../dumbs"
import zelfLogo2024 from "../../assets/zelf_logo_2024.webp"

const OnBoardingCreatorInvitedByX = (props) => {
  const { loaders } = useLoading()
  const api = useContext(apiContext)
  const [data] = useContext(dataContext)
  const [invite_config, set_invite_config] = useState(null)
  const brandName = data?.brand_ecosystem_brand?.name ?? "Brands"
  const brandLogo = data?.brand_ecosystem_brand?.brand_logo
  const brandID = data?.brand_ecosystem_brand?.id
  const political = getQueryParam(window.location.search, "political")
  const isPoliticalEngage = data?.brand_ecosystem_brand?.is_political_engage_enabled || data?.brand_ecosystem_brand?.settings?.is_political_engage_enabled

  const refcode = getQueryParam(window.location.search, "refcode")
  // we have to use our own _uri_bota otherwise we get artifact

  const inviteConfigParam = getQueryParam(window.location.search, "invite_config")




  const navigate = useNavigate()

  useEffect(() => {
    console.log("delete_previous_invites")
    _lsd("brand-ecosystem-invite-refcode")
  }, [])

  useEffect(() => {
    if (refcode) {
      ; (async () => {
        let isValidInviteId = await api.creator.getBrandInfoFromInviteIDOrBrandID(refcode)
        if (isValidInviteId) {
          return
        }
      })()
    } else {
      navigate("/")
    }
    //
    // window.location.href = "https://hellozelf.com";
  }, [refcode])

  useEffect(() => {
    if (!refcode) return
    if (!brandID) return
    try {
      const _invite_config = decodeDoubleEncodedBase64(inviteConfigParam)
      set_invite_config(_invite_config)
      if (_invite_config) {
        _lsw("invite_config", _invite_config)
      } else {
        // remove old stuff
        _lsd("invite_config")
      }
    } catch (e) { }
  }, [inviteConfigParam, brandID])

  const onContinueClick = async () => {
    const me = await api.common.me()
    // if(!me) return _logout("No Auth0 Me");
    _lsw("cs_last_set_mode", "creator")
    _lsw("brand-ecosystem-invite-refcode", refcode)
    if (!me?.creator) {
      api.login(true)
      return
    }

    navigate("/start-description")
    // _lsd("brand-ecosystem-invite-refcode");
  }

  if (loaders["get_brand_invite_info"]) {
    return <FullLoader />
  }
  //let customText = "Join Daiso’s back-to-school campaign:\n\n• earn $5 per 1000 views generated\n• chance to win $1,000 shopping spree\n• get invited to future campaigns";
  let customText = invite_config?.invite_text

  let terms_and_condition_link = invite_config?.terms_and_condition_link

  return (
    <OnboardingCreatorContainer>
      <div className="creator-invited-by-x-header-container padding-y-5x creator-invited-by-x-header-container">
        {isPoliticalEngage ? null : <div className="confetti-container">
          <img className="confetti-img" src={ConfettiImage} alt="confetti" />
        </div>}
        {isPoliticalEngage ? <>
          <h2 className="headline-medium margin-top-15x margin-bottom-4x creator-invited-by-x-header-text txt-center">
            you are about to join the {brandName} TikTok volunteering environment. Click join below to create your profile.
          </h2>


        </> : <>
          <h2 className="headline-medium-font margin-top-15x margin-bottom-8x creator-invited-by-x-header-text txt-center">
            {invite_config?.invite_title ? (
              <>{invite_config?.invite_title}</>
            ) : (
              <>You have been invited to {brandName}’s creator ecosystem</>
            )}
          </h2>
          <p
            className={`body-regular-font nc100-fg ${!customText ? "txt-center" : ""} creator-invited-by-x-header-text margin-x-auto  margin-bottom-2x max-width-328`}
          >
            {!customText ? (
              `As a member of the ${brandName} creator ecosystem you will receive opportunities to work with ${brandName} and get paid seamlessly, without having to send invoices. Easy for you, easy for us.`
            ) : (
              <NewLineParser value={customText} />
            )}
          </p>
        </>}
      </div>

      {isPoliticalEngage ? <>
        <div className='flex'>
          <img src={zelfLogo2024} aria-hidden="true" style={{ maxWidth: 191 }} alt="Kamala 2024 powered by Zelf." />
        </div>
      </> : <>
        <div className="">
          {brandLogo ? (
            <img className="invitee-brand-logo margin-top-4x" src={brandLogo} alt={brandName} />
          ) : (
            <h2 className="headline-large margin-top-4x">{brandName}</h2>
          )}
        </div>
        <div className="nc50-fg margin-y-3x">
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.7658 4.37863L9.45445 7.69001L12.7955 11.0311L11.8303 11.9963L8.48925 8.65521L5.19272 11.9517L4.30176 11.0608L7.5983 7.76425L4.25722 4.42317L5.22242 3.45797L8.5635 6.79905L11.8749 3.48767L12.7658 4.37863Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <div></div>
        <img className="onboarding-brand-colab-zelf-logo " src={zlogo} alt="zelf" />
      </>}
      <div className="flex column a-center margin-y-10x full-width">
        <Button className="max-width-328 " fluid size="large" onClick={onContinueClick}>
          {loaders["accept_brand_invite_info"] ? <Loader /> : "Join"}
        </Button>
        {terms_and_condition_link ? (
          <p className="margin-y-3x txt-center nc50-fg caption ">
            By clicking the Join button, you accept the Zelf x <>{brandName}</>{" "}
            <a className="caption-highlight " target="_blank" href={terms_and_condition_link}>
              Terms & Conditions
            </a>
          </p>
        ) : null}
      </div>
    </OnboardingCreatorContainer>
  )
}

export { OnBoardingCreatorInvitedByX }
