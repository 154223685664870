import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { ChevronFrowardSmallOutlinedMono } from "./Icons"

const BreadCrumbsItem = ({ itemNumber, itemIndex, item, active, last, onItemClick, className }) => {
  const onClickHandler = () => {
    onItemClick && onItemClick(itemIndex, item)
  }

  return (
    <li className={`flex a-center  bread-crumb-item ${className}`} onClick={onClickHandler}>
      <div
        className={`margin-right-2x bread-crumb-number flex j-center a-center ${active ? "nc10-bg nc75-fg" : "nc30-fg"}`}
      >
        {" "}
        {itemNumber}{" "}
      </div>
      <div className={` ${active ? "headline-small-highlight nc75-fg" : "caption-regular nc50-fg"}`}>{item}</div>
      {!last ? (
        <div className={"flex j-center a-center nc50-fg margin-left-2x pointer-event-none"}>
          {ChevronFrowardSmallOutlinedMono}
        </div>
      ) : null}
    </li>
  )
}

const BreadCrumbs = ({
  list,
  activeIndex,
  onItemClick,
  className = "",
  enableMobileFadeStyle,
  scrollableBreadcrumb,
}) => {
  const ref = useRef(null)

  const scrollLeft = () => {
    if (ref?.current) {
      // console.log(ref.current.childNodes)
      let scrollAmount = 0
      for (let i = 0; i < activeIndex; i++) {
        //console.log({i,activeIndex})
        scrollAmount = scrollAmount + ref.current.childNodes[i].clientWidth + 5
      }
      // console.log(scrollAmount)
      ref.current.scrollLeft = scrollAmount
    }
  }

  useEffect(() => {
    scrollLeft()
  }, [activeIndex])

  return (
    <div className={`flex a-center ${enableMobileFadeStyle ? "fade-bread-crumbs-container" : ""} ${className} `}>
      <ol
        ref={ref}
        className={`bread-crumbs ${scrollableBreadcrumb ? "scrollable-breadcrumb" : ""} ${enableMobileFadeStyle ? "fade-bread-crumbs" : ""} flex a-center full `}
      >
        {list.map((item, index) => (
          <BreadCrumbsItem
            onItemClick={onItemClick}
            className={`margin-right cursor-pointer`}
            key={index}
            item={item}
            itemNumber={index + 1}
            itemIndex={index}
            active={index === activeIndex}
            last={list.length === index + 1}
          />
        ))}
        {enableMobileFadeStyle ? (
          <div className=" hide-on-desktop breadcrumb-mobile-filler opacity-0">filler for mobile</div>
        ) : null}
      </ol>
      {enableMobileFadeStyle ? (
        <div className="hide-on-desktop body-thin-font breadcrumb-mobile-counter nc50-fg">
          {activeIndex + 1} of {list.length}
        </div>
      ) : null}
    </div>
  )
}

BreadCrumbs.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string),
  activeIndex: PropTypes.number,
  onItemClick: PropTypes.func,
  className: PropTypes.string,
  enableMobileFadeStyle: PropTypes.bool,
}

export { BreadCrumbs }
