import { useAuth0 } from "@auth0/auth0-react"
import { dataContext } from "../../contexts/datacontext"
import { useLoading } from "../../contexts/loaderContext"
import { FullLoader } from "../loaders"
import { useContext } from "react"
import { Navigate } from "react-router-dom"
import { useAuthHelper } from "../../hooks/useAuthHelper"

function Authenticated({ children, allowGuest = false }) {
  const [data] = useContext(dataContext)
  const { isAuthenticated, isLoading } = useAuthHelper()
  if ((!isAuthenticated || isLoading) && !(allowGuest && data?.me?.is_guest_user)) return null
  return <>{children}</>
}

function Unauthenticated({ children, showWhileLoading = true, disAllowGuest = false }) {
  const { isAuthenticated, isLoading } = useAuthHelper()
  const [data] = useContext(dataContext)
  if (isAuthenticated) return null
  if ((isLoading && !showWhileLoading) || (disAllowGuest && data?.me?.is_guest_user)) return null
  return <>{children}</>
}

function WhileAuthLoading({ children }) {
  const { isLoading } = useAuthHelper()
  if (!isLoading) return null
  return <>{children}</>
}

function IfGuest({ children }) {
  const { isGuest } = useAuthHelper()
  if (isGuest) return children
  return null
}
function IfNotGuest({ children }) {
  const { isGuest } = useAuthHelper()
  if (!isGuest) return children
  return null
}

function IfCreator({ children, current = false, redirect = false, showLoader = true }) {
  const [data] = useContext(dataContext)
  const { loaders } = useLoading()
  if (loaders.me && showLoader) return <FullLoader />
  if (loaders.me && !showLoader) return null
  if (data?.role !== "creator") {
    if (redirect) return <Navigate to="/" />
    else return null
  }
  if (current && data?.creator_name !== data?.me?.username) {
    if (redirect) return <Navigate to="/" />
    else return null
  }
  return <Authenticated>{children}</Authenticated>
}

function IfBrand({ children, redirect = false, showLoader = true }) {
  const [data] = useContext(dataContext)
  const { loaders } = useLoading()
  if (loaders.me && showLoader) return <FullLoader />
  if (loaders.me && !showLoader) return null
  if (data?.me?.role !== "BRAND_USER" || !data?.me?.brand) {
    if (redirect) return <Navigate to="/" />
    else return null
  }
  return <Authenticated>{children}</Authenticated>
}

export { Authenticated, Unauthenticated, WhileAuthLoading, IfCreator, IfBrand, IfGuest, IfNotGuest }
