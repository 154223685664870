import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { dataContext } from "../../contexts/datacontext"
import { Button } from "../button"

const PreviewMessage = ({ data }) => {
  const navigate = useNavigate()
  return (
    <>
      <div>
        This is what brands see<span className="hide-on-mobile"> when they visit your profile</span>
      </div>{" "}
      <Button
        className="margin-left-2x margin-y-2x header-banner-button"
        onClick={() => navigate(`/creator-dashboard`)}
        type="primary"
        size="small"
      >
        Go To Dashboard
      </Button>
    </>
  )
}

const OnCreatorOwnHome = ({ data }) => {
  const navigate = useNavigate()
  return (
    <>
      <div>
        See what brands see<span className="hide-on-mobile"> when they visit your profile</span>.
      </div>{" "}
      <Button
        className="margin-left-2x margin-y-2x header-banner-button"
        onClick={() => navigate(`/${data?.creator_name}`)}
        type="primary"
        size="small"
      >
        See Public Profile
      </Button>
    </>
  )
}

const STDBanner = () => {
  return (
    <div className="padding-y-4x txt-center">Easily license organic content from creators on all major platforms.</div>
  )
}

const HeaderComponent = ({ className = "" }) => {
  const [data] = useContext(dataContext)
  if (data?.is_me_creator) {
    if (!data?.on_creator_dashboard) return <PreviewMessage data={data} />
    else return <OnCreatorOwnHome data={data} />
  } else return null
}

function HeaderBanner() {
  const [data] = useContext(dataContext)
  return (
    <div className="cs-header-banner ultralight-bg flex hcenter vcenter padding-x-2x">
      <div className="headline-small-highlight flex vcenter hcenter header-banner-content">
        <HeaderComponent />
      </div>
    </div>
  )
}

export { HeaderBanner }
