import { useAuth0 } from "@auth0/auth0-react"
import { useContext, useEffect } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { Loader } from "../loaders"
import { useSocial } from "../../hooks/useSocial"
import { dataContext } from "../../contexts/datacontext"
import { useAuthHelper } from "../../hooks/useAuthHelper"

function ReconnectLanding({ platform }) {
  const { isLoading, isAuthenticated } = useAuthHelper()
  const navigate = useNavigate()
  const { reconnect } = useSocial()
  const [data] = useContext(dataContext)

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) navigate("/")
      else if (isAuthenticated && data.role && data.role !== "creator") navigate("/")
      else if (isAuthenticated && data?.role === "creator" && data.me) {
        if (data.me.connected_accounts[platform]?.connection_status_is_healthy === false) reconnect(platform)
        else navigate("/")
      }
    }
  }, [isLoading, isAuthenticated, data.me])

  if (!isLoading && !isAuthenticated) return <Navigate to="/" />
  return (
    <div className="callback-page flex hcenter margin-10x">
      <Loader className="margin-right-2x" />
      <div className="wait-text txt-center">Please wait...</div>
    </div>
  )
}

export { ReconnectLanding }
