import { useContext, useEffect, Fragment } from "react"
import { dataContext } from "../../contexts/datacontext"
import { useState } from "react"
import { _nopropagate, num2Hip, moveToSubBrandPage } from "../../helpers/utils"
import { useNavigate, useSearchParams, createSearchParams, Outlet, useLocation } from "react-router-dom"
import { apiContext } from "../../contexts/apicontext"
import { Lock, PlusMediumFilledMono } from "./../Icons"
import { IfGuest, IfNotGuest } from "../functional/auth_components"
import { UnlockFullExperience } from "../structural/guestUnlockUI"
import {
  PriorityPostSettings,
  SelfServeSideBar,
  SelfServeSidebarPage,
} from "../structural/BrandEcosystem/selfServeSearchSettings"
import { useBrandConfig, useSelfServerSearchSettingsHelper } from "../../hooks/helperHooks"
import { Loader } from "../loaders"
import { PoliticalDashboard } from "./politicalDashboard"

function BrandTabLink({ title, className, selected, children, href, soft = false, onClick = null, ...props }) {
  const navigate = useNavigate()
  const _go = (e) => {
    if (href) {
      navigate(href)
    }
    onClick && onClick()
  }
  const classes = `brand-dashboard-tab flex vcenter headline-small-highlight nc100-fg cursor-pointer padding-4x border-bottom-2 nc00-border ${selected ? "bd-tab-selected primary-border" : ""} ${className}`
  if (soft)
    return (
      <div onClick={_go} className={classes} {...props}>
        {children ? children : title}
      </div>
    )
  return (
    <a href={href} className={classes} {...props}>
      {children ? children : title}
    </a>
  )
}
function BrandTabLocked({ title, className }) {
  const api = useContext(apiContext)
  return (
    <div
      onClick={api?.alert?.unlockFullExperience}
      className={`brand-dashboard-tab flex vcenter headline-small-highlight nc75-fg cursor-pointer padding-4x border-bottom-2 nc00-border  ${className}`}
    >
      <span className="margin-right">{Lock}</span> {title}
    </div>
  )
}


function BrandEcosystem({ _tab = "discovery", _subtab = "content", _campaignTab = "live", hideTabs = false }) {
  const [isFoundMeData, setIsFoundMeData] = useState(false)
  const [tab, setTab] = useState(_tab) // discovery, circle, campaigns
  const [campaignTab, setCampaignTab] = useState(_campaignTab) // live, draft
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const { is_mass_engage_enabled, is_campaign_enabled, is_circle_enabled } = useBrandConfig()
  const [searchParams, setSearchParams] = useSearchParams()
  const { toggleShowSettings } = useSelfServerSearchSettingsHelper()

  useEffect(() => {
    if (data?.me) {
      setIsFoundMeData(true)
    }
  }, [data])

  if (!isFoundMeData) {
    return (
      <div className="content-area">
        <div className="flex j-center v-center padding-top-10x padding-bottom-10x">
          <div className="flex j-center v-center">
            <Loader />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="brand-ecosystem-root brand-ecosystem-main-page-root nc02-bg padding-bottom-10x">
      <div className="brand-ecosystem-header nc00-bg">
        <div className="flex brand-header-items-left content-area">
          <div className="full">
            <div className="brand-ecosystem-header-logo-actions flex spreaded vcenter margin-top-10x padding-bottom-8x">
              <img
                className="brand-eco-brand-logo"
                src={data?.me?.brand?.brand_logo ?? ""}
                alt={data?.me?.brand?.title}
              ></img>
            </div>
          </div>
          <IfGuest>
            <UnlockFullExperience compact={true} action={true} />
          </IfGuest>
        </div>
      </div>
      {data?.show_self_serve_brand_settings ? <SelfServeSideBar toggleShowSettings={toggleShowSettings} /> : null}
      {data?.show_priority_post_settings ? <PriorityPostSettings /> : null}

      <Outlet />

      <IfGuest>
        {data?.bd_sec_all_posts?.length || data?.bd_sec_all_creators_by_brand?.length ? (
          <div className="brand-ecosystem-cover-for-guest flex hcenter vcenter">
            <div className="be-guest-dialog-holder padding-bottom-4x padding-top-2x">
              <UnlockFullExperience action={true} />
            </div>
          </div>
        ) : null}
      </IfGuest>
    </div>
  )
}

const SubBrandNames = ({ selected, hideNew, newCompetitor, overallSpace, customSubBrandClick }) => {
  const [subtab, setSubTab] = useState() //home, content, creator, tag
  const [data, dispatch] = useContext(dataContext)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const _subBrands = data?.me?.brand?.sub_brands ?? []

  const subBrands = _subBrands?.filter((x) => (overallSpace ? 2 : 1) == x.sub_brand_type)

  const onSubBrandClick = (subBrand) => {
    if (customSubBrandClick) {
      customSubBrandClick(subBrand)
      return
    }
    moveToSubBrandPage(dispatch, navigate, subBrand, overallSpace)
  }
  const currentTabID = searchParams.get("sub_brand_id")

  return (
    <div>
      <div className="brand-ecosystem-sub-tabs border-bottom-1 nc05-border nc00-bg">
        <div className=" flex content-area ">
          {hideNew ? null : (
            <BrandTabLink
              href={overallSpace ? "/overall-space-new" : `/competitor-self-serve-new`}
              selected={!!newCompetitor && !currentTabID}
              soft
            >
              {" "}
              <span className="nc75-fg flex a-center">
                {" "}
                {PlusMediumFilledMono} <span className="padding-left-2x"> New</span>
              </span>{" "}
            </BrandTabLink>
          )}
          {subBrands?.map((x, i) => (
            <Fragment key={i}>
              <IfNotGuest>
                <BrandTabLink
                  soft
                  title={x?.title}
                  selected={currentTabID == x.id}
                  key={i}
                  onClick={() => onSubBrandClick(x)}
                />
              </IfNotGuest>
              <IfGuest>
                <BrandTabLocked
                  soft
                  title={x?.title}
                  selected={currentTabID == x.id}
                  key={i}
                  onClick={() => onSubBrandClick(x)}
                />
              </IfGuest>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

function ReportingAndAnalysis() {
  const [isFoundMeData, setIsFoundMeData] = useState(false)
  const { pathname } = useLocation()
  const [data, dispatch] = useContext(dataContext)
  useEffect(() => {
    dispatch({
      discovery_section_platform: "tiktok",
    })
  }, [])

  useEffect(() => {
    if (data?.me) {
      setIsFoundMeData(true)
    }
  }, [data])

  if (!isFoundMeData) {
    return (
      <div className="content-area">
        <div className="flex j-center v-center padding-top-10x padding-bottom-10x">
          <div className="flex j-center v-center">
            <Loader />
          </div>
        </div>
      </div>
    )
  }
  if (data?.me?.brand?.settings?.is_political_dashboard_enabled) {
    return <PoliticalDashboard />
  }

  return (
    <>
      <div className="brand-ecosystem-main-tabs   brand-ecosystem-main-tabs border-bottom-1 nc05-border  nc00-bg">
        <div className="be-main-tab-inner content-area flex j-space-between a-center">
          <div className="flex">
            {!data?.me?.brand?.settings?.is_political_engage_enabled && (
              <BrandTabLink title="Content" selected={pathname === "/brand-dashboard"} href="/brand-dashboard" soft />
            )}
            <BrandTabLink
              title="Comments"
              selected={pathname?.includes("/brand-dashboard/comments")}
              href="/brand-dashboard/comments"
              soft
            />
          </div>
        </div>
      </div>
      <div className="content-area">
        <Outlet />
      </div>
    </>
  )
}

const DiscoverContent = () => {
  const [data, dispatch] = useContext(dataContext)
  const { pathname } = useLocation()

  return (
    <>
      <div className="brand-ecosystem-main-tabs   brand-ecosystem-main-tabs border-bottom-1 nc05-border  nc00-bg">
        <div className="be-main-tab-inner content-area flex j-space-between a-center">
          <div className="flex">
            <BrandTabLink title="Discovery" selected={pathname === "/discover-content"} soft href="/discover-content" />
            <IfNotGuest>
              <BrandTabLink
                title="Tracking"
                selected={pathname?.includes("/discover-content/tracking-boards")}
                soft
                href="/discover-content/tracking-boards"
              />
              {/* <BrandTabLink title="Detractors & Fans" selected={pathname?.includes("/discover-content/creator-boards")}  soft href="creator-boards"/> */}
              <BrandTabLink
                title="Saved content"
                soft
                selected={pathname?.includes("/discover-content/saved-content-boards")}
                href="/discover-content/saved-content-boards"
              />
            </IfNotGuest>
            <IfGuest>
              <BrandTabLocked title="Tracking" />
            </IfGuest>
          </div>
        </div>
      </div>

      <div className="content-area">
        <Outlet />
      </div>
    </>
  )
}

const DiscoverCreator = () => {
  const [data, dispatch] = useContext(dataContext)
  const { pathname } = useLocation()
  const { is_circle_enabled, is_campaign_enabled } = useBrandConfig()

  return (
    <>
      <div className="brand-ecosystem-main-tabs   brand-ecosystem-main-tabs border-bottom-1 nc05-border  nc00-bg">
        <div className="be-main-tab-inner content-area flex j-space-between a-center">
          <div className="flex">
            <BrandTabLink
              title="Discover Creators"
              selected={pathname === "/discover-creator"}
              soft
              href="/discover-creator"
            />
            <IfNotGuest>
              <BrandTabLink
                title="Saved Creators"
                selected={pathname?.includes("/discover-creator/creator-boards")}
                soft
                href="/discover-creator/creator-boards"
              />
            </IfNotGuest>

            {is_circle_enabled ? (
              <BrandTabLink
                title="Creator Circle"
                selected={pathname === "/discover-creator/creator-circle"}
                soft
                href="/discover-creator/creator-circle"
              />
            ) : null}
            {is_campaign_enabled ? (
              <BrandTabLink
                title="Campaign"
                selected={pathname === "/discover-creator/campaigns"}
                soft
                href="/discover-creator/campaigns"
              />
            ) : null}
            <IfGuest>
              <BrandTabLocked title="Creator Boards" />
            </IfGuest>
          </div>
        </div>
      </div>

      <div className="content-area">
        <Outlet />
      </div>
    </>
  )
}

export { BrandEcosystem, ReportingAndAnalysis, SubBrandNames, BrandTabLink, DiscoverContent, DiscoverCreator }
