import axios from "axios"
import { flattenPromise, _lsw, _lsr } from "../helpers/utils"
import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState } from "react"
import { API_BASE, ENV, API_TIMEOUT, AUTH0_TOKEN_CONF, DEFAULT_CACHE_VALIDITY, CACHE_PREFIX } from "../consts"
import { useAuthHelper } from "./useAuthHelper"

const LOG_HTTP_ERRORS = Boolean(ENV === "development")

const server = axios.create({
  baseURL: API_BASE,
  timeout: API_TIMEOUT,
})

function resourceCacheName(name) {
  if (!name) return ""
  return `${CACHE_PREFIX}${name.replace(/[/?=&-]/gi, "_")}`
}

function tryCache(resource, cacheSeparationId, ttl) {
  const cacheName = `${resourceCacheName(resource)}_${cacheSeparationId}`
  const cachedData = _lsr(cacheName)
  if (cachedData) {
    const now = Date.now()
    if (ttl === 0) return cachedData.data
    else if ((now - cachedData?.createdAt) / 1000 < ttl) {
      // Seconds
      console.log(`[Info] Found valid cache. key: ${cacheName} ... Serving...`)
      return cachedData.data
    }
  }
  return null
}

function createCache(data, resource, cacheSeparationId) {
  const cacheName = `${resourceCacheName(resource)}_${cacheSeparationId}`
  const cachePayload = {
    createdAt: Date.now(),
    data: data,
  }
  _lsw(cacheName, cachePayload)
}

function useServer() {
  const { getAccessTokenSilently, isAuthenticated } = useAuthHelper()
  const getAxiosConfig = async (authenticated, additional_headers = {}) => {
    let axiosConfig = {
      headers: {
        ...additional_headers,
      },
    }
    if (authenticated && isAuthenticated) {
      const token = await getAccessTokenSilently(AUTH0_TOKEN_CONF)
      axiosConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
          ...additional_headers,
        },
      }
    } else if (sessionStorage.getItem("guest_access_token")) {
      axiosConfig = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("guest_access_token")}`,
          ...additional_headers,
        },
      }
    }
    return axiosConfig
  }
  const get = async (resource, authenticated = true, additional_headers = {}, forceThrowOriginalError = false) => {
    const config = await getAxiosConfig(authenticated, additional_headers)
    const [data, err] = await flattenPromise(server.get(resource, config))
    if (forceThrowOriginalError) {
      throw err
    }
    if (LOG_HTTP_ERRORS && err) console.error(err)
    return data?.data ?? null
  }

  const _delete = async (resource, payload = {}, authenticated = true, additional_headers = {}) => {
    const config = await getAxiosConfig(authenticated, additional_headers)
    const [data, err] = await flattenPromise(
      server({
        method: "DELETE",
        url: resource,
        data: payload,
        ...config,
      }),
    )
    if (LOG_HTTP_ERRORS && err) console.error(err)
    return data?.data ?? null
  }

  const post = async (
    resource,
    payload = {},
    authenticated = true,
    verb = "post",
    additional_headers = {},
    forceThrowOriginalError = false,
  ) => {
    const config = await getAxiosConfig(authenticated, additional_headers)
    const [data, err] = await flattenPromise(server[verb](resource, payload, config))
    if (LOG_HTTP_ERRORS && err) console.error(err)
    if (err && forceThrowOriginalError) throw err
    return data?.data ?? null
  }
  const patch = (resource, payload, authenticated, forceThrowOriginalError = false) =>
    post(resource, payload, authenticated, "patch", {}, forceThrowOriginalError)

  const put = (resource, payload, authenticated) => post(resource, payload, authenticated, "put")

  // axios delete is different
  const deleteReq = async (resource, payload = {}, authenticated = true, additional_headers = {}) => {
    const config = await getAxiosConfig(authenticated, additional_headers)
    try {
      const data = await server.delete(resource, { ...config, data: payload })
      return data ?? null
    } catch (e) {
      if (LOG_HTTP_ERRORS) console.error(e)
    }
  }

  const getCacheFirst = async (
    resource,
    authenticated = true,
    cacheSeparationId = "",
    force_refetch = false,
    ttl = DEFAULT_CACHE_VALIDITY,
  ) => {
    if (!force_refetch) {
      const cachedData = tryCache(resource, cacheSeparationId, ttl)
      if (cachedData) return cachedData
      console.log(`[Info] No cache found. fetching data...`)
    }
    const data = await get(resource, authenticated)
    if (!data) return null
    createCache(data, resource, cacheSeparationId)
    return data
  }

  const postCacheFirst = async (
    resource,
    payload = {},
    authenticated = true,
    cacheSeparationId = "",
    force_refetch = false,
    ttl = DEFAULT_CACHE_VALIDITY,
  ) => {
    if (!force_refetch) {
      const cachedData = tryCache(resource, cacheSeparationId, ttl)
      if (cachedData) return cachedData
      console.log(`[Info] No cache found. making request...`)
    }
    const data = await post(resource, payload, authenticated)
    if (!data) return null
    createCache(data, resource, cacheSeparationId)
    return data
  }

  return {
    get,
    post,
    patch,
    put,
    deleteReq,
    delete: _delete,
    getCacheFirst,
    postCacheFirst,
  }
}

function useBoundEndpoint(path) {
  const [payload, setPayload] = useState(null)
  const [loading, setLoader] = useState(false)
  const server = useServer()
  useEffect(() => {
    ;(async () => {
      setLoader(true)
      const data = await server.get(path)
      if (data) setPayload(data)
      setLoader(false)
    })()
  }, [])
  return { loading, payload }
}

export { useServer, useBoundEndpoint }
