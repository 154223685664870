import { platforms, payouts, platform_list } from "../../consts"
import { useContext } from "react"
import { dataContext } from "../datacontext"
import { useLoading } from "../loaderContext"
import { useServer } from "../../hooks/useServer"
import { useSystemAlerts } from "./alerts"
import { suggestSort } from "../../helpers/utils"
import { useCommonAPIS } from "./common"

function extractSocial(creator) {
  const creator_social_list = suggestSort(Object.keys(creator.connected_accounts), platform_list)
  const _ca = creator.connected_accounts
  const creator_socials = creator_social_list.map((_p) => {
    if (_ca[_p]?.pages?.[0]) {
      return {
        platform: _p,
        ..._ca[_p].pages[0],
        ..._ca[_p],
      }
    } else {
      return {
        platform: _p,
        ..._ca[_p],
      }
    }
  })
  return {
    list: creator_social_list,
    items: creator_socials,
  }
}

function useCreator() {
  const [data, dispatch] = useContext(dataContext)
  const { actions } = useLoading()
  const server = useServer()
  const alert = useSystemAlerts()
  const common = useCommonAPIS()

  const loadProfile = async (creator_shortname = "", for_brand = "") => {
    const _creator = creator_shortname ?? data?.creator_name
    if (!_creator) return null
    const brandPart = for_brand ? `&for_brand=${for_brand}` : ""
    actions.batchLoading(["creator_public_info"])
    const creator = await server.get(`Creator/public?username=${_creator}`)
    actions.batchNotLoading(["creator_public_info"])
    if (!creator) return null
    dispatch({ creator })
    const { list, items } = extractSocial(creator)
    const creator_social_list_real_platforms = list.filter((_i) => _i !== "ugc")
    const creator_socials_real_platforms = items.filter((_i) => _i.platform !== "ugc")
    dispatch({
      creator_social_list: list,
      creator_socials: items,
      creator_social_list_real_platforms,
      creator_socials_real_platforms,
    })
    return creator
  }

  const contentDetail = async (product_id, platform) => {
    actions.batchLoading(["product_details", `productdetails_${product_id}`])
    const _url = platforms[platform].detail_path.replace("__uuid__", product_id)
    const content = await server.get(`${_url}details/`)
    actions.batchNotLoading(["product_details", `productdetails_${product_id}`])
    if (!content) return null
    return content
  }

  const changeContentAvailability = async (content, available) => {
    const platform = content.content_platform
    if (!platform) return null
    const _url = platforms[platform].detail_path.replace("__uuid__", content?.uuid)
    actions.batchLoading(["content-availability_change", `availability_${content?.uuid}`])
    const changed = await server.patch(_url, { is_active_on_zelf: available })
    actions.batchNotLoading(["content-availability_change", `availability_${content?.uuid}`])
    if (!changed) return null
    const _mList = available ? "restored_temp" : "deleted_temp"
    const _aList = available ? "deleted_temp" : "restored_temp"
    if (data[_aList].indexOf(content.uuid) !== -1) {
      const newList = [
        ...data[_aList].slice(0, data[_aList]?.indexOf(content.uuid)),
        ...data[_aList].slice(data[_aList].indexOf(content.uuid) + 1),
      ]
      dispatch({ [_aList]: newList })
    }
    const newMlist = [...data[_mList], content.uuid]
    dispatch({ [_mList]: newMlist })
    return changed
  }

  const me = async (silent = false) => {
    if (!silent) actions.batchLoading(["creator_me", "me"])
    const creator = await common.me()
    actions.batchNotLoading(["creator_me", "me"])
    if (!creator) return null
    dispatch({ me: creator, role: "creator" })
    const list = (creator?.connected_accounts ?? []).map((x) => x?.platform)
    dispatch({ me_social_list: list })
    return creator
  }

  const connectPayout = async (platform, payload) => {
    actions.batchLoading([
      "social_connect",
      `social_connect_${platform}`,
      "payout_connect",
      `payout_connect_${platform}`,
    ])
    const connection = await server.post(payouts[platform].connect_path, payload)
    actions.batchNotLoading([
      "social_connect",
      `social_connect_${platform}`,
      "payout_connect",
      `payout_connect_${platform}`,
    ])
    if (!connection) {
      alert.failedConnect(platform)
      return null
    }

    if (connection) {
      dispatch({
        creator_payout_connected: true,
      })
    }
    return connection
  }

  const setTopContent = async (platform, content_uuid) => {
    actions.batchLoading(["generic_top_content", `top_content_${content_uuid}`, `highlightcontent_${content_uuid}`])
    const added = await server.patch(platforms[platform].top_content_path.replace("__uuid__", content_uuid), {
      is_top_content: true,
    })
    actions.batchNotLoading(["generic_top_content", `top_content_${content_uuid}`, `highlightcontent_${content_uuid}`])
    if (!added) return null
    await creatorGetSections({ section: "top_contents", skipLoading: true, extraData: { platform } })
    return added
  }
  const RemoveTopContent = async (platform, content_uuid) => {
    actions.batchLoading(["generic_top_content", `top_content_${content_uuid}`, `unhighlightcontent_${content_uuid}`])
    const added = await server.patch(platforms[platform].top_content_path.replace("__uuid__", content_uuid), {
      is_top_content: false,
    })
    actions.batchNotLoading([
      "generic_top_content",
      `top_content_${content_uuid}`,
      `unhighlightcontent_${content_uuid}`,
    ])
    if (!added) return null
    await creatorGetSections({ section: "top_contents", skipLoading: true, extraData: { platform } })
    return added
  }

  const update = async (payload, extraloaders = []) => {
    actions.batchLoading(["creator_update", ...extraloaders])
    const updated = await server.patch("/User/me/", payload)
    actions.batchNotLoading(["creator_update", ...extraloaders])
    if (!updated) return null
    dispatch({ me: updated })
    return updated
  }

  const create = async (payload = {}) => {
    actions.batchLoading(["creator_create", "user_create"])
    const creator = await common.patchMe({ role: "CREATOR" })
    actions.batchNotLoading(["creator_create", "user_create"])
    if (!creator) return null
    dispatch({ me: creator, role: "creator" })
    return creator
  }
  const updateAndValidateAddressInfo = async (payload = {}) => {
    actions.batchLoading(["creator_address_update", "user_create"])
    // doing it this was because backend has very strict phone number verification, if error pops up in backend, using the backing verification for phone number
    let creatorInfo
    let backendValidationError
    try {
      creatorInfo = await server.patch("/User/me/", payload, true, true)
    } catch (e) {
      backendValidationError = e?.response?.data
    }
    actions.batchNotLoading(["creator_address_update", "user_create"])

    return [creatorInfo, backendValidationError]
  }

  const dismissTour = async () => {
    dispatch({ show_creator_tour: false })
    const updated = await update({ creator_settings: { is_onboarding_tour_complete: true } })
    return updated
  }

  const refreshInstagram = async (ig_uuid) => {
    actions.batchLoading(["creator_refresh_instagram"])
    const updated_ig_object = await server.post(`/Instagram/${ig_uuid}/refresh_profile_data/`, {})
    actions.batchNotLoading(["creator_refresh_instagram"])
    return updated_ig_object
  }

  const getBrandInfoFromInviteIDOrBrandID = async (inviteID, brandId) => {
    if (!inviteID && !brandId) {
      return null
    }
    actions.batchLoading(["get_brand_invite_info"])
    let res

    if (inviteID) {
      res = await server.get(`/Brand/details/?ref_code=${inviteID}`)
    } else {
      res = await server.get(`/Brand/${brandId}`)
    }

    if (res) {
      dispatch({
        brand_ecosystem_brand: res,
      })
    }
    actions.batchNotLoading(["get_brand_invite_info"])
    return res
  }
  const acceptBrandInfoFromInviteId = async (brandID, creatorID, is_included_in_creator_circle = true) => {
    actions.batchLoading(["accept_brand_invite_info"])
    // console.log(brand)
    const payload = {
      brand: brandID,
      creator: creatorID,
      is_included_in_creator_circle,
    }
    const res = await server.post(`/CreatorEcoSystem/`, payload)
    if (res) {
      dispatch({
        brand_ecosystem_invite: res,
      })
    }
    actions.batchNotLoading(["accept_brand_invite_info"])
    return res
  }
  const getStripeKey = async () => {
    actions.batchLoading(["onboarding_common"])
    const res = await server.get("/Payment/StripeACH/session_secret")

    actions.batchNotLoading(["onboarding_common"])
    return res
  }
  const postStripeToBackend = async (payload) => {
    actions.batchLoading(["onboarding_common"])
    const res = await server.post("/Payment/StripeACH/", { ach_details: payload })
    await me()

    actions.batchNotLoading(["onboarding_common"])
    return res
  }

  const getCampaignInfo = async ({ id, additionalLoaders = [] }) => {
    actions.batchLoading([...additionalLoaders, "creator_side_campaign_common"])
    const res = await server.get(`/Campaign/${id}/`)
    dispatch({ invite_campaign_summary: res })
    actions.batchNotLoading([...additionalLoaders, "creator_side_campaign_common"])
    return res
  }

  const inviteCampaignCreation = async ({ payload, additionalLoaders = [] }) => {
    actions.batchLoading([...additionalLoaders, "post_invite_creator_side_campaign_common"])
    const res = await server.post(`/Campaign/invitation/`, payload)

    actions.batchNotLoading([...additionalLoaders, "post_invite_creator_side_campaign_common"])
    return res
  }

  const getSocialAccountDetails = async ({ platform = "" }) => {
    const endpoint = platform ? `/SocialAccount/?platform=${platform}` : "/SocialAccount/"
    actions.batchLoading(["social_connect_details", `social_connect_details_${platform}`])
    const res = await server.get("/Payment/StripeACH/session_secret")

    actions.batchNotLoading(["social_connect_details", `social_connect_details_${platform}`])
    return res
  }

  const creatorConnectSocialMedia = async ({
    platform = "youtube",
    conn_data = "",
    additionalData = {},
    additionalLoaders = [],
  }) => {
    if (!conn_data) {
      console.error("no connection data for connection platform")
      return
    }
    const payload = { platform, conn_data, ...additionalData }
    const endpoint = platform === "ig_graph" ? "/SocialAccount/ig_graph_connect/" : "/SocialAccount/"

    if (platform === "ig_graph") {
      const ig = await getSocialAccountDetails({ platform: "instagram" })
      if (ig?.id) {
        payload["account_id"] = ig.id
      }
    }

    actions.batchLoading([
      ...additionalLoaders,
      "post_invite_creator_side_campaign_common",
      "social_connect",
      `social_connect_${platform}`,
    ])
    let res
    try {
      res = await server.post(endpoint, payload, true, "post", {}, true)
    } catch (e) {
      let social_connection_error = e?.response?.data
      dispatch({
        social_connection_error: social_connection_error,
      })
      return
    }

    const me_social_list = data?.me_social_list ?? []

    actions.batchNotLoading([
      ...additionalLoaders,
      "post_invite_creator_side_campaign_common",
      "social_connect",
      `social_connect_${platform}`,
    ])
    if (res) {
      me_social_list.push(platform)
      dispatch({
        me_social_list: [...me_social_list],
      })
    }
    return res
  }

  const creatorGetHelper = ({ endpoint, section, filter, additionalData, skipLoading }) => {
    return common.getRequestHelper({
      endpoint: endpoint,
      section: section,
      filter: filter,
      skipDiscoveryPlatform: true,
      dataContextPrefix: "cr_sec_",
      loaders: ["cr-section-default", `cr-section-${section}`],
      skipLoading,
    })
  }

  const creatorGetSections = async ({ section = "", filter, extraData, skipLoading = false }) => {
    switch (section) {
      case "top_contents":
        const topContentparams = {
          creator: data?.creator?.id,
          platform: extraData?.platform ?? "tiktok",
          is_top_content: true,
        }
        const topContentQueryParams = new URLSearchParams(topContentparams).toString()

        const contents = await creatorGetHelper({
          endpoint: "/Content/",
          filter: topContentQueryParams,
          section: section,
          skipLoading,
        })
        if (contents.length >= 0) {
          dispatch({ sample_contents: contents })
          return contents
        }
        return contents
      case "all_contents":
        const allContentParam = {
          creator: data?.creator?.id,
          platform: extraData?.platform ?? "tiktok",
          is_top_content: false,
        }
        const allContentQueryParam = new URLSearchParams(allContentParam).toString()
        return creatorGetHelper({ endpoint: "/Content/", filter: allContentQueryParam, section: section, skipLoading })
      case "creator_payment":
        return creatorGetHelper({ endpoint: "/Payment/", section: section, skipLoading })
      default:
        console.log("no section was provided")
        return
    }
  }

  const _creatorPostHelper = ({ endpoint, payload, section = "", skipLoading }) => {
    console.log("posting", endpoint)
    return common.postRequestHelper({
      endpoint,
      section,
      payload,
      skipLoading,
      loaders: ["cr-section-" + section],
      dataContextPrefix: "cr_sec_",
    })
  }
  const creatorPostHelper = ({ section = "", payload }) => {
    switch (section) {
      case "add_creator_to_campaign":
        return _creatorPostHelper({ endpoint: "/Campaign/creators/", section, payload })
      default:
        return null
    }
  }

  return {
    contentDetail,
    loadProfile,
    creatorConnectSocialMedia,
    connectPayout,
    me,
    update,
    create,
    updateAndValidateAddressInfo,
    changeContentAvailability,
    dismissTour,
    refreshInstagram,
    setTopContent,
    RemoveTopContent,
    getBrandInfoFromInviteIDOrBrandID,
    acceptBrandInfoFromInviteId,
    getStripeKey,
    postStripeToBackend,
    getCampaignInfo,
    inviteCampaignCreation,
    creatorGetSections,
    creatorPostHelper,
  }
}

export { useCreator }
