import React, { useContext } from "react"
import { BaseDialog } from "./baseDialog"
import { dataContext } from "../../contexts/datacontext"
import { apiContext } from "../../contexts/apicontext"
import { Button } from "../button"
import { useSocial } from "../../hooks/useSocial"

const GraphAPITourPreDialog = () => {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const { connect } = useSocial()

  const showMinitour = () => {
    dispatch({ graph_api_minitour_pre_dialog_show: false })
    dispatch({ show_graph_api_minitour: true })
  }
  const connectGraphAPI = async () => {
    console.info(`Instagram Connect button pressed`)
    const instagram = await api.creator.refreshInstagram(data?.me?.connected_accounts?.instagram?.account)
    if (instagram?.account_type === "business" || instagram?.account_type === "media_creator") {
      connect("ig_graph")
      dispatch({ graph_api_minitour_pre_dialog_show: false })
    } else showMinitour()
  }

  if (!data?.me?.connected_accounts?.instagram) {
    return null
  }

  return (
    <BaseDialog
      show={data?.graph_api_minitour_pre_dialog_show}
      headerElement={"Instagram Graph API"}
      dialogBodyClassName="gat-pre-dialog-body"
      dismissDialog={() => dispatch({ graph_api_minitour_pre_dialog_show: false })}
      contentClassName={"gat-pre-dialog-content nc00-bg  padding-y-5x padding-x-5x"}
      content={
        <>
          <h3 className="headline-small-highlight margin-bottom-5x txt-center nc50-fg">
            You will need two things to connect to graph API and show post statistics
          </h3>
          <div className="padding-5x border-1 nc10-border border-radius-dynamic margin-bottom-4x">
            <div className="flex a-center j-start margin-bottom-4x">
              <span className="onboarding-steps-number margin-right-2x">1</span>
              <span className="headline-small-highlight">A professional (Creator/ Business) Instagram Account</span>
            </div>
            <div className="flex a-center j-start ">
              <span className="onboarding-steps-number margin-right-2x">2</span>
              <span className="headline-small-highlight">A facebook page linked to your Instagram Account</span>
            </div>
          </div>
          <Button fluid className="margin-bottom" onClick={connectGraphAPI}>
            Yes I have these
          </Button>
          <div
            className="primary-fg button-medium-font padding cursor-pointer txt-center"
            onClick={showMinitour}
            type="ghost"
          >
            I don't have these, please talk me through how to set these up
          </div>
        </>
      }
      footer={null}
      footerClassName=""
    />
  )
}

export { GraphAPITourPreDialog }
