import { useContext, useEffect } from "react"
import { apiContext } from "../../contexts/apicontext"
import { Loader } from "../loaders"
import { useAuth0 } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom"
import { useAuthHelper } from "../../hooks/useAuthHelper"

function SendToLogin() {
  const { isLoading, isAuthenticated } = useAuthHelper()
  const api = useContext(apiContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated && !isLoading) api.login(false)
    else if (!isLoading && isAuthenticated) navigate("/")
  }, [isAuthenticated, isLoading])

  return (
    <div className="callback-page flex hcenter margin-10x">
      <Loader className="margin-right-2x" />
      <div className="wait-text txt-center">Please wait...</div>
    </div>
  )
}

export { SendToLogin }
