import React, { useState, useContext, useEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { StepCounter } from "./dumbs"
import { BaseDialog } from "./dialogs/baseDialog"
import { dataContext } from "./../contexts/datacontext"
import { apiContext } from "./../contexts/apicontext"
import { useSocial } from "./../hooks/useSocial"
import { Button } from "./button"
import {
  CheckLargeFilledMono,
  DottedLineVertical,
  RightArrowMediumOutlinedMono,
  ChevronBackwardSmallOutlinedMono,
  ChevronFrowardSmallOutlinedMono,
  LinkMediumMono,
} from "./Icons"

const CONVERT_TO_PROFESSINAL_ACCOUNT_STEPS = [
  "Go to your Instagram profile on your mobile device.",
  "In the top right corner go to Menu, then Settings, and then Account.",
  `Scroll all the way down and click “Switch to Professional Account”`,
  "Follow the steps and choose Creator Account or Business Account; both will work for Zelf Pro",
]
const CONVERT_TO_PROFESSIONAL_ACCOUNT_VIDEO =
  "https://player.vimeo.com/video/716705371?h=bf2b91dd39&title=0&byline=0&portrait=0"

const CONNECT_FACEBOOK_TO_IG_STEPS = [
  "Go to your Instagram profile",
  `Click “Edit Profile” underneath your display pic and description`,
  `Click “Page” underneath “Profile Information”`,
  "Choose create new page or connect to existing page",
  "Log into Facebook if need be, and choose the page to connect",
]
const CONNECT_FACEBOOK_TO_IG_VIDEO = "https://player.vimeo.com/video/720320760?h=88e0b72728&title=0&byline=0&portrait=0"

const CONNECT_GRAPH_API_STEPS = [
  `Click “Connect to Facebook” below`,
  "Log in with your Facebook credentials",
  "Select your Instagram Account and hit next.",
  "Select the Facebook Page that is associated with your Instagram Account.",
  "Leave the boxes checked and you are good to go!",
]

const StepList = ({ list }) => {
  return (
    <ol>
      {list.map((item, index) => (
        <li key={index}>
          <div className="headline-small-regular flex a-start margin-bottom">
            <span className="margin-right">&#160;{index + 1}.</span>
            <span>{item}</span>
          </div>
        </li>
      ))}
    </ol>
  )
}

const ConnectStep = ({
  list,
  title,
  stepNumber,
  alreadyCompleted,
  onContinueClick,
  videoLink,
  instagramHelpLink,
  finalStep,
  currentlyActive,
  onClick,
}) => {
  const [showVideo, setShowVideo] = useState(true)

  return (
    <div
      onClick={onClick}
      className={`nc00-bg border-1 nc10-border api-connect-step flex  full-height column   ${!currentlyActive || alreadyCompleted ? "" : "gat-card-active"} ${alreadyCompleted ? "completed" : ""}`}
    >
      <div className="full flex column">
        <div className="padding-x-5x padding-top-5x">
          <p className="caption nc50-fg">Step {stepNumber}</p>
          <h3 className="headline-small-highlight">{title}</h3>
          <div
            className={`padding-y-2x primary-fg headline-small-highlight margin-y cursor-pointer ${videoLink ? "" : "opacity-0 action-disabled cursor-default"}}`}
            onClick={() => setShowVideo(!showVideo)}
          >
            {showVideo ? "See written description" : "See Video "}
          </div>
        </div>
        {videoLink && showVideo ? (
          <div className="padding-x-3x full flex column">
            {" "}
            <div className=" embed-container full">
              <iframe src={videoLink} allowFullScreen></iframe>
            </div>
          </div>
        ) : (
          <div className={` padding-x-3x full flex column`}>
            <div className="padding-x-2x border-1 border-radius-dynamic nc10-border full  padding-y-3x">
              <StepList list={list} />
              {instagramHelpLink ? (
                <span className="primary-fg cursor-pointer headline-small-regular">
                  <a href={instagramHelpLink} target="_blank">
                    See also Instagram’s help page.
                  </a>
                </span>
              ) : null}
            </div>
          </div>
        )}
      </div>

      <hr className="hr margin-top-2x" />
      <div
        className="gat-continue-button primary-fg headline-small-highlight txt-center cursor-pointer flex a-center j-center"
        onClick={(e) => {
          if (!currentlyActive) return
          e.stopPropagation()
          onContinueClick()
        }}
      >
        {finalStep ? <div className="flex a-center j-center margin-right">{LinkMediumMono}</div> : null}
        {!finalStep && alreadyCompleted ? (
          <div className="flex a-center j-center margin-right"> {CheckLargeFilledMono}</div>
        ) : null}
        <span className="headline-small-highlight txt-center">
          {finalStep ? "Connect to Facebook" : alreadyCompleted ? "Completed" : "Continue to Next Step"}
        </span>
        {!finalStep && !alreadyCompleted ? (
          <div className="flex a-center j-center margin-left-2x">{RightArrowMediumOutlinedMono}</div>
        ) : null}
      </div>
    </div>
  )
}

const InitalialStepForMobile = ({ onContinueClick }) => {
  return (
    <div className={`nc00-bg border-1 nc10-border api-connect-step flex  full-height column gat-card-active `}>
      <div className="full flex column">
        <div className="flex column a-start padding-5x">
          <h3 className="margin-bottom-2x  headline-small-highlight txt-center">
            Add Post Statistics and set up Advanced Discoverability in Three Simple Steps!
          </h3>
          <p className="body-regular-font margin-bottom-5x nc50-fg">
            This will take about 6 minutes; we'll talk you through step by step
          </p>
          <div className="onboarding-steps">
            <span className="onboarding-steps-number margin-right-2x">1</span>
            <span>Convert your Instagram personal account to Instagram Professional Account</span>
          </div>
          <div className="dotted-line-container">{DottedLineVertical}</div>
          <div className="onboarding-steps">
            <span className="onboarding-steps-number margin-right-2x">2</span>
            <span>Connect your Facebook page to your instagram Account </span>
          </div>
          <div className="dotted-line-container">{DottedLineVertical}</div>
          <div className="onboarding-steps">
            <span className="onboarding-steps-number margin-right-2x">3</span>
            <span>Connect Zelf to your Facebook account</span>
          </div>
        </div>
      </div>
      <hr className="hr" />
      <div
        className="gat-continue-button flex a-center j-center primary-fg headline-small-highlight txt-center cursor-pointer"
        onClick={onContinueClick}
      >
        Continue
      </div>
    </div>
  )
}

const GraphAPIPromt = ({ className }) => {
  const [data, dispatch] = useContext(dataContext)
  const openMinitourDialog = () => {
    dispatch({ graph_api_minitour_pre_dialog_show: true })
  }

  // for  not connected
  if (
    !data?.me?.connected_accounts?.instagram ||
    data?.me?.connected_accounts?.instagram?.facebook_connected_instagram_page
  ) {
    return null
  }

  return (
    <div className={`border-1 padding-5x nc10-border border-radius-dynamic ${className}`}>
      <h3 className="headline-medium-highlight margin-bottom-3x ">Complete Your Profile</h3>
      <div></div>
      <div className="border-1 padding-5x nc10-border border-radius-dynamic box-shadow-decorative">
        <div className="flex a-center j-start margin-bottom-4x">
          <span className="onboarding-steps-number margin-right-2x">
            {data?.me?.connected_accounts?.instagram?.facebook_connected_instagram_page === null
              ? "1"
              : CheckLargeFilledMono}
          </span>
          <span className="headline-medium-highlight">Add post statistics</span>
        </div>

        <p className="button-medium-font margin-bottom-4x ">Connect Instagram Graph API for full benefits of Zelf.</p>
        <Button fluid onClick={openMinitourDialog}>
          Connect Now
        </Button>
      </div>
    </div>
  )
}

const GraphAPITour = (props) => {
  const [mySwiperInstance, setMySwiperInstance] = useState()
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const { connect } = useSocial()

  const is_professinal_account =
    data?.me?.connected_accounts?.instagram?.account_type === "business" ||
    data?.me?.connected_accounts?.instagram?.account_type === "media_creator"
  const dismissDialog = () => {
    dispatch({ show_graph_api_minitour: false })
  }
  const connectGraphAPI = async () => {
    const instagram = await api.creator.refreshInstagram(data?.me?.connected_accounts?.instagram?.account)

    if (instagram?.account_type === "business" || instagram?.account_type === "media_creator") {
      connect("ig_graph")
      dispatch({ show_graph_api_minitour: false })
    } else api?.alert?.GraphRequirements()
  }

  const setCompletedStep = (step) => {
    if (step >= 3) {
      return
    }
    if (!mySwiperInstance) return
    mySwiperInstance.slideTo(step + 1)
    dispatch({ graph_api_steps_completed: step })
  }
  const onContinueClick = async ({ mobileDescriptonStep = false }) => {
    if (!mySwiperInstance) return

    if (mobileDescriptonStep) {
      mySwiperInstance.slideNext()
      return
    }

    if (data?.graph_api_steps_completed === 0 || data?.graph_api_steps_completed === 1) {
      mySwiperInstance.slideNext()
      dispatch({ graph_api_steps_completed: data?.graph_api_steps_completed + 1 })
      return
    }
  }

  useEffect(() => {
    if (!mySwiperInstance) return

    if (is_professinal_account) {
      mySwiperInstance.slideTo(2)
      dispatch({ graph_api_steps_completed: 1 })
      return
    }
    if (data?.graph_api_steps_completed === 0) return
    mySwiperInstance.slideTo(data?.graph_api_steps_completed + 1 ?? 0)
  }, [mySwiperInstance])

  if (!data?.me?.connected_accounts?.instagram) {
    return null
  }

  return (
    <BaseDialog
      show={data?.show_graph_api_minitour}
      headerElement={"Instagram Graph API"}
      dismissDialog={dismissDialog}
      dialogBodyClassName={"gat-body"}
      content={
        <>
          <div className="margin-bottom-5x margin-top-5x hide-on-mobile">
            <h2 className="headline-large  txt-center">Connect Instagram Graph API in Three Simple Steps!</h2>
            <p className="body-regular-font nc50-fg txt-center hide-on-mobile margin-top">
              It will take you about two minutes to set everything up. Here’s what you need to do:
            </p>
          </div>
          <div className="gat-slider-container ">
            <Swiper
              className="padding-bottom "
              slidesPerView={"auto"}
              spaceBetween={8}
              onInit={(ev) => {
                setMySwiperInstance(ev)
              }}
            >
              <SwiperSlide className="hide-on-desktop gat-card">
                <InitalialStepForMobile onContinueClick={() => onContinueClick({ mobileDescriptonStep: true })} />
              </SwiperSlide>
              <SwiperSlide className="tester">
                <ConnectStep
                  alreadyCompleted={1 <= data?.graph_api_steps_completed}
                  currentlyActive={0 === data?.graph_api_steps_completed}
                  onClick={() => setCompletedStep(0)}
                  onContinueClick={() => onContinueClick({ mobileDescriptonStep: false })}
                  stepNumber={1}
                  title="Convert your Instagram Personal Account to a Professional Account"
                  list={CONVERT_TO_PROFESSINAL_ACCOUNT_STEPS}
                  videoLink={CONVERT_TO_PROFESSIONAL_ACCOUNT_VIDEO}
                  instagramHelpLink={"https://help.instagram.com/502981923235522"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ConnectStep
                  alreadyCompleted={2 <= data?.graph_api_steps_completed}
                  currentlyActive={1 === data?.graph_api_steps_completed}
                  onClick={() => setCompletedStep(1)}
                  onContinueClick={() => onContinueClick({ mobileDescriptonStep: false })}
                  stepNumber={2}
                  title="Connect a Facebook page to your Instagram Account"
                  list={CONNECT_FACEBOOK_TO_IG_STEPS}
                  videoLink={CONNECT_FACEBOOK_TO_IG_VIDEO}
                  instagramHelpLink={"https://help.instagram.com/176235449218188"}
                />
              </SwiperSlide>
              <SwiperSlide>
                <ConnectStep
                  currentlyActive={2 === data?.graph_api_steps_completed}
                  onClick={() => setCompletedStep(2)}
                  onContinueClick={connectGraphAPI}
                  stepNumber={3}
                  title="Connect to Facebook to provide per-post data for advanced discoverability"
                  list={CONNECT_GRAPH_API_STEPS}
                  finalStep={true}
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </>
      }
      contentClassName={"gat-content nc02-bg  padding-y-2x padding-x-2x"}
      footerClassName={"gat-footer nc00-bg padding-x-5x padding-y-3x flex j-center"}
      footerElement={<StepCounter currentStep={data?.graph_api_steps_completed + 1} totalStep={3} />}
    />
  )
}

export { GraphAPITour, GraphAPIPromt }
