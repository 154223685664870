import { HeaderBanner } from "../structural/headerbanner"
import { ReferralBanner } from "../structural/referralBanner"
import { InstagramDisconnectedBanner } from "../structural/instagramDisconnectedBanner"
import { PublicCreatorProfile, NotRegisteredPublicCreatorProfile } from "../structural/creatorProfilePublic"
import { IfCreator, IfGuest, IfNotGuest } from "../functional/auth_components"
import React, { useContext, useEffect, useState } from "react"
import { apiContext } from "../../contexts/apicontext"
import { CreatorTabBar } from "../structural/tabbar"
import { FullLoader } from "../loaders"
import { dataContext } from "../../contexts/datacontext"
import { Tab } from "../tab"
import { Tabs } from "../tabs"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { UserTourDialogWithDataContextWrapper } from "../userTourDialog"
import { Card, FullContentSyncedTop, NewLineParser, StatBlock, WrappedInLoader } from "../dumbs"
import { BookingPage } from "../structural/bookingPage"
import { Button } from "../button"
import { GiftingLargeMono, IsNotInterestingMark, Lock } from "../Icons"
import { useTabPageSpecificAnalytics } from "../../contexts/apis/analytics"
import { MadeWithZelf } from "../madeWithZelf"
import { getQueryParam, scrollToTop, capitalizeFirstLetter } from "../../helpers/utils"
import { useLoading } from "../../contexts/loaderContext"
import { Email, TrashMediumFilledMono } from "../Icons"
import { BEContentList, BELocalContentList } from "../structural/BrandEcosystem/beSections"
import { useBDBoundData, useBDPostData, useLocalBoundData, useLocalDataMutation } from "../../hooks/useBoundData"
import { ContentShowingSelect } from "../select"
import { useCampaignPopupHelper } from "../structural/BrandEcosystem/campaignPopup"
import { BoardPopup, BothTypeBoardPopup } from "../structural/BrandEcosystem/boardPopup"
import { GET_CALLS, MUTATION_CALLS } from "../../consts"
import { useToast } from "../../hooks/useToast"

const DesktopTab = ({ selected = false, title, onClick, href = "" }) => {
  const clickAction = (e) => {
    e.preventDefault()
    onClick()
  }
  return (
    <a
      href={href}
      rel="noreferrer"
      onClick={clickAction}
      className={`${selected ? "selected" : ""} full creator-homepage-main-tab txt-center padding-y-4x body-regular-font cursor-pointer nc02-bg`}
    >
      {title}
    </a>
  )
}

const MobileTab = ({ selected = false, title, onClick, href = "" }) => {
  const clickAction = (e) => {
    e.preventDefault()
    onClick()
  }
  return (
    <a
      onClick={clickAction}
      href={href}
      className={`${selected ? "selected" : ""} full creator-homepage-main-tab txt-center padding-y-3x nc50-fg body-regular-font mobile-body-highlight-font cursor-pointer nc02-bg`}
    >
      {title}
    </a>
  )
}

function CreatorHome({ self }) {
  const api = useContext(apiContext)
  const [data, dispatch] = useContext(dataContext)
  const navigate = useNavigate()
  const { loaders } = useLoading()
  const params = useParams()
  useTabPageSpecificAnalytics("Main_Profile", "creator profile")
  const setMobileTab = (tab) => {
    dispatch({ creator_mobile_tab: tab })
  }
  const setHomepageTab = (tab) => {
    dispatch({ creator_homepage_tab: tab, bookingState: "landing" })
  }

  const loadCreatorPublicProfile = async (username, self, brand = "") => {
    const pub = await api.creator.loadProfile(username, brand)
    if (!pub) {
      if (!self) api.analytics.page(`/${username}`, `Creator Notfound: ${username}`)
      navigate("/")
    } else {
      if (!self) api.analytics.page(`/${username}`, `Creator Home: ${username}`)
      else api.analytics.page(`/creator-dashboard`, `Creator Home: ${username}`)
      if (data?.creator_name !== username) dispatch({ creator_name: username })
    }
  }
  useEffect(() => {
    dispatch({ on_creator_dashboard: self, fold: null })
    if (self) {
      setMobileTab("preference")
      if (data?.me && data?.role === "creator")
        loadCreatorPublicProfile(data?.me?.username, true, data.brand_proposal_brand)
      else if (data?.me && data?.role && data?.role !== "creator") navigate("/")
    } else {
      loadCreatorPublicProfile(params.creator_name, false, data.brand_proposal_brand)
      setMobileTab("content")
    }
  }, [data.me, self])

  // Redirect from creator dashboard page, essentially removing for creator  it
  useEffect(() => {
    if (!self) return
      ; (async () => {
        if (!data?.me?.id) {
          return
        }

        if (data?.me?.creator_ecosystem?.length >= 1) {
          navigate("/start-thank-you")
          return
        }
        return navigate("/start-description")
      })()
  }, [data?.me?.id])

  // Reset creator info on exit
  useEffect(() => {
    return () =>
      dispatch({
        creator: null,
        creator_name: "",
        creator_current_platform: "",
        creator_social_list: [],
        creator_socials: [],
      })
  }, [])
  // const shouldShowLandingPage = data?.creator?.content_creation?.length > 0
  const shouldShowLandingPage = true
  useEffect(() => {
    const mode = getQueryParam(window.location.href, "mode")
    switch (mode) {
      case "book":
        if (shouldShowLandingPage) dispatch({ creator_homepage_tab: "book" })
        break
      case "license":
        dispatch({ creator_homepage_tab: "license" })
        break
      default:
        if (shouldShowLandingPage) dispatch({ creator_homepage_tab: "book" })
    }
  }, [shouldShowLandingPage])
  const brandPostfix = data?.brand_proposal_brand ? `&for_brand=${data?.brand_proposal_brand}` : ""

  if (self && loaders["onboarding_common"]) {
    return <FullLoader />
  }

  return (
    <>
      {/* Creator tour mobile  */}
      {data?.is_me_creator && self ? (
        <UserTourDialogWithDataContextWrapper className="hide-on-desktop" visibleInStep={data.creator_tour_step} />
      ) : null}
      {/* creator tour desktop first step  */}
      {data?.is_me_creator && self ? (
        <UserTourDialogWithDataContextWrapper className="hide-on-mobile" visibleInStep={0} />
      ) : null}

      <HeaderBanner />
      <ReferralBanner className="hide-on-desktop" />
      <InstagramDisconnectedBanner className="hide-on-desktop" />
      <div className="content-area creator-homepage-wrapper section-spanned-window-height">
        {self ? (
          <IfCreator current={true}>
            <div className="creator-control-tabs flex hcenter hide-on-desktop margin-top-2x">
              <Tabs>
                <Tab
                  title="Account Settings"
                  onChange={() => setMobileTab("preference")}
                  selected={data.creator_mobile_tab === "preference"}
                />
                <div className="line-between-tabs"></div>
                <Tab
                  onChange={() => setMobileTab("content")}
                  title="Account Preview"
                  selected={data.creator_mobile_tab === "content"}
                />
              </Tabs>
            </div>
          </IfCreator>
        ) : null}
        {data?.creator ? (
          <div className="page-root creator-page-root flex">
            <div
              className={`creator-public-area full ${data.creator_mobile_tab === "content" ? "" : "hide-on-mobile"} ${self ? "creator-homepage-mode" : ""}`}
            >
              <FullContentSyncedTop data={data} />
              <div className="creator-public-profile-holder flex j-space-between a-center margin-top-8x  cs-homepage-shrinked-top-padding padding-x-10x">
                <PublicCreatorProfile className="margin-x-2x" />
                <IfNotGuest>
                  <Button
                    beforeElement={<div className="flex a-center j-center primary-fg">{Email}</div>}
                    type="tertiary"
                    onClick={() => api?.alert?.ContactCreator(data?.creator)}
                  >
                    Contact creator
                  </Button>
                </IfNotGuest>
              </div>

              <div className="creator-homepage-main-section border-1 nc10-border mobile-nc05-border border-radius-dynamic overflow-hidden margin-top-13x">
                {data?.on_creator_dashboard ? null : (
                  <div className="creator-homepage-tabs flex border-bottom-1 nc10-border hide-on-mobile">
                    {/* show booking tab first and license tab last if booking is available otherwise just show license tab first  */}
                    {shouldShowLandingPage ? (
                      <DesktopTab
                        href={`/${data.creator_name}?mode=book${brandPostfix}`}
                        onClick={(e) => setHomepageTab("book")}
                        selected={data.creator_homepage_tab === "book"}
                        title="book me for new content"
                      />
                    ) : (
                      <DesktopTab
                        href={`/${data.creator_name}?mode=license${brandPostfix}`}
                        onClick={(e) => setHomepageTab("license")}
                        selected={data.creator_homepage_tab === "license"}
                        title="view my portfolio"
                      />
                    )}
                    {shouldShowLandingPage ? (
                      <DesktopTab
                        href={`/${data.creator_name}?mode=license${brandPostfix}`}
                        onClick={(e) => setHomepageTab("license")}
                        selected={data.creator_homepage_tab === "license"}
                        title="view my portfolio"
                      />
                    ) : null}
                  </div>
                )}

                {data?.on_creator_dashboard ? null : (
                  <div className="creator-homepage-tabs flex border-bottom-1 nc10-border mobile-nc05-border hide-on-desktop">
                    {/* show booking tab first and license tab last if booking is available otherwise just show license tab first  */}
                    {shouldShowLandingPage ? (
                      <MobileTab
                        href={`/${data.creator_name}?mode=book${brandPostfix}`}
                        onClick={(e) => setHomepageTab("book")}
                        selected={data.creator_homepage_tab === "book"}
                        title="Book me"
                      />
                    ) : (
                      <MobileTab
                        href={`/${data.creator_name}?mode=license${brandPostfix}`}
                        onClick={(e) => setHomepageTab("license")}
                        selected={data.creator_homepage_tab === "license"}
                        title="Portfolio"
                      />
                    )}
                    {/* <MobileTab href={`/${data.creator_name}?mode=stats${brandPostfix}`} onClick={openCreatorStats} selected={data.creator_homepage_tab === "stats"} title="Media kit"  /> */}
                    {shouldShowLandingPage ? (
                      <MobileTab
                        href={`/${data.creator_name}?mode=license${brandPostfix}`}
                        onClick={(e) => setHomepageTab("license")}
                        selected={data.creator_homepage_tab === "license"}
                        title="Portfolio"
                      />
                    ) : null}
                  </div>
                )}
                <div className="creator-homepage-inner padding-4x">
                  {self ? (
                    <>
                      <CreatorTabBar />
                    </>
                  ) : (
                    <>
                      {data.creator_homepage_tab === "license" ? (
                        <>
                          <CreatorTabBar />
                        </>
                      ) : null}
                      {data.creator_homepage_tab === "book" ? <BookingPage /> : null}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <FullLoader />
        )}
      </div>
      <MadeWithZelf />
    </>
  )
}

const NotSignedInZelfCreatorHome = () => {
  const [data, dispatch] = useContext(dataContext)
  const filters = {
    brand_id: data?.sub_brand_id ?? data?.me?.brand?.id,
    account: data?.discover_selected_creator_id,
    sort_by: "timestamp",
    fe_for: "creator_specfic_contents"
  }
  const { payload: brandContentStats, loader: brandContentLoader } = useLocalBoundData({
    ...GET_CALLS.content_stats,
    filter: filters,
    skip: !(data?.me?.brand?.name && data?.discover_selected_creator_id)
  })
  const { payload: creator, loader: creator_loader } = useBDBoundData({
    section: data?.me?.brand?.name && data?.discover_selected_creator_id ? "discover_selected_creator" : "",
  })
  const { executeCall: changeInterestingStatus } = useBDPostData({ section: "mark_as_not_interesting" })
  const api = useContext(apiContext)
  const toast = useToast()
  const [searchParams] = useSearchParams()
  const sub_brand_title = searchParams.get("sub_brand_title")
  const subBrands = data?.me?.brand?.sub_brands ?? []
  const subBrand = data?.sub_brand_id ? subBrands.find((x) => x.id == Number(data?.sub_brand_id)) : null
  const for_brand = subBrand?.title ?? subBrand?.name ?? "brand"
  const boardPopup = useCampaignPopupHelper()
  const navigate = useNavigate()
  const [clientIsNotInterested, setClientIsNotInterested] = useState(null)
  const { executeCall: addCreatorToBoard } = useLocalDataMutation(MUTATION_CALLS.add_creators_to_board)

  useEffect(() => {
    scrollToTop()
    if (!data?.me?.brand?.name) {
      // navigate("/")
    }
  }, [])
  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search)

    let params = Object.fromEntries(searchParams.entries())
    // if(!params?.id) navigate("/")
    dispatch({
      discover_selected_creator_id: params.id,
    })
  }, [])

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search)

    let params = Object.fromEntries(searchParams.entries())
    if (!params?.id) navigate("/")
    dispatch({
      discover_selected_creator_id: params.id,
    })
  }, [])

  useEffect(() => {
    return () => {
      dispatch({
        discover_selected_creator_id: null,
        discover_selected_creator: null,
      })
    }
  }, [])

  const is_not_interesting = clientIsNotInterested ?? creator?.is_not_interesting
  const setInterestedStatus = async () => {
    if (!creator?.id) return
    const oldValue = is_not_interesting
    setClientIsNotInterested(!is_not_interesting)
    const res = await changeInterestingStatus({
      creator: creator?.id,
      is_not_interesting: !is_not_interesting,
    })
    if (res) {
      toast(!is_not_interesting ? `Creator marked as not interesting.` : "Mark removed.")
      return
    }
    setClientIsNotInterested(oldValue)
  }

  const connected_accounts = creator?.connected_accounts ?? {}
  let followers = creator?.followers
  let followers_platform =
    Object.keys(connected_accounts).length === 1 ? capitalizeFirstLetter(Object.keys(connected_accounts)[0]) : "Total"
  let creatorUsername = creator?.social_accounts

  return (
    <div className="content-area ">
      <WrappedInLoader data={creator} loader={creator_loader || !data?.me?.brand?.name}>
        <div className="creator-public-profile-holder flex j-space-between a-center margin-top-8x  cs-homepage-shrinked-top-padding ">
          <div>
            <NotRegisteredPublicCreatorProfile
              force_show_follower
              className="margin-x-2x"
              creator={creator}
              tailElement={
                <div className="margin-top-3x">
                  <p className="headline-small-regular">
                    {creator?.text ? (
                      <NewLineParser value={creator?.text} />
                    ) : creator?.bio ? (
                      <NewLineParser value={creator?.bio} />
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              }
            />
          </div>
          <div className="flex a-center no-flex-shrink">
            <IfNotGuest>
              <Button type="tertiary" onClick={() => api?.alert?.ContactCreator(creator)}>
                Contact creator
              </Button>
            </IfNotGuest>
            <IfGuest>
              <Button onClick={api?.alert?.unlockFullExperience} type="tertiary">
                Contact creator
              </Button>
            </IfGuest>

            <IfNotGuest>
              <Button
                className="margin-left-2x"
                beforeElement={<div className="flex a-center j-center nc50-fg">{TrashMediumFilledMono}</div>}
                type="tertiary"
                onClick={() => api?.alert?.archiveCreator(creator)}
              >
                Remove from Zelf.
              </Button>
              <Button className="margin-left-2x no-flex-shrink z-index-5" type="tertiary" onClick={boardPopup.show}>
                Add to
                {boardPopup.shown ? (
                  <BoardPopup
                    className="left-bottom-mounted"
                    // type="tracking"
                    hasDraft={true}
                    showContentBoard={false}
                    onAddNewClick={() => {
                      api?.alert?.createOrEditBoard({ type: "tracking" })
                    }}
                    onBoardClick={(b) => {
                      // console.log(b)
                      api.brand.brandPostRequestHelper({
                        section: "add_creators_to_board",
                        payload: {
                          board: b.id,
                          other_creators: [creator.id],
                          onboarded_creators: [],
                          for_brand: data?.sub_brand_id ? Number(data?.sub_brand_id) : data?.me?.brand?.id,
                        },
                      }).then((res) => {
                        if (res) {
                          toast("Creator added to board")
                        }
                      })
                    }}
                    onDismiss={() => {
                      boardPopup.hide()
                    }}
                  />
                ) : null}
              </Button>
              <Button
                className={`margin-left-2x nc100-fg-override   no-flex-shrink ${is_not_interesting ? "nc100-border-override" : "nc10-border-override"}`}
                type="tertiary"
                beforeElement={<div className="flex nc30-fg a-center j-center">{IsNotInterestingMark}</div>}
                onClick={setInterestedStatus}
              >
                Not Interesting
              </Button>
            </IfNotGuest>
            <IfGuest>
              <Button
                className="margin-left-2x"
                onClick={api?.alert?.unlockFullExperience}
                beforeElement={<div className="flex a-center j-center nc50-fg">{TrashMediumFilledMono}</div>}
                type="tertiary"
              >
                Remove creator
              </Button>
            </IfGuest>
          </div>
        </div>
        <Card className={"padding-y-5x margin-y-10x padding-x-4x"}>
          <WrappedInLoader data={brandContentStats} loader={brandContentLoader}>
            <div>
              <h5 className="body-regular-font nc50-fg margin-bottom-2x">
                Statistics for {followers_platform === "Youtube" ? "videos" : "posts"} from {creator?.username} about{" "}
                {for_brand}
              </h5>
              <div className="flex a-center">
                <StatBlock
                  titleFont="body-regular-font"
                  className="padding-right-4x"
                  align="left"
                  separator={false}
                  data={followers}
                  title={
                    <>
                      {followers_platform} <br /> {followers_platform === "Youtube" ? "subscribers" : "followers"}
                    </>
                  }
                ></StatBlock>
                <StatBlock
                  titleFont="body-regular-font"
                  className="padding-x-4x"
                  align="left"
                  separator={false}
                  data={brandContentStats?.total_posts}
                  title={
                    <>
                      Total <br /> {followers_platform === "Youtube" ? "videos" : "posts"}
                    </>
                  }
                ></StatBlock>
                <StatBlock
                  titleFont="body-regular-font"
                  className="padding-x-4x"
                  align="left"
                  separator={false}
                  data={brandContentStats?.total_views}
                  title={
                    <>
                      Total
                      <br /> Views
                    </>
                  }
                ></StatBlock>
                <StatBlock
                  titleFont="body-regular-font"
                  className="padding-x-4x"
                  align="left"
                  separator={false}
                  data={brandContentStats?.total_engagement}
                  title={
                    <>
                      Total Engagements
                      <br /> <span className="margin-top caption-regular">(likes + comments)</span>
                    </>
                  }
                ></StatBlock>
                <StatBlock
                  titleFont="body-regular-font"
                  className="padding-left-4x padding-right"
                  align="left"
                  separator={false}
                  data={brandContentStats?.engagement_rate}
                  title={
                    <>
                      Engagement rate
                      <br /> <span className="margin-top caption-regular">(total engagement / total views)</span>
                    </>
                  }
                  percentage
                ></StatBlock>
              </div>
            </div>
          </WrappedInLoader>
          {creator?.id ? (
            <BELocalContentList
              skipPlatform
              force_show
              skipStats
              section="creator_public_not_zelf_profile_content"
              customSectionDetailsOverwrite={{
                filter: filters
              }}
              className="margin-top-5x"
              title={`Posts about ${for_brand}`}
            />
          ) : null}
        </Card>
      </WrappedInLoader>
    </div>
  )
}

export { CreatorHome, NotSignedInZelfCreatorHome }
